import React from "react";
import {COUPON} from "../../../../consts/coupon";
import {CouponItemNotSelected} from "../../entities/CouponItemNotSelected";
import {useCoupon} from "../../../../hooks/useCoupon";

/**
 * @desc 회원 쿠폰 메뉴 내부 위젯 (쿠폰 선택 이전)
 */
export const NotSelectedCoupons = () => {
    const {selectCoupon} = useCoupon();

    return (
            <>
                <p className="coupon-desc-02">최대 3천원<br/><span className="em">할인 혜택</span> 받기</p>
                <p className="coupon-desc-03">이달의 쿠폰을 선택하세요!</p>
                <div className="coupon-list-box var-01">
                    <ul className="coupon-list">
                        <CouponItemNotSelected
                                coupon={COUPON.GS}
                                selectCoupon={selectCoupon}
                        />
                        <CouponItemNotSelected
                                coupon={COUPON.NAVER}
                                selectCoupon={selectCoupon}
                        />
                        {/*
                        <CouponItemNotSelected
                                coupon={COUPON.TEST}
                                selectCoupon={selectCoupon}
                        />
                        */}
                    </ul>
                </div>
            </>
    );
};