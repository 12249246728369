import axios from 'axios';
import { AXIOS_TYPE, AXIOS_EXCEPTION } from 'routes/common/axios/code/AxiosCode';

const axiosInstance = {
    instance: axios.create(),
    call: function (axiosType) {
        const _self = this;

        _self.init();
        
        switch (axiosType) {
            case AXIOS_TYPE.AUTH: {
                _self.instance.interceptors.request.use(_self.reqConfigAuth());
                _self.instance.interceptors.response.use(null, _self.resError());

                break;
            }
            case AXIOS_TYPE.BASIC: {
                _self.instance.interceptors.response.use(null, _self.resError());

                break;
            }
        }

        return _self.instance;
    },
    init: function () {
        const _self = this;

        _self.instance.interceptors.request.handlers  = [];
        _self.instance.interceptors.response.handlers = [];
    },
    reqConfig: function () {
        return (config) => {
            return config;
        }
    },
    reqConfigAuth: function () {
        return (config) => {
            if (localStorage.getItem('jwtToken')) {
                config.headers['Authorization'] = ('Bearer ' + localStorage.getItem('jwtToken'));
            }
            
            return config;
        }
    },
    reqError: function () {
        return (error) => {
            return Promise.reject(error);
        }
    },
    resResponse: function () {
        return (response) => {
            return response;
        }
    },
    resError: function () {
        return (error) => {
            const { status, data } = error.response;
                
                if (data) {
                    switch (status) {
                        case 400: case 404: case 500: case 504: {
                            if (data) {
                                if (data.message) {
                                    alert(data.message);
                                }
                            } else {
                                AXIOS_EXCEPTION.filter((except) => {
                                    if (except.code === status) {
                                        alert(except.msg);
                                    }
                                });
                            }

                            break;
                        }
                    }
                } else {
                    AXIOS_EXCEPTION.filter((except) => {
                        if (except.code === status) {
                            alert(except.msg);
                        }
                    });
                }
        
                return Promise.reject(error);
        }
    }
};

export const basic = axiosInstance.call(AXIOS_TYPE.BASIC);
export const auth = axiosInstance.call(AXIOS_TYPE.AUTH);