import axiosCall from 'routes/common/axios/AxiosCall';

const getMap = async (obj) => {
    const defaultObj = { config: {} };

    try {
        const { status, data } = await axiosCall.getMap(((obj || {}).config || defaultObj.config));

        return Promise.resolve({ status, data, isSuccess: true });
    } catch (err) {
        const { status, data = null } = err.response;

        return Promise.reject({ status, data, isSuccess: false });
    }
}

const findAptList = async (obj) => {
    const defaultObj = { config: {} };

    try {
        const { status, data } = await axiosCall.findAptList(((obj || {}).config || defaultObj.config));

        return Promise.resolve({ status, data, isSuccess: true });
    } catch (err) {
        const { status, data = null } = err.response;

        return Promise.reject({ status, data, isSuccess: false });
    }
}

const findMapApplyList = async (obj) => {
    const defaultObj = { config: {} };

    try {
        const { status, data } = await axiosCall.findMapApplyList(((obj || {}).config || defaultObj.config));

        return Promise.resolve({ status, data, isSuccess: true });
    } catch (err) {
        const { status, data = null } = err.response;

        return Promise.reject({ status, data, isSuccess: false });
    }
}

const findApplyList = async (obj) => {
    const defaultObj = { config: {} };

    try {
        const { status, data } = await axiosCall.findApplyList(((obj || {}).config || defaultObj.config));

        return Promise.resolve({ status, data, isSuccess: true });
    } catch (err) {
        const { status, data = null } = err.response;

        return Promise.reject({ status, data, isSuccess: false });
    }
}

const findMyLocApplyList = async (obj) => {
    const defaultObj = { config: {} };

    try {
        const { status, data } = await axiosCall.findMyLocApplyList(((obj || {}).config || defaultObj.config));

        return Promise.resolve({ status, data, isSuccess: true });
    } catch (err) {
        const { status, data = null } = err.response;

        return Promise.reject({ status, data, isSuccess: false });
    }
}

const findAvgApplyScoreList = async (obj) => {
    const defaultObj = { config: {} };

    try {
        const { status, data } = await axiosCall.findAvgApplyScoreList(((obj || {}).config || defaultObj.config));

        return Promise.resolve({ status, data, isSuccess: true });
    } catch (err) {
        const { status, data = null } = err.response;

        return Promise.reject({ status, data, isSuccess: false });
    }
}

const findMarginPriceList = async (obj) => {
    const defaultObj = { config: {} };

    try {
        const { status, data } = await axiosCall.findMarginPriceList(((obj || {}).config || defaultObj.config));

        return Promise.resolve({ status, data, isSuccess: true });
    } catch (err) {
        const { status, data = null } = err.response;

        return Promise.reject({ status, data, isSuccess: false });
    }
}

const findAptTrxMonthlyGroup = async (obj) => {
    const defaultObj = { config: {} };

    try {
        const { status, data } = await axiosCall.findAptTrxMonthlyGroup(((obj || {}).config || defaultObj.config));

        return Promise.resolve({ status, data, isSuccess: true });
    } catch (err) {
        const { status, data = null } = err.response;

        return Promise.reject({ status, data, isSuccess: false });
    }
}

const applyDtl = async (obj) => {
    const defaultObj = { config: {} };

    try {
        const { status, data } = await axiosCall.applyDtl(((obj || {}).config || defaultObj.config));

        return Promise.resolve({ status, data, isSuccess: true });
    } catch (err) {
        const { status, data = null } = err.response;

        return Promise.reject({ status, data, isSuccess: false });
    }
}

const aptDtl = async (obj) => {
    const defaultObj = { config: {} };

    try {
        const { status, data } = await axiosCall.aptDtl(((obj || {}).config || defaultObj.config));

        return Promise.resolve({ status, data, isSuccess: true });
    } catch (err) {
        const { status, data = null } = err.response;

        return Promise.reject({ status, data, isSuccess: false });
    }
}

const aptAreaDtl = async (obj) => {
    const defaultObj = { config: {} };

    try {
        const { status, data } = await axiosCall.aptAreaDtl(((obj || {}).config || defaultObj.config));

        return Promise.resolve({ status, data, isSuccess: true });
    } catch (err) {
        const { status, data = null } = err.response;

        return Promise.reject({ status, data, isSuccess: false });
    }
}

const aptDtlGraphList = async (obj) => {
    const defaultObj = { config: {} };

    try {
        const { status, data } = await axiosCall.aptDtlGraphList(((obj || {}).config || defaultObj.config));

        return Promise.resolve({ status, data, isSuccess: true });
    } catch (err) {
        const { status, data = null } = err.response;

        return Promise.reject({ status, data, isSuccess: false });
    }
}

const aroundLocInfoList = async (obj) => {
    const defaultObj = { config: {} };

    try {
        const { status, data } = await axiosCall.aroundLocInfoList(((obj || {}).config || defaultObj.config));

        return Promise.resolve({ status, data, isSuccess: true });
    } catch (err) {
        const { status, data = null } = err.response;

        return Promise.reject({ status, data, isSuccess: false });
    }
}

const login = async (obj) => {
    const defaultObj = { config: {}, data: {} };

    try {
        const { status, data } = await axiosCall.login(((obj || {}).data || defaultObj.data), ((obj || {}).config || defaultObj.config));

        return Promise.resolve({ status, data, isSuccess: true });
    } catch (err) {
        const { status, data = null } = err.response;

        return Promise.reject({ status, data, isSuccess: false });
    }
}

const expire = async (obj) => {
    const defaultObj = { config: {}, data: {} };

    try {
        const { status, data } = await axiosCall.expire(((obj || {}).data || defaultObj.data), ((obj || {}).config || defaultObj.config));

        return Promise.resolve({ status, data, isSuccess: true });
    } catch (err) {
        const { status, data = null } = err.response;

        return Promise.reject({ status, data, isSuccess: false });
    }
}

export default {
    getMap,
    findAptList,
    findMapApplyList,
    findApplyList,
    findMyLocApplyList,
    findAvgApplyScoreList,
    findMarginPriceList,
    findAptTrxMonthlyGroup,
    applyDtl,
    aptDtl,
    aptAreaDtl,
    aptDtlGraphList,
    aroundLocInfoList,
    login,
    expire,
}