import Moment from 'moment';

export function ApplyOverlay (data) {
  this.type     = 'apply';
  this.position = new kakao.maps.LatLng(data.lat, data.lng);    // eslint-disable-line

  let node = this.node = document.createElement('div');

  node.className = 'map_marker';
  node.id        = `markerApply_${data.applyId}`;

  let flag =
      (data.notiYn === 'N' ?
          'notiNFlag'
          :
          (
              this.dateDiffMsg(data.notiDate) != '완료' ? 'notiDateFlag' :
                  (
                      data.spLocalDate != '' && this.dateDiffMsg(data.spLocalDate) != '완료' ? 'spLocalDateFlag' :
                          (
                              this.dateDiffMsg(data.fstLocalDate) != '완료' ? 'fstLocalDateFlag' :
                                  (
                                      this.dateDiffMsg(data.pbshDate) != '완료' ? 'pbshDateFlag' :
                                          'completeFlag'
                                  )
                          )
                  )
          ));

  let html;

  if (data.isMap) {
    let dDay     = '';
    let flagDesc = '';

    node.id = node.id.concat('_', flag);

    switch (flag) {
      case 'notiNFlag': {
        dDay     = this.dateFormatMsg(data.notiDate);
        flagDesc = '분양예정';

        break;
      }
      case 'completeFlag': {
        dDay     = this.dateFormatMsg(data.pbshDate);
        flagDesc = '분양완료';

        break;
      }
      case 'notiDateFlag': {
        dDay     = this.dateDiffMsg(data.notiDate);
        flagDesc = '모집공고';

        break;
      }
      case 'spLocalDateFlag': {
        dDay     = this.dateDiffMsg(data.spLocalDate);
        flagDesc = '특별공급';

        break;
      }
      case 'fstLocalDateFlag': {
        dDay     = this.dateDiffMsg(data.fstLocalDate);
        flagDesc = '일반공급';

        break;
      }
      case 'pbshDateFlag': {
        dDay     = this.dateDiffMsg(data.pbshDate);
        flagDesc = '당첨자발표';

        break;
      }
    }

    switch (flag) {
      case 'notiNFlag': case 'completeFlag': {
        html = `<div class="marker_inner type_subs">
                    <input type="hidden" id="latApply_${data.applyId}" value="${data.lat}" >
                    <input type="hidden" id="lngApply_${data.applyId}" value="${data.lng}" >
                    <div class="data">
                      <p class="city">${flagDesc}</p>
                      <p class="price">${dDay}</p>
                      <p class="day">${data.aptName}</p>
                    </div>
                    <div class="marker_shadow"></div>
                  </div>`;

        break;
      }
      case 'notiDateFlag': case 'spLocalDateFlag': case 'fstLocalDateFlag': case 'pbshDateFlag': {
        html = `<div class="marker_inner type_subs">
                    <input type="hidden" id="latApply_${data.applyId}" value="${data.lat}" >
                    <input type="hidden" id="lngApply_${data.applyId}" value="${data.lng}" >
                    <div class="data">
                      <p class="city">${flagDesc}</p>
                      <p class="price">${dDay}</p>
                      <p class="day">${data.aptName}</p>
                    </div>
                    <div class="marker_shadow"></div>
                  </div>`;

        break;
      }
    }
  } else {
    if (flag === 'notiNFlag' || flag === 'completeFlag') {
      html = `<div class="marker_inner type_schedule">
                    <input type="hidden" id="latApply_${data.applyId}" value="${data.lat}" >
                    <input type="hidden" id="lngApply_${data.applyId}" value="${data.lng}" >
                    <div class="data">
                      <p class="schedule">
                        ${
          flag === 'notiNFlag' ? '분양예정' :
              flag === 'completeFlag' ? '분양완료' : ''
      }
                      </p>
                      <p class="day">
                      ${
          flag === 'notiNFlag' ? this.dateFormatMsg(data.notiDate) :
              flag === 'completeFlag' ? this.dateFormatMsg(data.pbshDate) : ''
      }
                      </p>
                    </div>
                    <div class="marker_shadow"></div>
                  </div>`;

    }
    else if (flag === 'notiDateFlag' || flag === 'spLocalDateFlag' || flag === 'fstLocalDateFlag' || flag === 'pbshDateFlag') {

      html = `<div class="marker_inner type_sm">
                    <input type="hidden" id="latApply_${data.applyId}" value="${data.lat}" >
                    <input type="hidden" id="lngApply_${data.applyId}" value="${data.lng}" >
                    <div class="data">
                      <p class="city">
                        ${
          flag === 'notiDateFlag' ? '모집공고' :
              flag === 'spLocalDateFlag' ? '특별공급' :
                  flag === 'fstLocalDateFlag' ? '일반공급' :
                      flag === 'pbshDateFlag' ? '당첨자발표' : ''
      }
                      </p>
                      <p class="price">
                        ${
          flag === 'notiDateFlag' ? this.dateDiffMsg(data.notiDate) :
              flag === 'spLocalDateFlag' ? this.dateDiffMsg(data.spLocalDate) :
                  flag === 'fstLocalDateFlag' ? this.dateDiffMsg(data.fstLocalDate) :
                      flag === 'pbshDateFlag' ? this.dateDiffMsg(data.pbshDate) : ''
      }
                      </p>
                      <p class="day">
                      ${
          flag === 'notiDateFlag' ? this.dateFormatMsg(data.notiDate) :
              flag === 'spLocalDateFlag' ? this.dateFormatMsg(data.spLocalDate) :
                  flag === 'fstLocalDateFlag' ? this.dateFormatMsg(data.fstLocalDate) :
                      flag === 'pbshDateFlag' ? this.dateFormatMsg(data.pbshDate) : ''
      }
                      </p>
                    </div>
                    <div class="marker_shadow"></div>
                  </div>`;
    }
  }

  node.innerHTML = html;
}

if (window.kakao) {
  ApplyOverlay.prototype = new window.kakao.maps.AbstractOverlay;

  ApplyOverlay.prototype.onAdd = function () {
    var panel = this.getPanels().overlayLayer;

    panel.appendChild(this.node);
  }

  ApplyOverlay.prototype.onRemove = function () {
    this.node.parentNode.removeChild(this.node);
  };

  ApplyOverlay.prototype.draw = function () {
    // 화면 좌표와 지도의 좌표를 매핑시켜주는 projection객체
    var projection = this.getProjection();

    // overlayLayer는 지도와 함께 움직이는 layer이므로
    // 지도 내부의 위치를 반영해주는 pointFromCoords를 사용합니다.
    var point = projection.pointFromCoords(this.position);

    // 내부 엘리먼트의 크기를 얻어서
    var width  = this.node.offsetWidth;
    var height = this.node.offsetHeight;

    // 해당 위치의 정중앙에 위치하도록 top, left를 지정합니다.
    // marker 특성 고려 말풍선꼭지부분이 중앙에 오도록 재조정 (left+40, top-40)
    this.node.style.left = (point.x - width / 2) + 40 + "px";
    this.node.style.top  = (point.y - height / 2) - 40 + "px";
  };

// 좌표를 반환하는 메소드
  ApplyOverlay.prototype.getPosition = function () {
    return this.position;
  };

  ApplyOverlay.prototype.dateFormatMsg = function (x) {
    if (x === null || x === undefined || x === NaN) return '';
    else {
      return Moment(x).format('YY. MM. DD');
    }
  }

  ApplyOverlay.prototype.dateDiffMsg = function (x) {
    if (x === null || x === undefined || x === NaN) return;
    else {
      const currDate = Moment().tz("Asia/Seoul");
      currDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

      const targetDateStr = `${x.substr(0, 4)}-${x.substr(4, 2)}-${x.substr(6, 2)}`;
      const targetDate = Moment(new Date(targetDateStr)).tz("Asia/Seoul");
      targetDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

      if (currDate.diff(targetDate, 'days') > 0) {
        return '완료';
      }
      else if (currDate.diff(targetDate, 'days') === 0) {
        return 'D - DAY';
      }
      else {
        return 'D ' + (currDate.diff(targetDate, 'days')).toString().replace('-', '- ');
      }
    }
  }
}

// export class ApplyOverlay extends kakao.maps.AbstractOverlay {    // eslint-disable-line
//
//   constructor(data) {
//     super();
//     this.type = 'apply';
//     this.position = new kakao.maps.LatLng(data.lat, data.lng);    // eslint-disable-line
//     let node = this.node = document.createElement('div');
//     node.className = 'map_marker';
//     node.id = 'markerApply_' + data.applyId;
//
//     let flag =
//       (data.notiYn === 'N' ?
//         'notiNFlag'
//         :
//         (
//           this.dateDiffMsg(data.notiDate) != '완료' ? 'notiDateFlag' :
//             (
//               data.spLocalDate != '' && this.dateDiffMsg(data.spLocalDate) != '완료' ? 'spLocalDateFlag' :
//                 (
//                   this.dateDiffMsg(data.fstLocalDate) != '완료' ? 'fstLocalDateFlag' :
//                     (
//                       this.dateDiffMsg(data.pbshDate) != '완료' ? 'pbshDateFlag' :
//                         'completeFlag'
//                     )
//                 )
//             )
//         ));
//
//     let html;
//
//     if (data.isMap) {
//       let dDay     = '';
//       let flagDesc = '';
//
//       node.id = node.id.concat('_', flag);
//
//       switch (flag) {
//         case 'notiNFlag': {
//           dDay     = this.dateFormatMsg(data.notiDate);
//           flagDesc = '분양예정';
//
//           break;
//         }
//         case 'completeFlag': {
//           dDay     = this.dateFormatMsg(data.pbshDate);
//           flagDesc = '분양완료';
//
//           break;
//         }
//         case 'notiDateFlag': {
//           dDay     = this.dateDiffMsg(data.notiDate);
//           flagDesc = '모집공고';
//
//           break;
//         }
//         case 'spLocalDateFlag': {
//           dDay     = this.dateDiffMsg(data.spLocalDate);
//           flagDesc = '특별공급';
//
//           break;
//         }
//         case 'fstLocalDateFlag': {
//           dDay     = this.dateDiffMsg(data.fstLocalDate);
//           flagDesc = '일반공급';
//
//           break;
//         }
//         case 'pbshDateFlag': {
//           dDay     = this.dateDiffMsg(data.pbshDate);
//           flagDesc = '당첨자발표';
//
//           break;
//         }
//       }
//
//       switch (flag) {
//         case 'notiNFlag': case 'completeFlag': {
//           html = `<div class="marker_inner type_subs">
//                     <input type="hidden" id="latApply_${data.applyId}" value="${data.lat}" >
//                     <input type="hidden" id="lngApply_${data.applyId}" value="${data.lng}" >
//                     <div class="data">
//                       <p class="city">${flagDesc}</p>
//                       <p class="price">${dDay}</p>
//                       <p class="day">${data.aptName}</p>
//                     </div>
//                     <div class="marker_shadow"></div>
//                   </div>`;
//
//           break;
//         }
//         case 'notiDateFlag': case 'spLocalDateFlag': case 'fstLocalDateFlag': case 'pbshDateFlag': {
//           html = `<div class="marker_inner type_subs">
//                     <input type="hidden" id="latApply_${data.applyId}" value="${data.lat}" >
//                     <input type="hidden" id="lngApply_${data.applyId}" value="${data.lng}" >
//                     <div class="data">
//                       <p class="city">${flagDesc}</p>
//                       <p class="price">${dDay}</p>
//                       <p class="day">${data.aptName}</p>
//                     </div>
//                     <div class="marker_shadow"></div>
//                   </div>`;
//
//           break;
//         }
//       }
//     } else {
//       if (flag === 'notiNFlag' || flag === 'completeFlag') {
//         html = `<div class="marker_inner type_schedule">
//                     <input type="hidden" id="latApply_${data.applyId}" value="${data.lat}" >
//                     <input type="hidden" id="lngApply_${data.applyId}" value="${data.lng}" >
//                     <div class="data">
//                       <p class="schedule">
//                         ${
//           flag === 'notiNFlag' ? '분양예정' :
//             flag === 'completeFlag' ? '분양완료' : ''
//           }
//                       </p>
//                       <p class="day">
//                       ${
//           flag === 'notiNFlag' ? this.dateFormatMsg(data.notiDate) :
//             flag === 'completeFlag' ? this.dateFormatMsg(data.pbshDate) : ''
//           }
//                       </p>
//                     </div>
//                     <div class="marker_shadow"></div>
//                   </div>`;
//
//       }
//       else if (flag === 'notiDateFlag' || flag === 'spLocalDateFlag' || flag === 'fstLocalDateFlag' || flag === 'pbshDateFlag') {
//
//         html = `<div class="marker_inner type_sm">
//                     <input type="hidden" id="latApply_${data.applyId}" value="${data.lat}" >
//                     <input type="hidden" id="lngApply_${data.applyId}" value="${data.lng}" >
//                     <div class="data">
//                       <p class="city">
//                         ${
//           flag === 'notiDateFlag' ? '모집공고' :
//             flag === 'spLocalDateFlag' ? '특별공급' :
//               flag === 'fstLocalDateFlag' ? '일반공급' :
//                 flag === 'pbshDateFlag' ? '당첨자발표' : ''
//           }
//                       </p>
//                       <p class="price">
//                         ${
//           flag === 'notiDateFlag' ? this.dateDiffMsg(data.notiDate) :
//             flag === 'spLocalDateFlag' ? this.dateDiffMsg(data.spLocalDate) :
//               flag === 'fstLocalDateFlag' ? this.dateDiffMsg(data.fstLocalDate) :
//                 flag === 'pbshDateFlag' ? this.dateDiffMsg(data.pbshDate) : ''
//           }
//                       </p>
//                       <p class="day">
//                       ${
//           flag === 'notiDateFlag' ? this.dateFormatMsg(data.notiDate) :
//             flag === 'spLocalDateFlag' ? this.dateFormatMsg(data.spLocalDate) :
//               flag === 'fstLocalDateFlag' ? this.dateFormatMsg(data.fstLocalDate) :
//                 flag === 'pbshDateFlag' ? this.dateFormatMsg(data.pbshDate) : ''
//           }
//                       </p>
//                     </div>
//                     <div class="marker_shadow"></div>
//                   </div>`;
//       }
//     }
//
//     node.innerHTML = html;
//   }
//
//   onAdd() {
//     var panel = super.getPanels().overlayLayer;
//     panel.appendChild(this.node);
//   }
//
//   onRemove() {
//     this.node.parentNode.removeChild(this.node);
//   };
//
//   draw() {
//     // 화면 좌표와 지도의 좌표를 매핑시켜주는 projection객체
//     var projection = super.getProjection();
//
//     // overlayLayer는 지도와 함께 움직이는 layer이므로
//     // 지도 내부의 위치를 반영해주는 pointFromCoords를 사용합니다.
//     var point = projection.pointFromCoords(this.position);
//
//     // 내부 엘리먼트의 크기를 얻어서
//     var width = this.node.offsetWidth;
//     var height = this.node.offsetHeight;
//
//     // 해당 위치의 정중앙에 위치하도록 top, left를 지정합니다.
//     // marker 특성 고려 말풍선꼭지부분이 중앙에 오도록 재조정 (left+40, top-40)
//     this.node.style.left = (point.x - width / 2) + 40 + "px";
//     this.node.style.top = (point.y - height / 2) - 40 + "px";
//   };
//
//   // 좌표를 반환하는 메소드
//   getPosition() {
//     return this.position;
//   };
//
//   dateFormatMsg = (x) => {
//     if (x === null || x === undefined || x === NaN) return '';
//     else {
//       return Moment(x).format('YY. MM. DD');
//     }
//   }
//
//   dateDiffMsg = (x) => {
//     if (x === null || x === undefined || x === NaN) return;
//     else {
//       const currDate = Moment().tz("Asia/Seoul");
//       currDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
//
//       const targetDateStr = `${x.substr(0, 4)}-${x.substr(4, 2)}-${x.substr(6, 2)}`;
//       const targetDate = Moment(new Date(targetDateStr)).tz("Asia/Seoul");
//       targetDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
//
//       if (currDate.diff(targetDate, 'days') > 0) {
//         return '완료';
//       }
//       else if (currDate.diff(targetDate, 'days') === 0) {
//         return 'D - DAY';
//       }
//       else {
//         return 'D ' + (currDate.diff(targetDate, 'days')).toString().replace('-', '- ');
//       }
//     }
//   }
// }