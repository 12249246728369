import React, {useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'; // url 구분 작업
import {useStateWithCallbackLazy} from 'use-state-with-callback';

import {MyOverlay} from 'routes/overlay/MyOverlay';
import {AroundOverlay} from 'routes/overlay/AroundOverlay';

import SubFooter from 'routes/SubFooter';

import axios from 'axios';
import {orderBy} from 'lodash';
import $ from 'jquery';
import {dateDiffMsg, dateFormatMsg, dateFormatMsg2, numberWithCommas, numberWithCounts} from 'routes/common/StringUtil';
import {
    popupClose,
    popupOpen,
    uiAccoScrollFix,
    uiChartTap,
    uiFilterTabSwipe,
    uiFixValue,
    uiInputDel,
    uiIpFocus,
    uiMainSwitch,
    uiSelFocus,
    uiToggle,
    uiTopGo
} from 'routes/common/Design.js'

import {getLcParamItem, getLcUrlItem, setLcParamItem, setLcUrlItem} from 'routes/common/ParamStorage';
import {callAppInterface} from 'routes/common/AppInterface';

import {osCheck} from 'routes/common/OsUtil';

import {addLcApplyFavItem, clearLcApplyFavItem, getLcApplyFavList} from 'routes/common/AptFavUtil';
import axiosRequest from "../common/axios/services/AxiosRequest";
import {clearRecentItem} from "../common/Search";

let map = {};
let overlayList = [];
let aroundOverlayList = [];

//// 컴포넌트 사용처 : 청약 정보
const ApplyDtl = (props) => {

  const [applyDtl, setApplyDtl] = useStateWithCallbackLazy([]);

  const lcUrlMap = getLcUrlItem();
  const lcParamMap = getLcParamItem();

  // url 구분 작업
  const history = useHistory();
  const location = useLocation();

  // useEffect(() => {
  //   // [SOM.220503] 청약정보리스트 상세 페이지에서 안드로이드 뒤로가기시 청약정보리스트(메인)으로 이동 (기존-아파트정보)
  //   window.goback = backToApplyList;
  //   window.checkMainPage = () => { return false; }
  //   return () => {
  //     window.checkMainPage = () => { return true; }
  //   }
  // });

  useEffect(() => {
    popupOpen('saledetail', 'mwf');
    uiFixValue.init();
    uiFilterTabSwipe.init();
    uiSelFocus.init();
    uiMainSwitch.init();
    uiChartTap.init();
    uiToggle.init();
    uiTopGo.init();

    uiIpFocus.init();
    uiInputDel.init();

    //uiAccoScrollFix.init();

    // [SOM.240219] 청약상세페이지 > AOS 뒤로가기 시, 메인으로 이동하는 이슈로 timer 설정
    setTimeout(() => {
      window.goback = ((props.aptMainEntry) ? backToAptMap : backToApplyList);     
    }, 200);

    window.checkMainPage = () => { return false; }
    return () => {
      window.checkMainPage = () => { return true; }
    }

  }, []);

  useEffect(() => {
    setApplyDtl([]);
    clearOverlay();
    clearAroundOverlay();

    let lat = 37.57100;
    let lng = 126.97696;

    let mapContainer = document.getElementById('mapviewDtl'); // 지도를 표시할 div 

    let mapOption = {
      center: new kakao.maps.LatLng(lat, lng), // eslint-disable-line
      level: 5
    };

    map = new kakao.maps.Map(mapContainer, mapOption);  // eslint-disable-line
    map.setMinLevel(2);
    map.setMaxLevel(11);

    kakao.maps.event.addListener(map, 'dragend', () => { }); // eslint-disable-line
    kakao.maps.event.addListener(map, 'zoom_changed', () => { });  // eslint-disable-line

    //if (aptDtl != null && aptDtl.applyId != '') {

    //map.relayout();
    //onPanTo(aptDtl.lat, aptDtl.lng);
    //}

    //setTimeout(() => {
    fetchApplyDtl(lcParamMap.applyId);
    //}, 200);

  }, [lcParamMap.applyId]);

  const fetchApplyDtl = async (paramApplyId) => {
    if (paramApplyId) {
      try {
        const { data } = await axiosRequest.applyDtl({ config: { params: { applyId: paramApplyId } } });

        if (data) {
          setApplyDtl(data.response.applyDtl);
          renderMyMarker(data.response.applyDtl);

          onPanTo(data.response.applyDtl.lat, data.response.applyDtl.lng);
          renderingAroundDataIcons(data.response.applyDtl);

          uiAccoScrollFix.init();
          uiToggle.init();
        }
      } catch (err) {
        console.log(err);

        alert('잘못된 접근 입니다. 다시 시도해 주세요.');

        setLcUrlItem('applyDtl', 'aptMap');
        clearRecentItem('applyMap');

        window.location.href = '/';
      }

      // axios
      //     .get(`apt/apply/dtl?applyId=${paramApplyId}`)
      //     .then(res => {
      //       setApplyDtl(res.data.response.applyDtl);
      //       renderMyMarker(res.data.response.applyDtl);
      //
      //       onPanTo(res.data.response.applyDtl.lat, res.data.response.applyDtl.lng);
      //       renderingAroundDataIcons(res.data.response.applyDtl);
      //
      //       uiAccoScrollFix.init();
      //       uiToggle.init();
      //       //   this.props.onRenderMarker({ data: res.data.response.applyDtl });
      //       //   this.props.onPanTo({ lat: res.data.response.applyDtl.lat, lng: res.data.response.applyDtl.lng });
      //
      //       //   setTimeout(() => {
      //       //     $('div.sub_header > h3').attr('data-tit', '최근 청약정보');
      //       //     $('div.sub_header > h3').text('최근 청약정보');
      //       //     uiAptHeaderCustom.init();
      //       //   }, 500);
      //     });
    } else {
      alert('잘못된 접근 입니다. 다시 시도해 주세요.');

      setLcUrlItem('applyDtl', 'aptMap');
      clearRecentItem('applyMap');

      window.location.href = '/';
    }
  }

  const moreView = (e) => {
    $(e.target).parent().toggleClass('show');
    $(e.target).parent().find('li').eq(2).nextAll().toggleClass('hide');
  }

  const clearOverlay = () => {
    overlayList.forEach(ov => ov.setMap(null));
    overlayList = [];
  }

  const clearAroundOverlay = () => {
    aroundOverlayList.forEach(ov => ov.setMap(null));
    aroundOverlayList = [];
  }

  const renderMyMarker = (data) => {
    overlayList.forEach(ov => ov.setMap(null));
    overlayList = [];

    let overlay = new MyOverlay(data);
    overlay.setMap(map);
    overlayList.push(overlay);
  }

  const renderingAroundDataIcons = (data) => {
    aroundOverlayList.forEach(ov => ov.setMap(null));
    aroundOverlayList = [];

    const dtl = data;

    dtl.schoolList.forEach(v => {
      v.type = 'school';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.departList.forEach(v => {
      v.type = 'ico_mart';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.stationList.forEach(v => {
      v.type = 'ico_subway';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.hospitalList.forEach(v => {
      v.type = 'ico_hospital';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.parkList.forEach(v => {
      v.type = 'ico_park02';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });
  }

  const onPanTo = (paramLat, paramLng) => {
    let moveLatLng = new kakao.maps.LatLng(paramLat, paramLng); // eslint-disable-line
    map.panTo(moveLatLng);
  }



  //--'open' : 현 화면이 다음 화면으로 변경되는 action
  const openApplyDtlMap = () => {
    setLcUrlItem('applyDtl', 'applyDtlMap');
    setLcParamItem({ 'applyId': applyDtl.applyId, 'applyDtl': applyDtl });
    props.onApplyPopupType('P_B');
  }

  const openApplyPredict = () => {
    let os = osCheck() == 'Android' ? "A" : "I";
    axios.get('apt/authorized/check',
      { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }, timeout: 1000 }).then(res => {
        if (res.data.response.isValid) {
          setLcUrlItem('applyDtl', 'applyPredict');
          setLcParamItem({ 'applyId': applyDtl.applyId, 'applyDtl': applyDtl });

          // url 구분 작업
          history.push({
            pathname: location.pathname,
            search: `PER-1000#`
          })

          props.onApplyMenuType('B');
          props.onApplyPopupType('');
          //popupClose('saledetail', 'mwf');
          setTimeout(() => {
            $('.bg_body_full').remove();
            uiFixValue.init();
          }, 100);
        }
        else {
          window.location.href = '/login';
          //const param = { type: 'I', url: '/login' }
          //callAppInterface('openWebPage', JSON.stringify(param), result => {
          //alert(result);
          //});
        }
      }).catch(err => {
        if (localStorage.getItem('isAutoLogin') == 'true') {
          // 자동로그인 적용시 사용
          if (err.response.status === 401 || err.response.status === 403) {
            axios.post('apt/authorized/reIssue',
              { 'ref': localStorage.getItem('ref'), 'moLogin': true },
              { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } }).then(res => {
                if (res.status === 200) {
                  localStorage.setItem('jwtToken', res.headers.authorization);

                  callAppInterface("tokenToApp", JSON.stringify({
                    token: res.headers.authorization,
                    ref: localStorage.getItem('ref'),
                    isAuto: true,
                  }), result => {

                  });

                  setLcUrlItem('applyDtl', 'applyPredict');
                  setLcParamItem({ 'applyId': applyDtl.applyId, 'applyDtl': applyDtl });

                  // url 구분 작업
                  history.push({
                    pathname: location.pathname,
                    search: `PER-1000#`
                  })

                  props.onApplyMenuType('B');
                  props.onApplyPopupType('');
                  props.onApplyPopupType('');
                  //popupClose('saledetail', 'mwf');
                  setTimeout(() => {
                    $('.bg_body_full').remove();
                    uiFixValue.init();
                  }, 100);
                }
                else {
                  window.location.href = '/login';
                  //const param = { type: 'I', url: '/login' }
                  //callAppInterface('openWebPage', JSON.stringify(param), result => {
                  //alert(result);
                  //});
                }
              }).catch(err => {
                window.location.href = '/login';
                //const param = { type: 'I', url: '/login' }
                //callAppInterface('openWebPage', JSON.stringify(param), result => {
                //alert(result);
                //});
              });
          }
        }
        else {
          window.location.href = '/login';
          //const param = { type: 'I', url: '/login' }
          //callAppInterface('openWebPage', JSON.stringify(param), result => {
          //alert(result);
          //});
        }
      });
  }

  const openApplyCalc = () => {
    setLcUrlItem('applyDtl', 'applyCalc');
    setLcParamItem({ 'applyId': applyDtl.applyId, 'applyDtl': applyDtl });
    popupClose('saledetail', 'mwf');
    setTimeout(() => {
      $('.bg_body_full').remove();
      uiFixValue.init();
    }, 100);

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `PAL-1000#`
    })

    props.onApplyMenuType('C');
    props.onApplyPopupType('');
  }

  const openApplyPay = () => {
    let os = osCheck() == 'Android' ? "A" : "I";
    axios.get('apt/authorized/check',
      { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }, timeout: 1000 }).then(res => {
        if (res.data.response.isValid) {
          setLcUrlItem('applyDtl', 'applyPayList');
          setLcParamItem({ 'applyId': applyDtl.applyId, 'applyDtl': applyDtl });
          popupClose('saledetail', 'mwf');
          setTimeout(() => {
            $('.bg_body_full').remove();
            uiFixValue.init();
          }, 100);

          // url 구분 작업
          history.push({
            pathname: location.pathname,
            search: `CAL-1000#`
          })

          props.onApplyMenuType('D');
          props.onApplyPopupType('');
        }
        else {
          window.location.href = '/login';
          //const param = { type: 'I', url: '/login' }
          //callAppInterface('openWebPage', JSON.stringify(param), result => {
          //alert(result);
          //});
        }
      }).catch(err => {
        if (localStorage.getItem('isAutoLogin') == 'true') {
          // 자동로그인 적용시 사용
          if (err.response.status === 401 || err.response.status === 403) {
            axios.post('apt/authorized/reIssue',
              { 'ref': localStorage.getItem('ref'), 'moLogin': true },
              { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } }).then(res => {
                if (res.status === 200) {
                  localStorage.setItem('jwtToken', res.headers.authorization);

                  callAppInterface("tokenToApp", JSON.stringify({
                    token: res.headers.authorization,
                    ref: localStorage.getItem('ref'),
                    isAuto: true,
                  }), result => {

                  });

                  setLcUrlItem('applyDtl', 'applyPayList');
                  setLcParamItem({ 'applyId': applyDtl.applyId, 'applyDtl': applyDtl });
                  popupClose('saledetail', 'mwf');
                  setTimeout(() => {
                    $('.bg_body_full').remove();
                    uiFixValue.init();
                  }, 100);

                  // url 구분 작업
                  history.push({
                    pathname: location.pathname,
                    search: `CAL-1000#`
                  })

                  props.onApplyMenuType('D');
                  props.onApplyPopupType('');
                }
                else {
                  window.location.href = '/login';
                  //const param = { type: 'I', url: '/login' }
                  //callAppInterface('openWebPage', JSON.stringify(param), result => {
                  //alert(result);
                  //});
                }
              }).catch(err => {
                window.location.href = '/login';
                //const param = { type: 'I', url: '/login' }
                //callAppInterface('openWebPage', JSON.stringify(param), result => {
                //alert(result);
                //});
              });
          }
        }
        else {
          window.location.href = '/login';
          //const param = { type: 'I', url: '/login' }
          //callAppInterface('openWebPage', JSON.stringify(param), result => {
          //alert(result);
          //});
        }
      });
  }

  const handleFavCheck = (e) => {
    if ($(e.target).hasClass('active')) {
      $(e.target).removeClass('active');
      clearLcApplyFavItem({ applyId: lcParamMap.applyId })
    }
    else {
      $(e.target).addClass('active');
      addLcApplyFavItem({ applyId: lcParamMap.applyId, favTime: new Date() });
    }
  }


  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  const backToApplyList = () => {
    setLcUrlItem('applyDtl', 'applyList');
    popupClose('saledetail', 'mwf');
    setTimeout(() => {
      $('.bg_body_full').remove();
      uiFixValue.init();
    }, 100);

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?APY-1000#`
    });

    props.onApplyMenuType('A');
    props.onApplyPopupType('');
  }

  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  const backToAptMap = () => {
    $('.map_wrap').show();
    setLcUrlItem('applyDtl', 'aptMap');
    popupClose('aptdetail', 'mwf');
    props.onApplyPopupType('');

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: ``
    });
  }

  return (
    <>
      <div id="mw-saledetail" className="ly_pop ly_apt_detail full">
        <div className="wrap">
          <span className="bg_body_full"></span>
          <div className="content">
            <header className="header">
              <button type="button" onClick={() => ((props.aptMainEntry) ? backToAptMap() : backToApplyList())} className="btn_home" title="홈으로 바로가기"><i></i></button>
              <button type="button" onClick={() => ((props.aptMainEntry) ? backToAptMap() : backToApplyList())} className="btn_prev" title="뒤로가기"><i></i></button>
              <strong className="tit"></strong>
              <button type="button" onClick={() => openApplyDtlMap()} className="btn_location" title="지도보기"><i></i></button>
            </header>
            <section className="tit_wrap">
              <button type="button" className={getLcApplyFavList().some(v => v.applyId === lcParamMap.applyId) ? "tit active" : "tit"}
                //onclick="$(this).toggleClass('active')"
                onClick={handleFavCheck}
              >{applyDtl.aptName}</button>
              <span className="info_txt">{applyDtl.address}</span>
              {
                applyDtl.notiYn === 'Y' &&
                <ul className="info_list">
                  <li>{dateFormatMsg(applyDtl.notiDate)}</li>
                  <li>{numberWithCounts(applyDtl.maxApplyPrice * 10000)}</li>
                  <li>{numberWithCommas(applyDtl.applyCnt)}세대</li>
                  {
                    applyDtl &&
                      (applyDtl.loanRateCase == 1 || applyDtl.loanRateCase == 2) ? <li>투기 과열</li> :
                      (applyDtl.loanRateCase == 3 || applyDtl.loanRateCase == 4) ? <li>조정 지역</li> : null
                  }
                </ul>
              }
            </section>
            {
              applyDtl.notiYn === 'N' &&
              <>
                <div className="nodata_wrap">
                  <p className="txt">아직 입주공고문이 올라오지 않았어요.</p>
                </div>
                {/* <div className="btn_wrap">
                  <button type="button" className="btn">단계별 필요 금액</button>
                  <button type="button" className="btn">청약가점 확인하기</button>
                </div> */}
              </>
            }
            <div className="acco_wrap">
              {
                applyDtl.notiYn != 'N' &&
                <section className="item">
                  <button type="button" className="btn_acco">청약정보</button>
                  <div className="acco_view">
                    <ul className="apt_bbs_info">
                      <li>
                        <strong className="th">주소</strong>
                        <div className="td">
                          <span className="sub_txt">{applyDtl.address}</span>
                        </div>
                      </li>
                      {
                        dateDiffMsg(applyDtl.pbshDate) === '완료' ?
                          <li>
                            <strong className="th">입주예정일</strong>
                            <div className="td">
                              <span className="sub_txt">{dateFormatMsg(applyDtl.expectDate)}</span>
                              <code className="r_txt">{dateDiffMsg(applyDtl.expectDate)}</code>
                            </div>
                          </li>
                          :
                          <li>
                            <strong className="th">모집공고일</strong>
                            <div className="td">
                              <span className="sub_txt">{dateFormatMsg(applyDtl.notiDate)}</span>
                              <code className="r_txt">{dateDiffMsg(applyDtl.notiDate)}</code>
                            </div>
                          </li>
                      }
                      <li className="toggle_wrap active">
                        <div className="th">
                          <button type="button" className="btn_toggle">청약일정</button>
                        </div>
                        <div className="td">
                          <div className="table_wrap view_toggle">
                            <table>
                              <colgroup>
                                <col style={{ "width": "40%" }} />
                                <col style={{ "width": "30%" }} />
                                <col style={{ "width": "30%" }} />
                              </colgroup>
                              <thead>
                                <tr>
                                  <th scope="col" className="a_l">분류</th>
                                  <th scope="col">해당지역</th>
                                  <th scope="col">기타지역</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="a_l">특별공급</td>
                                  <td>{applyDtl.spLocalDate == '' ? '사업주체문의' : dateFormatMsg2(applyDtl.spLocalDate)}</td>
                                  <td>{applyDtl.spEtcDate == '' ? '사업주체문의' : dateFormatMsg2(applyDtl.spEtcDate)}</td>
                                </tr>
                                <tr>
                                  <td className="a_l">1순위</td>
                                  <td>{dateFormatMsg2(applyDtl.fstLocalDate)}</td>
                                  <td>{dateFormatMsg2(applyDtl.fstEtcDate)}</td>
                                </tr>
                                <tr>
                                  <td className="a_l">2순위</td>
                                  <td>{dateFormatMsg2(applyDtl.sndLocalDate)}</td>
                                  <td>{dateFormatMsg2(applyDtl.sndEtcDate)}</td>
                                </tr>
                                <tr>
                                  <td className="a_l">당첨자발표일</td>
                                  {applyDtl.pbshDate == null ? <td>사업주체문의</td> : <td>{dateFormatMsg2(applyDtl.pbshDate)}</td>}
                                  {applyDtl.pbshDate == null ? <td>사업주체문의</td> : <td>{dateFormatMsg2(applyDtl.pbshDate)}</td>}
                                </tr>
                                <tr>
                                  <td className="a_l">계약일</td>
                                  {applyDtl.contBeginDate == null ? <td>사업주체문의</td> : <td>{dateFormatMsg2(applyDtl.contBeginDate)}</td>}
                                  {applyDtl.contBeginDate == null ? <td>사업주체문의</td> : <td>{dateFormatMsg2(applyDtl.contBeginDate)}</td>}
                                </tr>
                                <tr>
                                  <td className="a_l">입주예정일</td>
                                  {applyDtl.expectDate == null ? <td>사업주체문의</td> : <td>{dateFormatMsg2(applyDtl.expectDate)}</td>}
                                  {applyDtl.expectDate == null ? <td>사업주체문의</td> : <td>{dateFormatMsg2(applyDtl.expectDate)}</td>}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </li>
                      {
                        (dateDiffMsg(applyDtl.pbshDate) != '완료')
                        &&
                        <li className="toggle_wrap active">
                          <div className="th">
                            <button type="button" className="btn_toggle">공급정보</button>
                            <span className="txt">* 분양가 최고가 기준</span>
                          </div>
                          <div className="td">
                            <div className="table_wrap view_toggle">
                              <table>
                                <colgroup>
                                  <col style={{ "width": "20%" }} />
                                  <col style={{ "width": "20%" }} />
                                  <col style={{ "width": "20%" }} />
                                  <col style={{ "width": "20%" }} />
                                  <col style={{ "width": "20%" }} />
                                </colgroup>
                                <thead>
                                  <tr>
                                    <th scope="col">주택유형</th>
                                    <th scope="col">공급면적</th>
                                    <th scope="col">분양가</th>
                                    <th scope="col">특별공급</th>
                                    <th scope="col">일반공급</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    applyDtl.areaList &&
                                    (applyDtl.areaList.length <= 0 ?
                                      <tr>
                                      </tr>
                                      :
                                      applyDtl.areaList.map((result, idx) => {
                                        return (
                                          <React.Fragment key={idx}>
                                            <tr>
                                              <td>{result.hsType}</td>
                                              <td>{result.hsTypePy}평</td>
                                              <td>{numberWithCounts(result.applyPrice * 10000)}</td>
                                              <td>{result.spCnt}</td>
                                              <td>{result.geCnt}</td>
                                            </tr>
                                          </React.Fragment>
                                        )
                                      })
                                    )
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </li>
                      }
                      {
                        (dateDiffMsg(applyDtl.pbshDate) == '완료')
                        &&
                        <li className="toggle_wrap active">
                          <div className="th">
                            <button type="button" className="btn_toggle">청약경쟁률</button>
                            <span className="txt">* 분양가 최고가 기준</span>
                          </div>
                          <div className="td">
                            <div className="table_wrap view_toggle">
                              <table>
                                <colgroup>
                                  <col style={{ "width": "25%" }} />
                                  <col style={{ "width": "25%" }} />
                                  <col style={{ "width": "25%" }} />
                                  <col style={{ "width": "25%" }} />
                                </colgroup>
                                <thead>
                                  <tr>
                                    <th scope="col">주택유형</th>
                                    <th scope="col">공급면적</th>
                                    <th scope="col">분양가</th>
                                    <th scope="col">1순위경쟁률</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    applyDtl.areaList &&
                                    (applyDtl.areaList.length <= 0 ?
                                      <tr>
                                      </tr>
                                      :
                                      applyDtl.areaList.map((result, idx) => {
                                        return (
                                          <React.Fragment key={idx}>
                                            <tr>
                                              <td>{result.hsType}</td>
                                              <td>{result.hsTypePy}평</td>
                                              <td>{numberWithCounts(result.applyPrice * 10000)}</td>
                                              <td>{result.firstRate}</td>
                                            </tr>
                                          </React.Fragment>
                                        )
                                      })
                                    )
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </li>
                      }
                    </ul>
                    <div className="btn_wrap">
                      <button type="button" className="btn GTM-APY-2" onClick={() => openApplyPredict()}>청약 당첨 예상 계산</button>
                      {/* <button type="button" className="btn">단계별 필요 금액</button>
                      <button type="button" className="btn">청약가점 확인하기</button> */}
                    </div>
                  </div>
                </section>
              }
              <section className="item">
                <button type="button" className="btn_acco">단지정보</button>
                <div className="acco_view">
                  <ul className="apt_bbs_info">
                    <li>
                      <strong className="th">아파트명</strong>
                      <div className="td">
                        <span className="sub_txt">{applyDtl.aptName}</span>
                      </div>
                    </li>
                    <li>
                      <strong className="th">주소</strong>
                      <div className="td">
                        <span className="sub_txt">{applyDtl.address}</span>
                      </div>
                    </li>
                    <li>
                      <strong className="th">세대수</strong>
                      <div className="td">
                        <span className="sub_txt">{numberWithCommas(applyDtl.applyCnt)}세대</span>
                      </div>
                    </li>
                  </ul>
                  <div className="btn_wrap">
                    <button type="button" className="btn GTM-APY-3" onClick={() => openApplyPay()}>단계별 필요 금액</button>
                    <button type="button" className="btn GTM-APY-4" onClick={() => openApplyCalc()}>청약가점 확인하기</button>
                  </div>
                </div>
              </section>
              <section className="item">
                <button type="button" className="btn_acco">주변정보</button>
                <div className="acco_view">
                  <div className="map_sec" id="mapviewDtl">
                    {/* <img src="resources/images/temp/map.png" alt="" /> */}
                  </div>
                  <ul className="amenities">
                    {
                      (applyDtl.stationList != null && applyDtl.stationList.length > 0) ?
                        applyDtl.stationList &&
                        <li className={applyDtl.stationList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="th icon_subway">지하철역</strong>
                          <div className="td sub_list">
                            <ul>
                              {
                                orderBy(applyDtl.stationList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (    
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <strong className="sub_th"><span className="tit" onClick={() => onPanTo(result.lat, result.lng)}>{result.locName}</span>({numberWithCommas(result.distance)}m)</strong>
                                      <span className="sub_td">도보 {parseInt(result.distance / 66, 10)} 분</span>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                          </div>
                          {applyDtl.stationList.length > 3 && <button type="button" className="btn_more" onClick={(e) => moreView(e)}></button>
                          }
                        </li>
                        :
                        null
                    }
                    {
                      (applyDtl.schoolList != null && applyDtl.schoolList.length > 0) ?
                        applyDtl.schoolList &&
                        <li className={applyDtl.schoolList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="th icon_school">학교</strong>
                          <div className="td sub_list">
                            <ul>
                              {
                                orderBy(applyDtl.schoolList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (    
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <strong className="sub_th"><span className="tit" onClick={() => onPanTo(result.lat, result.lng)}>{result.locName}</span>({numberWithCommas(result.distance)}m)</strong>
                                      <span className="sub_td">도보 {parseInt(result.distance / 66, 10)} 분</span>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                          </div>
                          {applyDtl.schoolList.length > 3 && <button type="button" className="btn_more" onClick={(e) => moreView(e)}></button>
                          }
                        </li>
                        :
                        null
                    }
                    {
                      (applyDtl.departList != null && applyDtl.departList.length > 0) ?
                        applyDtl.departList &&
                        <li className={applyDtl.departList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="th icon_mart">마트·백화점</strong>
                          <div className="td sub_list">
                            <ul>
                              {
                                orderBy(applyDtl.departList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (    
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <strong className="sub_th"><span className="tit" onClick={() => onPanTo(result.lat, result.lng)}>{result.locName}</span>({numberWithCommas(result.distance)}m)</strong>
                                      <span className="sub_td">도보 {parseInt(result.distance / 66, 10)} 분</span>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                          </div>
                          {applyDtl.departList.length > 3 && <button type="button" className="btn_more" onClick={(e) => moreView(e)}></button>
                          }
                        </li>
                        :
                        null
                    }
                    {
                      (applyDtl.hospitalList != null && applyDtl.hospitalList.length > 0) ?
                        applyDtl.hospitalList &&
                        <li className={applyDtl.hospitalList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="th icon_hospital">병원</strong>
                          <div className="td sub_list">
                            <ul>
                              {
                                orderBy(applyDtl.hospitalList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (    
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <strong className="sub_th"><span className="tit" onClick={() => onPanTo(result.lat, result.lng)}>{result.locName}</span>({numberWithCommas(result.distance)}m)</strong>
                                      <span className="sub_td">도보 {parseInt(result.distance / 66, 10)} 분</span>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                          </div>
                          {applyDtl.hospitalList.length > 3 && <button type="button" className="btn_more" onClick={(e) => moreView(e)}></button>
                          }
                        </li>
                        :
                        null
                    }
                    {
                      (applyDtl.parkList != null && applyDtl.parkList.length > 0) ?
                        applyDtl.parkList &&
                        <li className={applyDtl.parkList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="th icon_park">공원</strong>
                          <div className="td sub_list">
                            <ul>
                              {
                                orderBy(applyDtl.parkList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (    
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <strong className="sub_th"><span className="tit" onClick={() => onPanTo(result.lat, result.lng)}>{result.locName}</span>({numberWithCommas(result.distance)}m)</strong>
                                      <span className="sub_td">도보 {parseInt(result.distance / 66, 10)} 분</span>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                          </div>
                          {applyDtl.parkList.length > 3 && <button type="button" className="btn_more" onClick={(e) => moreView(e)}></button>
                          }
                        </li>
                        :
                        null
                    }
                  </ul>
                </div>
              </section>
            </div>
            <SubFooter></SubFooter>
          </div>
        </div>
        {/*<button type="button" className="btn_top"><span className="blind">상단으로 이동</span></button>*/}
        <div className="btn_expect_wrap">
          <button type="button" className="btn GTM-APY-0" onClick={() => openApplyPredict()}>
            <span className="full"><strong>내 청약당첨가능성</strong> 확인하기</span><span className="simple">당첨예상</span>
          </button>
        </div>
      </div>
    </>
  );
}

export default ApplyDtl;