import React, {useEffect, useState} from "react";
import {padZero} from "../../../utils/string";
import moment from "moment";
import {useCoupon} from "../../../hooks/useCoupon";
import {useUserStore} from "../../../store/userStore";
import _ from "lodash";

/**
 * @desc 유의사항 컴포넌트
 *
 * - 미가입자
 * - 쿠폰 미선택자
 * - 쿠폰 재선택자
 * -> 매월 (오늘날짜-1일) 까지 발급 가능, 매월 (오늘날짜)에 재선택 가능
 *
 * - 쿠폰 선택자
 * -> 매월 (쿠폰선택일자-1일) 까지 발급 가능, 매월 (쿠폰선택일자)에 재선택 가능
 * (재선택 일자가 지날경우 미가입자 기준을 따른다)
 */
export const CouponNotice = () => {
    const {coupon} = useCoupon();
    // 쿠폰 재선택일
    const [stndDay, setStndDay] = useState(new Date().getDate());
    // 쿠폰발급 가능 날짜 = 재선택일 - 1일
    const [useEndDttm, setUseEndDttm] = useState(() => {
        const date = new Date();
        date.setDate(new Date().getDate() - 1);
        return date.getDate();
    });
    const {user} = useUserStore();

    useEffect(() => {
        
        /**
         * 가입자
         * -> 매월 (가입일자-1일) 까지 발급 가능, 매월 (가입일자)에 재선택 가능
         *
         * 미가입자
         * -> 매월 (오늘날짜-1일) 까지 발급 가능, 매월 (오늘날짜)에 재선택 가능
         */
        if (!_.isEmpty(user)) {
            // 회원은 가입 날짜 기준으로 쿠폰 발급, 재발급일 설정
            const regTime = new Date(user.regTime);

            // 업체재선택일 = 가입일
            const _stndDay = regTime.getDate();

            // 발급가능기한 = 업체재선택일 - 1일
            regTime.setDate(regTime.getDate() - 1);

            // 발급가능기한
            const _useEndDttm = regTime.getDate();
            setStndDay(_stndDay);
            setUseEndDttm(_useEndDttm);
        }
    }, [coupon]);

    return (
            <div className="coupon-notice">
                <strong className="coupon-notice-title">※ 유의사항</strong>
                <ul className="coupon-notice-list">
                    <li className="coupon-notice-item">매월 {padZero(useEndDttm)}일까지 선택한 업체의 쿠폰만 발급받을 수 있으며 해당일까지
                        미발급 받은 쿠폰은 사용할 수 없습니다.
                    </li>
                    <li className="coupon-notice-item">업체당 3개의 쿠폰을 제공하며 1개 쿠폰 사용 완료 시, 1일 후 다음
                        쿠폰을 발급받아 사용할 수 있습니다.
                    </li>
                    <li className="coupon-notice-item">매월 {padZero(stndDay)}일에 업체를 재선택 할 수 있습니다.</li>
                    <li className="coupon-notice-item">쿠폰은 발급일로부터 30일간 사용 가능합니다.</li>
                </ul>
            </div>
    );
};