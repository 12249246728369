import React from 'react';

import {CouponRoot} from "./layout/CouponRoot";
import {SelectedCoupons} from "../../components/coupon/widgets/couponList/SelectedCoupons";
import {useCoupon} from "../../hooks/useCoupon";

/**
 * @desc 로그인 회원 쿠폰 화면(쿠폰 선택된 상태)
 * @desc 쿠폰 잔여금액, 사용현황들을 가져와야 한다.
 */
export const Selected = () => {
    const {hasCoupon} = useCoupon();

    return (
            <>
                {
                        hasCoupon() &&
                        <CouponRoot>
                            <SelectedCoupons/>
                        </CouponRoot>
                }
            </>
    );
};
