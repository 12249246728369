import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'; // url 구분 작업

import axios from 'axios';
import $ from 'jquery';
import {numberTextReplaceWithEmtag, numberWithCounts} from 'routes/common/StringUtil';
import {
    popupClose,
    popupOpen,
    uiChartTap,
    uiFilterTabSwipe,
    uiFixValue,
    uiInputDel,
    uiIpFocus,
    uiMainSwitch,
    uiSelFocus,
    uiToggle,
    uiTopGo
} from 'routes/common/Design.js'

import {getLcParamItem, getLcUrlItem, setLcUrlItem} from 'routes/common/ParamStorage';

import RMateDonutType from 'routes/common/rMateChart/RMateDonutType';

const ApplyPayCalc = (props) => {

  const lcUrlMap = getLcUrlItem();
  const lcParamMap = getLcParamItem();

  const applyId = lcParamMap.applyPayParams.applyDtl.applyId;

  const flrNo = lcParamMap.applyPayParams.flrNo;
  const hsType = lcParamMap.applyPayParams.hsType;

  const balconyYn = lcParamMap.applyPayParams.balconyYn;
  const optYn = lcParamMap.applyPayParams.optYn;

  const optAmt = lcParamMap.applyPayParams.optAmt;
  const applyDtl = lcParamMap.applyPayParams.applyDtl;

  const applyDtlId = lcParamMap.applyPayParams.applyDtlId;

  const [applyPayCalc, setApplyPayCalc] = useState([]);
  const [applyPayAmtList, setApplyPayAmtList] = useState([]);
  const [applyPayBalconyAmtList, setApplyPayBalconyAmtList] = useState([]);

  const [fstAmt, setFstAmt] = useState(0);
  const [sndAmt, setSndAmt] = useState(0);
  const [trdAmt, setTrdAmt] = useState(0);
  const [totAmt, setTotAmt] = useState(0);
  //const snd1Amt = parseInt(totAmt * (applyPayCalc.ltv / 100));
  const [snd1Amt, setSnd1Amt] = useState(0);
  const [snd2Amt, setSnd2Amt] = useState(0);

  const [fstAmtRate, setFstAmtRate] = useState(0.0);
  const [sndAmtRate, setSndAmtRate] = useState(0.0);
  const [trdAmtRate, setTrdAmtRate] = useState(0.0);

  const [balconyAmtObj, setBalconyAmtObj] = useState(null);

  const [balconyTotAmt, setBalconyTotAmt] = useState(0);
  const [balconyContAmt, setBalconyContAmt] = useState(0);
  const [balconyProgAmt, setBalconyProgAmt] = useState(0);
  const [balconyRemAmt, setBalconyRemAmt] = useState(0);

  const [balconyContAmtRate, setBalconyContAmtRate] = useState('0');
  const [balconyProgAmtRate, setBalconyProgAmtRate] = useState('0');
  const [balconyRemAmtRate, setBalconyRemAmtRate] = useState('0');

  const [applyPayAmtType1List, setApplyPayAmtType1List] = useState([]);
  const [applyPayAmtType2List, setApplyPayAmtType2List] = useState([]);
  const [applyPayAmtType3List, setApplyPayAmtType3List] = useState([]);

  //const { applyInfo, applyPayCalc, applyPayAmtList, applyPayBalconyAmtList } = props.applyPayCalc;

  const [amtGraphList, setAmtGraphList] = useState([]);
  const [balconyAmtGraphList, setBalconyAmtGraphList] = useState([]);
  const [etcAmtGraphList, setEtcAmtGraphList] = useState([]);

  const [onRenderGraph, setOnRenderGraph] = useState(false);

  // url 구분 작업
  const history = useHistory();
  const location = useLocation();

  //// 컴포넌트 사용처 : 청약 비용 계산기
  const calcuration = (applyPayCalc, applyPayAmtList, applyPayBalconyAmtList) => {

    const fstAmt = applyPayCalc.fstAmt;
    const sndAmt = applyPayCalc.sndAmt;
    const trdAmt = applyPayCalc.trdAmt;
    const totAmt = fstAmt + sndAmt + trdAmt;
    //const snd1Amt = parseInt(totAmt * (applyPayCalc.ltv / 100));
    const snd1Amt = parseInt(sndAmt * (applyPayCalc.ltv / 100));
    const snd2Amt = sndAmt - snd1Amt;

    const fstAmtRate = Math.floor((fstAmt / totAmt) * 100);
    const sndAmtRate = Math.floor((sndAmt / totAmt) * 100);
    const trdAmtRate = Math.floor((trdAmt / totAmt) * 100);

    const balconyAmtObj = applyPayBalconyAmtList != null && applyPayBalconyAmtList.length > 0 ? applyPayBalconyAmtList[0] : null;

    const balconyTotAmt = balconyAmtObj != null ? balconyAmtObj.totAmt : 0;
    const balconyContAmt = balconyAmtObj != null ? balconyAmtObj.contAmt : 10;
    const balconyProgAmt = balconyAmtObj != null ? balconyAmtObj.progAmt : 40;
    const balconyRemAmt = balconyAmtObj != null ? balconyAmtObj.remAmt : 50;

    const balconyContAmtRate = balconyAmtObj != null ? Math.floor((balconyContAmt / balconyTotAmt) * 100) : '??';
    const balconyProgAmtRate = balconyAmtObj != null ? Math.floor((balconyProgAmt / balconyTotAmt) * 100) : '??';
    const balconyRemAmtRate = balconyAmtObj != null ? Math.floor((balconyRemAmt / balconyTotAmt) * 100) : '??';

    const applyPayAmtType1List = applyPayAmtList.filter(v => v.payType == 'A');
    const applyPayAmtType2List = applyPayAmtList.filter(v => v.payType == 'B');
    const applyPayAmtType3List = applyPayAmtList.filter(v => v.payType == 'C');

    setApplyPayCalc(applyPayCalc);
    setApplyPayAmtList(applyPayAmtList);
    setApplyPayBalconyAmtList(applyPayBalconyAmtList);

    setFstAmt(fstAmt);
    setSndAmt(sndAmt);
    setTrdAmt(trdAmt);
    setTotAmt(totAmt);
    //const snd1Amt = parseInt(totAmt * (applyPayCalc.ltv / 100));
    setSnd1Amt(snd1Amt);
    setSnd2Amt(snd2Amt);

    setFstAmtRate(fstAmtRate);
    setSndAmtRate(sndAmtRate);
    setTrdAmtRate(trdAmtRate);

    setBalconyAmtObj(balconyAmtObj);

    setBalconyTotAmt(balconyTotAmt);
    setBalconyContAmt(balconyContAmt);
    setBalconyProgAmt(balconyProgAmt);
    setBalconyRemAmt(balconyRemAmt);

    setBalconyContAmtRate(balconyContAmtRate);
    setBalconyProgAmtRate(balconyProgAmtRate);
    setBalconyRemAmtRate(balconyRemAmtRate);

    setApplyPayAmtType1List(applyPayAmtType1List);
    setApplyPayAmtType2List(applyPayAmtType2List);
    setApplyPayAmtType3List(applyPayAmtType3List);

    setAmtGraphList([
      { "field": "계약금", "amt": fstAmt },
      { "field": "중도금", "amt": sndAmt },
      { "field": "잔금", "amt": trdAmt }]);

    setBalconyAmtGraphList([
      { "field": "계약금", "amt": balconyContAmt },
      { "field": "중도금", "amt": balconyProgAmt },
      { "field": "잔금", "amt": balconyRemAmt }]);

    setEtcAmtGraphList([
      { "field": "계약금", "amt": (optYn === true && optAmt > 0) ? parseInt((optAmt * 10000) * 0.1) : 10 },
      //{ "field": "중도금", "amt": 0 },
      { "field": "잔금", "amt": (optYn === true && optAmt > 0) ? parseInt((optAmt * 10000) * 0.9) : 90 }]);

    setOnRenderGraph(true);

    /*
    console.log('fstAmt(계약금): ['+fstAmt+']');
    console.log('sndAmt(중도금): ['+sndAmt+']');
    console.log('trdAmt(잔금): ['+trdAmt+']');
    console.log('---------------------------------------');
    console.log('balconyTotAmt: ['+balconyTotAmt+']');
    console.log('balconyContAmt(계약금): ['+balconyContAmt+']');
    console.log('balconyProgAmt(중도금): ['+balconyProgAmt+']');
    console.log('balconyRemAmt(잔금): ['+balconyRemAmt+']');
    console.log('---------------------------------------');
    console.log('balconyContAmtRate: ['+balconyContAmtRate+']');
    console.log('balconyProgAmtRate: ['+balconyProgAmtRate+']');
    console.log('balconyRemAmtRate: ['+balconyRemAmtRate+']');
    console.log('---------------------------------------');
    console.log('balconyAmtObj: ['+balconyAmtObj+']');
    if(balconyAmtObj != null) {
      console.log('(balconyContAmt / balconyTotAmt) * 100 : ['+Math.floor((balconyContAmt / balconyTotAmt) * 100)+']');
      console.log('(balconyProgAmt / balconyTotAmt) * 100 : ['+Math.floor((balconyProgAmt / balconyTotAmt) * 100)+']');
      console.log('(balconyRemAmt / balconyTotAmt) * 100 : ['+Math.floor((balconyRemAmt / balconyTotAmt) * 100)+']');
    }
    */

    // 계약금/잔금/중도금 % 계산값 0일 경우 발코니 그래프 표시 안되는 문제로 defualt값 설정
    if (balconyContAmtRate === 0 && balconyProgAmtRate === 0 && balconyRemAmtRate === 0) {
      setBalconyAmtGraphList([
        { "field": "계약금", "amt": 10 },
        { "field": "중도금", "amt": 40 },
        { "field": "잔금", "amt": 50 }]);
    }

  }

  useEffect(() => {
    uiFixValue.init();
    uiFilterTabSwipe.init();
    uiSelFocus.init();
    uiMainSwitch.init();
    uiChartTap.init();
    uiToggle.init();
    uiTopGo.init();

    uiIpFocus.init();
    uiInputDel.init();

    $(window).scrollTop(0);

    fetchApplyPayCalc();

    // setTimeout(() => {
    //   console.log(applyPayCalc);
    //   console.log(applyPayAmtList);
    //   console.log(applyPayBalconyAmtList);

    //   calcuration();
    // }, 1000);

    window.goback = backToApplyPayDtl;
    window.checkMainPage = () => { return false; }

    return () => {
      window.checkMainPage = () => { return true; }
    }

  }, []);


  const fetchApplyPayCalc = () => {
    //const { applyId, flrNo, hsType, balconyYn, optYn, optAmt } = this.state;
    //if (this.popupWording()) {

    let param = `?applyId=${applyId}&flrNo=${flrNo}&applyDtlId=${applyDtlId}&hsType=${hsType}&balconyYn=${balconyYn}&optYn=${optYn}&optAmt=${optAmt}`;
    axios
      .get('/apt/apply/applyPayDetail' + param)
      .then(res => {

        //setApplyPayCalc(res.data.response.applyPayCalc);
        //setApplyPayAmtList(res.data.response.applyPayAmtList);
        //setApplyPayBalconyAmtList(res.data.response.applyPayBalconyAmtList);

        calcuration(res.data.response.applyPayCalc, res.data.response.applyPayAmtList, res.data.response.applyPayBalconyAmtList);

      });

  }


  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  const backToApplyPayDtl = () => {
    setLcUrlItem('applyPayCalc', 'applyPayDtl');
    props.onApplyPopupType('P_C');

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?CAL-1200#`
    });
  }

  const backToApplyList = () => {
    setLcUrlItem('applyPayCalc', 'applyList');
    setTimeout(() => {
      $('.bg_body_full').remove();
      uiFixValue.init();
    }, 100);
    props.onApplyMenuType('A');
    props.onApplyPopupType('');

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?APY-1000#`
    });
  }

  return (
    <>
      <div id="mw-costdetail" className="ly_apt_cost_detail full">
        <div className="wrap">
          <div className="content">
            <header className="header">
              <button type="button" onClick={() => backToApplyList()} className="btn_home" title="홈으로 바로가기"><i></i></button>
              <button type="button" onClick={() => backToApplyPayDtl()} className="btn_prev" title="뒤로가기"><i></i></button>
              <strong className="tit">비용 계산기</strong>
            </header>
            <div className="ly_content">
              <section className="view_tit_wrap">
                <strong className="tit">{applyDtl.aptName}</strong>
                <div className="sub_txt">
                  <span>{applyDtl.address}</span>
                  <span>{hsType} / {parseInt(parseInt(hsType) / 3.3)}평({parseFloat(hsType).toFixed(2)}㎡) / {flrNo}</span>
                </div>
              </section>
              <section className="view">
                <div className="tit_wrap">
                  <strong className="tit">1. 단계별 총 금액 요약</strong>
                </div>
                <div className="col_max">
                  <div className="inner_wrap">
                    <div className="progress_step_main">
                      <div className="total_cost">
                        <span><em>총 금액</em> {numberTextReplaceWithEmtag(numberWithCounts(totAmt))}</span>
                      </div>
                      <div className="progress_bar">
                        <div className="step_01">
                          <span>{fstAmtRate}<em>%</em></span>
                          <div className="step_lbl">
                            <div className="dec_dash"></div>
                            <div className="text_area">
                              <p>{numberTextReplaceWithEmtag(numberWithCounts(fstAmt))}</p>
                              <p className="sub_txt">계약금</p>
                            </div>
                          </div>
                        </div>
                        <div className="step_02">
                          <span>{sndAmtRate}<em>%</em></span>
                          <div className="step_lbl">
                            <div className="dec_dash"></div>
                            <div className="text_area">
                              <p>{numberTextReplaceWithEmtag(numberWithCounts(sndAmt))}</p>
                              <p className="sub_txt">중도금</p>
                            </div>
                          </div>
                        </div>
                        <div className="step_03">
                          <span>{trdAmtRate}<em>%</em></span>
                          <div className="step_lbl">
                            <div className="dec_dash"></div>
                            <div className="text_area">
                              <p>{numberTextReplaceWithEmtag(numberWithCounts(trdAmt))}</p>
                              <p className="sub_txt">잔금</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <hr />
              <section className="view">
                <div className="tit_wrap">
                  <strong className="tit">2. 비용별 상세</strong>
                </div>
                <div className={totAmt > 0 ? "toggle_wrap active" : "toggle_wrap nodata"} style={{ "z-index": "1" }}>
                  <div className="graph">
                    {/* <img src="resources/images/temp/graph.png" alt="" /> */}
                    {/* <Donut
                      fstAmt={fstAmt}
                      sndAmt={sndAmt}
                      trdAmt={trdAmt}
                    ></Donut> */}
                    <div className="pct_wrap dash_tl" style={{ "bottom": "148px", "left": "130px", "z-index": "1" }}>
                      <strong className="tit">계약금</strong>
                      <p>{fstAmtRate}%</p>
                    </div>
                    <div className="pct_wrap dash_l" style={{ "bottom": "63px", "left": "184px", "z-index": "1" }}>
                      <strong className="tit">중도금</strong>
                      <p>{sndAmtRate}%</p>
                    </div>
                    <div className="pct_wrap dash_r" style={{ "bottom": "84px", "left": "3px", "z-index": "1" }}>
                      <strong className="tit">잔금</strong>
                      <p>{trdAmtRate}%</p>
                    </div>
                    <div id="rMateDivDonut1" style={{ "width": "220px", "height": "155px" }}>
                      {
                        onRenderGraph &&
                        <RMateDonutType
                          parentDivId={'rMateDivDonut1'}
                          graphList={amtGraphList}
                        ></RMateDonutType>
                      }
                    </div>
                  </div>
                  <div className="btn_toggle_wrap">
                    <button type="button" className="btn_toggle">
                      <strong>분양가</strong>
                      <code>{numberTextReplaceWithEmtag(numberWithCounts(totAmt))}</code>
                    </button>
                  </div>
                  <div className="view_toggle">
                    <div className="price_detail">
                      <ul>
                        <li className="price_fir">
                          <strong>계약금</strong>
                          <span>{numberTextReplaceWithEmtag(numberWithCounts(fstAmt))}</span>
                        </li>
                        {
                          applyPayAmtType1List.map((v, idx) =>
                            <li>
                              {idx === 0 &&
                                <strong>납입일자</strong>
                              }
                              <span>{v.payDate}</span>
                            </li>
                          )
                        }
                      </ul>
                      <ul>
                        <li className="price_sec">
                          <strong>중도금</strong>
                          <span>{numberTextReplaceWithEmtag(numberWithCounts(sndAmt))}</span>
                        </li>
                        {
                          applyPayAmtType2List.map((v, idx) =>
                            <li>
                              {idx === 0 &&
                                <strong>납입일자</strong>
                              }
                              <span>{v.payDate}</span>
                            </li>
                          )
                        }
                      </ul>
                      <ul>
                        <li className="price_thr">
                          <strong>잔금</strong>
                          <span>{numberTextReplaceWithEmtag(numberWithCounts(trdAmt))}</span>
                        </li>
                        {
                          applyPayAmtType3List.map((v, idx) =>
                            <li>
                              {idx === 0 &&
                                <strong>납입일자</strong>
                              }
                              <span>{v.payDate}</span>
                            </li>
                          )
                        }
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={balconyYn === true ? "toggle_wrap active" : "toggle_wrap nodata"} style={{ "z-index": "1" }}>
                  <div className="graph">
                    {/* <img src="resources/images/temp/graph.png" alt="" /> */}
                    {/* <Donut
                      fstAmt={balconyContAmt}
                      sndAmt={balconyProgAmt}
                      trdAmt={balconyRemAmt}
                    ></Donut> */}
                    <div className="pct_wrap dash_tl" style={{ "bottom": "148px", "left": "130px", "z-index": "1" }}>
                      <strong className="tit">계약금</strong>
                      <p>{balconyContAmtRate}%</p>
                    </div>
                    <div className="pct_wrap dash_l" style={{ "bottom": "63px", "left": "184px", "z-index": "1" }}>
                      <strong className="tit">중도금</strong>
                      <p>{balconyProgAmtRate}%</p>
                    </div>
                    <div className="pct_wrap dash_r" style={{ "bottom": "84px", "left": "3px", "z-index": "1" }}>
                      <strong className="tit">잔금</strong>
                      <p>{balconyRemAmtRate}%</p>
                    </div>
                    <div id="rMateDivDonut2" style={{ "width": "220px", "height": "155px" }}>
                      {
                        onRenderGraph &&
                        <RMateDonutType
                          parentDivId={'rMateDivDonut2'}
                          graphList={balconyAmtGraphList}
                        ></RMateDonutType>
                      }
                    </div>
                  </div>
                  <div className="btn_toggle_wrap">
                    <button type="button" className="btn_toggle">
                      <strong>발코니</strong>
                      <code>{numberTextReplaceWithEmtag(numberWithCounts(balconyTotAmt))}</code>
                    </button>
                  </div>
                  <div className="view_toggle">
                    {
                      balconyYn === true ?
                        <div className="price_detail">
                          <ul>
                            <li className="price_fir">
                              <strong>계약금</strong>
                              <span>{numberTextReplaceWithEmtag(numberWithCounts(balconyContAmt))}</span>
                            </li>
                          </ul>
                          <ul>
                            <li className="price_sec">
                              <strong>중도금</strong>
                              <span>{numberTextReplaceWithEmtag(numberWithCounts(balconyProgAmt))}</span>
                            </li>
                          </ul>
                          <ul>
                            <li className="price_thr">
                              <strong>잔금</strong>
                              <span>{numberTextReplaceWithEmtag(numberWithCounts(balconyRemAmt))}</span>
                            </li>
                          </ul>
                        </div>
                        :
                        <div className="price_detail">
                          <ul>
                            <li className="price_fir">
                              <strong>계약금</strong>
                              <span>?억 ????</span>
                            </li>
                          </ul>
                          <ul>
                            <li className="price_sec">
                              <strong>중도금</strong>
                              <span>?억 ????</span>
                            </li>
                          </ul>
                          <ul>
                            <li className="price_thr">
                              <strong>잔금</strong>
                              <span>?억 ????</span>
                            </li>
                          </ul>
                        </div>
                    }
                  </div>
                </div>
                <div className={(optYn === true && optAmt > 0) ? "toggle_wrap active" : "toggle_wrap nodata"} style={{ "z-index": "1" }}>
                  <div className="graph">
                    {/* <img src="resources/images/temp/graph.png" alt="" /> */}
                    {/* <Donut
                      fstAmt={(optYn === true && optAmt > 0) ? parseInt((optAmt * 10000) * 0.1) : 0}
                      sndAmt={0}
                      trdAmt={(optYn === true && optAmt > 0) ? parseInt((optAmt * 10000) * 0.9) : 0}
                    ></Donut> */}
                    <div className="pct_wrap dash_tl" style={{ "bottom": "148px", "left": "130px", "z-index": "1" }}>
                      <strong className="tit">계약금</strong>
                      <p>{(optYn === true && optAmt > 0) ? `10` : `??`}%</p>
                    </div>
                    {/* <div className="pct_wrap dash_l" style={{ "bottom": "63px", "left": "184px", "z-index": "1" }}>
                      <strong className="tit">중도금</strong>
                      <p>{balconyProgAmtRate}%</p>
                    </div> */}
                    <div className="pct_wrap dash_r" style={{ "bottom": "84px", "left": "3px", "z-index": "1" }}>
                      <strong className="tit">잔금</strong>
                      <p>{(optYn === true && optAmt > 0) ? `90` : `??`}%</p>
                    </div>
                    <div id="rMateDivDonut3" style={{ "width": "220px", "height": "155px" }}>
                      {
                        onRenderGraph &&
                        <RMateDonutType
                          parentDivId={'rMateDivDonut3'}
                          graphList={etcAmtGraphList}
                        ></RMateDonutType>
                      }
                    </div>
                  </div>
                  <div className="btn_toggle_wrap">
                    <button type="button" className="btn_tip" onClick={() => popupOpen('alert01', 'mwf', 0)}>유상옵션이란?</button>
                    <button type="button" className="btn_toggle">
                      <strong>유상옵션</strong>
                      <code>{(optYn === true && optAmt > 0) ? numberTextReplaceWithEmtag(numberWithCounts((optAmt * 10000))) : ''}</code>
                    </button>
                  </div>
                  <div className="view_toggle">
                    {
                      (optYn === true && optAmt > 0) ?
                        <div className="price_detail">
                          <ul>
                            <li className="price_fir">
                              <strong>계약금</strong>
                              <span>{numberTextReplaceWithEmtag(numberWithCounts(parseInt((optAmt * 10000) * 0.1)))}</span>
                            </li>
                          </ul>
                          <ul>
                            <li className="price_sec">
                              <strong>중도금</strong>
                              <span>0</span>
                            </li>
                          </ul>
                          <ul>
                            <li className="price_thr">
                              <strong>잔금</strong>
                              <span>{numberTextReplaceWithEmtag(numberWithCounts(parseInt((optAmt * 10000) * 0.9)))}</span>
                            </li>
                          </ul>
                        </div>
                        :
                        <div className="price_detail">
                          <ul>
                            <li className="price_fir">
                              <strong>계약금</strong>
                              <span>?억 ????</span>
                            </li>
                          </ul>
                          <ul>
                            <li className="price_sec">
                              <strong>중도금</strong>
                              <span>?억 ????</span>
                            </li>
                          </ul>
                          <ul>
                            <li className="price_thr">
                              <strong>잔금</strong>
                              <span>?억 ????</span>
                            </li>
                          </ul>
                        </div>
                    }
                  </div>
                </div>
              </section>
              <section className="view">
                <div className="tit_wrap">
                  <strong className="tit">3. 단계별 금액 상세</strong>
                </div>
                <div className="progress_step_detail">
                  <ul className="progress_bar">
                    <li>
                      <strong>즉시 납입액</strong>
                      <div className="progress_bg">
                        <div className="step_01">
                          <span className="step_lbl">{numberTextReplaceWithEmtag(numberWithCounts(fstAmt))}</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <strong>중도금</strong>
                      <div className="progress_bg_md">
                        <div className="step_02_1">
                          <span className="step_lbl">{numberTextReplaceWithEmtag(numberWithCounts(snd1Amt))}</span>
                        </div>
                        <div className="step_02_2">
                          <span className="step_lbl">{snd1Amt >= sndAmt ? 0 : numberTextReplaceWithEmtag(numberWithCounts(snd2Amt))}</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <strong>잔금</strong>
                      <div className="progress_bg">
                        <div className="step_03">
                          <span className="step_lbl">{numberTextReplaceWithEmtag(numberWithCounts(trdAmt))}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="btn_toggle_wrap">
                  <div className="txt_toggle">
                    <strong>분양가</strong>
                    <code>{numberTextReplaceWithEmtag(numberWithCounts(totAmt))}</code>
                  </div>
                </div>
                <div className="price_detail type02">
                  <ul>
                    <li className="price_fir">
                      <strong>즉시납입액</strong>
                      <span>{numberTextReplaceWithEmtag(numberWithCounts(fstAmt))}</span>
                    </li>
                  </ul>
                  <ul>
                    <li className="price_sec">
                      <strong>중도금</strong>
                      <span>{numberTextReplaceWithEmtag(numberWithCounts(sndAmt))}</span>
                    </li>
                    <li>
                      <strong>- 대출필요금액</strong>
                      <span className="td_pri01">{numberTextReplaceWithEmtag(numberWithCounts(snd1Amt))} (<em>중도금</em> {applyPayCalc.ltv}%)</span>
                    </li>
                    <li>
                      <strong>- 자납필요금액</strong>
                      <span className="td_pri02">{snd1Amt >= sndAmt ? 0 : numberTextReplaceWithEmtag(numberWithCounts(snd2Amt))} (<em>중도금</em> {100 - (applyPayCalc.ltv)}%)</span>
                    </li>
                  </ul>
                  <ul>
                    <li className="price_thr">
                      <strong>잔금</strong>
                      <span>{numberTextReplaceWithEmtag(numberWithCounts(trdAmt))}</span>
                    </li>
                  </ul>
                </div>
              </section>
              <hr />
              <section className="view">
                <ul className="info_txt_wrap">
                  <li>
                    <strong className="tit">즉시 납입 금액이란?</strong>
                    <p className="sub_txt">청약 당첨 후 정당 계약 시 즉시 납입이 필요해요. 청약 당첨 이후 계약 미진행 시 1년간 청약을 신청 할 수 없어요. 꼭! 감당이 가능한 금액인지 먼저 확인 후 청약을 신청하세요.</p>
                  </li>
                  <li>
                    <strong className="tit">중도금이란?</strong>
                    <p className="sub_txt">분양가의 일부를 6회차에 걸쳐 분납하는 금액이에요. 규제 지역 별 대출 가능한 금액이 다르니, 청약 전 확인 하세요. 대출 가능 금액 이외에는 자납으로 진행하시면 되요.</p>
                  </li>
                  <li>
                    <strong className="tit">잔금이란?</strong>
                    <p className="sub_txt">계약금 및 중도금 납입 이후 최종적으로 납입해야하는 금액이에요. 입주 날짜에 필요한 잔금으로, 실제 입주일에 적용되는 규제 및 대출정보를 입주 시점에 확인 가능해요.</p>
                  </li>
                </ul>
              </section>
            </div>
            <div className="btn_area_wide fix btm">
              <button type="button" className="skip" onClick={() => backToApplyPayDtl()} >다시 계산하기</button>
            </div>
          </div>
        </div>
      </div>
      <div id="mw-alert01" className="ly_pop popBtm ly_alert_b">
        <div className="wrap">
          <div className="content">
            <strong className="tit">유상옵션 금액?</strong>
            <p className="txt">청약 아파트도 자동차 옵션 처럼 다양한 옵션이 존재해요.<br />예상되는 유상옵션 비용을 직접 입력해주세요.</p>
            <button type="button" onClick={() => popupClose('alert01', 'mwf', 0)} className="close"><i>닫기</i></button>
          </div>
        </div>
      </div>
    </>
  )
}
export default ApplyPayCalc;