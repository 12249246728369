import React, { useEffect, useState } from 'react'
import { useStateWithCallbackLazy } from 'use-state-with-callback';

import { uiFixValue, uiFilterTabSwipe, popupOpen, popupClose, uiIpFocus, uiInputDel, uiAccoScrollFix, uiSelFocus, uiMainSwitch, uiChartTap, uiToggle, uiTopGo, joinInfoEvent } from 'routes/common/Design.js'
import { callAppInterface } from 'routes/common/AppInterface';

//// 컴포넌트 사용처 : Qna게시판 (사용중이긴 하나 페이지만 있음. 접근시 alert)
const Qna = (props) => {

  useEffect(() => {
    popupOpen('alert', 'mwf')
    window.goback = backToAptMain;
  });

  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  //단독페이지들은 location href = / 로 보냄
  const backToAptMain = () => {
    //window.location.href = '/';
    callAppInterface("closeWebPage", JSON.stringify({ url: '/' }), result => {
      //alert(result);
    });
  }

  return (
    <>
      <div id="mw-info" className="ly_apt_sale ly_apt_join full">
        <div className="wrap">
          <div className="content">
            <div className="header">
              <header>
                <button type="button" onClick={backToAptMain} className="btn_prev" title="뒤로가기"><i></i></button>
                <strong className="tit">고객센터</strong>
              </header>
              <ul className="menu_wrap">
                <li><a href="/notice">공지사항</a></li>
                <li><a href="/faq">FAQ</a></li>
                <li className='active'><a href="/qna">1:1문의</a></li>
                <li><a href="/expire">서비스해지</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id="mw-alert" className="ly_pop popBtm ly_alert ndim">
        <div className="wrap">
          <div className="content">
            <div className="txt_wrap">
              <strong className="tit">1:1 문의</strong>
              <p className="sTxt">준비 중입니다.</p>
            </div>
          </div>
          <ul className="btn_area_half">
            <li><button type="button" className="skip" onClick={() => popupClose('alert', 'mwf')}>확인</button></li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Qna;