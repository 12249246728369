import React, { Component } from 'react';
import Moment from 'moment';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import RMateBarMSType from 'routes/common/rMateChart/RMateBarMSType';
import RMateColumnMSType from 'routes/common/rMateChart/RMateColumnMSType';
import { popupAlert02 } from 'routes/predict/PopupNotice';
import { numberWithCommas } from 'routes/common/StringUtil';

//// 컴포넌트 사용처 : 청약 당첨 예상 상세
const PopInOut = ({ calcResult, onRenderGraph }) => {

  const { bjdInfo, popInOut } = calcResult;

  const options = {
    chart: {
      type: 'column',
      height: 300
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: popInOut.map(v => v.year + v.month + "01"),
      labels: {
        formatter: function () {
          return Moment(this.value).format('YY/<br/> MM');
        },
        useHtml: true,
        rotation: 0
      },
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        formatter: function () {
          return numberWithCommas(this.value)
        }
      },
      tickAmount: 8
    },
    tooltip: {
      valueSuffix: ' 명',
      shared: true
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: false,
        },
        groupPadding: 0.25
      }
    },
    series: [{
      name: '전입',
      data: popInOut.map(v => v.inCnt),
      color: 'rgb(94, 206, 197)'
    }, {
      name: '전출',
      data: popInOut.map(v => v.outCnt),
      color: 'rgb(252, 77, 64)'

    }]
  }

  const minData = popInOut.filter((v, idx) => idx === 0)[0];
  const maxData = popInOut.filter((v, idx) => idx === popInOut.length - 1)[0];
  const popIn = popInOut.map(v => v.inCnt).reduce((pv, cv) => pv + cv, 0);
  const popOut = popInOut.map(v => v.outCnt).reduce((pv, cv) => pv + cv, 0);
  const scoreType = popIn > popOut ? 'good' : 'bad';

  let graphList = new Array();

  popInOut.forEach(v => {
    const yyyy = v.year.substr(2, 2).replace('-', '');
    const mm = v.month;

    const targetDateStr = `${yyyy}. ${mm}`;

    graphList.push({ 'yearMonth': targetDateStr, 'inCnt': v.inCnt, 'outCnt': v.outCnt, 'counts': '건' });
  });

  return (
    <div className="con ani" id="ani9">
      <div className="tit_wrap">
        <span className="tit">청약 지역 <strong>전입·전출 인구</strong></span>
        <button type="button" className="btn_tip GTM-PER-TIP-5" onClick={popupAlert02}>청약 지역 전입·전출 인구 란?</button>
      </div>
      {/* <!-- verygood/good/normal/bad로 색상,이모지 변경 --> */}
      <div className={`ty_graph ${scoreType}`}>
        <div className="graph">
          <div className="dount_area">
            <div className="circle_chart full" data-percent="100" data-permax="100">
              <div className="chart-box">
                <svg>
                  <defs>
                    <linearGradient id={`dount_${scoreType}`} x1="0" y1="0" x2="1" y2="1">
                      <stop className="color_1" offset="0%" />
                      <stop className="color_2" offset="100%" />
                    </linearGradient>
                  </defs>
                  <circle stroke={`url(#dount_${scoreType})`} r="50%" cx="50%" cy="50%" className="circle_progress" stroke-width="18" stroke-linecap="round"></circle>
                </svg>
                <div className={`per-txt02 ${popIn > popOut ? '' : 'down'}`}><span>전입</span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="caption emoji">
          {
            popIn > popOut ?
              <p>
                <b>{bjdInfo.bjdName}</b> 지역은 최근 1년간 전입인구가 <em>전출 인구보다 많아요. 전입 인구가 많은 경우 아파트 수요가 증가</em>하여<br />
                추후에 아파트 시세에 긍정적인 영향을 미칠 수 있어요.
              </p>
              :
              <p>
                <b>{bjdInfo.bjdName}</b> 지역은 최근 1년간 전출인구가 <em>전입 인구보다 많아요. 전출 인구가 많은 경우 아파트 수요가 감소</em>하여<br />
                추후에 아파트 시세에 부정적인 영향을 미칠 수 있어요.
              </p>
          }
          <strong>기준기간 : <code>{Moment(minData.year + minData.month + "01").format('YYYY. MM')} ~ {Moment(maxData.year + maxData.month + "01").format('YYYY. MM')}</code></strong>
        </div>
      </div>
      {/* <div id="rMateDiv2" className="chart" style={{ "width": "380px", "height": "250px" }}> */}
      <div className="chart">
        {/* <div id="rMateDiv2"> */}
        {/* <HighchartsReact highcharts={Highcharts} options={options} /> */}
        {/* <RMateBarMSType
            parentDivId={'rMateDiv2'}
            graphList={graphList}
            toggleArounds={toggleArounds}
          ></RMateBarMSType> */}

        {/* </div> */}
        <div id="rMateDiv2_1">
          {/* <HighchartsReact highcharts={Highcharts} options={options} /> */}
          {
            onRenderGraph &&
            <RMateColumnMSType
              parentDivId={'rMateDiv2_1'}
              graphList={graphList}
            ></RMateColumnMSType>
          }
        </div>
      </div>
    </div>
  );
}

export default PopInOut;
