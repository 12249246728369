import React from "react";

/**
 * @desc 유의사항 컴포넌트
 *
 * - 미가입자
 * - 쿠폰 미선택자
 * - 쿠폰 재선택자
 * -> 매월 (오늘날짜-1일) 까지 발급 가능, 매월 (오늘날짜)에 재선택 가능
 *
 * - 쿠폰 선택자
 * -> 매월 (쿠폰선택일자-1일) 까지 발급 가능, 매월 (쿠폰선택일자)에 재선택 가능
 * (재선택 일자가 지날경우 미가입자 기준을 따른다)
 */
export const CouponTestNotice = () => {

    return (
            <div className="coupon-notice">
                <strong className="coupon-notice-title">※ 유의사항</strong>
                <ul className="coupon-notice-list">
                    <li className={"coupon-notice-item"}>test</li>
                    <li className={"coupon-notice-item"}>test</li>
                    <li className={"coupon-notice-item"}>test</li>
                    <li className={"coupon-notice-item"}>test</li>
                    <li className={"coupon-notice-item"}>test</li>
                    <li className={"coupon-notice-item"}>test</li>
                    <li className={"coupon-notice-item"}>test</li>
                </ul>
            </div>
    );
};