export const osCheck = () => {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;

}

export const os = () => {
    const userAgent = navigator.userAgent;

    let os = '';

    if (userAgent.match(/Win(dows )?NT 6\.0/)) {
        os = 'Windows Vista';
    } else if (userAgent.match(/Win(dows )?(NT 5\.1|XP)/)) {
        os = 'Windows XP';
    } else {
        if ((userAgent.indexOf('Windows NT 5.1') != -1) || (userAgent.indexOf('Windows XP') != -1))                             os = 'Windows XP';
        else if ((userAgent.indexOf('Windows NT 7.0') != -1) || (userAgent.indexOf('Windows NT 6.1') != -1))                    os = 'Windows 7';
        else if ((userAgent.indexOf('Windows NT 8.0') != -1) || (userAgent.indexOf('Windows NT 6.2') != -1))                    os = 'Windows 8';
        else if ((userAgent.indexOf('Windows NT 8.1') != -1) || (userAgent.indexOf('Windows NT 6.3') != -1))                    os = 'Windows 8.1';
        else if ((userAgent.indexOf('Windows NT 10.0') != -1) || (userAgent.indexOf('Windows NT 6.4') != -1))                   os = 'Windows 10';
        else if ((userAgent.indexOf('iPad') != -1) || (userAgent.indexOf('iPhone') != -1) || (userAgent.indexOf('iPod') != -1)) os = 'Apple iOS';
        else if (userAgent.indexOf('Android' != -1))                                                                            os = 'Android OS';
        else if (userAgent.match(/Win(dows )?NT( 4\.0)?/))                                                              os = 'Windows NT';
        else if (userAgent.match(/Mac|PPC/))                                                                            os = 'Mac OS';
        else if (userAgent.match(/Linux/))                                                                              os = 'Linux';
        else if (userAgent.match(/(Free|Net|Open)BSD/))                                                                 os = userAgent.match(/(Free|Net|Open)BSD/)[1] + 'BSD';
        else if (userAgent.match(/SunOS/))                                                                              os = 'Solaris';
    }

    if (os.indexOf("Windows") != -1) {
        os += ((userAgent.indexOf('WOW64') > -1 || userAgent.indexOf('Win64') > -1) ? ' 64bit' : ' 32bit');
    }

    return os;
}

export const version = () => {
    const userAgent = navigator.userAgent;

    let version = [];

    if (userAgent.indexOf('Android') != -1) {
        version = (userAgent).match(/Android (\d+).?(\d+)?.?(\d+)?/);
    } else if ((userAgent.indexOf('iPad') != -1) || (userAgent.indexOf('iPhone') != -1) || (userAgent.indexOf('iPod') != -1)) {
        version = (userAgent).match(/OS (\d+)_(\d+)_?(\d+)?/);
    }

    return [parseInt((version[1] || 0), 10), parseInt((version[2] || 0), 10), parseInt((version[3] || 0), 10)];
}