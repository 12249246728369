import React from "react";

export const SideMenuCategory = (props) =>{
    return (
            <dl className="dl_menu">
                <dt>{props.categoryName}</dt>
                {Array(props.children).map(v => v)}
            </dl>
    );

}