import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'; // url 구분 작업

import {MyOverlay} from 'routes/overlay/MyOverlay';
import {AroundOverlay} from 'routes/overlay/AroundOverlay';

import SubFooter from 'routes/SubFooter';

import axios from 'axios';
import {orderBy} from 'lodash';
import $ from 'jquery';
import {
    dateFormatMsg,
    dateFormatMsgKor,
    numberReplaceWithCodetag,
    numberWithCommas,
    numberWithCounts
} from 'routes/common/StringUtil';
import {
    popupClose,
    popupOpen,
    uiAccoScrollFix,
    uiChartTap,
    uiFilterTabSwipe,
    uiFixValue,
    uiInputDel,
    uiIpFocus,
    uiMainSwitch,
    uiSelFocus,
    uiToggle,
    uiTopGo
} from 'routes/common/Design.js'

import {getLcParamItem, getLcUrlItem, setLcUrlItem} from 'routes/common/ParamStorage';

import RMateCandleStickType from 'routes/common/rMateChart/RMateCandleStickType';

import {addFavItem, clearFavItem, getFavList} from 'routes/common/AptFavUtil';
import RMateChartH5 from "../common/rMateChart/RMateChartH5";

let map = {};
let overlayList = [];
let aroundOverlayList = [];
//let trxType = 'D';

//// 컴포넌트 사용처 : 아파트 정보
const AptDtl = (props) => {
  //let aptAreaId = props.aptAreaId;
  //const [aptDtl, setAptDtl] = useStateWithCallbackLazy(props.aptDtl);
  //const [aptArea, setAptArea] = useStateWithCallbackLazy(props.aptArea);
  //const [graphList, setGraphList] = useStateWithCallbackLazy([]);

  //const aptDtl = props.aptDtl;
  //let aptArea = props.aptArea;
  //let graphList = props.aptArea.graphDataList;

  //const [trxType, setTrxType] = useStateWithCallbackLazy('D');
  const [trxType, setTrxType] = useState(props.trxType);

  // const [aptDtl, setAptDtl] = useStateWithCallbackLazy([]);
  // const [aptArea, setAptArea] = useStateWithCallbackLazy([]);
  // const [aptAreaId, setAptAreaId] = useStateWithCallbackLazy('');
  // const [graphList, setGraphList] = useStateWithCallbackLazy([]);

  const [aptDtl, setAptDtl] = useState([]);
  const [aptArea, setAptArea] = useState([]);
  const [aptAreaId, setAptAreaId] = useState('');
  const [graphList, setGraphList] = useState([]);

  const [trxList, setTrxList] = useState([]);
  const [trxLimitSize, setTrxLimitSize] = useState(10);

  const [aroundLocInfoMap, setAroundLocInfoMap] = useState([]);

  const [onRenderGraph, setOnRenderGraph] = useState(false);

  const [initAreaTxt, setInitAreaTxt] = useState('');


  //setAptArea(props.aptArea);
  //setGraphList(props.aptArea.graphDataList);

  const lcUrlMap = getLcUrlItem();
  const lcParamMap = getLcParamItem();

  // url 구분 작업
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    popupOpen('aptdetail', 'mwf');

    uiFixValue.init();
    uiFilterTabSwipe.init();
    uiSelFocus.init();
    uiMainSwitch.init();
    uiChartTap.init();
    uiToggle.init();
    uiTopGo.init();

    uiIpFocus.init();
    uiInputDel.init();

    //uiAccoScrollFix.init();

    window.goback = backToAptMap;
    window.checkMainPage = () => { return false; }

    return () => {
      window.checkMainPage = () => { return true; }
    }

  }, []);

  useEffect(() => {
    setAptDtl([]);
    clearOverlay();
    clearAroundOverlay();

    let lat = 37.57100;
    let lng = 126.97696;

    let mapContainer = document.getElementById('mapviewDtl'); // 지도를 표시할 div 

    let mapOption = {
      center: new kakao.maps.LatLng(lat, lng), // eslint-disable-line
      level: 5
    };

    map = new kakao.maps.Map(mapContainer, mapOption);  // eslint-disable-line
    map.setMinLevel(2);
    map.setMaxLevel(11);

    kakao.maps.event.addListener(map, 'dragend', () => { }); // eslint-disable-line
    kakao.maps.event.addListener(map, 'zoom_changed', () => { });  // eslint-disable-line

    //if (aptDtl != null && aptDtl.applyId != '') {

    //map.relayout();
    //onPanTo(aptDtl.lat, aptDtl.lng);
    //}

    setTimeout(() => {
      fetchAptDtl(props.aptId);
    }, 100);

  }, [props.aptId]);

  const clearOverlay = () => {
    overlayList.forEach(ov => ov.setMap(null));
    overlayList = [];
  }

  const clearAroundOverlay = () => {
    aroundOverlayList.forEach(ov => ov.setMap(null));
    aroundOverlayList = [];
  }

  const renderMyMarker = (data) => {
    overlayList.forEach(ov => ov.setMap(null));
    overlayList = [];

    let overlay = new MyOverlay(data);
    overlay.setMap(map);
    overlayList.push(overlay);
  }

  const renderingAroundDataIcons = (data) => {
    aroundOverlayList.forEach(ov => ov.setMap(null));
    aroundOverlayList = [];

    const dtl = data;

    dtl.schoolList.forEach(v => {
      v.type = 'school';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.departList.forEach(v => {
      v.type = 'ico_mart';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.stationList.forEach(v => {
      v.type = 'ico_subway';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.hospitalList.forEach(v => {
      v.type = 'ico_hospital';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.parkList.forEach(v => {
      v.type = 'ico_park02';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });
  }

  const onPanTo = (paramLat, paramLng) => {
    let moveLatLng = new kakao.maps.LatLng(paramLat, paramLng); // eslint-disable-line
    map.panTo(moveLatLng);
  }

  const moreView = (e) => {
    $(e.target).parent().toggleClass('show');
    $(e.target).parent().find('li').eq(2).nextAll().toggleClass('hide');
  }

  const fetchAptDtl = (aptId) => {
    const promise = axios
      .get(`apt/map/mblAptDtl?aptId=${aptId}&trxType=${trxType}`)
      .then(res => {
        setAptDtl(res.data.response);
        renderMyMarker(res.data.response);

        fetchAptAreaDtl(aptId, props.aptAreaId, trxType);

        setTimeout(() => {
          onPanTo(res.data.response.lat, res.data.response.lng);
          fetchAround(aptId, res.data.response.lat, res.data.response.lng);
        }, 400);

        uiAccoScrollFix.init();
      });

    promise.then((result) => {
      bindSelect();
    });

    //$('li.tab1').click();
  }

  const fetchAptAreaDtl = (aptId, aptAreaId, trxType, callback) => {
    setOnRenderGraph(false);
    setTrxLimitSize(10);
    axios
      .get(`/apt/map/aptAreaDtl?aptId=${aptId}&aptAreaId=${aptAreaId}&trxType=${trxType}`)
      .then(res => {
        setAptArea(res.data.response);
        setAptAreaId(aptAreaId);
        setTrxList(res.data.response.trxList);
        //fetchGraph('tab1');

        axios
          .get(`apt/map/aptDtlGraphList?aptAreaId=${aptAreaId}&trxType=${trxType}&graphTab=tab1`)
          .then(
            (res) => {
              //this.setState({ graphList: res.data.response.graphDataList });
              //setGraphList(res.data.response.graphDataList);
              //graphList = res.data.response.graphDataList;
              setGraphList(res.data.response.graphDataList);
              setOnRenderGraph(true);
            }
          );
        $(".chart_list .tab_inner").scrollLeft(0);
        $('ul.chart_tit').find('li').removeClass('current');
        $('ul.chart_tit').find('li').eq(0).addClass('current');
        $('.tab_cont').addClass('current');
      });
  }

  const fetchGraph = (dataTab) => {
    setOnRenderGraph(false);
    axios
      .get(`apt/map/aptDtlGraphList?aptAreaId=${aptAreaId === '' ? props.aptAreaId : aptAreaId}&trxType=${trxType}&graphTab=${dataTab}`)
      .then(
        (res) => {
          //this.setState({ graphList: res.data.response.graphDataList });
          //setGraphList(res.data.response.graphDataList);
          //graphList = res.data.response.graphDataList;
          setGraphList(res.data.response.graphDataList);
          setOnRenderGraph(true);
        }
      );
    $(".chart_list .tab_inner").scrollLeft(0);
    $('.tab_cont').addClass('current');
  }

  const fetchAround = (aptId, lat, lng) => {
    axios
      .get(`apt/map/aroundLocInfoList?aptId=${aptId}&lat=${lat}&lng=${lng}`)
      .then(
        (res) => {
          setAroundLocInfoMap(res.data.response.aroundLocInfoMap);
          renderingAroundDataIcons(res.data.response.aroundLocInfoMap);
        }
      );
  }

  const onChangeArea = (aptAreaId) => {
    //aptAreaId = aptAreaId;
    //$('#select').val(aptAreaId);
    setOnRenderGraph(false);
    setAptAreaId(aptAreaId);
    fetchAptAreaDtl(props.aptId, aptAreaId, trxType);
  }

  const onChangeAreaTrxType = (trxType) => {
    //trxType = trxType;
    setOnRenderGraph(false);
    setTrxType(trxType);
    fetchAptAreaDtl(props.aptId, aptAreaId, trxType);
  }

  const bindSelect = () => {

    $(".btn_sel_wrap").each(function (idx) {
      $(this).data("key", idx + 1);
      // btm select popup 호출
      $(this).find(".btn_sel").on("click", function () {
        var key = $(this).parents(".btn_sel_wrap").data("key");
        popupOpen('select0' + key, 'mwf', 0);
      });
    });

    $(".ly_select_wrap").each(function (idx) {
      $(this).data("ctrlKey", idx + 1);
      // btm select 값 선택// btm select 값 선택
      $(this).find(".pop_sel_wrap .list input").on("change", function () {
        var ctrlKey = $(this).parents(".ly_select_wrap").data("ctrlKey");
        var selTxt = $(this).parents(".item").find(".txt").text();

        $(".btn_sel_wrap").eq(ctrlKey - 1).removeClass("focus").find(".lbl").addClass("hide");
        $(".btn_sel_wrap").eq(ctrlKey - 1).find(".sel_txt").text(selTxt);

        //select value 찾도록 추가
        var selAreaId = $(this).parents(".item").find(".txt").attr('data-value');
        onChangeArea(selAreaId);

        popupClose('select0' + ctrlKey, 'mwf', 0);
      });
    });

    //화면진입시 지도에서 선택되어 들어온 평이 선택되어있도록함
    $('#mw-select01 span').each(function () {
      if ($(this).attr('data-value') == props.aptAreaId) {
        $(this).parents(".item").find('input[type=radio]').prop('checked', true);
        $('.btn_sel_wrap .sel_txt').text($(this).text());
      }
    });
  }

  const handleFavCheck = (e) => {
    if ($(e.target).hasClass('active')) {
      $(e.target).removeClass('active');
      clearFavItem({ aptId: props.aptId })
    }
    else {
      $(e.target).addClass('active');
      addFavItem({ aptId: props.aptId, aptAreaId: props.aptAreaId, favTime: new Date() });
    }
  }


  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  const backToAptMap = () => {
    $('.map_wrap').show();
    setLcUrlItem('aptDtl', 'aptMap');
    popupClose('aptdetail', 'mwf');
    props.onAptDtlDisplay(false);

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: ``
    });
  }

  return (
    <>
      <div id="mw-aptdetail" className="ly_pop ly_apt_detail full">
        <div className="wrap">
          <span className="bg_body_full"></span>
          <div className="content">
            <header className="header">
              <button type="button" onClick={() => backToAptMap()} className="btn_prev" title="뒤로가기"><i></i></button>
              <strong className="tit"></strong>
              <button type="button" onClick={() => backToAptMap()} className="btn_location" title="지도보기"><i></i></button>
            </header>
            <section className="tit_wrap">
              <button type="button" className={getFavList().some(v => v.aptId === props.aptId) ? "tit active" : "tit"}
                //onClick="$(this).toggleClass('active')"
                onClick={handleFavCheck}
              >{aptDtl.aptName}</button>
              <span className="info_txt">{aptDtl.jibunAddr}</span>
              <ul className="info_list">
                <li>{aptDtl.regYear}년</li>
                <li>{numberWithCounts(aptDtl.dpsAmt * 10000)}</li>
                <li>{numberWithCommas(aptDtl.hhldCnt)}세대</li>
                {
                  (aptDtl) &&
                    (aptDtl.loanRateCase == 1 || aptDtl.loanRateCase == 2) ?
                    <li><span>투기 과열</span></li>
                    :
                    (aptDtl.loanRateCase == 3 || aptDtl.loanRateCase == 4) ?
                      <li><span>조정 지역</span></li>
                      :
                      null
                }
              </ul>
            </section>
            <div className="acco_wrap">
              <section className="item">
                <button type="button" className="btn_acco">최근 실거래가 정보</button>
                <div className="acco_view">
                  <section className="apt_d_info">
                    <div className="col_wrap">
                      <div className="col">
                        <div className="tit_wrap">
                          {
                            aptArea.dpsAmt && aptArea.dpsAmt != '-' ?
                              <>
                                <span className="sub_txt">최근 거래 금액</span>
                                <strong className="tit">
                                  <span>{numberReplaceWithCodetag(numberWithCounts(aptArea.dpsAmt * 10000))}</span>
                                  {
                                    aptArea.prevDpsAmt === null ? null :
                                      (aptArea.dpsAmt * 10000) > (aptArea.prevDpsAmt * 10000) ?
                                        <><em className="up">({numberWithCounts((aptArea.dpsAmt * 10000) - (aptArea.prevDpsAmt * 10000))}<i></i>)</em></> :
                                        (aptArea.dpsAmt * 10000) < (aptArea.prevDpsAmt * 10000) ?
                                          <><em className="dw">({numberWithCounts((aptArea.prevDpsAmt * 10000) - (aptArea.dpsAmt * 10000))}<i></i>)</em></> :
                                          null
                                  }
                                </strong>
                              </>
                              : <p className="nodata">최근 거래 내역 없음</p>
                          }
                        </div>
                        {
                          aptArea.dpsAmt > 0 ?
                            <span className="sub_txt">최근 거래일 : {dateFormatMsgKor(aptArea.trxDate)}</span>
                            : null
                        }
                      </div>
                      <div className="col">
                        <div className="form">
                          <div className="ip_box in btn_sel_wrap">
                            <div className="inner_wrap">
                              <button type="button GTM-AIF-1" className="btn_sel">
                                <span className="sel_txt"></span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="ip_wrap rdo_wrap">
                          <div className="rdo_item">
                            <input className="ip_sale" type="radio" id="rdo01-01" name="rdo01"
                              checked={trxType === 'D' ? true : false}
                              onClick={(e) => { onChangeAreaTrxType('D'); }}
                            />
                            <label for="rdo01-01" className="GTM-AIF-2"><i>매매</i></label>
                          </div>
                          <div className="rdo_item">
                            <input className="ip_rent" type="radio" id="rdo01-02" name="rdo01"
                              checked={trxType === 'R' ? true : false}
                              onClick={(e) => { onChangeAreaTrxType('R'); }}
                            />
                            <label for="rdo01-02" className="GTM-AIF-3"><i>전·월세</i></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="chart_list">
                      <ul className="chart_tit">
                        <li data-tab="tab1" className="current"><a href="#" onClick={(e) => { e.preventDefault(); fetchGraph('tab1') }}>전체 기간</a></li>
                        <li data-tab="tab2"><a href="#" onClick={(e) => { e.preventDefault(); fetchGraph('tab2') }}>최근 1년</a></li>
                        <li data-tab="tab3"><a href="#" onClick={(e) => { e.preventDefault(); fetchGraph('tab3') }}>최근 3년</a></li>
                      </ul>
                      <div className="tab1 tab_cont current">
                        {
                          //graphList &&
                          // <MultiType
                          //   graphList={graphList}
                          // ></MultiType>
                          <div id="rMateDivAptDtl" style={{ "height": "300px" }} className={!graphList || graphList.length <= 0 ? 'tab_inner dim' : ''}>
                            {
                              // onRenderGraph && <RMateCandleStickType parentDivId={'rMateDivAptDtl'} graphList={graphList}></RMateCandleStickType>
                              onRenderGraph && <RMateChartH5 chartId={'rMateDivAptDtl'} layout={'COMBINATION_2D_CHART_1'} data={graphList} chartVars={''} width={'100%'} height={'300px'} />
                            }
                          </div>
                        }
                      </div>
                    </div>
                    {
                      onRenderGraph && graphList && graphList.length > 0 &&
                      <>
                        <div className="tb_apt">
                          <table>
                            <colgroup>
                              <col style={{ "width": "25%;" }} />
                              <col style={{ "width": "auto;" }} />
                              <col style={{ "width": "28%;" }} />
                              <col style={{ "width": "15%;" }} />
                            </colgroup>
                            <thead>
                              <tr>
                                <th>거래일</th>
                                <th>실거래가</th>
                                <th>타입</th>
                                <th>층</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                orderBy(trxList, ["trxDate"], ["desc"]).map((v, index) => {
                                  return (
                                    index < trxLimitSize &&
                                    <tr>
                                      <td>{dateFormatMsg(v.trxDate)}</td>
                                      <td>{numberReplaceWithCodetag(numberWithCounts(v.dpsAmt * 10000))}</td>
                                      <td>{(aptDtl.aptAreaList.find(x => x.aptAreaId == v.aptAreaId)).pubAreaPy}평({(aptDtl.aptAreaList.find(x => x.aptAreaId == v.aptAreaId)).pubArea}㎡)</td>
                                      <td>{v.flrNo}층</td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                        {graphList && trxList.length > 10 && trxLimitSize != trxList.length &&
                          <div className="btn_wrap">
                            <button type="button" className="r_btn" onClick={() => setTrxLimitSize(trxList.length)}>더보기</button>
                          </div>
                        }
                      </>
                    }
                  </section>
                </div>
              </section>
              <section className="item">
                <button type="button" className="btn_acco">단지정보</button>
                <div className="acco_view">
                  <ul className="apt_bbs_info">
                    <li>
                      <strong className="th">아파트명</strong>
                      <div className="td">
                        <span className="sub_txt">{aptDtl.aptName}</span>
                      </div>
                    </li>
                    <li>
                      <strong className="th">주소</strong>
                      <div className="td">
                        <span className="sub_txt">{aptDtl.jibunAddr}</span>
                      </div>
                    </li>
                    <li>
                      <strong className="th">건축년도</strong>
                      <div className="td">
                        <span className="sub_txt">{aptDtl.regYear}년</span>
                      </div>
                    </li>
                    <li>
                      <strong className="th">세대수</strong>
                      <div className="td">
                        <span className="sub_txt">{numberWithCommas(aptDtl.hhldCnt)}세대</span>
                      </div>
                    </li>
                    <li>
                      <strong className="th">층수</strong>
                      <div className="td">
                        <span className="sub_txt">{aptDtl.minFlrCnt} ~ {aptDtl.maxFlrCnt}층</span>
                      </div>
                    </li>
                    <li>
                      <strong className="th">주차대수</strong>
                      <div className="td">
                        <span className="sub_txt">총 {numberWithCommas(aptDtl.pkngCnt)}대</span>
                      </div>
                    </li>
                    <li>
                      <strong className="th">용적률</strong>
                      <div className="td">
                        <span className="sub_txt">{aptDtl.vlRate}%</span>
                      </div>
                    </li>
                    <li>
                      <strong className="th">건폐율</strong>
                      <div className="td">
                        <span className="sub_txt">{aptDtl.bcRate}%</span>
                      </div>
                    </li>
                  </ul>
                  {/* <div className="btn_wrap">
                    <button type="button" className="btn" onClick={() => openApplyPay()}>단계별 필요 금액</button>
                    <button type="button" className="btn" onClick={() => openApplyCalc()}>청약가점 확인하기</button>
                  </div> */}
                </div>
              </section>
              <section className="item">
                <button type="button" className="btn_acco">주변정보</button>
                <div className="acco_view">
                  <div className="map_sec" id="mapviewDtl">
                    {/* <div id="mapviewDtl"></div> */}
                    {/* <img src="resources/images/temp/map.png" alt="" /> */}
                  </div>
                  <ul className="amenities">
                    {
                      (aroundLocInfoMap.stationList != null && aroundLocInfoMap.stationList.length > 0) ?
                        aroundLocInfoMap.stationList &&
                        <li className={aroundLocInfoMap.stationList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="th icon_subway">지하철역</strong>
                          <div className="td sub_list">
                            <ul>
                              {
                                orderBy(aroundLocInfoMap.stationList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (    
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <strong className="sub_th"><span className="tit" onClick={() => onPanTo(result.lat, result.lng)}>{result.locName}</span>({numberWithCommas(result.distance)}m)</strong>
                                      <span className="sub_td">도보 {parseInt(result.distance / 66, 10)} 분</span>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                          </div>
                          {aroundLocInfoMap.stationList.length > 3 && <button type="button" className="btn_more" onClick={(e) => moreView(e)}></button>
                          }
                        </li>
                        :
                        null
                    }
                    {
                      (aroundLocInfoMap.schoolList != null && aroundLocInfoMap.schoolList.length > 0) ?
                        aroundLocInfoMap.schoolList &&
                        <li className={aroundLocInfoMap.schoolList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="th icon_school">학교</strong>
                          <div className="td sub_list">
                            <ul>
                              {
                                orderBy(aroundLocInfoMap.schoolList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (    
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <strong className="sub_th"><span className="tit" onClick={() => onPanTo(result.lat, result.lng)}>{result.locName}</span>({numberWithCommas(result.distance)}m)</strong>
                                      <span className="sub_td">도보 {parseInt(result.distance / 66, 10)} 분</span>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                          </div>
                          {aroundLocInfoMap.schoolList.length > 3 && <button type="button" className="btn_more" onClick={(e) => moreView(e)}></button>
                          }
                        </li>
                        :
                        null
                    }
                    {
                      (aroundLocInfoMap.departList != null && aroundLocInfoMap.departList.length > 0) ?
                        aroundLocInfoMap.departList &&
                        <li className={aroundLocInfoMap.departList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="th icon_mart">마트·백화점</strong>
                          <div className="td sub_list">
                            <ul>
                              {
                                orderBy(aroundLocInfoMap.departList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (    
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <strong className="sub_th"><span className="tit" onClick={() => onPanTo(result.lat, result.lng)}>{result.locName}</span>({numberWithCommas(result.distance)}m)</strong>
                                      <span className="sub_td">도보 {parseInt(result.distance / 66, 10)} 분</span>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                          </div>
                          {aroundLocInfoMap.departList.length > 3 && <button type="button" className="btn_more" onClick={(e) => moreView(e)}></button>
                          }
                        </li>
                        :
                        null
                    }
                    {
                      (aroundLocInfoMap.hospitalList != null && aroundLocInfoMap.hospitalList.length > 0) ?
                        aroundLocInfoMap.hospitalList &&
                        <li className={aroundLocInfoMap.hospitalList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="th icon_hospital">병원</strong>
                          <div className="td sub_list">
                            <ul>
                              {
                                orderBy(aroundLocInfoMap.hospitalList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (    
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <strong className="sub_th"><span className="tit" onClick={() => onPanTo(result.lat, result.lng)}>{result.locName}</span>({numberWithCommas(result.distance)}m)</strong>
                                      <span className="sub_td">도보 {parseInt(result.distance / 66, 10)} 분</span>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                          </div>
                          {aroundLocInfoMap.hospitalList.length > 3 && <button type="button" className="btn_more" onClick={(e) => moreView(e)}></button>
                          }
                        </li>
                        :
                        null
                    }
                    {
                      (aroundLocInfoMap.parkList != null && aroundLocInfoMap.parkList.length > 0) ?
                        aroundLocInfoMap.parkList &&
                        <li className={aroundLocInfoMap.parkList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="th icon_park">공원</strong>
                          <div className="td sub_list">
                            <ul>
                              {
                                orderBy(aroundLocInfoMap.parkList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (    
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <strong className="sub_th"><span className="tit" onClick={() => onPanTo(result.lat, result.lng)}>{result.locName}</span>({numberWithCommas(result.distance)}m)</strong>
                                      <span className="sub_td">도보 {parseInt(result.distance / 66, 10)} 분</span>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                          </div>
                          {aroundLocInfoMap.parkList.length > 3 && <button type="button" className="btn_more" onClick={(e) => moreView(e)}></button>
                          }
                        </li>
                        :
                        null
                    }
                  </ul>
                </div>
              </section>
            </div>
            <SubFooter></SubFooter>
          </div>
        </div>
        <button type="button" className="btn_top"><span className="blind">상단으로 이동</span></button>
      </div>

      <div id="mw-select01" className="ly_pop popBtm ly_select_wrap">
        <div className="wrap">
          <button type="button" onClick={() => popupClose('select01', 'mwf', 0)} className="close"><i>닫기</i></button>
          <div className="content pop_sel_wrap">
            <ul className="list">
              {
                aptDtl.aptAreaList &&
                aptDtl.aptAreaList.map((result, idx) => {
                  const radioId = `select01_${idx}`
                  return (
                    <li>
                      <span className="item">
                        <input type="radio" id={radioId} name="select01" />
                        <label for={radioId}>
                          <span className="txt" data-value={result.aptAreaId}>{result.pubAreaPy}평 ({result.pubArea}㎡)</span>
                        </label>
                      </span>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default AptDtl;