import create from 'zustand';

export const useJoinStore = create(set => ({
    isMVNO: false,
    setMVNO: boolean => set({
        isMVNO: boolean,
    }),
    isShowPopup: false,
    setShowPopup: boolean => set({
        isShowPopup: boolean,
    }),
}));