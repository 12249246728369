import { basic, auth } from 'routes/common/axios/AxiosInstance';

const getMap = (config) => {
    return auth.get('apt/map/getMap', config);
}

const findAptList = (config) => {
    return basic.get('apt/map/search', config);
}

const findMapApplyList = (config) => {
    return basic.get('apt/apply/findMapApplyList', config);
}

const findApplyList = (config) => {
    return basic.get('apt/apply/list', config);
}

const findMyLocApplyList = (config) => {
    return auth.get('apt/apply/findMyLocApplyList', config);
}

const findAvgApplyScoreList = (config) => {
    return basic.get('apt/apply/predict/findAvgApplyScoreList', config);
}

const findMarginPriceList = (config) => {
    return basic.get('apt/apply/predict/findMarginPriceList', config);
}

const findAptTrxMonthlyGroup = (config) => {
    return basic.get('apt/apply/predict/findAptTrxMonthlyGroup', config);
}

const applyDtl = (config) => {
    return basic.get('apt/apply/dtl', config);
}

const aptDtl = (config) => {
    return basic.get('apt/map/mblAptDtl', config);
}

const aptAreaDtl = (config) => {
    return basic.get('apt/map/aptAreaDtl', config);
}

const aptDtlGraphList = (config) => {
    return basic.get('apt/map/aptDtlGraphList', config);
}

const aroundLocInfoList = (config) => {
    return basic.get('apt/map/aroundLocInfoList', config);
}

const login = (data, config) => {
    return basic.post('apt/user/submitAuth', data, config);
}

const expire = (data, config) => {
    return basic.post('apt/expire/submit', data, config);
}

const check = (config) => {
    return auth.get('apt/authorized/check', config);
}

const reIssue = (data, config) => {
    return auth.post('apt/authorized/reIssue', data, config);
}

export default {
    getMap,
    findAptList,
    findMapApplyList,
    findApplyList,
    findMyLocApplyList,
    findAvgApplyScoreList,
    findMarginPriceList,
    findAptTrxMonthlyGroup,
    applyDtl,
    aptDtl,
    aptAreaDtl,
    aptDtlGraphList,
    aroundLocInfoList,
    login,
    expire,
    check,
    reIssue,
}