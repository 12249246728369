import React, {useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'; // url 구분 작업
import {useStateWithCallbackLazy} from 'use-state-with-callback';

import MainFilter from 'routes/apt/rcmd/filter/MainFilter';
import AprFilter from 'routes/apt/rcmd/filter/AprFilter';
import PyFilter from 'routes/apt/rcmd/filter/PyFilter';

import RcmdList from 'routes/apt/rcmd/RcmdList';
import RcmdDtl from 'routes/apt/rcmd/RcmdDtl';
import RcmdDtlMap from 'routes/apt/rcmd/RcmdDtlMap';

import axios from 'axios';
import $ from 'jquery';

import {addRecentItem, clearRecentItem, getRecentItem, searchBjd, setRecentList} from 'routes/common/Search';
import {
    popupClose,
    popupOpen,
    uiChartTap,
    uiFilterTabSwipe,
    uiFixValue,
    uiInputDel,
    uiIpFocus,
    uiMainSwitch,
    uiSelFocus,
    uiToggle,
    uiTopGo
} from 'routes/common/Design.js'
import {findIndex} from 'routes/common/ArrayUtil';
import _ from 'lodash';

import RecentResult from 'routes/common/RecentResult';
import SearchResult from 'routes/common/SearchResult';
import Moment from 'moment';

import {getLcParamItem, getLcUrlItem, setLcParamItem, setLcUrlItem} from 'routes/common/ParamStorage';
import {callAppInterface} from 'routes/common/AppInterface';


let apr = { min: -1, max: 9999 };
let py = { min: -1, max: 9999 };

//// 컴포넌트 사용처 : 아파트 추천
const RcmdSrch = (props) => {

  const [aptRecommParam1, setAptRecommParam1] = useStateWithCallbackLazy('N'); //주택소유여부
  const [aptRecommParam2Txt, setAptRecommParam2Txt] = useStateWithCallbackLazy(''); //지역(화면에보여지는 텍스트)
  const [aptRecommParam2, setAptRecommParam2] = useStateWithCallbackLazy(''); //지역

  const [aptRecommParam3Min, setAptRecommParam3Min] = useStateWithCallbackLazy(-1); //평수min
  const [aptRecommParam3Max, setAptRecommParam3Max] = useStateWithCallbackLazy(-1); //평수max

  const [aptRecommParam4Min, setAptRecommParam4Min] = useStateWithCallbackLazy(-1); //아파트연식min
  const [aptRecommParam4Max, setAptRecommParam4Max] = useStateWithCallbackLazy(-1); //아파트연식max

  const [aptRecommParam5, setAptRecommParam5] = useStateWithCallbackLazy(0); //연소득액
  const [aptRecommParam6, setAptRecommParam6] = useStateWithCallbackLazy(0); //현재보유자산
  const [aptRecommParam7, setAptRecommParam7] = useStateWithCallbackLazy(0); //기존대출연상환액

  const [searchInfo, setSearchInfo] = useStateWithCallbackLazy([]);

  const [filterName, setFilterName] = useStateWithCallbackLazy({});

  const [keyword, setKeyword] = useStateWithCallbackLazy('');

  const [recentResult, setRecentResult] = useStateWithCallbackLazy(getRecentItem('bjdMap'));
  const [searchResult, setSearchResult] = useStateWithCallbackLazy([]);

  const [searchIndex, setSearchIndex] = useStateWithCallbackLazy(-1);

  const [recentFocus, setRecentFocus] = useStateWithCallbackLazy(true);
  const [searchVisible, setSearchVisible] = useStateWithCallbackLazy(false);

  const [rcmdPopupType, setRcmdPopupType] = useStateWithCallbackLazy('');

  const lcUrlMap = getLcUrlItem();
  const lcParamMap = getLcParamItem();

  // url 구분 작업
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    uiFixValue.init();
    uiFilterTabSwipe.init();
    uiSelFocus.init();
    uiMainSwitch.init();
    uiChartTap.init();
    uiToggle.init();
    uiTopGo.init();

    uiIpFocus.init();
    uiInputDel.init();

    $(window).scrollTop(0);

    // 상단 검색 필터
    $(".filter_tab_swipe").each(function (idx) {
      $(this).data("key", idx).find(".view .item").each(function (_idx) {
        $(this).data("ctrlKey", _idx);
      });
    });

    // 추천 필터 값 선택
    $(".ly_apt_filter .filter_tab_swipe").find(".filter_box_wrap button").on("click", function () {
      var ctrlKey = $(this).parents(".item").data("ctrlKey");
      var filter_txt = $(this).text();

      $(".ly_apt_filter .filter_tab_swipe").find(".slide a").eq(ctrlKey).addClass("change_txt");
      $(".ly_apt_filter .filter_tab_swipe").find(".slide a").eq(ctrlKey).text(filter_txt);

      //UI.tabSwiper.slideTo(ctrlKey);
      $(".ly_apt_filter .filter_tab_swipe").find(".slide").removeClass("active");
      $(".ly_apt_filter .filter_tab_swipe").find(".slide").eq(ctrlKey).addClass("active");
      uiFilterTabSwipe.init();

    });

    // 추천 필터 값 입력시 포커스 이동
    $(".ly_apt_filter .filter_tab_swipe").find(".view .item").each(function (idx) {
      var key = idx;
      $(this).find(".ip_box .ip").each(function (_idx) {
        var _input = $(this);
        _input.on("focus", function () {
          var offset = $(this).parents(".filter_tab_swipe").find(".view .item").eq(idx).position().top;
          $("html,body").animate({ scrollTop: offset }, 200);

          //UI.tabSwiper.slideTo(idx);
          $(".ly_apt_filter .filter_tab_swipe").find(".slide a").eq(idx).addClass("change_txt");
          $(".ly_apt_filter .filter_tab_swipe").find(".slide").removeClass("active");
          $(".ly_apt_filter .filter_tab_swipe").find(".slide").eq(idx).addClass("active");
        });

        _input.on("blur", function () {
          uiFilterTabSwipe.init();
        });
      });
    })

    handleChangeFilter({ filterType: "PY", min: -1, max: 9999, mainName: "" });
    handleChangeFilter({ filterType: "APR", min: -1, max: 9999, mainName: "" });

    window.goback = backToAptMain;
    window.checkMainPage = () => { return false; }

    return () => {
      window.checkMainPage = () => { return true; }
    }

  }, []);

  // useEffect(() => {
  //   uiFixValue.init();
  //   uiFilterTabSwipe.init();
  // }, []);



  //aptRecommend
  const onChangeAptRecommendParam1 = (e) => {
    setAptRecommParam1(e.target.value);
    if (e.target.value == 'Y') {
      filterName.houseYn = '유주택자';
    }
    else {
      filterName.houseYn = '무주택자';
    }
  }
  const onChangeAptRecommendParam2 = (e) => {
    setAptRecommParam2Txt(e.target.value);
  }
  const onChangeAptRecommendParam3 = (pyMin, pyMax) => {
    setAptRecommParam3Min(pyMin);
    setAptRecommParam3Max(pyMax);
  }
  const onChangeAptRecommendParam4 = (aprMin, aprMax) => {
    setAptRecommParam4Min(aprMin);
    setAptRecommParam4Max(aprMax);
  }
  const onChangeAptRecommendParam5 = (e) => {
    const max = e.target.getAttribute('maxLength');
    let value = e.target.value;

    if (value.length > max) value = value.slice(0, max);
    e.target.value = value.replace(/[^0-9]/g, '');
    setAptRecommParam5(value);
    filterName.yearPay = value + '만원';
  }
  const onChangeAptRecommendParam6 = (e) => {
    const max = e.target.getAttribute('maxLength');
    let value = e.target.value;

    if (value.length > max) value = value.slice(0, max);
    e.target.value = value.replace(/[^0-9]/g, '');
    setAptRecommParam6(value);
    filterName.assets = value + '만원';
  }
  const onChangeAptRecommendParam7 = (e) => {
    const max = e.target.getAttribute('maxLength');
    let value = e.target.value;

    if (value.length > max) value = value.slice(0, max);
    e.target.value = value.replace(/[^0-9]/g, '');
    setAptRecommParam7(value);
    filterName.monthlyLoan = value + '만원';
  }

  const handleChangeKeyword = _.debounce((keyword) => {
    let recentData = getRecentItem('bjdMap');
    //this.setState({ keyword: keyword, aptRecommParam2Txt: keyword, aptRecommParam2: keyword });
    //setKeyword(keyword);
    setAptRecommParam2Txt(keyword);
    setAptRecommParam2(keyword);

    if (keyword) {
      searchBjd(keyword, (data) => {
        keyword && //this.setState({ searchResult: data, recentResult: [], searchIndex: -1, recentFocus: false });
          setSearchResult(data);
        setRecentResult(recentData);
        setSearchIndex(-1);
        setRecentFocus(false);
      })
    } else {

      //this.setState({ searchResult: [], recentResult: recentData, searchIndex: -1, recentFocus: true });
      setSearchResult([]);
      setRecentResult(recentData);
      setSearchIndex(-1);
      setRecentFocus(true);
    }

  }, 300);

  const debounceChangeKeyword = (e) => {

    const { target: { value } } = e;
    //this.setState({ keyword: value, searchVisible: true })
    setKeyword(value);
    setSearchVisible(true);
    setRecentFocus(false);
    handleChangeKeyword(value);
    //setAptRecommParam2(value);
    //onChangeAptRecommendParam2(e);
  }

  const handleClearRecent = () => {

    clearRecentItem('bjdMap');
    //this.setState({ recentResult: [] });
    setRecentResult([]);
  }

  const handleRemoveRecent = (searchId) => {
    let recentList = getRecentItem('bjdMap');
    if (recentList.some(f => f.searchId === searchId)) {
      recentList.splice(findIndex(recentList, f => f.searchId === searchId), 1);
    }
    setRecentList('bjdMap', recentList);
    //this.setState({ recentResult: recentList });
    setRecentResult(recentList);
  }

  const handleSelectSearch = (search) => {
    search.regTime = Moment(new Date()).format('YYYY. MM. DD');
    addRecentItem('bjdMap', search);
    //this.setState({ searchInfo: search, keyword: search.searchName, aptRecommParam2Txt: search.searchName, aptRecommParam2: search.searchId });
    setSearchInfo(search);
    setKeyword(search.searchName);
    setAptRecommParam2Txt(search.searchName);
    setAptRecommParam2(search.searchId);
    setSearchVisible(false);

    popupClose('srch', 'mwf');
    setSearchResult([]);

    let localsNameArr = search.searchName.split(' ');

    //if(localsNameArr.length)
    filterName.locals = localsNameArr[localsNameArr.length - 1];
  }
  const handleKeydownKeyword = (e) => {
    let keyCode = e.keyCode;
    if (!searchResult.length > 0) {
      return;
    }
    if (keyCode === 38) {     // uparrowKey
      //this.setState({ searchIndex: searchIndex <= 0 ? 0 : searchIndex - 1 });
      setSearchIndex(searchIndex <= 0 ? 0 : searchIndex - 1);
    } else if (keyCode === 40) {     // downarrowKey
      let maxIndex = searchResult.length - 1;
      //this.setState({ searchIndex: searchIndex >= maxIndex ? maxIndex : searchIndex + 1 });
      setSearchIndex(searchIndex >= maxIndex ? maxIndex : searchIndex + 1);
    } else if (keyCode === 13) {      // enterKey
      if (searchIndex === -1) {
        //this.setState({ searchResult: [], searchIndex: -1, recentFocus: false, keyword: searchResult[0].searchName, aptRecommParam2Txt: searchResult[0].searchName, aptRecommParam2: searchResult[0].searchId, searchVisible: false });

        setSearchResult([]);
        setSearchIndex(-1);
        setRecentFocus(false);
        setKeyword(searchResult[0].searchName);
        setAptRecommParam2Txt(searchResult[0].searchName);
        setAptRecommParam2(searchResult[0].searchId);
        setSearchVisible(false);

        searchResult[0].regTime = Moment(new Date()).format('YYYY. MM. DD');
        addRecentItem('bjdMap', searchResult[0]);
        handleSelectSearch(searchResult[0]);
      } else {
        let search = searchResult[searchIndex];
        //this.setState({ searchResult: [], searchIndex: -1, recentFocus: false, keyword: search.searchName, aptRecommParam2Txt: search.searchName, aptRecommParam2: search.searchId, searchVisible: false });

        setSearchResult([]);
        setSearchIndex(-1);
        setRecentFocus(false);
        setKeyword(search.searchName);
        setAptRecommParam2Txt(search.searchName);
        setAptRecommParam2(search.searchId);
        setSearchVisible(false);

        search.regTime = Moment(new Date()).format('YYYY. MM. DD');
        addRecentItem('bjdMap', search);
        handleSelectSearch(search);
      }

    }
  }

  const handleChangeFilter = ({ filterType, min, max, mainName }) => {

    const filterObj = {};
    filterObj.min = min;
    filterObj.max = max;

    switch (filterType) {
      case "PY": py = filterObj; filterName.py = mainName; onChangeAptRecommendParam3(min, max); break;
      case "APR": apr = filterObj; filterName.apr = mainName; onChangeAptRecommendParam4(min, max); break;
      default: break;
    }

    setFilterName({ ...filterName }, () => {
      //fetchApplyList();
    });
  }

  const openCreditAgreePop = () => {
    const agreeUrl = `${process.env.REACT_APP_APT_WEB_ORIGIN}/apt/terms?type=10`;
    callAppInterface('openWebPage', JSON.stringify({ type: 'I', url: agreeUrl }), result => {

    });
  }

  //--'open' : 현 화면이 다음 화면으로 변경되는 action
  const openRcmdList = async () => {

    let rcmdParams = {
      "param1": aptRecommParam1,
      "param2": aptRecommParam2,
      "param3Min": aptRecommParam3Min,
      "param3Max": aptRecommParam3Max,
      "param4Min": aptRecommParam4Min,
      "param4Max": aptRecommParam4Max,
      "param5": aptRecommParam5,
      "param6": aptRecommParam6,
      "param7": aptRecommParam7
    };

    if (aptRecommParam1 === '' || aptRecommParam2 === ''
      || (aptRecommParam3Min === -1 && aptRecommParam3Max === -1)
      || (aptRecommParam4Min === -1 && aptRecommParam4Max === -1)
      || aptRecommParam5 === 0 || aptRecommParam6 === 0
      || aptRecommParam7 === 0) {
      alert("입력되지 않은 부분을 확인하여 주세요.");
      return;
    }

    if ($('#termsCreditAgree').prop('checked') === true) {

      const config = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } };
      // AGREE.java 참고
      const agreePath = 'ETC_AGREE';
      const agreeName = 'ETCA_002_O';
      
      let param2 = `?agreePath=${agreePath}&agreeName=${agreeName}`;
      // 약관 동의 여부 저장
      const termsAgreeSave = await axios.get('apt/terms/agree/termsAgreeSave'+param2, config);

      if (termsAgreeSave) {
        switch (termsAgreeSave.data.code) {
          case 'E0000': {
            popupClose('creditAgree', 'mwf', 0);

            break;
          }
          default : {
            alert(termsAgreeSave.data.message);
            
            return false;
          }
        }
      }

      const resultGetAptList = await axios.get(`apt/rcmd/aptList?&param1=${aptRecommParam1}&param2=${aptRecommParam2}&param3Min=${aptRecommParam3Min}&param3Max=${aptRecommParam3Max}&param4Min=${aptRecommParam4Min}&param4Max=${aptRecommParam4Max}&param5=${aptRecommParam5}&param6=${aptRecommParam6}&param7=${aptRecommParam7}`);
      if(resultGetAptList.data) {
        if (resultGetAptList.data.response != null && resultGetAptList.data.response.aptList.length > 0) {
          setLcParamItem({ 'rcmdParams': rcmdParams });
          setRcmdPopupType('P_A');

          // url 구분 작업
          history.push({
            pathname: location.pathname,
            search: `?REC-1200#`
          });
        }
        else {
          alert('검색 결과가 없습니다.');
          return false;
        }
      }
    }
    else {
      alert('약관에 동의해 주세요.');
      return false;
    }
  }


  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  const backToAptMain = () => {
    setLcUrlItem('rcmdSrch', 'aptMain');
    setLcParamItem({});
    $('.map_wrap').show();
    props.onRcmdSrchDisplay(false);

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: ``
    });
  }

  return (
    <>
      {
        rcmdPopupType === '' &&
        <>
          <div id="mw-recommend" className="ly_apt_filter full">
            <div className="wrap">
              <div className="content">
                <header className="header">
                  <button type="button" onClick={() => backToAptMain()} className="btn_prev GTM-REC-PREV-1" title="뒤로가기"><i></i></button>
                  <strong className="tit">아파트추천</strong>
                  {/* <button type="button" onClick={() => openRcmdDtlMap()} className="btn_location" title="지도보기"><i></i></button> */}
                </header>
                <div className="inner_wrap">
                  <div className="top_srch_wrap full active">
                    <div className="filter_tab_swipe">
                      <MainFilter filterName={filterName} />
                      <div className="view">
                        <div className="item">
                          <strong className="tit">주택소유 여부</strong>
                          <div className="ip_wrap rdo_wrap">
                            <div className="rdo_item">
                              <input type="radio" id="rdo01-01" name="rdo01" //onChange={onChangeAptRecommendParam1} />
                                value='Y'
                                onClick={(e) => onChangeAptRecommendParam1(e)}
                                checked={aptRecommParam1 === 'Y' ? true : false}
                              />
                              <label for="rdo01-01" className="ico_house_o GTM-REC-1"><i>주택 있음</i></label>
                            </div>
                            <div className="rdo_item">
                              <input type="radio" id="rdo01-02" name="rdo01" //onChange={onChangeAptRecommendParam1} checked />
                                value='N'
                                onClick={(e) => onChangeAptRecommendParam1(e)}
                                checked={aptRecommParam1 === 'N' ? true : false}
                              />
                              <label for="rdo01-02" className="ico_house_x GTM-REC-2"><i>주택 없음</i></label>
                            </div>
                          </div>
                        </div>
                        <div className="item">
                          <strong className="tit">지역</strong>
                          <button type="button" className="btn_srch_full GTM-REC-3" onClick={() => { popupOpen('srch', 'mwf'); uiFilterTabSwipe.init(); uiIpFocus.init(); uiInputDel.init(); $('#srch_input02').focus() }}>
                            <span>{aptRecommParam2Txt === '' ? "지역을 검색해주세요." : aptRecommParam2Txt}</span>
                          </button>
                        </div>
                        <PyFilter onChangeFilter={handleChangeFilter} />
                        <AprFilter onChangeFilter={handleChangeFilter} />
                        <div className="item">
                          <strong className="tit">나의 연 소득액</strong>
                          <div className="form_wrap">
                            <div className="form">
                              <div className="ip_box ip_del">
                                <label for="ip02" className="lbl">연 소득액을 입력하세요.</label>
                                <input type="number" id="ip02" className="ip code GTM-REC-4" maxLength="8" onChange={onChangeAptRecommendParam5} />
                                <button type="button" onClick="" className="del"><i>삭제</i></button>
                                <em className="txt">만원</em>
                              </div>
                            </div>
                          </div>
                          <button type="button" className="btn_tip GTM-REC-TIP-1" onClick={() => popupOpen('alert01', 'mwf', 0)}>나의 연 소득액 이란?</button>
                        </div>
                        <div className="item">
                          <strong className="tit">현재 보유자산 (부채 제외)</strong>
                          <div className="form_wrap">
                            <div className="form">
                              <div className="ip_box ip_del">
                                <label for="ip03" className="lbl">보유자산을 입력하세요.</label>
                                <input type="number" id="ip03" className="ip code GTM-REC-5" maxLength="8" onChange={onChangeAptRecommendParam6} />
                                <button type="button" onClick="" className="del"><i>삭제</i></button>
                                <em className="txt">만원</em>
                              </div>
                            </div>
                          </div>
                          <button type="button" className="btn_tip GTM-REC-TIP-2" onClick={() => popupOpen('alert02', 'mwf', 0)}>현재 보유자산 이란?</button>
                        </div>
                        <div className="item">
                          <strong className="tit">대출 월 상환액 (대출 및 기타 부채)</strong>
                          <div className="form_wrap">
                            <div className="form">
                              <div className="ip_box ip_del">
                                <label for="ip04" className="lbl">대출 월 상한액을 입력하세요.</label>
                                <input type="number" id="ip04" className="ip code GTM-REC-6" maxLength="8" onChange={onChangeAptRecommendParam7} />
                                <button type="button" onClick="" className="del"><i>삭제</i></button>
                                <em className="txt">만원</em>
                              </div>
                            </div>
                          </div>
                          <button type="button" className="btn_tip GTM-REC-TIP-3" onClick={() => popupOpen('alert03', 'mwf', 0)}>대출 월 상한액이란?</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="page_info_txt">아파트청약케어는 고객님의 신용정보가 아닌,<br />입력 정보만을 기준으로 결과를 산출해드립니다.</p>
                </div>
                <div className="btn_area_wide fix btm">
                  <button type="button" className="skip GTM-REC-7" onClick={() => popupOpen('creditAgree', 'mwf', 0)}>결과보기</button>
                </div>
              </div>
            </div>
          </div>
          <div id="mw-srch" className="ly_pop ly_apt_srch full">
            <div className="wrap">
              <div className="content">
                <header className="header">
                  <button type="button" onClick={() => { popupClose('srch', 'mwf'); setSearchVisible(false); setSearchResult([]); }} className="btn_prev" title="닫기"><i></i></button>
                  <div className="form">
                    <div className="ip_box ip_del">
                      <label for="srch_input02" className="lbl" style={{ "display": "inline" }}>어느 지역 아파트가 궁금하세요?</label>
                      <input type="text" autocomplete="off" id="srch_input02" className="ip"
                        onChange={debounceChangeKeyword}
                        onFocus={debounceChangeKeyword}
                        onKeyDown={handleKeydownKeyword}
                        value={keyword}
                      />
                      <button type="button" onClick="" className="del" style={{ "display": "none" }}><i>삭제</i></button>
                    </div>
                  </div>
                </header>
                <div className="srch_list_wrap active">
                  <div className="inner_wrap">
                    {
                      recentFocus ?
                        <RecentResult
                          recentResult={recentResult}
                          onClearRecent={handleClearRecent}
                          onRemoveRecent={handleRemoveRecent}
                          onSelectRecent={(search) => handleSelectSearch(search)}
                        //onChange={(e) => onChangeAptRecommendParam2(e)}
                        ></RecentResult>
                        : <SearchResult
                          searchResult={searchResult}
                          searchKeyword={keyword}
                          onSelectSearch={(search) => handleSelectSearch(search)}
                          onKeyDown={handleKeydownKeyword}
                          searchIndex={searchIndex}
                          searchVisible={searchVisible}
                        //onChange={(e) => onChangeAptRecommendParam2(e)}
                        ></SearchResult>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="mw-alert01" className="ly_pop popBtm ly_alert_b">
            <div className="wrap">
              <div className="content">
                <strong className="tit">나의 연 소득액이란?</strong>
                <p className="txt">근로소득원천징수영수증 혹은 관할 세무서에서 발행한<br />소득금액증명원의  소득액 정보를 입력해주세요. <br /></p>
                <button type="button" onClick={() => popupClose('alert01', 'mwf', 0)} className="close"><i>닫기</i></button>
              </div>
            </div>
          </div>
          <div id="mw-alert02" className="ly_pop popBtm ly_alert_b">
            <div className="wrap">
              <div className="content">
                <strong className="tit">현재 보유자산 이란?</strong>
                <p className="txt">아파트 구매시 사용할수 있는<br />보유현금(예/적금/주식 등) 정보를 입력해주세요. <br /></p>
                <button type="button" onClick={() => popupClose('alert02', 'mwf', 0)} className="close"><i>닫기</i></button>
              </div>
            </div>
          </div>
          <div id="mw-alert03" className="ly_pop popBtm ly_alert_b">
            <div className="wrap">
              <div className="content">
                <strong className="tit">대출 월 상한액이란?</strong>
                <p className="txt">아파트 구매 예정자 명의 대출,사채 등 부채 총 금액을 입력해주세요. <br />대상 대출:  주택담보대출, 비주택담보대출, 신용대출, 전세보증금담보대출, 예적금담보대출,<br />유가증권담보대출 제외 대출 : 서민금융상품, 00만원 이하 소액신용대출,유가증권담보대출, 전세자금대출, 이주비중도금대출, 주택연금(역모기지론), 보험약관대출,할부, 리스 및 현금서비스, 카드론 등은 계산에서 제외</p>
                <button type="button" onClick={() => popupClose('alert03', 'mwf', 0)} className="close"><i>닫기</i></button>
              </div>
            </div>
          </div>

          <div id="mw-creditAgree" className="ly_pop popBtm ly_alert_b ly_terms">
            <div className="wrap">
              <div className="content">
                <strong className="tit">구매 가능 아파트 추천 기능 제공을 위해,<br /> 개인(신용)정보 수집 및 이용동의가<br /> 필요해요.</strong>
                <div className="chk_terms">
                  <span className="checkbox i_all">
                    <input type="checkbox" id="termsCreditAgree" />
                    <label for="termsCreditAgree"><i></i></label>
                    <button type="button" className="link" onClick={() => openCreditAgreePop()}>[선택] 개인(신용)정보 수집 및 이용 동의</button>
                  </span>
                </div>
                <div className="btn_area_half">
                  <button type="button" className="skip" onClick={() => openRcmdList()}>구매 가능 아파트 보기</button>
                </div>
                <button type="button" onClick={() => popupClose('creditAgree', 'mwf', 0)} className="close"><i>닫기</i></button>
              </div>
            </div>
          </div>

        </>
      }
      {rcmdPopupType === 'P_A' && <RcmdList onRcmdSrchDisplay={props.onRcmdSrchDisplay} onRcmdPopupType={setRcmdPopupType}></RcmdList>}
      {rcmdPopupType === 'P_B' && <RcmdDtl onRcmdSrchDisplay={props.onRcmdSrchDisplay} onRcmdPopupType={setRcmdPopupType} onRealtyMasterDisplay={props.onRealtyMasterDisplay} onRealtyMenuType={props.onRealtyMenuType} onApplyListDisplay={props.onApplyListDisplay} onApplyMenuType={props.onApplyMenuType} ></RcmdDtl>}
      {rcmdPopupType === 'P_C' && <RcmdDtlMap onRcmdSrchDisplay={props.onRcmdSrchDisplay} onRcmdPopupType={setRcmdPopupType}></RcmdDtlMap>}
    </>
  );
}

export default RcmdSrch;