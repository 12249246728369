import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'; // url 구분 작업
import {useStateWithCallbackLazy} from 'use-state-with-callback';

import axios from 'axios';
import $ from 'jquery';
import {dateFormatMsg2} from 'routes/common/StringUtil';
import {
    popupClose,
    popupOpen,
    uiChartTap,
    uiFilterTabSwipe,
    uiFixValue,
    uiInputDel,
    uiIpFocus,
    uiMainSwitch,
    uiSelFocus,
    uiToggle,
    uiTopGo
} from 'routes/common/Design.js'
import _ from 'lodash';

import {popupAlert01, popupAlert02, popupAlert03} from 'routes/apply/calc/PopupNotice';

import {getLcParamItem, getLcUrlItem, setLcParamItem, setLcUrlItem} from 'routes/common/ParamStorage';
import {callAppInterface} from 'routes/common/AppInterface';

import {osCheck} from 'routes/common/OsUtil';

let resultHouseScore = 0;
let resultFamilyScore = 0;
let resultAccountScore = 0;

let currentStep = 1;

// let validMap =
//   [
//     {
//       'step': 1,
//       'ownHouseYn': null,
//       'applyNotiDate': '',
//       'birthDate': '',
//       'marriageYn': null,
//       'marriageDate': '',
//       'ownHouseHistYn': null,
//       'ownHouseHistDate': '',
//       'familyYn': null,
//       'myParents': null,
//       'spouseParents': null,
//       'children': null,
//       'accountDate': '',
//       'step1CalcScore': 0,
//       'step2CalcScore': 0,
//       'step3CalcScore': 0,
//       'totalCalcScore': 0
//     }
//   ]


let validMap =
  [];


//// 컴포넌트 사용처 : 청약 가점계산기
const ApplyCalc = (props) => {

  const [updateState, setUpdateState] = useStateWithCallbackLazy(false);
  const [saved, setSaved] = useStateWithCallbackLazy(false);

  const [applyId, setApplyId] = useState('');

  const [step, setStep] = useState(1);
  //const [step1Score, setStep1Score] = useState('00');
  //const [step2Score, setStep2Score] = useState('00');
  //const [step3Score, setStep3Score] = useState('00');

  //const [prevTotalScore, setPrevTotalScore] = useState('00');
  //const [totalScore, setTotalScore] = useState('00');

  //step1변수
  const [ownHouseYn, setOwnHouseYn] = useState(null);
  const [applyNotiDate, setApplyNotiDate] = useState('');
  const [applyWishList, setApplyWishList] = useState([]);
  const [birthDate, setBirthDate] = useState('');
  const [marriageYn, setMarriageYn] = useState(null);
  const [marriageDate, setMarriageDate] = useState('');
  const [ownHouseHistYn, setOwnHouseHistYn] = useState(null);
  const [ownHouseHistDate, setOwnHouseHistDate] = useState('');

  //step2변수
  const [familyYn, setFamilyYn] = useState(null);
  const [myParents, setMyParents] = useState(null);
  const [spouseParents, setSpouseParents] = useState(null);
  const [children, setChildren] = useState(null);

  //step3변수
  const [accountDate, setAccountDate] = useState('');

  //const [validMap, setValidMap] = useState([]);

  // const goback = () => {
  //   selectMenu('A');
  //   window.goback = popupClose();
  // }

  const lcUrlMap = getLcUrlItem();
  const lcParamMap = getLcParamItem();

  // url 구분 작업
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    uiFixValue.init();
    uiFilterTabSwipe.init();
    uiSelFocus.init();
    uiMainSwitch.init();
    uiChartTap.init();
    uiToggle.init();
    uiTopGo.init();

    uiIpFocus.init();
    uiInputDel.init();

    $(window).scrollTop(0);

    // [2021-08-06] 스크립트 변경
    $(".step_info_wrap .item").each(function (idx) {
      $(this).attr("data-ctrl", idx);
      $(".step_info_wrap .item").eq(0).addClass("active");
      $(".ly_point_filter .view").eq(0).show();
    });

    $(".ly_point_filter .view").each(function (idx) {
      $(this).attr("data-ctrl", idx);
      var ctrlIdx = $(this).attr("data-ctrl");
      var ctrlName = $(this).attr("data-name");
      var fixSumH = $(".btn_area_wide").find(".sum_info_wrap").outerHeight();
      /* -- 해당스크립트 increaseStep으로 이동
      $(this).find(".btn_next").on("click", function () {
        $(".ly_point_filter .view").hide();
            $(".ly_point_filter .view").eq(idx+1).show();
            $(".step_info_wrap .item").removeClass("active")
            $(".step_info_wrap .item").eq(idx+1).addClass("active");
            // [2021-09-23] 하단 합계 문구 추가
            $(".sum_info_wrap .title").attr("data-name", $(".step_info_wrap .item").eq(idx+1).text());
            $(".sum_info_wrap .title .txt").html($(".step_info_wrap .item").eq(idx+1).text());
            if (ctrlIdx == 2) {
                
                // [2022.01.18] 변경
                $(".ly_point_filter").find(".content").addClass("end").css("padding-bottom", 100);

                $(".btn_area_wide").find(".sum_info_wrap").hide();
                $(".btn_area_wide").find(".btn_sum").show();
                counting('point',0);
            }
      });
      */
      /* -- 해당스크립트 decreaseStep으로 이동
       $(this).find(".btn_prev").on("click", function () {
         $(".ly_point_filter .view").hide();
            $(".ly_point_filter .view").eq(idx-1).show();
            $(".step_info_wrap .item").removeClass("active")
            $(".step_info_wrap .item").eq(idx-1).addClass("active");
            // [2021-09-23] 하단 합계 문구 추가
            $(".sum_info_wrap .title").attr("data-name", $(".step_info_wrap .item").eq(idx-1).text());
            $(".sum_info_wrap .title .txt").html($(".step_info_wrap .item").eq(idx-1).text());
            $(".ly_point_filter").find(".content").css("padding-bottom", fixSumH);
            $(".btn_area_wide").find(".btn_sum").hide();
            $(".btn_area_wide").find(".sum_info_wrap").show();
            $(window).scrollTop(0);
       });
       */

      // [2022.01.18] 다시 계산 하기
      $(".btn_sum .btn_reset").on("click", function () {
        $(".ly_point_filter .view").hide();
        $(".ly_point_filter .view").eq(0).show();
        $(".step_info_wrap .item").removeClass("active")
        $(".step_info_wrap .item").eq(0).addClass("active");
        // [2021-09-23] 하단 합계 문구 추가
        $(".sum_info_wrap .title").attr("data-name", $(".step_info_wrap .item").eq(0).text());
        $(".sum_info_wrap .title .txt").html($(".step_info_wrap .item").eq(0).text());
        $(".ly_point_filter").find(".content").css("padding-bottom", fixSumH);
        $(".btn_area_wide").find(".btn_sum").hide();
        $(".btn_area_wide").find(".sum_info_wrap").show();
        // [2022.01.18] end 클래스 삭제
        $(".ly_point_filter").find(".content").removeClass("end")
        $(window).scrollTop(0);
      });
    });

    // input val change > input display:block
    $('.rdo_question input').change(function () {
      var _this = $(this),
        _next = _this.parents('.rdo_question').next('.rdo_answer');
      if ($(this).hasClass('ip_yes')) {
        _next.slideDown(300);
        $("html,body").animate({ scrollTop: _next.offset().top }, 200);
      } else {
        _next.slideUp(300);
      }
    });

    //[2021-08-18] 셀렉트 박스 변경
    $(".btn_sel_wrap").each(function (idx) {
      $(this).data("key", idx + 1);
      // btm select popup 호출
      $(this).find(".btn_sel").on("click", function () {
        var key = $(this).parents(".btn_sel_wrap").data("key");
        popupOpen('select0' + key, 'mwf', 0);
      });
    });

    $(".ly_select_wrap").each(function (idx) {
      $(this).data("ctrlKey", idx + 1);
      // btm select 값 선택// btm select 값 선택
      $(this).find(".pop_sel_wrap .list input").on("change", function () {
        var ctrlKey = $(this).parents(".ly_select_wrap").data("ctrlKey");
        var selTxt = $(this).parents(".item").find(".txt").text();

        $(".btn_sel_wrap").eq(ctrlKey - 1).removeClass("focus").find(".lbl").addClass("hide");
        $(".btn_sel_wrap").eq(ctrlKey - 1).find(".sel_txt").text(selTxt);

        popupClose('select0' + ctrlKey, 'mwf', 0);
      });
    });

    axios.get('/apt/apply/predict/applyWishList').then(res => {
      setApplyWishList(res.data.response);
    });

    window.goback = backToApplyList;

  }, []);

  useEffect(() => {
    uiSelFocus.init();
    bindSelect01();

    if (lcParamMap && lcParamMap.applyInfo) {
      if (lcParamMap.applyInfo.applyId != '') {
        setTimeout(() => {
          $("#select01").val((lcParamMap.applyInfo.applyId + '_' + lcParamMap.applyInfo.notiDate)).change();
          selectApplyInfo((lcParamMap.applyInfo.applyId + '_' + lcParamMap.applyInfo.notiDate));
        }, 400);
      }
    }
    else if (lcParamMap && lcParamMap.applyDtl) {
      if (lcParamMap.applyDtl.applyId != '') {
        setTimeout(() => {
          $("#select01").val((lcParamMap.applyDtl.applyId + '_' + lcParamMap.applyDtl.notiDate)).change();
          selectApplyInfo((lcParamMap.applyDtl.applyId + '_' + lcParamMap.applyDtl.notiDate));
        }, 400);
      }
    }
  }, [applyWishList]);

  /*useEffect(() => {
    if (step === 1) { calcHouseScore(); }
    else if (step === 2) { calcFamilyScore(); }
    else if (step === 3) { calcAccountScore(); }
    else if (step === 4) {
      if (updateState === false && (parseInt(totalScore) != parseInt(prevTotalScore))) {
        setUpdateState(true);
        setTotalScore(lpadScore(parseInt(totalScore)));
        setPrevTotalScore(lpadScore(parseInt(totalScore)));
        setTimeout(() => {
          asyncCounting('point', .3, prevTotalScore, totalScore);
          setUpdateState(false);
        }, 10);
      }
    }
    else {
      return;
    }
  });*/


  const selectApplyInfo = (vo) => {
    const valArr = vo.split('_');
    setApplyId(valArr[0]);
    setApplyNotiDate(valArr[1]);
  }

  const lpadScore = (score) => {
    return parseInt(score) < 10 ? ('0' + score).toString() : score.toString();
  }

  const calcHouseScore = () => {
    if (
      (ownHouseYn === false || (ownHouseYn === true && birthDate != '' && birthDate.length === 8)) &&
      applyNotiDate != '' && applyNotiDate.length === 8 &&
      (marriageYn === false || (marriageYn === true && marriageDate != '' && marriageDate.length === 8)) &&
      (ownHouseHistYn === false || (ownHouseHistYn === true && ownHouseHistDate != '' && ownHouseHistDate.length === 8))
    ) {

      let houseScore = 0;

      if (ownHouseYn) {
        houseScore = 0;
      }
      else {
        const age = applyAge(birthDate);
        let marriageScore = 0;

        if (!marriageYn) {
          if (age < 30) {
            houseScore = 0;
          }
          else {
            marriageScore = (age - 30) * 2;
          }
        }
        else {
          marriageScore = (applyAge(marriageDate)) * 2;
          marriageScore = ((age - 30) * 2) > marriageScore ? ((age - 30) * 2) : marriageScore;
        }

        if (ownHouseHistYn) {
          const ownHouseHistScore = (applyAge(ownHouseHistDate)) * 2;
          houseScore = marriageScore > ownHouseHistScore ? ownHouseHistScore : marriageScore;
        }
        else {
          houseScore = marriageScore;
        }
      }

      resultHouseScore = houseScore < 0 ? 0 : houseScore > 32 ? 32 : houseScore;
      return resultHouseScore;
    }
    else {
      return 0;
    }
  }

  const calcFamilyScore = () => {
    if (
      //(familyYn === false || (familyYn === true && myParents != null && spouseParents != null && children != null))
      familyYn != null || myParents != null || spouseParents != null || children != null
    ) {
      //let me = familyYn === false ? 1 : 0;
      let me = 1;
      let spouse = (marriageYn === true && marriageDate != '') ? 1 : 0;
      let familyTotal = 0;
      if (familyYn) {
        familyTotal = ((parseInt(me) + parseInt(spouse) + parseInt(myParents == null ? 0 : myParents) + parseInt(spouseParents == null ? 0 : spouseParents) + parseInt(children == null ? 0 : children)) * 5);
      }
      else {
        familyTotal = ((parseInt(me) + parseInt(spouse)) * 5);
      }


      if (familyTotal === isNaN()) {
        familyTotal = 0;
      }
      resultFamilyScore = familyTotal > 35 ? 35 : familyTotal;
      return resultFamilyScore;
    }
    else {
      resultFamilyScore = 0;
      return 0;
    }
  }

  const calcAccountScore = () => {


    if (accountDate != '' && accountDate.length === 8) {
      let accountScore = 0;
      if (!applyNotiDate || applyNotiDate.length !== 8) { accountScore = 0; }
      if (!accountDate || accountDate.length !== 8) { accountScore = 0; }
      if (!birthDate || birthDate.length !== 8) { accountScore = 0; }

      const age = applyAge(birthDate);
      const baseScore = 1;

      const cApplyNotiDate = new Date(Number(applyNotiDate.substring(0, 4)), Number(applyNotiDate.substring(4, 6)) - 1, Number(applyNotiDate.substring(6, 8)));
      const cAccountDate = new Date(Number(accountDate.substring(0, 4)), Number(accountDate.substring(4, 6)) - 1, Number(accountDate.substring(6, 8)));
      const cBirthDate = new Date(Number(birthDate.substring(0, 4)), Number(birthDate.substring(4, 6)) - 1, Number(birthDate.substring(6, 8)));
      if (cApplyNotiDate.getTime() < cBirthDate.getTime() || cAccountDate.getTime() < cBirthDate.getTime() || cApplyNotiDate.getTime() < cAccountDate.getTime()) accountScore = 0;

      ////////////////////////////////////////////////////////////////////////////////
      // [SOM.230405] 청약홈 > 가점계산기 기준에 맞게 통장점수 계산 방식 변경
      // 1. (청약공고일 - 통장가입일) 날짜 차이 구하기
      let diffDate1 = cApplyNotiDate - cAccountDate;      // 청약공고일 - 통장가입일

      let currDay = 24 * 60 * 60 * 1000;                  // 시 * 분 * 초 * 밀리세컨
      let currMonth = currDay * 30;                       // 월 만듦
      let currYear = currMonth * 12;                      // 년 만듦
      
      let yearDf = parseInt( diffDate1 / currYear );      // 년수 차이 
      let monthDf = parseInt( diffDate1 / currMonth );    // 월수 차이 
      //let dayDf = parseInt( diffDate1 / currDay );     // 일수 차이 

      // console.log("-------------------------");
      // console.log("* 나이 : " + age + "<br/>");
      // console.log("* 청약공고일 / 통장가입일 : " + cApplyNotiDate + " / " + accountDate + "<br/>");
      // console.log("* 년수 차이(yearDf) : " + yearDf + " 년<br/><br/>");
      // console.log("* 월수 차이(monthDf) : " + monthDf + " 월<br/>");
      // //console.log("* 일수 차이 : " + dayDf + " 일<br/>");
      // console.log("-------------------------");
      ////////////////////////////////////////////////////////////////////////////////

      ////////////////////////////////////////////////////////////////////////////////
    
      // 2. 가점 계산 
      if (age > 18) { //18세이상 성인
        
        // 통장가입일이 2년 미만
        if (yearDf < 2) {
          // 통장가입일이 1년 이상
          if (yearDf === 1) {
            accountScore = 2 + baseScore;   // 3점
            //console.log("ApplyCalc > accountScore (1) : "+accountScore);
          
          // 통장가입일이 1년 미만 (0년)
          } else {
            // 통장가입일이 6개월 미만
            if (yearDf === 0 && monthDf < 6) {
              accountScore = baseScore;     // 1점
              //console.log("ApplyCalc > accountScore (2) : "+accountScore);
            
            // 통장가입일이 6개월 이상
            } else {
              accountScore = 1 + baseScore; // 2점
              //console.log("ApplyCalc > accountScore (3) : "+accountScore);
            }
          }

        // 통장가입일이 2년 이상 
        } else {
          // 성년기준일
          const cAdDate = new Date(Number(birthDate.substring(0, 4)) + 18, Number(birthDate.substring(4, 6)) - 1, Number(birthDate.substring(6, 8)));

          // 미성년자때 통장개설한 경우
          if (cAdDate.getTime() > cAccountDate.getTime()) {

            // 1. 두 날짜 차이 구하기
            let diffDate2 = cAdDate - cAccountDate;               // 성년기준일 - 통장가입일
            let yearDiff = parseInt( diffDate2 / currYear );      // 년수 차이 
            let monthDiff = parseInt( diffDate2 / currMonth );    // 월수 차이 

            let diffDate3 = cApplyNotiDate - cAdDate;             // 청약공고일 - 성년기준일
            let yearDiff2 = parseInt( diffDate3 / currYear );     // 년수 차이 
            
            let partScore = 0;
            let partScore2 = yearDiff2;

            // console.log("-------------------------");
            // console.log("* 청약공고일 / 통장가입일 / 성년기준일 : " + cApplyNotiDate + " / " + cAccountDate + " / " + cAdDate + "<br/>");
            // console.log("* 년수 차이 (yearDiff) : " + yearDiff + " 년<br/><br/>");
            // console.log("* 월수 차이 (monthDiff) : " + monthDiff + " 월<br/>");
            // console.log("* 년수 차이 (yearDiff2) : " + yearDiff2 + " 년<br/>");
            // console.log("-------------------------");

            // 미성년으로서 가입한 기간은 2년까지만 인정
            if (yearDiff > 2) {
              partScore = 2;
              //console.log("ApplyCalc >> partScore (1) : "+partScore);

            // 미성년으로 가입한 기간이 2년 미만일 때
            } else {
              // 통장가입일이 6개월 미만
              if (yearDiff === 0 && monthDiff < 6) {
                partScore = baseScore;     // 1점
                //console.log("ApplyCalc >> partScore (2) : "+partScore);
              
              // 통장가입일이 6개월 이상
              } else {
                partScore = 1 + baseScore; // 2점
                //console.log("ApplyCalc >> partScore (3) : "+partScore);
              }
            }

            accountScore = partScore + partScore2;
            //console.log("ApplyCalc > accountScore (4) : "+accountScore);

          // 성인일때 통장 개설한 경우
          } else {
            accountScore = yearDf + 1 + baseScore;
            //console.log("ApplyCalc > accountScore (6) : "+accountScore);
          }
        }

      // 18세 미만 미성년자
      } else {
        // 통장가입일이 2년 미만
        if (yearDf < 2) {
          if (yearDf === 1) {
            accountScore = 1 + baseScore;   // 2점
            //console.log("ApplyCalc > accountScore (7) : "+accountScore);
          
          // 통장가입일이 1년 미만 (0년)
          } else {
            // 통장가입일이 6개월 미만
            if (yearDf === 0 && monthDf < 6) {
              accountScore = baseScore;     // 1점
              //console.log("ApplyCalc > accountScore (8) : "+accountScore);
            
            // 통장가입일이 6개월 이상
            } else {
              accountScore = 1 + baseScore; // 2점
              //console.log("ApplyCalc > accountScore (9) : "+accountScore);
            }
          }
        
        // 통장가입일이 2년 이상 
        } else {
          accountScore = 1 + baseScore;
          //console.log("ApplyCalc > accountScore (10) : "+accountScore);
        }
      }

      accountScore = accountScore > 17 ? 17 : accountScore;
      resultAccountScore = accountScore;
      return resultAccountScore;
    }
    else {
      resultAccountScore = 0;
      return 0;
    }

  }

  const applyAge = (calcDate) => {
    const noti = applyNotiDate;

    if (!noti || noti.length !== 8) return 0;
    if (!calcDate || calcDate.length !== 8) return 0;

    const sYear = calcDate.substring(0, 4);
    const sMonth = calcDate.substring(4, 6);
    const sDate = calcDate.substring(6, 8);
    const calcDay = new Date(Number(sYear), Number(sMonth) - 1, Number(sDate));
    //const today = new Date();

    const sYearNoti = noti.substring(0, 4);
    const sMonthNoti = noti.substring(4, 6);
    const sDateNoti = noti.substring(6, 8);
    const notiDay = new Date(Number(sYearNoti), Number(sMonthNoti) - 1, Number(sDateNoti));

    let age = notiDay.getFullYear() - calcDay.getFullYear();
    calcDay.setFullYear(notiDay.getFullYear());

    if (notiDay < calcDay) {
      age--;
    }

    return age + 1;
  }

  const setOwnHouse = (paramYn) => {
    let isOwnHouse = paramYn;
    if (isOwnHouse == true) {//주택여부 있음일시 무주택기간 input hide
      setOwnHouseYn(true);
      $('div.view').eq(0).children().eq(7).slideUp(300);
      $('div.view').eq(0).children().eq(6).hide();
    } else {
      setOwnHouseYn(false);
      $('div.view').eq(0).children().eq(6).show();
      if (ownHouseHistYn == true) {
        $('div.view').eq(0).children().eq(7).slideDown(300);
      }
      else {
        $('div.view').eq(0).children().eq(7).slideUp(300);
      }
    }
  }

  const onChangeBirthDate = (e) => {
    /*const max = e.target.getAttribute('maxLength');
    let value = e.target.value;

    if (value.length > max) value = value.slice(0, max);
    e.target.value = value.replace(/[^0-9]/g, '');
    setBirthDate(value);*/

    let birthDate = e.target.value;
    const regex = new RegExp(/^[0-9]*$/);
    birthDate = birthDate.replace(/\./g, '');

    if (birthDate === '' || regex.test(birthDate)) {
      setBirthDate(birthDate);
    }
  }

  const onBlurBirthDate = (e) => {
    const re = new RegExp(/^(19|20)[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/);
    if (birthDate && !re.test(birthDate)) {
      alert('생년월일을 정확히 입력하세요.');
      e.target.value = '';
      setBirthDate('');
    }
  }

  const onChangeMarriageDate = (e) => {
    /*const max = e.target.getAttribute('maxLength');
    let value = e.target.value;

    if (value.length > max) value = value.slice(0, max);
    e.target.value = value.replace(/[^0-9]/g, '');
    setMarriageDate(value);*/

    let marriageDate = e.target.value;
    const regex = new RegExp(/^[0-9]*$/);
    marriageDate = marriageDate.replace(/\./g, '');
    if (marriageDate === '' || regex.test(marriageDate)) {
      setMarriageDate(marriageDate);
    }
  }

  const onBlurMarriageDate = (e) => {
    const re = new RegExp(/^(19|20)[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/);
    if (marriageDate && !re.test(marriageDate)) {
      alert('혼인신고일을 정확히 입력하세요.');
      e.target.value = '';
      setMarriageDate('');
    }
  }

  const onChangeOwnHouseHistDate = (e) => {
    /*const max = e.target.getAttribute('maxLength');
    let value = e.target.value;

    if (value.length > max) value = value.slice(0, max);
    e.target.value = value.replace(/[^0-9]/g, '');
    setOwnHouseHistDate(value);*/
    let ownHouseHistDate = e.target.value;
    const regex = new RegExp(/^[0-9]*$/);
    ownHouseHistDate = ownHouseHistDate.replace(/\./g, '');
    if (ownHouseHistDate === '' || regex.test(ownHouseHistDate)) {
      setOwnHouseHistDate(ownHouseHistDate);
    }
  }

  const onBlurOwnHouseHistDate = (e) => {
    const re = new RegExp(/^(19|20)[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/);
    if (ownHouseHistDate && !re.test(ownHouseHistDate)) {
      alert('무주택자가 된 날을 정확히 입력하세요.');
      e.target.value = '';
      setOwnHouseHistDate('');
    }
  }

  const onChangeAccountDate = (e) => {
    // const max = e.target.getAttribute('maxLength');
    // let value = e.target.value;

    // if (value.length > max) value = value.slice(0, max);
    // e.target.value = value.replace(/[^0-9]/g, '');
    // setAccountDate(value);
    let accountDate = e.target.value;
    const regex = new RegExp(/^[0-9]*$/);
    accountDate = accountDate.replace(/\./g, '');
    if (accountDate === '' || regex.test(accountDate)) {
      setAccountDate(accountDate);
    }
  }

  const onBlurAccountDate = (e) => {
    const re = new RegExp(/^(19|20)[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/);
    if (accountDate && !re.test(accountDate)) {
      alert('청약통장 가입일을 정확히 입력하세요.');
      e.target.value = '';
      setAccountDate('');
    }
  }

  const openCreditAgreePop = () => {
    const agreeUrl = `${process.env.REACT_APP_APT_WEB_ORIGIN}/apt/terms?type=9`;
    callAppInterface('openWebPage', JSON.stringify({ type: 'I', url: agreeUrl }), result => {

    });
  }

  // [가점 저장 후 청약 당첨 가능성 확인] 버튼
  const beforeScoreSaved = () => {
  axios.get('apt/authorized/check',
    { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }, timeout: 1000 }).then(res => {
      if (res.data.response.isValid) {
        // 약관 동의 팝업 open
        popupOpen('creditAgree', 'mwf', 0);
      }
      else {
        alert('가점을 저장하기 위해서는 로그인이 필요해요.');
        window.location.href = '/login';
        //const param = { type: 'I', url: '/login' }
        //callAppInterface('openWebPage', JSON.stringify(param), result => {
        //alert(result);
        //});
      }
    }).catch(err => {
      if (localStorage.getItem('isAutoLogin') == 'true') {
        // 자동로그인 적용시 사용
        if (err.response.status === 401 || err.response.status === 403) {
          axios.post('apt/authorized/reIssue',
            { 'ref': localStorage.getItem('ref'), 'moLogin': true },
            { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } }).then(res => {
              if (res.status === 200) {
                localStorage.setItem('jwtToken', res.headers.authorization);

                callAppInterface("tokenToApp", JSON.stringify({
                  token: res.headers.authorization,
                  ref: localStorage.getItem('ref'),
                  isAuto: true,
                }), result => {

                });

                // 약관 동의 팝업 open
                popupOpen('creditAgree', 'mwf', 0);

              }
              else {
                alert('가점을 저장하기 위해서는 로그인이 필요해요.');
                window.location.href = '/login';
                //const param = { type: 'I', url: '/login' }
                //callAppInterface('openWebPage', JSON.stringify(param), result => {
                //alert(result);
                //});
              }
            }).catch(err => {
              alert('가점을 저장하기 위해서는 로그인이 필요해요.');
              window.location.href = '/login';
              //const param = { type: 'I', url: '/login' }
              //callAppInterface('openWebPage', JSON.stringify(param), result => {
              //alert(result);
              //});
            });
        }
      }
      else {
        alert('가점을 저장하기 위해서는 로그인이 필요해요.');
        window.location.href = '/login';
        //const param = { type: 'I', url: '/login' }
        //callAppInterface('openWebPage', JSON.stringify(param), result => {
        //alert(result);
        //});
      }
    });
  }

  // [나의 청약가점 계산 결과 저장] 버튼
  const scoreSaved = async () => {
    const config = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } };
    // AGREE.java 참고
    const agreePath = 'ETC_AGREE';
    const agreeName = 'ETCA_001_O';

    if ($('#termsCreditAgree').prop('checked') === true) {
      let param2 = `?agreePath=${agreePath}&agreeName=${agreeName}`;

      // 약관 동의 여부 저장
      const termsAgreeSave = await axios.get('apt/terms/agree/termsAgreeSave'+param2, config);

      if(termsAgreeSave) {
        switch (termsAgreeSave.data.code) {
          case 'E0000': {
            popupClose('creditAgree', 'mw');
            break;
          }
          default : {
            alert(termsAgreeSave.data.message);
            return false;
          }
        }
      }

      let param = `?houseScore=${resultHouseScore}&familyScore=${resultFamilyScore}&accountScore=${resultAccountScore}&totalScore=${(resultHouseScore + resultFamilyScore + resultAccountScore)}`;

      // 나의 청약 가점 저장
      const saveApplyMyScore = await axios.get('apt/apply/applyCalc/saveApplyMyScore'+param, config);

      if (saveApplyMyScore.data) {
        switch (saveApplyMyScore.data.code) {
          case 'E0000': {
            setSaved(true);
            alert('청약가점이 저장되었습니다.');
            openApplyPredict();
            break;
          }
          default : {
            alert(saveApplyMyScore.data.message);
            return false;
          }
        }
      }

    } else {
      alert('약관에 동의해 주세요.');
      return false;
    }
  }

  const bindSelect01 = () => {
    $(".btn_sel_wrap").each(function (idx) {
      $(this).data("key", idx + 1);
      // btm select popup 호출
      $(this).find(".btn_sel").on("click", function () {
        var key = $(this).parents(".btn_sel_wrap").data("key");
        popupOpen('select0' + key, 'mwf', 0);
      });
    });

    $(".ly_select_wrap").each(function (idx) {
      $(this).data("ctrlKey", idx + 1);
      // btm select 값 선택// btm select 값 선택
      $(this).find(".pop_sel_wrap .list input").on("change", function () {
        var ctrlKey = $(this).parents(".ly_select_wrap").data("ctrlKey");
        var selTxt = $(this).parents(".item").find(".txt").text();

        $(".btn_sel_wrap").eq(ctrlKey - 1).removeClass("focus").find(".lbl").addClass("hide");
        $(".btn_sel_wrap").eq(ctrlKey - 1).find(".sel_txt").text(selTxt);

        //select value 찾도록 추가
        if (ctrlKey === 1) {
          var selVal = $(this).parents(".item").find(".txt").attr('data-value');
          selectApplyInfo(selVal);
        }

        popupClose('select0' + ctrlKey, 'mwf', 0);
      });
    });
  }

  const bindSelect02 = () => {
    $(".btn_sel_wrap").each(function (idx) {
      $(this).data("key", idx + 1);
      // btm select popup 호출
      $(this).find(".btn_sel").on("click", function () {
        var key = $(this).parents(".btn_sel_wrap").data("key");
        popupOpen('select0' + key, 'mwf', 0);
      });
    });

    $(".ly_select_wrap").each(function (idx) {
      $(this).data("ctrlKey", idx + 1);
      // btm select 값 선택// btm select 값 선택
      $(this).find(".pop_sel_wrap .list input").on("change", function () {
        var ctrlKey = $(this).parents(".ly_select_wrap").data("ctrlKey");
        var selTxt = $(this).parents(".item").find(".txt").text();

        $(".btn_sel_wrap").eq(ctrlKey - 1).removeClass("focus").find(".lbl").addClass("hide");
        $(".btn_sel_wrap").eq(ctrlKey - 1).find(".sel_txt").text(selTxt);

        //select value 찾도록 추가
        if (ctrlKey === 2) {
          var selVal = $(this).parents(".item").find(".txt").attr('data-value');
          setMyParents(selVal);
        }

        popupClose('select0' + ctrlKey, 'mwf', 0);
      });
    });
  }

  const bindSelect03 = () => {
    $(".btn_sel_wrap").each(function (idx) {
      $(this).data("key", idx + 1);
      // btm select popup 호출
      $(this).find(".btn_sel").on("click", function () {
        var key = $(this).parents(".btn_sel_wrap").data("key");
        popupOpen('select0' + key, 'mwf', 0);
      });
    });

    $(".ly_select_wrap").each(function (idx) {
      $(this).data("ctrlKey", idx + 1);
      // btm select 값 선택// btm select 값 선택
      $(this).find(".pop_sel_wrap .list input").on("change", function () {
        var ctrlKey = $(this).parents(".ly_select_wrap").data("ctrlKey");
        var selTxt = $(this).parents(".item").find(".txt").text();

        $(".btn_sel_wrap").eq(ctrlKey - 1).removeClass("focus").find(".lbl").addClass("hide");
        $(".btn_sel_wrap").eq(ctrlKey - 1).find(".sel_txt").text(selTxt);

        //select value 찾도록 추가
        if (ctrlKey === 3) {
          var selVal = $(this).parents(".item").find(".txt").attr('data-value');
          setSpouseParents(selVal);
        }

        popupClose('select0' + ctrlKey, 'mwf', 0);
      });
    });
  }

  const bindSelect04 = () => {
    $(".btn_sel_wrap").each(function (idx) {
      $(this).data("key", idx + 1);
      // btm select popup 호출
      $(this).find(".btn_sel").on("click", function () {
        var key = $(this).parents(".btn_sel_wrap").data("key");
        popupOpen('select0' + key, 'mwf', 0);
      });
    });

    $(".ly_select_wrap").each(function (idx) {
      $(this).data("ctrlKey", idx + 1);
      // btm select 값 선택// btm select 값 선택
      $(this).find(".pop_sel_wrap .list input").on("change", function () {
        var ctrlKey = $(this).parents(".ly_select_wrap").data("ctrlKey");
        var selTxt = $(this).parents(".item").find(".txt").text();

        $(".btn_sel_wrap").eq(ctrlKey - 1).removeClass("focus").find(".lbl").addClass("hide");
        $(".btn_sel_wrap").eq(ctrlKey - 1).find(".sel_txt").text(selTxt);

        //select value 찾도록 추가
        if (ctrlKey === 4) {
          var selVal = $(this).parents(".item").find(".txt").attr('data-value');
          setChildren(selVal);
        }

        popupClose('select0' + ctrlKey, 'mwf', 0);
      });
    });
  }

  const printDate = (paramDate) => {

    let rtnDate = '';
    if (paramDate > 0 && paramDate.length <= 4) {
      rtnDate = paramDate;
    } else if (paramDate > 4 && paramDate.length <= 6) {
      rtnDate = paramDate.substr(0, 4) + "." + paramDate.substr(4, 2);
    } else if (paramDate > 6) {
      rtnDate = paramDate.substr(0, 4) + "." + paramDate.substr(4, 2) + "." + paramDate.substr(6, 2);
    }
    return rtnDate;
  }

  useEffect(() => { //popupWording을 위해 valid용 map을 생성
    if (step === 2) { bindSelect02(); bindSelect03(); bindSelect04(); }
    //validMap.step = step;
    validMap.ownHouseYn = ownHouseYn;
    validMap.applyNotiDate = applyNotiDate;
    validMap.birthDate = birthDate;
    validMap.marriageYn = marriageYn;
    validMap.marriageDate = marriageDate;
    validMap.ownHouseHistYn = ownHouseHistYn;
    validMap.ownHouseHistDate = ownHouseHistDate;
    validMap.familyYn = familyYn;
    validMap.myParents = myParents;
    validMap.spouseParents = spouseParents;
    validMap.children = children;
    validMap.accountDate = accountDate;

  }, [ownHouseYn, applyNotiDate, birthDate, marriageYn, marriageDate, ownHouseHistYn, ownHouseHistDate, familyYn, myParents, spouseParents, children, accountDate]);


  // useEffect(() => {

  //   if (step === 2) { bindSelect02(); bindSelect03(); bindSelect04(); }
  //   validMap.step = step;
  // }, [step]);


  const popupWording = () => {
    const { ownHouseYn, applyNotiDate, birthDate, marriageYn, marriageDate, ownHouseHistYn, ownHouseHistDate, familyYn, myParents, spouseParents, children, accountDate } = validMap;
    if (step === 1) {
      if (ownHouseYn === null) {
        alert('주택소유여부를 선택해주세요.');
        return false;
      }
      else if (applyNotiDate === '') {
        alert('입주자모집공고를 선택해주세요.');
        return false;
      }
      else if (birthDate === '') {
        alert('생년월일을 입력해주세요.');
        return false;
      }
      else if (marriageYn === null) {
        alert('혼인여부를 선택해주세요.');
        return false;
      }
      else if (marriageYn === true && marriageDate === '') { //기획서에없지만 필요하여 신규추가
        alert('혼인신고일을 입력해주세요.');
        return false;
      }
      else if (ownHouseYn === false && ownHouseHistYn === null) { //주택소유여부가 없어야 선택가능
        alert('무주택기간을 선택해주세요.');
        return false;
      }
      else if (ownHouseYn === false && ownHouseHistYn === true && ownHouseHistDate === '') { //주택소유여부가 없고 현재무주택(과거소유) 여야 무주택기간 입력가능
        alert('무주택기간을 입력해주세요.');
        return false;
      }
      else {
        return true;
      }
    }
    else if (step === 2) {
      if (familyYn === null) {
        alert('부양가족 유무를 선택해주세요.');
        return false;
      }
      else if (familyYn == true && myParents === null) {
        alert('본인의 부모/조부모 인원을 선택해주세요.');
        return false;
      }
      else if (familyYn == true && spouseParents === null) {
        alert('배우자의 부모/조부모 인원을 선택해주세요.');
        return false;
      }
      else if (familyYn == true && children === null) {
        alert('자녀/손자녀 인원을 선택해주세요.');
        return false;
      }
      else {
        return true;
      }
    }
    else if (step === 3) {
      if (accountDate === '') {
        alert('청약통장 가입일을 입력해주세요.');
        return false;
      }
      else {
        return true;
      }
    }
  }

  const decreaseStep = () => {
    setStep(step - 1);

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?PAL-${step - 1}000#`
    });

    let idx = (step - 1);
    let fixSumH = $(".btn_area_wide").find(".sum_info_wrap").outerHeight();

    $(".ly_point_filter .view").hide();
    $(".ly_point_filter .view").eq(idx - 1).show();
    $(".step_info_wrap .item").removeClass("active")
    $(".step_info_wrap .item").eq(idx - 1).addClass("active");
    // [2021-09-23] 하단 합계 문구 추가
    $(".sum_info_wrap .title").attr("data-name", $(".step_info_wrap .item").eq(idx - 1).text());
    $(".sum_info_wrap .title .txt").html($(".step_info_wrap .item").eq(idx - 1).text());
    $(".ly_point_filter").find(".content").css("padding-bottom", fixSumH);
    $(".btn_area_wide").find(".btn_sum").hide();
    $(".btn_area_wide").find(".sum_info_wrap").show();
    $(window).scrollTop(0);

  }

  const increaseStep = (e) => {
    if (popupWording()) { //popupWording 타고 다음스텝가도록 추가
      setStep(step + 1);

      // url 구분 작업
      history.push({
        pathname: location.pathname,
        search: `?PAL-${step + 1}000#`
      });

      let idx = (step - 1);
      let ctrlIdx = (step - 1);
      let fixSumH = $(".btn_area_wide").find(".sum_info_wrap").outerHeight();

      $(".ly_point_filter .view").hide();
      $(".ly_point_filter .view").eq(idx + 1).show();
      $(".step_info_wrap .item").removeClass("active")
      $(".step_info_wrap .item").eq(idx + 1).addClass("active");
      // [2021-09-23] 하단 합계 문구 추가
      $(".sum_info_wrap .title").attr("data-name", $(".step_info_wrap .item").eq(idx + 1).text());
      $(".sum_info_wrap .title .txt").html($(".step_info_wrap .item").eq(idx + 1).text());
      if (ctrlIdx == 2) {
        // [2022.01.18] 변경
        $(".ly_point_filter").find(".content").addClass("end").css("padding-bottom", 100);

        $(".btn_area_wide").find(".sum_info_wrap").hide();
        $(".btn_area_wide").find(".btn_sum").show();
        //counting('point', 0);
      }

    }
  }


  //--'open' : 현 화면이 다음 화면으로 변경되는 action
  const openApplyCalc = () => {
    resultHouseScore = 0;
    resultFamilyScore = 0;
    resultAccountScore = 0;
    props.onRefreshState(true);
    setTimeout(() => {
      props.onRefreshState(false);
    }, 100);

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `PAL-1000#`
    })
  }

  const openApplyPredict = () => {
    let os = osCheck() == 'Android' ? "A" : "I";
    axios.get('apt/authorized/check',
      { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }, timeout: 1000 }).then(res => {
        if (res.data.response.isValid) {
          setLcUrlItem('applyCalc', 'applyPredict');
          setLcParamItem({ 'applyId': applyId });
          setTimeout(() => {
            $('.bg_body_full').remove();
            uiFixValue.init();
          }, 100);
          props.onApplyMenuType('B');

          // url 구분 작업
          history.push({
            pathname: location.pathname,
            search: `PER-1000#`
          })
        }
        else {
          window.location.href = '/login';
          //const param = { type: 'I', url: '/login' }
          //callAppInterface('openWebPage', JSON.stringify(param), result => {
          //alert(result);
          //});
        }
      }).catch(err => {
        if (localStorage.getItem('isAutoLogin') == 'true') {
          // 자동로그인 적용시 사용
          if (err.response.status === 401 || err.response.status === 403) {
            axios.post('apt/authorized/reIssue',
              { 'ref': localStorage.getItem('ref'), 'moLogin': true },
              { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } }).then(res => {
                if (res.status === 200) {
                  localStorage.setItem('jwtToken', res.headers.authorization);

                  callAppInterface("tokenToApp", JSON.stringify({
                    token: res.headers.authorization,
                    ref: localStorage.getItem('ref'),
                    isAuto: true,
                  }), result => {

                  });

                  setLcUrlItem('applyCalc', 'applyPredict');
                  setLcParamItem({ 'applyId': applyId });
                  setTimeout(() => {
                    $('.bg_body_full').remove();
                    uiFixValue.init();
                  }, 100);
                  props.onApplyMenuType('B');

                  // url 구분 작업
                  history.push({
                    pathname: location.pathname,
                    search: `PER-1000#`
                  })
                }
                else {
                  window.location.href = '/login';
                  //const param = { type: 'I', url: '/login' }
                  //callAppInterface('openWebPage', JSON.stringify(param), result => {
                  //alert(result);
                  //});
                }
              }).catch(err => {
                window.location.href = '/login';
                //const param = { type: 'I', url: '/login' }
                //callAppInterface('openWebPage', JSON.stringify(param), result => {
                //alert(result);
                //});
              });
          }
        }
        else {
          window.location.href = '/login';
          //const param = { type: 'I', url: '/login' }
          //callAppInterface('openWebPage', JSON.stringify(param), result => {
          //alert(result);
          //});
        }
      });
  }

  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  const backToAptMain = () => {
    setLcUrlItem('applyCalc', 'aptMain');
    setLcParamItem({});
    $(".srch_list_wrap").removeClass("active");

    $('.map_wrap').show();
    props.onApplyMenuType('A');
    props.onApplyListDisplay(false);
  }

  const backToApplyList = () => {
    setLcUrlItem('applyCalc', 'applyList');
    setTimeout(() => {
      $('.bg_body_full').remove();
      uiFixValue.init();
    }, 100);
    props.onApplyMenuType('A');
    props.onApplyPopupType('');
  }

  const step1CalcScore = lpadScore(calcHouseScore());
  const step2CalcScore = lpadScore(calcFamilyScore());
  const step3CalcScore = lpadScore(calcAccountScore());
  const totalCalcScore = lpadScore(Number(step1CalcScore) + Number(step2CalcScore) + Number(step3CalcScore));

  return (
    <>
      <div className="ly_content">
        <div className="step_info_wrap">
          <span className="item">무주택자</span>
          <span className="item">부양가족</span>
          <span className="item">청약통장</span>
          <span className="item">합계</span>
        </div>

        <div className="view">
          <div className="tab_tit_wrap">
            <strong><code>01.</code>무주택자 점수 계산<button type="button" className="btn_tip GTM-PAL-TIP-1" onClick={popupAlert01}>무주택자 점수 계산이란?</button></strong>
            <p>미혼인 경우 만 30세부터 무주택 기간 산정이 가능해요!</p>
          </div>
          <div className="item">
            <strong className="tit">주택소유 여부</strong>
            <div className="ip_wrap rdo_wrap">
              <div className="rdo_item">
                <input type="radio" id="rdo01-01" name="rdo01"
                  checked={ownHouseYn === true ? true : false}
                  onClick={() => setOwnHouse(true)} />
                <label for="rdo01-01" className="ico_house_o GTM-PAL-1"><i>주택 있음</i></label>
              </div>
              <div className="rdo_item">
                <input type="radio" id="rdo01-02" name="rdo01"
                  checked={ownHouseYn === false ? true : false}
                  onClick={() => setOwnHouse(false)} />
                <label for="rdo01-02" className="ico_house_x GTM-PAL-2"><i>주택 없음</i></label>
              </div>
            </div>
          </div>
          <div className="item">
            <strong className="tit">입주자 모집공고</strong>
            <div className="form_wrap">
              <div className="form">
                <div className="ip_box btn_sel_wrap">
                  <div className="inner_wrap">
                    <button type="button" className="btn_sel GTM-PAL-3">
                      <span className="lbl">모집 공고를 선택해주세요.</span>
                      <span className="sel_txt"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <strong className="tit">생년월일</strong>
            <div className="form_wrap">
              <div className="form">
                <div className="ip_box ip_del normal">
                  <label for="ip01" className="lbl code">ex. 1991.01.26</label>
                  <input type="tel" autocomplete="off" id="ip01" className="ip code GTM-PAL-4"
                    maxLength="10"
                    onBlur={onBlurBirthDate}
                    onChange={onChangeBirthDate}
                    value={printDate(birthDate)}
                  />
                  <button type="button" onClick="" className="del"><i>삭제</i></button>

                </div>
              </div>
            </div>
          </div>
          <div className="item rdo_question">
            <strong className="tit">혼인여부</strong>
            <div className="ip_wrap rdo_wrap">
              <div className="rdo_item">
                <input className="ip_yes" type="radio" id="rdo02-01" name="rdo02"
                  checked={marriageYn === true ? true : false}
                  onClick={() => setMarriageYn(true)} />
                <label for="rdo02-01" className="ico_people_o GTM-PAL-5"><i>기혼</i></label>
              </div>
              <div className="rdo_item">
                <input type="radio" id="rdo02-02" name="rdo02"
                  checked={marriageYn === false ? true : false}
                  onClick={() => setMarriageYn(false)} />
                <label for="rdo02-02" className="ico_people_x GTM-PAL-6"><i>미혼</i></label>
              </div>
            </div>
          </div>
          <div className="item rdo_answer">
            <strong className="tit">혼인신고일</strong>
            <div className="form_wrap">
              <div className="form">
                <div className="ip_box ip_del normal">
                  <label for="ip02" className="lbl code">ex. 2000.08.14</label>
                  <input type="tel" autocomplete="off" id="ip02" className="ip code GTM-PAL-5-1"
                    maxLength="10"
                    onBlur={onBlurMarriageDate}
                    onChange={onChangeMarriageDate}
                    value={printDate(marriageDate)}
                  />
                  <button type="button" onClick="" className="del"><i>삭제</i></button>
                </div>
              </div>
            </div>
          </div>
          <div className="item rdo_question">
            <strong className="tit">무주택기간</strong>
            <div className="ip_wrap rdo_wrap">
              <div className="rdo_item">
                <input className="ip_yes" type="radio" id="rdo03-01" name="rdo03"
                  checked={ownHouseHistYn === true ? true : false}
                  onClick={() => setOwnHouseHistYn(true)} />
                <label for="rdo03-01" className="ico_period_o GTM-PAL-7"><i>현재무주택 (과거소유)</i></label>
              </div>
              <div className="rdo_item">
                <input type="radio" id="rdo03-02" name="rdo03"
                  checked={ownHouseHistYn === false ? true : false}
                  onClick={() => setOwnHouseHistYn(false)} />
                <label for="rdo03-02" className="ico_period_x GTM-PAL-8"><i>소유이력 없음</i></label>
              </div>
            </div>
          </div>
          <div className="item rdo_answer">
            <strong className="tit">무주택자가 된 날</strong>
            <div className="form_wrap">
              <div className="form">
                <div className="ip_box ip_del normal">
                  <label for="ip03" className="lbl code">ex. 2000.08.14</label>
                  <input type="tel" autocomplete="off" id="ip03" className="ip code GTM-PAL-7-1"
                    maxLength="10"
                    onBlur={onBlurOwnHouseHistDate}
                    onChange={onChangeOwnHouseHistDate}
                    value={printDate(ownHouseHistDate)}
                  />
                  <button type="button" onClick="" className="del"><i>삭제</i></button>
                </div>
              </div>
            </div>
          </div>
          <div className="btn_wrap">
            <button type="button" className="btn btn_next GTM-PAL-NEXT-1" onClick={(e) => increaseStep(e)}>다음</button>
          </div>
        </div>
        <div className="view">
          <div className="tab_tit_wrap">
            <strong><code>02.</code>부양가족 점수 계산<button type="button" className="btn_tip GTM-PAL-TIP-2" onClick={popupAlert02}>부양가족 점수 계산이란?</button></strong>
            <p>부양가족 기준을 확인 후 항목을 모두 선택해주세요. (본인은 제외)</p>
          </div>
          <div className="item rdo_question">
            <strong className="tit">부양가족 유무</strong>
            <div className="ip_wrap rdo_wrap">
              <div className="rdo_item">
                <input className="ip_yes" type="radio" id="rdo04-01" name="rdo04"
                  checked={familyYn === true ? true : false}
                  onClick={() => setFamilyYn(true)} />
                <label for="rdo04-01" className="ico_people_o GTM-PAL-9"><i>부양가족 있음</i></label>
              </div>
              <div className="rdo_item">
                <input type="radio" id="rdo04-02" name="rdo04"
                  checked={familyYn === false ? true : false}
                  onClick={() => setFamilyYn(false)} />
                <label for="rdo04-02" className="ico_people_x GTM-PAL-10"><i>부양가족 없음</i></label>
              </div>
            </div>
          </div>
          <div className="item rdo_answer">
            <div className="item">
              <strong className="tit">본인의 부모 / 조부모</strong>
              <div className="form_wrap">
                <div className="form">
                  <div className="ip_box btn_sel_wrap">
                    <div className="inner_wrap">
                      <button type="button" className="btn_sel GTM-PAL-9-1">
                        <span className="lbl">인원을 선택해주세요.</span>
                        <span className="sel_txt">0명</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <strong className="tit">배우자의 부모 / 조부모</strong>
              <div className="form_wrap">
                <div className="form">
                  <div className="ip_box btn_sel_wrap">
                    <div className="inner_wrap">
                      <button type="button" className="btn_sel GTM-PAL-9-2">
                        <span className="lbl">인원을 선택해주세요.</span>
                        <span className="sel_txt">0명</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <strong className="tit">자녀 / 손자녀</strong>
              <div className="form_wrap">
                <div className="form">
                  <div className="ip_box btn_sel_wrap">
                    <div className="inner_wrap">
                      <button type="button" className="btn_sel GTM-PAL-9-3">
                        <span className="lbl">인원을 선택해주세요.</span>
                        <span className="sel_txt">0명</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn_wrap">
            <button type="button" className="btn btn_prev GTM-PAL-PREV-1" onClick={() => decreaseStep()}>이전</button>
            <button type="button" className="btn btn_next GTM-PAL-NEXT-2" onClick={() => increaseStep()}>다음</button>
          </div>
        </div>
        <div className="view">
          <div className="tab_tit_wrap">
            <strong><code>03.</code>청약통장 점수 계산<button type="button" className="btn_tip GTM-PAL-TIP-3" onClick={popupAlert03}>청약통장 점수 계산이란?</button></strong>
            <p>미성년자로서 가입한 기간은 2년만 인정됩니다.</p>
          </div>
          <div className="item">
            <strong className="tit">청약통장 가입일</strong>
            <div className="form_wrap">
              <div className="form">
                <div className="ip_box ip_del normal">
                  <label for="ip01" className="lbl code">ex. 1991.01.26</label>
                  <input type="tel" autocomplete="off" id="ip01" className="ip code GTM-PAL-11"
                    maxLength="10"
                    onBlur={onBlurAccountDate}
                    onChange={onChangeAccountDate}
                    value={printDate(accountDate)}
                  />
                  <button type="button" onClick="" className="del"><i>삭제</i></button>
                </div>
              </div>
            </div>
          </div>
          <div className="btn_wrap">
            <button type="button" className="btn btn_prev GTM-PAL-PREV-2" onClick={() => decreaseStep()}>이전</button>
            <button type="button" className="btn btn_next GTM-PAL-NEXT-3" onClick={() => increaseStep()}>다음</button>
          </div>
        </div>
        <div className="view sum_wrap">

          <div className="tit_wrap">
            <span>청약 가점 계산이 완료되었어요.</span>
            <strong>나의 청약 가점은 <em><code>{totalCalcScore}</code>점</em>이에요</strong>
          </div>
          <ul className="count_list">
            <li className="item01">
              <strong>무주택자 점수</strong>
              <span><code>{step1CalcScore}</code>점</span>
            </li>
            <li className="item02">
              <strong>부양가족 점수</strong>
              <span><code>{step2CalcScore}</code>점</span>
            </li>
            <li className="item03">
              <strong>청약통장 점수</strong>
              <span><code>{step3CalcScore}</code>점</span>
            </li>
          </ul>
          <div className="sum_total_wrap">
            <p className="info_txt">아파트청약케어의 청약가점 계산기는 참고용이며,<br />산출 된 결과에 책임을 지지 않습니다.</p>
            <strong className="into_txt02">내 청약가점도 저장하고<br />당첨 예상도 확인해보세요</strong>
          </div>
        </div>
      </div>
      <div className="btn_area_wide fix btm">
        <div className="sum_info_wrap">
          <div className="tit_wrap">
            <strong className="title" data-name="무주택자"><span className="txt">무주택자</span> 점수</strong>

          </div>
          <div className="total_wrap">
            <span className="count">
              {
                step === 1 ? <><code>{step1CalcScore}</code>점</> :
                  step === 2 ? <><code>{step2CalcScore}</code>점</> :
                    step === 3 ? <><code>{step3CalcScore}</code>점</> :
                      step === 4 ? <><code>{totalCalcScore}</code>점</> : null
              }
            </span>
            <div className="total">
              <span>총</span>
              <span className="code"><code className="bold">{totalCalcScore}</code><i className="slash">/</i><code>84</code></span>
              <span>점</span>
            </div>
          </div>
        </div>
        <div className="btn_sum">
          <button type="button" className="skip btn_sum GTM-PAL-12" onClick={() => beforeScoreSaved()}>가점 저장 후 청약당첨가능성 확인</button>
          <button type="button" className="btn_txt btn_reset GTM-PAL-13" onClick={() => { setSaved(false); openApplyCalc() }}><u>다시 계산하기</u></button>
        </div>
      </div>

      <div id="mw-select01" className="ly_pop popBtm ly_select_wrap">
        <div className="wrap">
          <button type="button" onClick={() => popupClose('select01', 'mwf', 0)} className="close"><i>닫기</i></button>
          <div className="content pop_sel_wrap">
            <ul className="list">
              {
                applyWishList.map((v, idx) => {
                  const radioId = `select01_${idx}`
                  return (
                    <li>
                      <span className="item">
                        <input type="radio" id={radioId} name="select01" />
                        <label for={radioId}>
                          <span className="txt" data-value={v.applyId + "_" + v.notiDate}>{v.aptName}</span>
                          <code>{dateFormatMsg2(v.notiDate)}</code>
                        </label>
                      </span>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>

      <div id="mw-select02" className="ly_pop popBtm ly_select_wrap">
        <div className="wrap">
          <button type="button" onClick={() => popupClose('select02', 'mwf', 0)} className="close"><i>닫기</i></button>
          <div className="content pop_sel_wrap">
            <ul className="list">
              <li>
                <span className="item">
                  <input type="radio" id="select02_01" name="select02" />
                  <label for="select02_01">
                    <span className="txt" data-value="0">0명</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select02_02" name="select02" />
                  <label for="select02_02">
                    <span className="txt" data-value="1">1명</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select02_03" name="select02" />
                  <label for="select02_03">
                    <span className="txt" data-value="2">2명</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select02_04" name="select02" />
                  <label for="select02_04">
                    <span className="txt" data-value="3">3명</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select02_05" name="select02" />
                  <label for="select02_05">
                    <span className="txt" data-value="4">4명</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select02_06" name="select02" />
                  <label for="select02_06">
                    <span className="txt" data-value="5">5명</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select02_07" name="select02" />
                  <label for="select02_07">
                    <span className="txt" data-value="6">6명 이상</span>
                  </label>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div id="mw-select03" className="ly_pop popBtm ly_select_wrap">
        <div className="wrap">
          <button type="button" onClick={() => popupClose('select03', 'mwf', 0)} className="close"><i>닫기</i></button>
          <div className="content pop_sel_wrap">
            <ul className="list">
              <li>
                <span className="item">
                  <input type="radio" id="select03_01" name="select03" />
                  <label for="select03_01">
                    <span className="txt" data-value="0">0명</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select03_02" name="select03" />
                  <label for="select03_02">
                    <span className="txt" data-value="1">1명</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select03_03" name="select03" />
                  <label for="select03_03">
                    <span className="txt" data-value="2">2명</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select03_04" name="select03" />
                  <label for="select03_04">
                    <span className="txt" data-value="3">3명</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select03_05" name="select03" />
                  <label for="select03_05">
                    <span className="txt" data-value="4">4명</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select03_06" name="select03" />
                  <label for="select03_06">
                    <span className="txt" data-value="5">5명</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select03_07" name="select03" />
                  <label for="select03_07">
                    <span className="txt" data-value="6">6명 이상</span>
                  </label>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div >

      <div id="mw-select04" className="ly_pop popBtm ly_select_wrap">
        <div className="wrap">
          <button type="button" onClick={() => popupClose('select04', 'mwf', 0)} className="close"><i>닫기</i></button>
          <div className="content pop_sel_wrap">
            <ul className="list">
              <li>
                <span className="item">
                  <input type="radio" id="select04_01" name="select04" />
                  <label for="select04_01">
                    <span className="txt" data-value="0">0명</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select04_02" name="select04" />
                  <label for="select04_02">
                    <span className="txt" data-value="1">1명</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select04_03" name="select04" />
                  <label for="select04_03">
                    <span className="txt" data-value="2">2명</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select04_04" name="select04" />
                  <label for="select04_04">
                    <span className="txt" data-value="3">3명</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select04_05" name="select04" />
                  <label for="select04_05">
                    <span className="txt" data-value="4">4명</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select04_06" name="select04" />
                  <label for="select04_06">
                    <span className="txt" data-value="5">5명</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select04_07" name="select04" />
                  <label for="select04_07">
                    <span className="txt" data-value="6">6명 이상</span>
                  </label>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div id="mw-creditAgree" className="ly_pop popBtm ly_alert_b ly_terms">
        <div className="wrap">
          <div className="content">
            <strong className="tit">청약가점 저장을 위해서<br /> 개인(신용)정보 수집 및 이용동의가<br /> 필요해요.</strong>
            <div className="chk_terms">
              <span className="checkbox i_all">
                <input type="checkbox" id="termsCreditAgree" />
                <label for="termsCreditAgree"><i></i></label>
                <button type="button" className="link" onClick={() => openCreditAgreePop()}>[선택] 개인(신용)정보 수집 및 이용 동의</button>
              </span>
            </div>
            <div className="btn_area_half">
              <button type="button" className="skip" onClick={() => scoreSaved()}>나의 청약가점 계산 결과 저장</button>
            </div>
            <button type="button" onClick={() => popupClose('creditAgree', 'mwf', 0)} className="close"><i>닫기</i></button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplyCalc;