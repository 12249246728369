import React from "react";
import {COUPON} from "../../../consts/coupon";
import {useCoupon} from "../../../hooks/useCoupon";

/**
 * @desc 사용 가능한 유색쿠폰
 */
export const CouponItemUsable = () => {
    const {coupon, issueCoupon} = useCoupon();



    return (
            <li className="coupon-item usable" style={{backgroundColor: COUPON[coupon.key].backgroundColor}}>
                <div className="text-box">
                    <span className="text-01">{COUPON[coupon.key].cpnGrpNm} 1천원 쿠폰</span>
                    <span className="text-03"><i className="icon-won"></i> 1,000</span>
                </div>
                <button className="btn-coupon-down" onClick={issueCoupon}>
                    <i className="icon-download"></i>
                </button>
            </li>
    );
};