import {BjdOverlay} from "./BjdOverlay";

export function MyOverlay (data) {
  this.position = new kakao.maps.LatLng(data.lat, data.lng);    // eslint-disable-line
  let node = this.node = document.createElement('div');
  node.className = 'map_marker_icon my';
  node.setAttribute('data-lat', data.lat);
  node.setAttribute('data-lng', data.lng);

  let icoDivNode = document.createElement('div');
  icoDivNode.className = 'marker_icon';
  node.appendChild(icoDivNode);
}

if (window.kakao) {
  MyOverlay.prototype = new window.kakao.maps.AbstractOverlay;

  MyOverlay.prototype.onAdd = function () {
    var panel = this.getPanels().overlayLayer;
    panel.appendChild(this.node);
  }

  MyOverlay.prototype.onRemove = function () {
    this.node.parentNode.removeChild(this.node);
  }

  MyOverlay.prototype.draw = function () {
    // 화면 좌표와 지도의 좌표를 매핑시켜주는 projection객체
    var projection = this.getProjection();

    // overlayLayer는 지도와 함께 움직이는 layer이므로
    // 지도 내부의 위치를 반영해주는 pointFromCoords를 사용합니다.
    var point = projection.pointFromCoords(this.position);

    // 내부 엘리먼트의 크기를 얻어서
    var width = this.node.offsetWidth;
    var height = this.node.offsetHeight;

    // 해당 위치의 정중앙에 위치하도록 top, left를 지정합니다.
    // marker 특성 고려 말풍선꼭지부분이 중앙에 오도록 재조정 (left+10, top-10)
    this.node.style.left = (point.x - width / 2) + 10 + "px";
    this.node.style.top = (point.y - height / 2) - 10 + "px";

    //myoverlay는 zindex 지정 (제일 위에 표시)
    this.node.style.zIndex = 1;
  }

  // 좌표를 반환하는 메소드
  MyOverlay.prototype.getPosition = function () {
    return this.position;
  }
}

// export class MyOverlay extends kakao.maps.AbstractOverlay {    // eslint-disable-line
//
//   constructor(data) {
//     super();
//     this.position = new kakao.maps.LatLng(data.lat, data.lng);    // eslint-disable-line
//     let node = this.node = document.createElement('div');
//     node.className = 'map_marker_icon my';
//     node.setAttribute('data-lat', data.lat);
//     node.setAttribute('data-lng', data.lng);
//
//     let icoDivNode = document.createElement('div');
//     icoDivNode.className = 'marker_icon';
//     node.appendChild(icoDivNode);
//
//   }
//
//   onAdd() {
//     var panel = super.getPanels().overlayLayer;
//     panel.appendChild(this.node);
//   }
//
//   onRemove() {
//     this.node.parentNode.removeChild(this.node);
//   };
//
//   draw() {
//     // 화면 좌표와 지도의 좌표를 매핑시켜주는 projection객체
//     var projection = super.getProjection();
//
//     // overlayLayer는 지도와 함께 움직이는 layer이므로
//     // 지도 내부의 위치를 반영해주는 pointFromCoords를 사용합니다.
//     var point = projection.pointFromCoords(this.position);
//
//     // 내부 엘리먼트의 크기를 얻어서
//     var width = this.node.offsetWidth;
//     var height = this.node.offsetHeight;
//
//     // 해당 위치의 정중앙에 위치하도록 top, left를 지정합니다.
//     // marker 특성 고려 말풍선꼭지부분이 중앙에 오도록 재조정 (left+10, top-10)
//     this.node.style.left = (point.x - width / 2) + 10 + "px";
//     this.node.style.top = (point.y - height / 2) - 10 + "px";
//
//     //myoverlay는 zindex 지정 (제일 위에 표시)
//     this.node.style.zIndex = 1;
//   };
//
//   // 좌표를 반환하는 메소드
//   getPosition() {
//     return this.position;
//   };
//
// }