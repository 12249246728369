import React from 'react';

const SearchResult = (props) => {
//console.log(props.searchResult);
  return (
    <div className={`${props.searchVisible ? "search_list on" : "search_list"}`}>
      <ul className={`${props.searchResult.length > 0 ? "list_item" : "list_item recent"}`}>
        {

          props.searchResult.length === 0 && props.searchKeyword.trim() !== '' ?
            <li class="no_search">
              <a href="#;">'{props.searchKeyword}'에 대한 검색결과가 없어요.</a>
            </li>
            : props.searchResult && props.searchResult.map((v, idx) => {

              let searchName = v.info1 ? v.info1 + ' ' + v.searchName : v.searchName;
              props.searchKeyword.split(' ').forEach(v2 => {
                searchName = searchName.replace(v2, `<i class="core">${v2}</i>`);
              });
              return (
                <li key={idx}
                  onClick={(e) => { e.preventDefault(); props.onSelectSearch(v); }}>
                  {/* {className={`${idx == props.searchIndex ? "focus" : ""}`}  >} */}
                  {idx == props.searchIndex ?
                    <h3>
                      <a href="#" dangerouslySetInnerHTML={{ __html: searchName }} ></a>
                    </h3>
                    :
                    <a href="#" dangerouslySetInnerHTML={{ __html: searchName }} ></a>
                  }
                </li>
              )
            })
        }
      </ul>
    </div>
  );
}
export default SearchResult;