//// 컴포넌트 사용처 : 아파트 추천
const MainFilter = ({ filterName }) => {

  // console.log(filterName);

  return (
    <div className="fix">
      <div className="tab">
        <ul className="inner">
          <li className="slide active"><a href="#" className={`${filterName.houseYn ? "change_txt" : ""} GTM-REC-FILTER-1`} >{filterName.houseYn ? filterName.houseYn : '무주택자'}</a></li>
          <li className="slide"><a href="#" className={`${filterName.locals ? "change_txt" : ""} GTM-REC-FILTER-2`} >{filterName.locals ? filterName.locals : '지역'}</a></li>
          <li className="slide"><a href="#" className={`${filterName.py ? "change_txt" : ""} GTM-REC-FILTER-3`} >{filterName.py ? filterName.py : '평형'}</a></li>
          <li className="slide"><a href="#" className={`${filterName.apr ? "change_txt" : ""} GTM-REC-FILTER-4`} >{filterName.apr ? filterName.apr : '입주년차'}</a></li>
          <li className="slide"><a href="#" className={`${filterName.yearPay ? "change_txt" : ""} GTM-REC-FILTER-5`} >{filterName.yearPay ? filterName.yearPay : '연소득'}</a></li>
          <li className="slide"><a href="#" className={`${filterName.assets ? "change_txt" : ""} GTM-REC-FILTER-6`} >{filterName.assets ? filterName.assets : '보유자산'}</a></li>
          <li className="slide"><a href="#" className={`${filterName.monthlyLoan ? "change_txt" : ""} GTM-REC-FILTER-7`} >{filterName.monthlyLoan ? filterName.monthlyLoan : '상환액'}</a></li>
        </ul>
      </div>
    </div>
  );
}

export default MainFilter;