import { useEffect } from 'react';
import { popupClose, uiDropdown } from 'routes/common/Design';

import { callAppInterface } from 'routes/common/AppInterface';

import $ from 'jquery';
import axios from 'axios';

const MyPageEmailPopup = ({ myPageInfo, setMyPageInfo }) => {
  // 개인정보 수집 및 이용 동의
  const agreeClickPop = () => {
    callAppInterface('openWebPage', JSON.stringify({ type: 'I', url: (`${process.env.REACT_APP_ENV_URL}apt/terms?type=8`) }), result => {});
  };

  // 등록/수정하기 버튼 클릭 시
  const submitHandler = () => {
    const selected_email = $('.dropdown_content [class="selected"]').val();

    if ($('#email').val() === '') {
      alert('이메일을 입력해주세요.');
      return false;
    } else if(selected_email === '') {
      alert('이메일 주소를 선택해주세요.');
      return false;
    } else if (!$('#terms').prop('checked')) {
      alert('약관을 확인해주세요.');
      return false;
    }

    // 이메일
    let email = $('#email').val() || '';

    if (selected_email !== 'self') {
      email += selected_email;
    }

    // 약관동의여부
    const emailTermsAgree = ($('#terms').prop('checked')) ? 'Y' : 'N';

    // 사용자 email 정보 등록/수정
    registUserEmail(email, (($('#terms').prop('checked')) ? 'Y' : 'N'));
  };

  /**
   * @description 사용자 email 정보 등록/수정
   * @param { string } email            // 이메일
   * @param { string } emailTermsAgree  // 약관동의여부
   * @returns void
   */
  const registUserEmail = (email, emailTermsAgree) => {
    let paramForm = new FormData();

    paramForm.append('email'          , email);                        // 이메일 주소
    paramForm.append('emailTermsAgree', emailTermsAgree);    // 약관동의여부

    axios
        .post('apt/regist/registUserEmail', paramForm, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } })
        .then(res => {
          switch (res.data.code) {
            case 'E0000': {
              alert("이메일 등록/수정이 완료되었습니다.");

              setMyPageInfo({...myPageInfo, 'email': email});

              popupClose('email', 'mwf');

              break;
            }
            default : {
              alert(res.data.message);

              return false;
            }
          }
        });
  };

  useEffect(() => {
    uiDropdown.init();
  }, []);

  return (
    <div id="mw-email" className="ly_pop popBtm ly_alert ly_email ndim">
      <div className="wrap">
        <button type="button" onClick={() => popupClose('email', 'mwf')} className="close"><i>닫기</i></button>
        <div className="content">
          <div className="txt_wrap type1">
            <strong className="tit">이메일 정보 입력</strong>
            <p className="sTxt">이메일을 등록하시면 정기적으로 유용한<br />청약 및 부동산 정보를 받으실 수 있습니다.</p>
            <div className="form_wrap">
              <div className="form">
                <div className="ip_box">
                  <label htmlFor="email" className="lbl">이메일 아이디</label>
                  <input type="text" id="email" className="ip code" placeholder="아이디 입력"/>
                  {/* <input type="text" id="email" className="ip code" placeholder="아이디 입력" defaultValue={`${myPageInfo.email || ''}`}/> */}
                </div>
              </div>
              <div className="form">
                <div className="dropdown" data-dropdown>
                  <div className="dropdown_header" style={{ width: '124px' }}>
                    <button type="button" data-dropdown-trigger>선택</button>
                  </div>
                  <div className="dropdown_content" data-dropdown-select>
                    <button type="button" value="" className="selected">선택</button>
                    <button type="button" value="@naver.com">@naver.com</button>
                    <button type="button" value="@hanmail.com">@hanmail.com</button>
                    <button type="button" value="@nate.com">@nate.com</button>
                    <button type="button" value="@gmail.com">@gmail.com</button>
                    <button type="button" value="self">직접입력</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="chk_terms">
              <span className="checkbox i_all">
                <input type="checkbox" id="terms" />
                <label htmlFor="terms"><i></i></label>
                <button type="button" id="termsEmailAgree" className="link" onClick={() => agreeClickPop()}>개인정보 수집 및 이용 동의</button>
              </span>
            </div>
          </div>
        </div>
        <ul className="btn_area_wide">
          <li>
            <button type="button" className="skip" onClick={() => submitHandler()}>등록하기</button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MyPageEmailPopup;