import React, { useState, useEffect } from 'react';

import { ApplyOverlay } from 'routes/overlay/ApplyOverlay';
import { AroundOverlay } from 'routes/overlay/AroundOverlay';

import { uiFixValue, uiSelFocus, uiInputDel, popupOpen, popupClose, uiIpFocus, chartCircle, uiInfoPopSwiper01 } from 'routes/common/Design.js'

import $ from 'jquery'

let map = {};
let overlayList = [];
let aroundOverlayList = [];

//// 컴포넌트 사용처 : 청약 당첨 예상 상세
const LocMap = ({ toggleMap, calcResult }) => {

  const [locType, setLocType] = useState('');

  //console.log(toggleMap);

  useEffect(() => {

    //[2021-08-18] 셀렉트 박스 변경
    $(".btn_sel_wrap").each(function (idx) {
      $(this).data("key", idx + 1);
      // btm select popup 호출
      $(this).find(".btn_sel").on("click", function () {
        var key = $(this).parents(".btn_sel_wrap").data("key");
        popupOpen('select0' + key, 'mwf', 0);
      });
    });

    $(".ly_select_wrap").each(function (idx) {
      $(this).data("ctrlKey", idx + 1);
      // btm select 값 선택// btm select 값 선택
      $(this).find(".pop_sel_wrap .list input").on("change", function () {
        //console.log(locType);

        var ctrlKey = $(this).parents(".ly_select_wrap").data("ctrlKey");
        var selTxt = $(this).parents(".item").find(".txt").text();

        $(".btn_sel_wrap").eq(ctrlKey - 1).removeClass("focus").find(".lbl").addClass("hide");
        $(".btn_sel_wrap").eq(ctrlKey - 1).find(".sel_txt").text(selTxt);

        //select value 찾도록 추가
        var selVal = $(this).parents(".item").find(".txt").attr('data-value');
        selectLocType(selVal);

        popupClose('select0' + ctrlKey, 'mwf', 0);
      });
    });

    if (!toggleMap) {
      return;
    }

    let { applyInfo, locList, applyDtl, applyCalendar } = calcResult;

    setTimeout(function () {

      clearOverlay();
      clearAroundOverlay();

      const mapContainer = document.getElementById('around_map');
      const mapOption = {
        center: new kakao.maps.LatLng(applyInfo.lat, applyInfo.lng),   // eslint-disable-line
        level: 5
      };

      map = new kakao.maps.Map(mapContainer, mapOption);   // eslint-disable-line

      kakao.maps.event.addListener(map, 'dragend', () => { }); // eslint-disable-line
      kakao.maps.event.addListener(map, 'zoom_changed', () => { });  // eslint-disable-line

      //renderMarker(applyCalendar);
      onPanTo(applyInfo.lat, applyInfo.lng);
      renderingAroundDataIcons(locList);


      // const markerPosition = new kakao.maps.LatLng(applyInfo.lat, applyInfo.lng);    // eslint-disable-line
      // const marker = new kakao.maps.Marker({   // eslint-disable-line
      //   position: markerPosition
      // });

      // marker.setMap(map);

      // locList.forEach(v => {

      //   if(v.locType === 'SC4' || v.locType === 'SW8' || v.locType === 'MPARK' ){

      //     v.type = v.locType === 'SC4' ? 'school' :
      //             v.locType === 'SW8' ? 'ico_hospital' : 
      //             v.locType === 'MPARK' ? 'ico_park' :  null;

      //     const overlay = new AroundOverlay(v);
      //     overlay.setMap(map);  
      //   }
      // })
    }, 500);

  }, [toggleMap]);

  const clearOverlay = () => {
    overlayList.forEach(ov => ov.setMap(null));
    overlayList = [];
  }

  const clearAroundOverlay = () => {
    aroundOverlayList.forEach(ov => ov.setMap(null));
    aroundOverlayList = [];
  }

  const renderMarker = (data) => {
    let overlay = new ApplyOverlay(data);
    overlay.setMap(map);
  }

  const onPanTo = (paramLat, paramLng) => {
    let moveLatLng = new kakao.maps.LatLng(paramLat, paramLng); // eslint-disable-line
    map.panTo(moveLatLng);
  }

  const renderingAroundDataIcons = (data) => {
    aroundOverlayList.forEach(ov => ov.setMap(null));
    aroundOverlayList = [];

    const dtl = data;

    //console.log(data);

    data.filter(v => v.locType === 'SC4').forEach(v => {
      v.type = 'school';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    data.filter(v => v.locType === 'MDEPT').forEach(v => {
      v.type = 'ico_mart';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    data.filter(v => v.locType === 'SW8').forEach(v => {
      v.type = 'ico_subway';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    data.filter(v => v.locType === 'HP8').forEach(v => {
      v.type = 'ico_hospital';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    data.filter(v => v.locType === 'MPARK').forEach(v => {
      v.type = 'ico_park02';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });
  }

  const selectLocType = (value) => {
    setLocType(value);
  }
  const getAroundCount = type => {
    const { locCnt } = calcResult;
    return Number(locCnt.findIndex(v => v.locType === type) > -1 ? locCnt.filter(v => v.locType === type).map(v => v.locCnt) : 0);
  }

  const { locCnt, locList, applyScore } = calcResult;

  const totalAroundScore = (applyScore != null ?
    (applyScore.elementarySchoolScore + applyScore.middleSchoolScore +
      applyScore.subwayScore + applyScore.departmentScore +
      applyScore.parkScore + applyScore.woodsScore + applyScore.mdconaldScore + applyScore.starbucksScore + applyScore.hospitalScore)
    //+ (applyScore.applyId == '2021000362' ? 37 : 0))
    //+ (applyScore.applyId == '2021000362' ? 67 : 0))
    //+ (applyScore.applyId == '2021000362' ? 118 : 0))
    : 0);

  //console.log(totalAroundScore);

  const scoreType = (
    (totalAroundScore < 70) ? 'bad' :
      (totalAroundScore >= 70 && totalAroundScore < 100) ? 'normal' :
        (totalAroundScore >= 100 && totalAroundScore < 151) ? 'good' :
          (totalAroundScore >= 151) ? 'best' : '');

  //{applyScore != null ? applyScore.elementarySchoolScore + applyScore.middleSchoolScore : 0} 학교
  //{applyScore != null ? applyScore.subwayScore : 0} 지하철
  //{applyScore != null ? applyScore.hospitalScore : 0} 병원
  //{locList.filter(v => v.locType === 'MDEPT').length === 0 ? 0 : (applyScore != null ? applyScore.departmentScore : 0)} 백화점
  //{applyScore != null ? applyScore.parkScore : 0} 공원
  //{applyScore != null ? applyScore.woodsScore : 0}  숲
  //{applyScore != null ? (applyScore.mdconaldScore + applyScore.starbucksScore) : 0} 기타

  const schoolScore = (applyScore != null ? applyScore.elementarySchoolScore + applyScore.middleSchoolScore : 0);
  const subwayScore = (applyScore != null ? applyScore.subwayScore : 0);
  const hospitalScore = (applyScore != null ? applyScore.hospitalScore : 0);
  const departScore = (locList.filter(v => v.locType === 'MDEPT').length === 0 ? 0 : (applyScore != null ? applyScore.departmentScore : 0));
  const parkScore = (applyScore != null ? applyScore.parkScore : 0);
  const forestScore = (applyScore != null ? applyScore.woodsScore : 0);
  const etcScore = (applyScore != null ? (applyScore.mdconaldScore + applyScore.starbucksScore) : 0);


  return (
    <>
      <div className="view_toggle apt_info_wrap">
        <div className="tit_wrap">
          <span className="tit">주변 정보 <strong>종합 점수</strong></span>
          <p className="txt">해당 점수는 아파트 단지 정보와 분양 정보, 아파트가 위치하는 지역의 주변정보들을 종합하여 산출 한 점수에요. 해당 점수는 참고용이며, 투자 및 청약신청에 대한 책임은 이용자 본인에게 있습니다.</p>
        </div>
        {/* <!-- verygood/good/normal/bad로 색상,이모지 변경 --> */}
        <div className={`ty_graph ${scoreType}`}>
          <div className="graph">
            <div className="dount_area">
              <div className="circle_chart" data-percent={totalAroundScore} data-permax="170">
                <div className="chart-box">
                  <svg>
                    <defs>
                      <linearGradient id={`dount_${scoreType}`} x1="0" y1="0" x2="1" y2="1">
                        <stop className="color_1" offset="0%" />
                        <stop className="color_2" offset="100%" />
                      </linearGradient>
                    </defs>
                    <circle stroke={`url(#dount_${scoreType})`} r="50%" cx="50%" cy="50%" className="circle_progress" stroke-width="18" stroke-linecap="round"></circle>
                    <filter id="shadow">
                      <feDropShadow dx="0" dy="0" stdDeviation="2"
                        flood-color="#CDCDCD" />
                    </filter>
                    <circle className="marker" r="11" cx="50%" cy="107" style={{ 'filter': 'url(#shadow)' }}></circle>
                  </svg>
                  <div className="per-num"><span>{totalAroundScore}</span>/<s>170</s></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul className="facility_info">
          <li>
            <strong className="icon_school">학교</strong>
            <span>{locCnt.findIndex(v => v.locType === 'SC4') > -1 ? locCnt.filter(v => v.locType === 'SC4').map(v => v.locCnt) : 0}개</span>
          </li>
          <li>
            <strong className="icon_subway">지하철</strong>
            <span>{locCnt.findIndex(v => v.locType === 'SW8') > -1 ? locCnt.filter(v => v.locType === 'SW8').map(v => v.locCnt) : 0}개</span>
          </li>
          <li>
            <strong className="icon_hospital">병원</strong>
            <span>{locCnt.findIndex(v => v.locType === 'HP8') > -1 ? locCnt.filter(v => v.locType === 'HP8').map(v => v.locCnt) : 0}개</span>
          </li>
          <li>
            <strong className="icon_mart">백화점</strong>
            <span>{locCnt.findIndex(v => v.locType === 'MDEPT') > -1 ? locCnt.filter(v => v.locType === 'MDEPT').map(v => v.locCnt) : 0}개</span>
          </li>
          <li>
            <strong className="icon_park02">공원</strong>
            <span>{locCnt.findIndex(v => v.locType === 'MPARK') > -1 ? locCnt.filter(v => v.locType === 'MPARK').map(v => v.locCnt) : 0}개</span>
          </li>
          <li>
            <strong className="icon_park">산</strong>
            <span>{locCnt.findIndex(v => v.locType === 'MMONT') > -1 ? locCnt.filter(v => v.locType === 'MMONT').map(v => v.locCnt) : 0}개</span>
          </li>
          <li>
            <strong className="icon_etc">기타</strong>
            <span>{locCnt.findIndex(v => v.locType === 'MMCND' || v.locType === 'MSTBK') > -1 ? locCnt.filter(v => v.locType === 'MMCND' || v.locType === 'MSTBK').map(v => v.locCnt) : 0}개</span>
          </li>
          <li>
            <strong className="icon_mart02">편의점</strong>
            <span>0개</span>
          </li>
        </ul>
        <p className="noti">* 최근 1년 간 청약아파트 지역에서 진행되었던 청약 아파트 결과입니다.</p>
        <div className="map_sec" id="around_map">
          {/* <img src="resources/images/temp/map.png" alt="" /> */}
          {/* 
          <div className="map_marker" style="top:100px;left:70px;">
              <div className="marker_inner type_apt">
                  <div className="marker_info">
                      <p className="name">민앤지포레나퍼스트시티</p>
                      <div className="info">
                          <p className="family">1,500<em>세대</em></p>
                          /
                          <p className="build">1,990<span>년</span><em>준공</em></p>
                      </div>
                  </div>
                  <div className="data">
                      <p className="sqf">34<em>평</em></p>
                      <p className="price">10.5<em>억</em></p>
                      <p className="day">2020. 01</p>
                  </div>
                  <div className="marker_shadow"></div>
              </div>
          </div>
          */}
        </div>

        <div className="map_info">
          <div className="tit_wrap">
            <span className="tit">주변 정보 <strong>상세 점수</strong></span>
          </div>
          <div className="form_wrap">
            <div className="form">
              <div className="ip_box btn_sel_wrap">
                <div className="inner_wrap">
                  <button type="button" className="btn_sel">
                    <span className="sel_txt">전체({getAroundCount('SC4') + getAroundCount('SW8') + getAroundCount('HP8') + getAroundCount('MDEPT') + getAroundCount('MPARK') + getAroundCount('MMONT') + getAroundCount('MMCND') + getAroundCount('MSTBK')})</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ul className="map_info_list">
          {
            (locType === '' || locType === 'school') &&
            <li>
              <div className="tit_wrap">
                <strong className="icon_school">학교</strong>
                <span className={schoolScore < 5 ? "low" : ""}>
                  <code>{schoolScore}</code>점</span>
              </div>
              <ul className="sub_list">
                {
                  locList.filter(v => v.locType === 'SC4').length > 0 ?
                    locList.filter(v => v.locType === 'SC4').map((v, idx) => {
                      return <li key={idx}>
                        <strong className="sub_th"><span className="tit" onClick={() => onPanTo(v.lat, v.lng)}>{v.locName}</span>({v.locDistance}m)</strong>
                        <span className="sub_td">도보 {Math.round(v.locDistance / 66)}분</span>
                      </li>;
                    })
                    :
                    <p className="txt">1500m 근처에 위치하고 있는 학교가 없어요.</p>
                }
              </ul>
            </li>
          }
          {
            (locType === '' || locType === 'subway') &&
            <li>
              <div className="tit_wrap">
                <strong className="icon_subway">역세권</strong>
                <span className={subwayScore < 5 ? "low" : ""}>
                  <code>{subwayScore}</code>점</span>
              </div>
              <ul className="sub_list">
                {
                  locList.filter(v => v.locType === 'SW8').length > 0 ?
                    locList.filter(v => v.locType === 'SW8').map((v, idx) => {
                      return <li key={idx}>
                        <strong className="sub_th"><span className="tit" onClick={() => onPanTo(v.lat, v.lng)}>{v.locName}</span>({v.locDistance}m)</strong>
                        <span className="sub_td">도보 {Math.round(v.locDistance / 66)}분</span>
                      </li>;
                    })
                    :
                    <p className="txt">1500m 근처에 위치하고 있는 지하철역이 없어요.</p>
                }
              </ul>
            </li>
          }
          {
            (locType === '' || locType === 'hospital') &&
            <li>
              <div className="tit_wrap">
                <strong className="icon_hospital">병원</strong>
                <span className={hospitalScore < 5 ? "low" : ""}>
                  <code>{hospitalScore}</code>점</span>
              </div>
              <ul className="sub_list">
                {
                  locList.filter(v => v.locType === 'HP8').length > 0 ?
                    locList.filter(v => v.locType === 'HP8').map((v, idx) => {
                      return <li key={idx}>
                        <strong className="sub_th"><span className="tit" onClick={() => onPanTo(v.lat, v.lng)}>{v.locName}</span>({v.locDistance}m)</strong>
                        <span className="sub_td">도보 {Math.round(v.locDistance / 66)}분</span>
                      </li>;
                    })
                    :
                    <p className="txt">3000m 근처에 위치하고 있는 대형병원이 없어요.</p>
                }
              </ul>
            </li>
          }
          {
            (locType === '' || locType === 'mart') &&
            <li>
              <div className="tit_wrap">
                <strong className="icon_mart">백화점</strong>
                <span className={departScore < 5 ? "low" : ""}>
                  <code>{departScore}</code>점</span>
              </div>
              <ul className="sub_list">
                {
                  locList.filter(v => v.locType === 'MDEPT').length > 0 ?
                    locList.filter(v => v.locType === 'MDEPT').map((v, idx) => {
                      return <li key={idx}>
                        <strong className="sub_th"><span className="tit" onClick={() => onPanTo(v.lat, v.lng)}>{v.locName}</span>({v.locDistance}m)</strong>
                        <span className="sub_td">도보 {Math.round(v.locDistance / 66)}분</span>
                      </li>;
                    })
                    :
                    <p className="txt">1500m 근처에 위치하고 있는 백화점이 없어요.</p>
                }
              </ul>
            </li>
          }
          {
            (locType === '' || locType === 'park') &&
            <li>
              <div className="tit_wrap">
                <strong className="icon_park02">공세권</strong>
                <span className={parkScore < 5 ? "low" : ""}>
                  <code>{parkScore}</code>점</span>
              </div>
              <ul className="sub_list">
                {
                  locList.filter(v => v.locType === 'MPARK').length > 0 ?
                    locList.filter(v => v.locType === 'MPARK').map((v, idx) => {
                      return <li key={idx}>
                        <strong className="sub_th"><span className="tit" onClick={() => onPanTo(v.lat, v.lng)}>{v.locName}</span>({v.locDistance}m)</strong>
                        <span className="sub_td">도보 {Math.round(v.locDistance / 66)}분</span>
                      </li>;
                    })
                    :
                    <p className="txt">1500m 근처에 위치하고 있는 공원이 없어요.</p>
                }
              </ul>
            </li>
          }
          {
            (locType === '' || locType === 'mountain') &&
            <li>
              <div className="tit_wrap">
                <strong className="icon_park">숲세권</strong>
                <span className={forestScore < 5 ? "low" : ""}>
                  <code>{forestScore}</code>점</span>
              </div>
              <ul className="sub_list">
                {
                  locList.filter(v => v.locType === 'MMONT').length > 0 ?
                    locList.filter(v => v.locType === 'MMONT').map((v, idx) => {
                      return <li key={idx}>
                        <strong className="sub_th"><span className="tit" onClick={() => onPanTo(v.lat, v.lng)}>{v.locName}</span>({v.locDistance}m)</strong>
                        <span className="sub_td">도보 {Math.round(v.locDistance / 66)}분</span>
                      </li>;
                    })
                    :
                    <p className="txt">1500m 근처에 위치하고 있는 산/숲이 없어요.</p>
                }
              </ul>
            </li>
          }
          {
            (locType === '' || locType === 'etc') &&
            <li>
              <div className="tit_wrap">
                <strong className="">기타</strong>
                <span className={etcScore < 5 ? "low" : ""}>
                  <code>{etcScore}</code>점</span>
              </div>
              <ul className="sub_list">
                {
                  locList.filter(v => v.locType === 'MMCND' || v.locType === 'MSTBK').length > 0 ?
                    locList.filter(v => v.locType === 'MMCND' || v.locType === 'MSTBK').map((v, idx) => {
                      return <li key={idx}>
                        <strong className="sub_th"><span className="tit" onClick={() => onPanTo(v.lat, v.lng)}>{v.locName}</span>({v.locDistance}m)</strong>
                        <span className="sub_td">도보 {Math.round(v.locDistance / 66)}분</span>
                      </li>;
                    })
                    :
                    <p className="txt">1500m 근처에 위치하고 있는 기타장소가 없어요.</p>
                }
              </ul>
            </li>
          }
        </ul>
        <ul className="map_info_txt">
          <li>상세점수는 10점 만점 기준이에요.</li>
          <li>개인별로 실제 도보 소요시간은 상이 할 수 있으며, 실제 주변 정보와 상이 할 수 있어요.</li>
          <li>실제와 상이한 주변 정보는 고객센터로 접수 부탁드려요.<br />(고객센터 1599-2985) </li>
        </ul>
      </div>

      <div id="mw-select01" className="ly_pop popBtm ly_select_wrap">
        <div className="wrap">
          <button type="button" onClick={() => popupClose('select01', 'mwf', 0)} className="close"><i>닫기</i></button>
          <div className="content pop_sel_wrap">
            <ul className="list">
              <li>
                <span className="item">
                  <input type="radio" id="select01_01" name="select01" />
                  <label for="select01_01">
                    <span className="txt" data-value="">전체({getAroundCount('SC4') + getAroundCount('SW8') + getAroundCount('HP8') + getAroundCount('MDEPT') + getAroundCount('MPARK') + getAroundCount('MMONT') + getAroundCount('MMCND') + getAroundCount('MSTBK')})</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select01_02" name="select01" />
                  <label for="select01_02">
                    <span className="txt" data-value="school">학교({getAroundCount('SC4')})</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select01_03" name="select01" />
                  <label for="select01_03">
                    <span className="txt" data-value="subway">지하철({getAroundCount('SW8')})</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select01_04" name="select01" />
                  <label for="select01_04">
                    <span className="txt" data-value="hospital">병원({getAroundCount('HP8')})</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select01_05" name="select01" />
                  <label for="select01_05">
                    <span className="txt" data-value="mart">백화점({getAroundCount('MDEPT')})</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select01_06" name="select01" />
                  <label for="select01_06">
                    <span className="txt" data-value="park">공원({getAroundCount('MPARK')})</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select01_07" name="select01" />
                  <label for="select01_07">
                    <span className="txt" data-value="mountain">산({getAroundCount('MMONT')})</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select01_08" name="select01" />
                  <label for="select01_08">
                    <span className="txt" data-value="etc">기타({getAroundCount('MMCND') + getAroundCount('MSTBK')})</span>
                  </label>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default LocMap;
