import React, {useEffect, useRef, useState} from 'react'
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import axios from 'axios';
import Moment from 'moment';
import $ from 'jquery';

import { TweenMax, TimelineMax, Linear } from 'gsap'
import Swiper from 'swiper/bundle'

import { uiFixValue, uiFilterTabSwipe, popupOpen, popupClose, uiIpFocus, uiInputDel, uiAccoScrollFix, uiSelFocus, uiMainSwitch, uiChartTap, uiToggle, uiTopGo, joinInfoEvent } from 'routes/common/Design.js'
import { callAppInterface } from 'routes/common/AppInterface';

import { osCheck } from 'routes/common/OsUtil';
import aes256 from 'routes/common/crypto/Aes256';
import {KtMvno} from "../../components/common/KtMvno";
import {useJoinStore} from "../../store/useJoinStore";

//// 컴포넌트 사용처 : 가입(소개페이지포함)
const Join = (props) => {

  const [showjoinForm, setShowjoinForm] = useStateWithCallbackLazy(false);
  const [joinStep, setJoinStep] = useState(1);

  const [telcoCode, setTelcoCode] = useState('1');

  const [allAgree, setAllAgree] = useState(false);
  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [agree3, setAgree3] = useState(false);
  const [agree4, setAgree4] = useState(false);
  const [agree5, setAgree5] = useState(false);
  const [agree5_1, setAgree5_1] = useState(false);
  const [agreePos, setAgreePos] = useState(false);
  const [authCode, setAuthCode] = useState('');
  const [captchaAnswer, setCapchaAnswer] = useState('');
  const [savedPhoneNo, setSavedPhoneNo] = useState('');
  const [token, setToken] = useState('');

  const [isKtCsPhone, setIsKtCsPhone] = useState(false);

  const [authTimer, setAuthTimer] = useState(null);

  // [SOM.230208] 민원대응 로그 저장 관련
  const [accessJoin, setAccessJoin] = useState( Moment().format('YYYYMMDDHHmmss') );
  const [accessJoinUri, setAccessJoinUri] = useState( (window.location.pathname + window.location.search) );
  const [accessCheck, setAccessCheck] = useState('');
  const [accessCheckUri, setAccessCheckUri] = useState('');
  const [referrer, setReferrer] = useState( document.referrer );

  const {isShowPopup, setShowPopup, setMVNO, isMVNO} = useJoinStore();

  const phoneNumberInput = useRef(null);
  const authCodeInput = useRef(null);

  useEffect(() => {
    let os = osCheck() == 'Android' ? "A" : "I";

    //joinInfoEvent.call();

    //uiFixValue.init();
    uiFilterTabSwipe.init();
    uiSelFocus.init();
    uiMainSwitch.init();
    uiChartTap.init();
    uiToggle.init();
    uiTopGo.init();

    uiIpFocus.init();
    uiInputDel.init();
    $(window).scrollTop(0);
    window.goback = backToAptMain;

  }, []);

  useEffect(() => {
    if (showjoinForm === false) {
      joinInfoEvent.call();
    }
  }, [showjoinForm]);

  const addImpression = () => {
    let paramForm = new FormData();
    paramForm.append('siteCode', 'AB'); //아파트청약케어 APP가입창 가입코드 : AB (app차후배포시 수정)
    paramForm.append('ctgCode', '1');

    axios
      .post('apt/regist/addImpression', paramForm)
      .then(res => {
      });
  }


  const stepJoin = () => {
    // 하단 확인 버튼 클릭시 step 변환

    //$(".btn_join").on("click", function () {
    //count++;

    if (joinStep === 1) { // checkPhone
      checkAttack(joinStep, 'N');
      //checkPhone(joinStep, 'N');
    }
    else if (joinStep === 2) { // agree
      if (authCodeInput.current.value) {
        submitRegist();
      }
      else {
        checkAttack(joinStep, 'N');
        //checkPhone(joinStep, 'N');
      }
    }
    else if (joinStep === 3) { // submitRequest
      submitRegist();
    }
    //});
  }

  // 통신사 선택시 초기화 처리
  const onChangeTelco = (code) => {
    setTelcoCode(code);
    setAllAgree(false);
    setAgree1(false);
    setAgree2(false);
    setAgree3(false);
    setAgree4(false);
    setAgreePos(false);
    $('#allCheck').prop('checked', false);
    $('input[id^=terms]').prop('checked', false);
    // this.setState({
    //   telcoCode: code,
    // });

    // $('#btnTerms04').html(
    //   "<i></i>" +
    //   (code === 1 ? 'SKT' : (code === 2 ? 'KT' : 'LGU+')) + '개인정보 제3자 제공' +
    //   "<em>(선택)</em>");
  }
  const allAgreeCheckConfirm = () => {
    if (allAgree === false) {
      if (!window.confirm('약관에 모두 동의 하시겠습니까?')) {
        return false;
      }
      else {
        setAllAgree(true);
        setAgree1(true);
        setAgree2(true);
        setAgree3(true);
        setAgree4(true);
        setAgreePos(true);
        //setAgree5(true);
        //setAgree5_1(true);
        $('#allCheck').prop('checked', true);
        $('input[id^=terms]').prop('checked', true);
        // this.setState((state) => {
        //   state.allAgree = true;
        //   state.agree1 = true;
        //   state.agree2 = true;
        //   state.agree3 = true;
        //   state.agree4 = true;
        //   state.agree5 = true;
        //   state.agree5_1 = true;
        //   $('#allCheck').prop('checked', true);
        //   $('input[id^=terms]').prop('checked', true);
        // });
        return true;
      }
    }
  }
  const allAgreeCheck = () => {
    if ($('#terms01').prop('checked') === true &&
      $('#terms02').prop('checked') === true &&
      $('#terms03').prop('checked') === true &&
      $('#terms04').prop('checked') === true &&
      $('#termsPos').prop('checked') === true) {
      //$('#terms05').prop('checked') === true &&
      //$('#terms05_01').prop('checked') === true
      setAllAgree(true);
      $('#allCheck').prop('checked', true);
      // this.setState((state) => {
      //   state.allAgree = true;
      //   $('#allCheck').prop('checked', true);
      // })
    }
    else {
      setAllAgree(false);
      $('#allCheck').prop('checked', false);
      // this.setState((state) => {
      //   state.allAgree = false;
      //   $('#allCheck').prop('checked', false);
      // })
    }

  }
  const allAgreeClick = () => {
    setAllAgree($('#allCheck').prop('checked'));
    setAgree1($('#allCheck').prop('checked'));
    setAgree2($('#allCheck').prop('checked'));
    setAgree3($('#allCheck').prop('checked'));
    setAgree4($('#allCheck').prop('checked'));
    setAgreePos($('#allCheck').prop('checked'));
    //setAgree5($('#allCheck').prop('checked'));
    //setAgree5_1($('#allCheck').prop('checked'));
    $('input[id^=terms]').prop('checked', $('#allCheck').prop('checked'));
    // this.setState((state) => {
    //   state.allAgree = $('#allCheck').prop('checked');
    //   state.agree1 = $('#allCheck').prop('checked');
    //   state.agree2 = $('#allCheck').prop('checked');
    //   state.agree3 = $('#allCheck').prop('checked');
    //   state.agree4 = $('#allCheck').prop('checked');
    //   state.agree5 = $('#allCheck').prop('checked');
    //   state.agree5_1 = $('#allCheck').prop('checked');
    //   $('input[id^=terms]').prop('checked', $('#allCheck').prop('checked'));
    // });
  }
  const agreeClick = (agreeCode) => {
    if (agreeCode === 1) {
      setAgree1($('#terms01').prop('checked'));
      allAgreeCheck();
      // this.setState((state) => {
      //   state.agree1 = $('#terms01').prop('checked');
      //   this.allAgreeCheck();
      // });
    }
    else if (agreeCode === 2) {
      setAgree2($('#terms02').prop('checked'));
      allAgreeCheck();
      // this.setState((state) => {
      //   state.agree2 = $('#terms02').prop('checked');
      //   this.allAgreeCheck();
      // });
    }
    else if (agreeCode === 3) {
      setAgree3($('#terms03').prop('checked'));
      allAgreeCheck();
      // this.setState((state) => {
      //   state.agree3 = $('#terms03').prop('checked');
      //   this.allAgreeCheck();
      // });
    }
    else if (agreeCode === 4) {
      setAgree4($('#terms04').prop('checked'));
      allAgreeCheck();
      // this.setState((state) => {
      //   state.agree4 = $('#terms04').prop('checked');
      //   this.allAgreeCheck();
      // });
    }
    else if (agreeCode === 'pos') {
      setAgreePos($('#termsPos').prop('checked'));
      allAgreeCheck();
      // this.setState((state) => {
      //   state.agree4 = $('#terms04').prop('checked');
      //   this.allAgreeCheck();
      // });
    }
    // else if (agreeCode === 5) {
    //   setAgree5($('#terms05').prop('checked'));
    //   setAgree5_1($('#terms05').prop('checked'));
    //   allAgreeCheck();
    //   // this.setState((state) => {
    //   //   state.agree5 = $('#terms05').prop('checked');
    //   //   $('#terms05_01').prop('checked', $('#terms05').prop('checked'));
    //   //   state.agree5_1 = $('#terms05').prop('checked');
    //   //   this.allAgreeCheck();
    //   // });
    // }
    // else if (agreeCode === '5_1') {
    //   setAgree5($('#terms05_01').prop('checked'));
    //   setAgree5_1($('#terms05_01').prop('checked'));
    //   allAgreeCheck();
    //   // this.setState((state) => {
    //   //   state.agree5 = $('#terms05_01').prop('checked');
    //   //   $('#terms05').prop('checked', $('#terms05_01').prop('checked'));
    //   //   state.agree5_1 = $('#terms05_01').prop('checked');
    //   //   this.allAgreeCheck();
    //   // });
    // }

  }
  const agreeClickPop = (agreeCode) => {
    if (agreeCode === 1) {
      setAgree1(true);
      $('#terms01').prop('checked', true);
      allAgreeCheck();
      const agreeUrl =`${process.env.REACT_APP_APT_WEB_ORIGIN}/apt/terms?type=1&telcoCode=${telcoCode}&loc=N`;
      // callAppInterface('openWebPage', JSON.stringify({ type: 'O', url: agreeUrl }), result => {

      // });
      callAppInterface('openWebPage', JSON.stringify({ type: 'I', url: agreeUrl }), result => {

      });
    }
    else if (agreeCode === 2) {
      setAgree2(true);
      $('#terms02').prop('checked', true);
      allAgreeCheck();
      const agreeUrl = `${process.env.REACT_APP_APT_WEB_ORIGIN}/apt/terms?type=2&telcoCode=` + telcoCode;
      callAppInterface('openWebPage', JSON.stringify({ type: 'I', url: agreeUrl }), result => {

      });
    }
    else if (agreeCode === 3) {
      setAgree3(true);
      $('#terms03').prop('checked', true);
      allAgreeCheck();
      const agreeUrl = `${process.env.REACT_APP_APT_WEB_ORIGIN}/apt/terms?type=3&telcoCode=` + telcoCode;
      callAppInterface('openWebPage', JSON.stringify({ type: 'I', url: agreeUrl }), result => {

      });
    }
    else if (agreeCode === 4) {
      //if (telcoCode === '1' || telcoCode === '2') {
        setAgree4(true);
        $('#terms04').prop('checked', true);
        allAgreeCheck();
        const link = $('#btnTerms04').text().indexOf('MVNO') < 0 ? '/apt/terms?type=4' : '/apt/terms?type=5';
        const agreeUrl = `${process.env.REACT_APP_APT_WEB_ORIGIN}${link}&telcoCode=${telcoCode}`;
        callAppInterface('openWebPage', JSON.stringify({ type: 'I', url: agreeUrl }), result => {

        });
      //}
    }
    else if (agreeCode === 'pos') {
      //if (telcoCode === '1' || telcoCode === '2') {
        setAgreePos(true);
        $('#termsPos').prop('checked', true);
        allAgreeCheck();
        const agreeUrl = `${process.env.REACT_APP_APT_WEB_ORIGIN}/apt/terms?type=6&loc=N`;
        callAppInterface('openWebPage', JSON.stringify({ type: 'I', url: agreeUrl }), result => {

        });
      //}
    }
    else {
      alert('서비스 준비중입니다.');
    }
  }
  const onChangePhoneNo = (e) => {
    let value = e.target.value;
    let currentLength = e.target.value.length;
    let maxLength = e.target.getAttribute('maxLength');

    if (maxLength < currentLength) {
      value = e.target.value.slice(0, maxLength);
      e.target.value = value;
    }
    phoneNumberInput.current.value = value;
  }
  const onChangeAuthCode = (e) => {
    let value = e.target.value;
    let currentLength = e.target.value.length;
    let maxLength = e.target.getAttribute('maxLength');

    if (maxLength < currentLength) {
      value = e.target.value.slice(0, maxLength);
      $('#authCode').val(value);
    }

    authCodeInput.current.value = e.target.value;
    setJoinStep(3);
    // this.setState({
    //   authCode: e.target.value,
    // });
  }
  const onChangeCaptchaAnswer = (e) => {
    let value = e.target.value;
    let currentLength = e.target.value.length;
    let maxLength = e.target.getAttribute('maxLength');

    if (maxLength < currentLength) {
      value = e.target.value.slice(0, maxLength);
      $('#answer_captcha').val(value);
    }

    setCapchaAnswer(e.target.value);
    // this.setState({
    //   captchaAnswer: e.target.value,
    // });
  }
  const commonUtil = {
    //authTimer: null,
    displayTimer(str) {
      var html = $("#" + str).html();
      var min = html.substr(0, 2);
      var sec = html.substr(3, 2);

      if (sec === "00") {
        min = "0" + (parseInt(min) - 1);
        sec = "59";

      } else {
        if (html.substr(3, 1) === "0") {
          sec = html.substr(4, 1);
        }
        var tmp = parseInt(sec) - 1;
        if (tmp < 10) {
          sec = "0" + tmp;
        } else {
          sec = tmp;
        }
      }
      if (min === '00' && sec === '00') {
        $("#" + str).html("입력시간 만료");
        $("#authCode").attr('disabled', true);

      } else {
        $("#" + str).html(min + ":" + sec);
        setAuthTimer(setTimeout(() => {
          this.displayTimer(str);
        }, 1000));
      }
    }
  }

  // 유효성체크 및 통신사 조회 (일반 or MVNO인지 체크) (checkAttack)
  const checkAttack = (joinStep,resendYn) => {
    if (telcoCode === '') {
      alert('통신사를 선택해주세요.');
      return;
    } else if (!phoneNumberInput.current.value) {
      alert('휴대폰번호를 입력해주세요.');
      phoneNumberInput.current.focus();
      return;
    }

    if (authTimer != null) {
      window.clearTimeout(authTimer);
      $("#auth_timer").html("");
    }
    // APP <-> 서버일 경우 휴대폰번호 구간 암호화
    const encPhoneNo = aes256.encrypt(phoneNumberInput.current.value);

    let paramForm = new FormData();
    paramForm.append('phoneNo', encPhoneNo);
    paramForm.append('telcoCode', telcoCode);
    paramForm.append('siteCode', 'AB'); //아파트청약케어 APP가입창 가입코드 : AB (app차후배포시 수정)
    paramForm.append('ctgCode', '1');
    paramForm.append('captchaAnswer', captchaAnswer);
    paramForm.append('appCheck', true);

    axios
      .post('apt/regist/checkAttack', paramForm)
      .then(res => {
        if (res.data.code === "E0000") {
          // KT MVNO 동의 팝업 오픈
          if (telcoCode === '2'
              && res.data.response.isMVNO
              && $('#btnTerms04').text().indexOf('MVNO') < 0
          ) {
            setMVNO(true);
            window.popupOpen('alert', 'mwf', 0);

             return;
          }

          // ▶ 점유인증 호출 (checkPhone)
          callCheckPhone(joinStep,resendYn);
        }
        else {
          if (res.data.code === "EW101" || res.data.code === "EW102") {
            if (res.data.code === "EW102") alert(res.data.message);

            setJoinStep(2);

            window.captcha.phoneNo = phoneNumberInput.current.value;
            $("#newSafeNum").click();
            $('#section_auth').hide();
            $("#section_stepB").show();
            $("#answer_captcha").val('');
            setCapchaAnswer('');
            $('#authCode').val('');
            authCodeInput.current.value = '';

          } else {
            alert(res.data.message);
            window.location.reload();
          }
        }
      });
  }

  const callCheckPhone = (joinStep, resendYn) => {
    if (isMVNO) {
        $('#btnTerms04').contents().each(function() {
            if (this.nodeType === Node.TEXT_NODE) {
                this.nodeValue = 'KT MVNO 제3자제공'; // 텍스트 노드의 값 변경
            }
        });
    }

    // ▶ 점유인증 (checkPhone)
    checkPhone(joinStep, resendYn);
    return false;
  }

  //regist
  const checkPhone = (joinStep, resendYn) => {
    if (telcoCode === '') {
      alert('통신사를 선택해주세요.');
      return;
    } else if (!phoneNumberInput.current.value) {
      alert('휴대폰번호를 입력해주세요.');
      phoneNumberInput.current.focus();
      return;
    }
    //// [SOM.221223] LGU+ CS폰 예외처리 (QA용)
    // else if ((telcoCode === '3') && !(mdn === '01022530158' 
    //                                   || mdn === '01039154463' 
    //                                   || mdn === '01076225149' 
    //                                   || mdn === '01023899310' 
    //                                   || mdn === '01056104463')) {
    //   alert('서비스 준비중입니다.');
    //   $('#ip01').val('');
    //   return false;
    // }
    else if (allAgree === false) {
      //alert('약관에 모두 동의해주세요.');
      if (allAgreeCheckConfirm() === false) {
        return false;
      }
    }
    // 인증번호 최초 요청완료 후(step2) 인증번호 입력하지 않고 유료가입 버튼 클릭했을 경우 예외처리
    // ( 보안문자 입력창 없을때 + 재전송 버튼 클릭시엔 체크 안되도록 )
    else if (joinStep === 2 && authCodeInput.current.value && resendYn == 'N' && !$('#section_stepB').is(':visible')) {
      alert('인증번호를 입력해주세요.');
      authCodeInput.current.focus();
      return false;
    }
    /*
    else if (allAgree === false) {
      //alert('약관에 모두 동의해주세요.');
      if (allAgreeCheckConfirm() === false) {
        return false;
      }
    }
    */

    if (authTimer != null) {
      window.clearTimeout(authTimer);
      $("#auth_timer").html("");
    }

    // APP <-> 서버일 경우 휴대폰번호 구간 암호화
    const encPhoneNo = aes256.encrypt(phoneNumberInput.current.value);

    let paramForm = new FormData();
    paramForm.append('phoneNo', encPhoneNo);
    paramForm.append('telcoCode', telcoCode);
    paramForm.append('siteCode', 'AB'); //아파트청약케어 APP가입창 가입코드 : AB (app차후배포시 수정)
    paramForm.append('ctgCode', '1');
    paramForm.append('captchaAnswer', captchaAnswer);
    paramForm.append('appCheck', true);

    axios
      .post('apt/regist/checkPhone', paramForm)
      .then(res => {
        if (res.data.code === "E0000") {
          alert("휴대폰으로 인증번호가 발송되었습니다.");
          if ($('#section_stepB').css('display') == 'block') {
            window.captcha.close();
          }
          // $('input[name=telcoCode]').attr("disabled", "disabled");
          // $('#allCheck').attr("disabled", "disabled");
          // $('input[name=terms]').attr("disabled", "disabled");
          // $('#btn_setend').attr("disabled", "disabled");
          $('.btn_sel_wrap button').attr('disabled', true);
          $('#allCheck').attr('disabled', true);
          $('input[id^=terms]').attr('disabled', true);
          $('#section_stepB').hide();
          $("#section_auth").show();
          // $("#phoneNo1").attr("disabled", "disabled");
          // $("#phoneNo2").attr("disabled", "disabled");
          $('#ip01').attr('disabled', true);
          $("#btn_reque").show();
          $("#authCode").removeAttr("disabled").focus();
          $('#authCode').val('');
          authCodeInput.current.value = '';
          setCapchaAnswer('');  // [SOM.230113] 재전송시에도 캡챠 뜨도록 보안문자값 초기화

          // [SOM.230208] 민원대응 로그 저장 관련
          setAccessCheck( Moment().format('YYYYMMDDHHmmss') );
          setAccessCheckUri( '/apt/regist/checkPhone' );

          $("#auth_timer").show();
          $("#auth_timer").html("03:00");

          setAuthTimer(setTimeout(() => {
            commonUtil.displayTimer('auth_timer');
          }));

          setJoinStep(2);
          $('.btn_txt').eq(0).attr('disabled', true);
          //$(".btn_join").text('확인');
          //$(".step01").hide();
          //$(".step03").hide();
          //$(".step02").show();

        }
        else {
          //count = 0;

          if (res.data.code === "EW101" || res.data.code === "EW102") {
            if (res.data.code === "EW102") alert(res.data.message);

            setJoinStep(2);
            //$(".btn_join").text('확인');
            //$(".step01").hide();
            //$(".step03").hide();
            //$(".step02").show();

            window.captcha.phoneNo = phoneNumberInput.current.value;
            $("#newSafeNum").click();
            $('#section_auth').hide();
            $("#section_stepB").show();
            $("#answer_captcha").val('');
            setCapchaAnswer('');
            $('#authCode').val('');
            authCodeInput.current.value = '';

            // this.setState({
            //   captchaAnswer: '',
            // });

          } else {
            alert(res.data.message);
            window.location.reload();
          }
        }
      });
  }
  const submitRegist = (e) => {
    if (joinStep === 3) {
      if (!authCodeInput.current.value) {
        alert('인증번호를 입력해주세요.');
        authCodeInput.current.focus();
        return false;
      }

      if (!window.confirm('가입을 진행하시겠습니까?')) {
        return false;
      }

      // APP <-> 서버일 경우 휴대폰번호 구간 암호화
      const encPhoneNo = aes256.encrypt(phoneNumberInput.current.value);

      let paramForm = new FormData();
      paramForm.append('phoneNo', encPhoneNo);
      paramForm.append('telcoCode', telcoCode);
      paramForm.append('authCode', authCodeInput.current.value);
      paramForm.append('siteCode', 'AB'); //아파트청약케어 APP가입창 가입코드 : AB (app차후배포시 수정)
      paramForm.append('ctgCode', '1');
      paramForm.append('marketingYn', 'N');
      // [SOM.230208] 민원대응 로그 저장 관련
      paramForm.append('accessJoin', accessJoin);
      paramForm.append('accessJoinUri', accessJoinUri);
      paramForm.append('accessCheck', accessCheck);
      paramForm.append('accessCheckUri', accessCheckUri);
      paramForm.append('referrer', referrer);
      paramForm.append('appCheck', true);

      axios
        .post('apt/regist/submitRegist', paramForm)
        .then(res => {
          if (res.data.code === "E0000") {
            alert("가입이 완료되었습니다.");
            //window.location.reload();
            window.location.href = '/';
          }
          else {
            if (res.data.code === "EW012"){ // 인증번호가 일치하지 않습니다. 다시 입력해주세요.
              authCodeInput.current.value = '';
            }

            alert(res.data.message);
          }
        });
    }
  }


  //--'open' : 현 화면이 다음 화면으로 변경되는 action
  const openJoinForm = () => {
    setShowjoinForm(true);
    $(window).scrollTop(0);
    addImpression();
    $('.ly_apt_info').attr('class', 'ly_apt_sale ly_apt_join full');
    setTimeout(() => {
      window.captcha.init.event();
      uiFixValue.init();
      uiSelFocus.init();
      uiIpFocus.init();
      uiInputDel.init();

      $(".btn_sel_wrap").each(function (idx) {
        $(this).data("key", idx + 1);
        // btm select popup 호출
        $(this).find(".btn_sel").on("click", function () {
          var key = $(this).parents(".btn_sel_wrap").data("key");
          popupOpen('select0' + key, 'mwf', 0);
        });
      });

      $(".ly_select_wrap").each(function (idx) {
        $(this).data("ctrlKey", idx + 1);
        // btm select 값 선택// btm select 값 선택
        $(this).find(".pop_sel_wrap .list input").on("change", function () {
          var ctrlKey = $(this).parents(".ly_select_wrap").data("ctrlKey");
          var selTxt = $(this).parents(".item").find(".txt").text();
          //telco value 찾도록 추가
          var telcoVal = $(this).parents(".item").find(".txt").attr('data-value');

          // [SOM.230922] 통신사 점검으로 인한 해지 차단
          // if(telcoVal === '3') {
          //   alert("시스템 점검으로 인한 앱 가입불가. 홈페이지를 통해 가입해주세요. https://aptcare.kr");
          //   popupClose('select0' + ctrlKey, 'mwf', 0);
          //   return false;
          // }

          $(".btn_sel_wrap").eq(ctrlKey - 1).removeClass("focus").find(".lbl").addClass("hide");
          $(".btn_sel_wrap").eq(ctrlKey - 1).find(".sel_txt").text(selTxt);

          onChangeTelco(telcoVal);

          popupClose('select0' + ctrlKey, 'mwf', 0);
        });
      });

    }, 300);

  }

  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  //단독페이지들은 location href = / 로 보냄
  const backToAptMain = () => {
    window.location.href = '/';
  }


  return (
    <>
      <div id="mw-info" className="ly_apt_info full">
        {/* <div id="mw-info" className="ly_apt_sale ly_apt_join full"> */}
        <div className="wrap">
          {
            showjoinForm === false ?
              <div className="content">
                <div className="header ty_left">
                  <header>
                    <button type="button" onClick={backToAptMain} className="btn_prev" title="뒤로가기"><i></i></button>
                    <strong className="tit">회원가입</strong>
                  </header>
                </div>
                <div className="ly_content">
                  <section className="view_tit_wrap">
                    <strong className="tit">아파트청약케어 프리미엄 가입</strong>
                    <div className="sub_txt">
                      <span>프리미엄 회원만의 특별한 혜택을 만나보세요!</span>
                    </div>
                  </section>
                  <section className="info_wrap">
                    <div className="item01 ani" id="ani1">
                      <div className="img_wrap"><img src={process.env.REACT_APP_ENV_URL + "resources/images/info_img01.png"} alt="" /></div>
                      <strong className="tit"><code>01</code>내 자산으로<br />구매가능한 아파트는 어디?</strong>
                      <p className="txt">내가 <strong>보유한 자산</strong>으로 <strong>구매가능 아파트만 확인</strong> 할 순 없을까? <strong>보유자산 및 대출 가능 금액</strong>까지 포함하여 현실적으로 구매 가능한 아파트를 지금 확인해보세요.</p>
                    </div>
                    <div className="item02 ani" id="ani2">
                      <div className="img_wrap"><img src={process.env.REACT_APP_ENV_URL + "resources/images/info_img02.png"} alt="" /></div>
                      <strong className="tit"><code>02</code>청약에 당첨 된다면<br />나는 얼마를 가지고 있어야 할까?</strong>
                      <p className="txt">청약에 당첨되었는데, 당장 <strong>계약금은 얼마</strong>를 내야할까? 입주 시 내가 대출이외에 내야하는 돈은 총 얼마지? <strong>청약 당첨 후 필요한 금액들을 단계별</strong>로 확인하세요.</p>
                    </div>
                    <div className="item03 ani" id="ani3">
                      <div className="img_wrap"><img src={process.env.REACT_APP_ENV_URL + "resources/images/info_img03.png"} alt="" /></div>
                      <strong className="tit"><code>03</code>청약 신청 시 나의 당첨 가능성은?</strong>
                      <p className="txt">나는 지금 청약을 신청 할 수 있는 상황인가? <strong>어느 지역에 청약을 넣으면 당첨</strong> 될 수 있을까? 어떤 유형으로 신청해야 당첨 될 가능성이 높을까? <strong>청약 신청 전 청약 당첨 가능성</strong>을 계산하세요.</p>
                    </div>
                  </section>
                </div>
                <div className="btn_area_wide fix btm">
                  <button type="button" className="skip" onClick={() => openJoinForm()}>아파트청약케어 유료가입</button>
                </div>
              </div>
              :
              <div className="content">
                <div className="header ty_left">
                  <header>
                    <button type="button" onClick={backToAptMain} className="btn_prev" title="뒤로가기"><i></i></button>
                    {/* <button type="button" onClick="" className="btn_home" title="메인으로 가기"><i></i></button> */}
                    <strong className="tit">회원가입</strong>
                  </header>
                </div>
                <div className="ly_content">
                  <section className="view_txt_list">
                    <ul>
                      <li>아파트청약케어 가입을 위하여, 휴대폰 인증이 필요해요.</li>
                      <li>본인명의 휴대폰번호로만 가입이 가능해요.</li>
                      <li>휴대폰 정보 입력 후, 수신 된 인증번호를 입력해주세요.</li>
                    </ul>
                  </section>
                  <section className="view">
                    <div className="item">
                      <strong className="tit">통신사</strong>
                      <div className="form_wrap">
                        <div className="form">
                          {/* <div className="ip_box in">
                            <div className="select_box select_apt">
                              <select className="code" id="select01" onChange={(e) => onChangeTelco(e.target.value)}>
                                <option value="1">SKT</option>
                                <option value="2">KT</option>
                                <option value="3">LGU</option>
                              </select>
                            </div>
                          </div> */}
                          <div className="ip_box btn_sel_wrap">
                            <div className="inner_wrap">
                              <button className="button" className="btn_sel">
                                <span className="sel_txt">SKT</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <strong className="tit">휴대폰번호</strong>
                      <div className="form_wrap">
                        <div className="form">
                          <div className="ip_box ip_del">
                            <label for="ip01" className="lbl code">0102345678</label>
                            <input type="number" id="ip01" autocomplete="off" className="ip code" maxLength="11" onChange={onChangePhoneNo} ref={phoneNumberInput}/>
                            <button type="button" className="del"><i>삭제</i></button>
                            <button type="button" id="btn_setend" className="btn_txt" onClick={() => checkAttack(joinStep, 'N')}>인증요청</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <button id="btn_setend" type="button" className="btn btn01" disabled={!phoneNo2} onClick={this.checkPhone}>인증요청</button> */}
                    <div id="section_auth" className="item cert_wrap" style={{ "display": "none" }}>
                      <strong className="tit">인증번호</strong>
                      <div className="form_wrap">
                        <div className="form col_wrap">
                          <div className="ip_box ip_del">
                            <label for="authCode" className="lbl code">인증번호를 입력해주세요.</label>
                            <input type="number" id="authCode" name="authCode" className="ip code" maxLength="6" onChange={onChangeAuthCode} disabled ref={authCodeInput}/>
                            <span id="auth_timer" className="time" style={{ "display": "none" }}>03:00</span>
                            <button type="button" className="del"><i>삭제</i></button>
                          </div>
                          <button id="btn_reque" type="button" className="btn_txt" onClick={() => checkAttack(joinStep, 'Y')}>재전송</button>
                        </div>
                      </div>
                    </div>
                    <div id="section_stepB" className="item col_wrap" style={{ "display": "none" }}>
                      <div className="form_wrap security_wrap">
                        <img id="img_captcha" src={process.env.REACT_APP_ENV_URL + "resources/images/temp/temp.png"} alt="" />
                        <button id="newSafeNum" type="button" className="btn_reset">새로고침</button>
                      </div>
                      <div className="form_wrap">
                        <strong className="tit">보안문자</strong>
                        <div className="form">
                          <div className="ip_box ip_del">
                            <label for="security" className="lbl code"></label>
                            <input type="text" id="answer_captcha" className="ip code" name="security" maxLength="6" onChange={onChangeCaptchaAnswer} />
                            <button type="button" onClick="" className="del"><i>삭제</i></button>
                          </div>
                        </div>
                        {/* <button id="submit_captcha" type="button" className="btn btn03">다음</button> */}
                      </div>
                    </div>
                    <div id="termsCheck" className="terms_list toggle_wrap active">
                      <ul className="all_list">
                        <li className="all">
                          <span className="checkbox i_all">
                            <input type="checkbox" name="allCheck" id="allCheck" onClick={() => allAgreeClick()} />
                            <label for="allCheck"><i></i>모두동의</label>
                          </span>
                        </li>
                      </ul>
                      <ul className="item_list view_toggle">
                        <li>
                          <span className="checkbox i_sub">
                            <input type="checkbox" id="terms01" onClick={() => agreeClick(1)} />
                            <label for="terms01"><i></i>서비스약관</label>
                            <button type="button" className="btn" onClick={() => agreeClickPop(1)}>보기</button>
                          </span>
                        </li>
                        <li>
                          <span className="checkbox i_sub">
                            <input type="checkbox" id="terms02" onClick={() => agreeClick(2)} />
                            <label for="terms02"><i></i>개인정보수집 및 이용</label>
                            <button type="button" className="btn" onClick={() => agreeClickPop(2)}>보기</button>
                          </span>
                        </li>
                        <li>
                          <span className="checkbox i_sub">
                            <input type="checkbox" id="terms03" onClick={() => agreeClick(3)} />
                            <label for="terms03"><i></i>개인정보 제3자 제공</label>
                            <button type="button" className="btn" onClick={() => agreeClickPop(3)}>보기</button>
                          </span>
                        </li>
                        <li>
                          <span className="checkbox i_sub">
                            <input type="checkbox" id="terms04" onClick={() => agreeClick(4)} />
                            <label for="terms04" id="btnTerms04"><i></i>
                              {telcoCode === '1' ? 'SKT 개인정보 제3자 제공' :
                                telcoCode === '2' ? 'KT 개인정보 제3자 제공' :
                                  telcoCode === '3' ? 'LGU+ 및 MVNO 제3자제공' : 'SKT 개인정보 제3자 제공'}
                              {/* <em>(선택)</em> */}
                            </label>
                            <button type="button" className="btn" onClick={() => agreeClickPop(4)}>보기</button>
                          </span>
                        </li>
                        <li>
                          <span className="checkbox i_sub">
                            <input type="checkbox" id="termsPos" onClick={() => agreeClick('pos')} />
                            <label for="termsPos"><i></i>위치기반서비스 이용약관</label>
                            <button type="button" className="btn" onClick={() => agreeClickPop('pos')}>보기</button>
                          </span>
                        </li>
                        {/* <li>
                          <span className="checkbox i_sub">
                            <input type="checkbox" id="terms05" onClick={() => agreeClick(5)} />
                            <label for="terms05"><i></i>마케팅 활용 동의<em>(선택)</em></label>
                            <button type="button" className="btn">보기</button>
                          </span>
                        </li>
                        <li>
                          <span className="checkbox i_sub">
                            <input type="checkbox" id="terms05_01" onClick={() => agreeClick('5_1')} />
                            <label for="terms05_01"><i></i>마케팅 수신 동의<em>(선택)</em></label>
                            <button type="button" className="btn">보기</button>
                          </span>
                        </li> */}
                      </ul>
                    </div>
                    <div className="term_info_wrap">

                      {
                        telcoCode === '1' ?
                          <>
                            <ul>
                              <li className="point">매월 3,300원(부가세포함)의 유료 서비스로, 사용 일 수 만큼 일할 계산되어 과금됩니다.</li>
                              <li>본 서비스는 가입 즉시 서비스가 개시되는 상품으로 청약철회가 불가능 합니다. (전자상거래 등에서의 소비자 보호에 관한 법률 제17조 2항 5호에 따라 디지털 콘텐츠의 제공이 개시된 경우 청약철회를 제공하지 않습니다.)  </li>


                              <li>본 서비스는 (주)헥토이노베이션과 SKT가 제휴하여 공동으로 제공하는 서비스입니다.</li>
                              <li>서비스 관련 문의사항은 아래 고객센터로 문의바랍니다.</li>
                              <li>(주)헥토이노베이션 고객센터 : 1599-2985 (가입/이용/해지/환불 등 서비스 상세 문의)</li>
                              <li>SKT 고객센터 : 114 및 Tworld (해지 및 일반 문의)</li>
                            </ul>
                          </>
                          :
                          telcoCode === '2' ?
                            <>
                              <ul>
                                <li className="point">매월 3,300원(부가세포함)의 유료 서비스로, 사용 일 수 만큼 일할 계산되어 과금됩니다.</li>
                                <li>본 서비스는 가입 즉시 서비스가 개시되는 상품으로 청약철회가 불가능 합니다. (전자상거래 등에서의 소비자 보호에 관한 법률 제17조 2항 5호에 따라 디지털 콘텐츠의 제공이 개시된 경우 청약철회를 제공하지 않습니다.)  </li>


                                <li>본 서비스는 (주)헥토이노베이션과 KT가 제휴하여 공동으로 제공하는 서비스입니다.</li>
                                <li>서비스 관련 문의사항은 아래 고객센터로 문의바랍니다.</li>
                                <li>(주)헥토이노베이션 고객센터 : 1599-2985 (가입/이용/해지/환불 등 서비스 상세 문의)</li>
                                <li>KT 고객센터 : 114 및 kt.com (해지 및 일반 문의)</li>
                              </ul>
                            </>
                            :
                          telcoCode === '3' ?
                            <>
                              <ul>
                                <li className="point">매월 3,300원(부가세포함)의 유료 서비스로, 사용 일 수 만큼 일할 계산되어 과금됩니다.</li>
                                <li>본 서비스는 가입 즉시 서비스가 개시되는 상품으로 청약철회가 불가능 합니다. (전자상거래 등에서의 소비자 보호에 관한 법률 제17조 2항 5호에 따라 디지털 콘텐츠의 제공이 개시된 경우 청약철회를 제공하지 않습니다.)  </li>


                                <li>본 서비스는 (주)헥토이노베이션과 LGU+가 제휴하여 공동으로 제공하는 서비스입니다.</li>
                                <li>서비스 관련 문의사항은 아래 고객센터로 문의바랍니다.</li>
                                <li>(주)헥토이노베이션 고객센터 : 1599-2985 (가입/이용/해지/환불 등 서비스 상세 문의)</li>
                                <li>LGU+ 고객센터 : 114 및 lguplus.com (해지 및 일반 문의)</li>
                              </ul>
                            </>
                            :
                            null
                      }
                    </div>
                  </section>
                </div>
                <div className="btn_area_wide fix btm">
                  <button type="button" className="skip btn_join" id="submit_captcha" onClick={() => stepJoin()}>아파트청약케어 유료가입</button>
                  {/* <button type="button" className="btn" disabled={!authCode} onClick={this.submitRegist}>아파트청약케어 유료가입</button> */}
                </div>
              </div>
          }
        </div>
      </div>

      <div id="mw-select01" className="ly_pop popBtm ly_select_wrap">
        <div className="wrap">
          <button type="button" onClick={() => popupClose('select01', 'mwf', 0)} className="close"><i>닫기</i></button>
          <div className="content pop_sel_wrap">
            <ul className="list">
              <li>
                <span className="item">
                  <input type="radio" id="select01_01" name="select01" />
                  <label for="select01_01">
                    <span className="txt" data-value="1" >SKT</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select01_02" name="select01" />
                  <label for="select01_02">
                    <span className="txt" data-value="2">KT</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select01_03" name="select01" />
                  <label for="select01_03">
                    <span className="txt" data-value="3">LGU{'+'}</span>
                  </label>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <KtMvno
          agreeClickPop={agreeClickPop}
          callCheckPhone={callCheckPhone}
      />
    </>
  )
}
export default Join;