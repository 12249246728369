
//// 컴포넌트 사용처 : 청약 정보
const MainFilter = ({ filterName }) => {

  // console.log(filterName);

  return (
    <div className="tab">
      <ul className="inner">
        <li className="slide"><a href="#" className={`${filterName.status ? "change_txt" : ""} GTM-APPLY-FILTER-1`} data-name="진행상태">{filterName.status ? filterName.status : '진행상태'}</a></li>
        {/*<li className="slide"><a href="#" className={`${filterName.locals ? "change_txt" : ""} GTM-APPLY-FILTER-2`} data-name="전국">{filterName.locals ? filterName.locals : '전국'}</a></li>*/}
        <li className="slide"><a href="#" className={`${filterName.trxAmt ? "change_txt" : ""} GTM-APPLY-FILTER-3`} data-name="분양가">{filterName.trxAmt ? filterName.trxAmt : '분양가'}</a></li>
        <li className="slide"><a href="#" className={`${filterName.py ? "change_txt" : ""} GTM-APPLY-FILTER-4`} data-name="평형">{filterName.py ? filterName.py : '평형'}</a></li>
        <li className="slide"><a href="#" className={`${filterName.hhld ? "change_txt" : ""} GTM-APPLY-FILTER-5`} data-name="세대수">{filterName.hhld ? filterName.hhld : '세대수'}</a></li>
      </ul>
    </div>
  );
}

export default MainFilter;