import create from 'zustand';

export const useCouponStore = create(set => ({
    coupon: {
    },
    setCoupon: paramCoupon => set({
        coupon: paramCoupon,
    }),
    pin: {
        pinNo: '',
        issued: false,
    },
    setPin: paramPin => set({
        pin: {
            pinNo: paramPin.pinNo,
            issued: paramPin.issued,
        },
    }),
    couponItem: {
    },
    setCouponItem: paramCouponItem => set({
        couponItem: paramCouponItem,
    }),

}));