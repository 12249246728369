import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'; // url 구분 작업
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import MainFilter from 'routes/apply/filter/MainFilter';
import LocalsFilter from 'routes/apply/filter/LocalFilter';
import HhldFilter from 'routes/apply/filter/HhldFilter';
import PyFilter from 'routes/apply/filter/PyFilter';
import TrxAmtFilter from 'routes/apply/filter/TrxAmtFilter';
import MyPage from 'routes/mypage/MyPage';

import axiosAuth from 'routes/common/axios/services/AxiosAuth';
import axiosRequest from 'routes/common/axios/services/AxiosRequest';
import { AXIOS_AUTH_RESULT } from 'routes/common/axios/code/AxiosCode';

import ApplyPredict from 'routes/predict/ApplyPredict';

import ApplyCalc from 'routes/apply/calc/ApplyCalc';

import ApplyPaySrch from 'routes/apply/pay/ApplyPaySrch';
import ApplyPayList from 'routes/apply/pay/ApplyPayList';

import ApplyDtl from 'routes/apply/ApplyDtl';
import ApplyDtlMap from 'routes/apply/ApplyDtlMap';

import ApplyPayDtl from 'routes/apply/pay/ApplyPayDtl';
import ApplyPayCalc from 'routes/apply/pay/ApplyPayCalc';

import FavApplyList from 'routes/apt/fav/FavApplyList';

import { AptOverlay } from 'routes/overlay/AptOverlay.js';
import { BjdOverlay } from 'routes/overlay/BjdOverlay.js';

import axios from 'axios';
import $ from 'jquery';

import { searchApply, addRecentItem, getRecentItem, clearRecentItem, setRecentList, addLsItem, getLsItem } from 'routes/common/Search';
import { dateDiffMsg, numberWithCounts, numberWithCommas, numberWithCommas2, dateFormatMsg, dateFormatMsg2, numberReplaceWithStag, textReplaceWithStag, numberReplaceWithCodetag } from 'routes/common/StringUtil';
import { uiFixValue, uiFilterTabSwipe, popupOpen, popupClose, uiIpFocus, uiInputDel, uiAccoScrollFix, uiSelFocus, uiMainSwitch, uiChartTap, uiToggle, uiTopGo, callGaTag } from 'routes/common/Design.js'
import { findIndex } from 'routes/common/ArrayUtil';
import _ from 'lodash';

import RecentResult from 'routes/common/RecentResult';
import SearchResult from 'routes/common/SearchResult';
import Moment from 'moment';

import { setLcUrlItem, getLcUrlItem, setLcParamItem, getLcParamItem } from 'routes/common/ParamStorage';
import { callAppInterface } from 'routes/common/AppInterface';

import { osCheck } from 'routes/common/OsUtil';

// 메인개편 추가
import MenuPopup from 'routes/MenuPopup';
import {clearLsItem} from "../common/LocalStorage";
import {SERVICE_TYPE} from "../common/Code";

//let keyword = '';
let status = { filterType: "STATUS", value: '1', mainName: '' };
let locals = { filterType: "LOCALS", value: '', mainName: '' };
let trxAmt = { filterType: "TRX", min: -1, max: 9999, mainName: '' };
let py = { filterType: "PY", min: -1, max: 9999, mainName: '' };
let hhld = { filterType: "HHLD", min: -1, max: 99999, mainName: '' };

//// 컴포넌트 사용처 : 청약 정보
const ApplyList = (props) => {
  const [refreshState, setRefreshState] = useStateWithCallbackLazy(false);

  const [filterName, setFilterName] = useStateWithCallbackLazy({});
  const [applyList, setApplyList] = useStateWithCallbackLazy([]);
  const [applyPayList, setApplyPayList] = useStateWithCallbackLazy([]);
  const [updateState, setUpdateState] = useStateWithCallbackLazy(false);
  const [limitSize, setLimitSize] = useStateWithCallbackLazy(10);

  const [keyword, setKeyword] = useStateWithCallbackLazy('');

  const [recentResult, setRecentResult] = useStateWithCallbackLazy(getRecentItem('applyMap'));
  const [searchResult, setSearchResult] = useStateWithCallbackLazy([]);

  const [searchIndex, setSearchIndex] = useStateWithCallbackLazy(-1);

  const [recentFocus, setRecentFocus] = useStateWithCallbackLazy(false);
  const [searchVisible, setSearchVisible] = useStateWithCallbackLazy(false);
  const [applyMenuType, setApplyMenuType] = useStateWithCallbackLazy('A');

  const [isFiltersLoad, setIsFiltersLoad] = useStateWithCallbackLazy(false);
  const [isFiltersRefresh, setIsFiltersRefresh] = useStateWithCallbackLazy(false);

  const [isLocalsLoad, setIsLocalsLoad] = useStateWithCallbackLazy(false);
  const [isTrxAmtLoad, setIsTrxAmtLoad] = useStateWithCallbackLazy(false);
  const [isPyLoad, setIsPyLoad] = useStateWithCallbackLazy(false);
  const [isHhldLoad, setIsHhldLoad] = useStateWithCallbackLazy(false);

  const [isCalcResult, setIsCalcResult] = useStateWithCallbackLazy(false);


  const [applyPopupType, setApplyPopupType] = useStateWithCallbackLazy('');

  const [favApplyListDisplay, setFavApplyListDisplay] = useStateWithCallbackLazy(false);

  const [applyMenuTypeClickCheck, setApplyMenuTypeClickCheck] = useStateWithCallbackLazy('');
  const [applyMenuTypeClickCheck2, setApplyMenuTypeClickCheck2] = useStateWithCallbackLazy(false);

  // 마이페이지 추가
  const [isMyPage, setIsMyPage] = useStateWithCallbackLazy(false);
  const [myPageInfo, setMyPageInfo] = useStateWithCallbackLazy({});

  const lcUrlMap = getLcUrlItem();
  const lcParamMap = getLcParamItem();

  const mwSale = useRef(null);

  // url 구분 작업
  const history = useHistory();
  const location = useLocation();

  const goback = () => {
    selectMenu('A');
    window.goback = backToAptMain;
  }

  useEffect(() => {
    // selectMenu('A');
    //popupOpen('sale', 'mwf');
    $('#mw_sale').show();

    if (typeof props.applyMenuType == 'undefined') {
      setApplyMenuType('A');
    } else if(props.applyMenuType != '') {
      setApplyMenuType(props.applyMenuType);
    }

    $('.bg_body_full').remove();
    uiFixValue.init();
    uiFilterTabSwipe.init();
    uiSelFocus.init();
    uiMainSwitch.init();
    uiChartTap.init();
    uiToggle.init();
    uiTopGo.init();

    uiIpFocus.init();
    uiInputDel.init();

    $(window).scrollTop(0);


    // 상단 검색 필터
    $(".ly_apt_srch .filter_tab_swipe").each(function (idx) {
      $(this).data("key", idx).find(".view .item").each(function (_idx) {
        $(this).data("ctrlKey", _idx);
      });
    });
    $(".ly_apt_srch .filter_tab_swipe").find(".filter_box_wrap button").on("click", function () {
      var key = $(this).parents(".filter_tab_swipe").data("key");
      var ctrlKey = $(this).parents(".item").data("ctrlKey");
      //var filter_txt = $(this).text();

      $(".ly_apt_srch .filter_tab_swipe").eq(key).find(".slide a").eq(ctrlKey).addClass("change_txt");
      //$(".ly_apt_srch .filter_tab_swipe").eq(key).find(".slide a").eq(ctrlKey).text(filter_txt);

      uiFilterTabSwipe.init();
    });

    // 상단 검색 필터 닫기
    $(".ly_apt_srch .filter_save_wrap").find(".btn_close").on("click", function () {
      uiFilterTabSwipe.init();
    });

    // 상단 검색 필터 초기화
    $(".ly_apt_srch .filter_save_wrap .btn_reset").on("click", function () {
      $(".ly_apt_srch .filter_tab_swipe").find(".slide").each(function (idx) {
        var reset_txt = $(this).find("a").data("name");
        $(".ly_apt_srch .filter_tab_swipe").find(".slide").eq(idx).find("a").text(reset_txt);
      });
      $(document).find(".ly_apt_srch .filter_tab_swipe").find(".slide").removeClass("active");
      $(document).find(".ly_apt_srch .filter_tab_swipe").find(".slide a").removeClass("change_txt");
      uiFilterTabSwipe.init();
    });

    // 검색 리스트 보기
    $(".ly_apt_srch .top_srch_wrap .ip_box .ip").on("focus", function () {
      $(".srch_list_wrap").addClass("active");
    });
    $(".ly_apt_srch .top_srch_wrap .ip_box .ip").on("keyup", function () {
      if (!$.trim($(this).val()) == "") {
        if ($(this).parents().hasClass("top_srch_wrap")) {
          $(".ly_apt_srch .srch_list_wrap").addClass("active");
        }
      }
    });

    // 검색 리스트 닫기
    $(".srch_list_wrap .inner_wrap").on("click.srch", function () {
      $(".srch_list_wrap").removeClass("active");
    });

    // window.goback = backToAptMain;
    // window.checkMainPage = () => { return false; }

    // return () => {
    //   window.checkMainPage = () => { return true; }
    // }
  }, []);

  useEffect(() => {
    let applySrchFilter = getLsItem('applySrchFilter');
    if (applyMenuType === 'A' && applyPopupType == '' && applySrchFilter && Object.keys(applySrchFilter).length > 0) {
      if (applySrchFilter.trxAmt) {
        if (applySrchFilter.trxAmt.mainName) {
          setIsTrxAmtLoad(true);
        }
      }

      if (applySrchFilter.py) {
        if (applySrchFilter.py.mainName) {
          setIsPyLoad(true);
        }
      }

      if (applySrchFilter.hhld) {
        if (applySrchFilter.hhld.mainName) {
          setIsHhldLoad(true);
        }
      }

      setIsFiltersLoad(true);
      setTimeout(() => {
        setIsFiltersLoad(false);
        setTimeout(() => {
          status = { filterType: "STATUS", value: applySrchFilter.status.value, mainName: applySrchFilter.status.mainName };

          handleChangeStatus(status);

          locals = { filterType: "LOCALS", value: applySrchFilter.locals.value, mainName: applySrchFilter.locals.mainName };

          handleChangeLocals(locals);
        }, 200);
      }, 200);
    }

    $('.btn_bookmark').removeClass('active');
    $('.btn_save').removeClass('active');
    $('.btn_reset').removeClass('active');

    window.goback = backToAction;
    window.checkMainPage = () => { return false; }

    $('.bg_body_full').remove();
    uiFixValue.init();
    uiIpFocus.init();
    uiInputDel.init();

    return () => {
      window.checkMainPage = () => { return true; }
    }
  }, [applyMenuType, applyPopupType]);

  useEffect(() => {
    $(window).on('scroll', function () {
      if (updateState === false && ((window.innerHeight + window.scrollY) >= $('.apt_list_wrap').innerHeight())) {
        setUpdateState(true);
        setLimitSize(limitSize + 10);
        setTimeout(() => {
          setUpdateState(false);
        }, 100);
      }
      else if (updateState === true) {
        return;
      }
    })
  });

  useEffect(() => {
    // if (props.applyFiltersReload) {
    //   handleChangeStatus({ filterType: "STATUS", value: status.value, mainName: "" });
    //   handleChangeLocals({ filterType: "LOCALS", value: locals.value, mainName: "" });
    //   handleChangeFilter({ filterType: "TRX", min: trxAmt.min, max: trxAmt.max, mainName: "" });
    //   handleChangeFilter({ filterType: "PY", min: py.min, max: py.max, mainName: "" });
    //   handleChangeFilter({ filterType: "HHLD", min: hhld.min, max: hhld.max, mainName: "" });
    // }
    fetchApplyList();

  }, [keyword, status, locals, py, trxAmt, hhld]);

  // [SOM.220603] 타탭에서 청약정보탭 클릭시, 스타벅스 관심사 프로모션 팝업창 띄우기
  useEffect(() => {
    if(applyMenuTypeClickCheck === 'A') {
      // [SOM.220603] 스타벅스 관심사 프로모션 '다시보지않기' 버튼 체크 조건 추가
      // const neverOpenToken = localStorage.getItem('interestEventPop01Open');

      // [SOM.220603] '다시보지않기' 버튼 미클릭이면 프로모션 창으로 이동 (App.js에서 구분)
      // if(neverOpenToken != 'false' && applyMenuTypeClickCheck2) {
      //   window.location.href = '/';
      // }
    }
  }, [applyMenuTypeClickCheck, applyMenuTypeClickCheck2]);  

  useEffect(() => {
    if (props.applyMenuType)  setApplyMenuType(props.applyMenuType);
    else                      setApplyMenuType('A');
  }, [props.applyMenuType]);

  const fetchApplyList = () => {

    axios
      .get(`/apt/apply/list?aptName=` + encodeURI(keyword) + `&status=${status.value}&locals=${locals.value}&pyMin=${py.min}&pyMax=${py.max}&trxMin=${trxAmt.min}&trxMax=${trxAmt.max}&hhldCntMin=${hhld.min}&hhldCntMax=${hhld.max}`)
      //.get(`apt/apply/list?aptName=` + encodeURI(keyword) + `&status=${status}&locals=${paramLocals}&pyMin=${pyMin}&pyMax=${pyMax}&trxMin=${trxMin}&trxMax=${trxMax}&hhldCntMin=${hhldCntMin}&hhldCntMax=${hhldCntMax}`)
      .then(res => {
        //setLimitSize(limitSize + 10);
        setApplyList(res.data.response.applyList);
        //props.setApplyFiltersReload(false);
      });

  }




  const handleStatus = (value) => {
    status = value;
  }

  const handleLocal = (value) => {
    locals = value;
  }

  const filtersRefresh = () => {
    setKeyword('');
    handleChangeStatus({ filterType: "STATUS", value: "1", mainName: "", exCall: true });
    handleChangeLocals({ filterType: "LOCALS", value: [], mainName: "", exCall: true });
    handleChangeFilter({ filterType: "TRX", min: -1, max: 9999, mainName: "", exCall: true });
    handleChangeFilter({ filterType: "PY", min: -1, max: 9999, mainName: "", exCall: true });
    handleChangeFilter({ filterType: "HHLD", min: -1, max: 99999, mainName: "", exCall: true });
    setIsFiltersRefresh(true);
    setTimeout(() => {
      setIsFiltersRefresh(false);
    }, 200);
  }

  const handleChangeStatus = ({ filterType, value, mainName, exCall }) => {
    const filterObj = {};
    filterObj.filterType = filterType;
    filterObj.value = value;
    filterObj.mainName = mainName;

    status = filterObj;
    filterName.status = mainName;
    setFilterName({ ...filterName });

    if (exCall) clearLsItem('applySrchFilter');
    else        saveFilter();
  }

  const handleChangeLocals = ({ filterType, value, mainName, exCall }) => {
    const filterObj = {};
    filterObj.filterType = filterType;
    filterObj.value = value;
    filterObj.mainName = mainName;

    locals = filterObj;
    filterName.locals = mainName;
    setFilterName({ ...filterName });

    if (exCall) clearLsItem('applySrchFilter');
    else        saveFilter();
  }

  const handleChangeFilter = ({ filterType, min, max, mainName, exCall }) => {

    const filterObj = {};
    filterObj.filterType = filterType;
    filterObj.min = min;
    filterObj.max = max;
    filterObj.mainName = mainName;

    switch (filterType) {
      case "TRX": trxAmt = filterObj; filterName.trxAmt = mainName; break;
      case "PY": py = filterObj; filterName.py = mainName; break;
      case "HHLD": hhld = filterObj; filterName.hhld = mainName; break;
      default: break;
    }
    setFilterName({ ...filterName }, () => {
      //fetchApplyList();
    });

    if (exCall) clearLsItem('applySrchFilter');
    else        saveFilter();
  }

  const saveFilter = (e) => {
    // if ($(e.target).hasClass('active')) $(e.target).removeClass('active');
    // else                                $(e.target).addClass('active');

    addLsItem('applySrchFilter', { status, locals: { filterType: "LOCALS", value: '', mainName: '' }, trxAmt, py, hhld });
  }

  const handleClearRecent = () => {
    clearRecentItem('applyMap');
    setRecentResult([]);
  }

  const handleRemoveRecent = (searchId) => {
    let recentList = getRecentItem('applyMap');
    if (recentList.some(f => f.searchId === searchId)) {
      recentList.splice(findIndex(recentList, f => f.searchId === searchId), 1);
    }
    setRecentList('applyMap', recentList);
    setRecentResult([]);
  }

  const handleSelectSearch = (search) => {
    $('#srch_input02').click();
    search.regTime = Moment(new Date()).format('YYYY. MM. DD');
    addRecentItem('applyMap', search);

    searchApply(search.searchName, status.value, locals.value, py.min, py.max, trxAmt.min, trxAmt.max, hhld.min, hhld.max, (data) => {
      let searchArr = [];
      if ((data.response.applyList).length > 0) {
        (data.response.applyList).forEach(v => searchArr.push({ searchId: v.searchId, searchName: v.searchName }));
      }
      search.searchName && //this.setState({ searchResult: data, keyword: search.searchName, searchVisible: false });
        setSearchResult(searchArr);
      setKeyword(search.searchName);
      setSearchVisible(false);
    })
  }

  const handleChangeKeyword = _.debounce((keyword) => {
    let recentData = getRecentItem('applyMap');
    if (keyword) {
      searchApply(keyword, status.value, locals.value, py.min, py.max, trxAmt.min, trxAmt.max, hhld.min, hhld.max, (data) => {
        let searchArr = [];
        if ((data.response.applyList).length > 0) {
          (data.response.applyList).forEach(v => searchArr.push({ searchId: v.searchId, searchName: v.searchName }));
        }
        keyword &&  //this.setState({ searchResult: data, recentResult: [], searchIndex: -1, recentFocus: false });
          setSearchResult(searchArr);
        setRecentResult(recentData);
        setSearchIndex(-1);
        setRecentFocus(false);
      })
    } else {
      //this.setState({ searchResult: [], recentResult: recentData, searchIndex: -1, recentFocus: true });
      setSearchResult([]);
      setRecentResult(recentData);
      setSearchIndex(-1);
      setRecentFocus(true);
    }
  }, 300);

  const debounceChangeKeyword = (e) => {

    const { target: { value } } = e;
    //this.setState({ keyword: value, searchVisible: true });
    setKeyword(value);
    setSearchVisible(true);
    setRecentFocus(false);
    handleChangeKeyword(value);
  }

  const handleKeydownKeyword = (e) => {
    let keyCode = e.keyCode;
    if (!searchResult.length > 0) {
      return;
    }
    if (keyCode === 38) {     // uparrowKey
      //this.setState({ searchIndex: this.state.searchIndex <= 0 ? 0 : this.state.searchIndex - 1 });
      setSearchIndex(searchIndex <= 0 ? 0 : searchIndex - 1);
    } else if (keyCode === 40) {     // downarrowKey
      let maxIndex = searchResult.length - 1;
      //this.setState({ searchIndex: this.state.searchIndex >= maxIndex ? maxIndex : this.state.searchIndex + 1 });
      setSearchIndex(searchIndex >= maxIndex ? maxIndex : searchIndex + 1);
    } else if (keyCode === 13) {      // enterKey
      if (searchIndex === -1) {
        //this.setState({ searchResult: [], searchIndex: -1, recentFocus: false, searchVisible: false, keyword: this.state.searchResult[0].searchName });
        //setSearchResult([]);
        setSearchIndex(-1);
        setRecentFocus(false);
        setSearchVisible(false);
        setKeyword(searchResult[0].searchName);

        searchResult[0].regTime = Moment(new Date()).format('YYYY. MM. DD');
        addRecentItem('applyMap', searchResult[0]);
        //$('.apt_wrap').removeClass('dim');
        //this.props.onGotoFavApplyDtl(this.state.searchResult[0].searchId);
      } else {
        let search = searchResult[searchIndex];
        //this.setState({ searchResult: [], searchIndex: -1, recentFocus: false, searchVisible: false, keyword: search.searchName });
        //setSearchResult([]);
        setSearchIndex(-1);
        setRecentFocus(false);
        setSearchVisible(false);
        setKeyword(search.searchName);

        search.regTime = Moment(new Date()).format('YYYY. MM. DD');
        addRecentItem('applyMap', search);
        //$('.apt_wrap').removeClass('dim');
        //this.props.onGotoFavApplyDtl(search.searchId);
      }
    }
  }



  const onApplyPayIdAndApplyDtlDisplay = (applyId) => {
    $(".srch_list_wrap").removeClass("active");
    props.onApplyPayDtlDisplay(true);
  }



  //--'open' : 현 화면이 다음 화면으로 변경되는 action
  const openApplyDtl = (applyId) => {
    setLcUrlItem('applyList', 'applyDtl');
    setLcParamItem({ 'applyId': applyId });
    filtersRefresh();

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?APY-${applyId}#`
    })

    // 청약정보 리스트 아이템 클릭시 GA Script 호출
    callGaTag('apply_' + applyId);

    setApplyPopupType('P_A');
  }

  const openFavApplyList = (e) => {
    $(e.target).addClass('active');
    $('#mw-sale').hide();
    $('#mw-srch').hide();
    setFavApplyListDisplay(true);
  }

  //--selectMenu 도 open의 역할을 함
  //--selectMenuAuth 인증필요 url에 대한 추가 체크
  const selectMenuAuth = (menuType) => {

    // ---[S] 다른 탭에서 청약정보 탭 클릭시 스타벅스 관심사 프로모션 띄우기 위함 [S]---
    // [SOM.220603] 상단 메뉴탭 클릭시 메뉴클릭 플래그값 menuType으로 설정 
    setApplyMenuTypeClickCheck(menuType);
    // [SOM.220603] applyList 청약정보 탭 외 다른 탭에서 진입 여부 체크
    if(menuType === 'B' || menuType === 'C' || menuType === 'D'){
      setApplyMenuTypeClickCheck2(true);
    }
    // ---[E] 다른 탭에서 청약정보 탭 클릭시 스타벅스 관심사 프로모션 띄우기 위함 [E]---
    if (menuType === 'B' || menuType === 'D') {
      let os = osCheck() == 'Android' ? "A" : "I";
      axios.get('apt/authorized/check',
        { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }, timeout: 1000 }).then(res => {
          if (res.data.response.isValid) {
            // url 구분 작업
            history.push({
              pathname: location.pathname,
              search: menuType === 'B' ? `?PER-1000#` : `?CAL-1000#`
            });

            setKeyword('');
            selectMenu(menuType);
          }
          else {
            window.location.href = '/login';
            //const param = { type: 'I', url: '/login' }
            //callAppInterface('openWebPage', JSON.stringify(param), result => {
            //alert(result);
            //});
          }
        }).catch(err => {
          if (localStorage.getItem('isAutoLogin') == 'true') {
            // 자동로그인 적용시 사용
            if (err.response.status === 401 || err.response.status === 403) {
              axios.post('apt/authorized/reIssue',
                { 'ref': localStorage.getItem('ref'), 'moLogin': true },
                { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } }).then(res => {
                  if (res.status === 200) {
                    localStorage.setItem('jwtToken', res.headers.authorization);

                    callAppInterface("tokenToApp", JSON.stringify({
                      token: res.headers.authorization,
                      ref: localStorage.getItem('ref'),
                      isAuto: true,
                    }), result => {

                    });

                    setKeyword('');
                    selectMenu(menuType);
                  }
                  else {
                    window.location.href = '/login';
                    //const param = { type: 'I', url: '/login' }
                    //callAppInterface('openWebPage', JSON.stringify(param), result => {
                    //alert(result);
                    //});
                  }
                }).catch(err => {
                  window.location.href = '/login';
                  //const param = { type: 'I', url: '/login' }
                  //callAppInterface('openWebPage', JSON.stringify(param), result => {
                  //alert(result);
                  //});
                });
            }
          }
          else {
            window.location.href = '/login';
            //const param = { type: 'I', url: '/login' }
            //callAppInterface('openWebPage', JSON.stringify(param), result => {
            //alert(result);
            //});
          }
        });
    }
  }

  //--selectMenu 도 open의 역할을 함
  const selectMenu = (menuType) => {

    // ---[S] 다른 탭에서 청약정보 탭 클릭시 스타벅스 관심사 프로모션 띄우기 위함 [S]---
    // [SOM.220603] 상단 메뉴탭 클릭시 메뉴클릭 플래그값 menuType으로 설정 
    setApplyMenuTypeClickCheck(menuType);
    // [SOM.220603] applyList 청약정보 탭 외 다른 탭에서 진입 여부 체크
    if(menuType === 'B' || menuType === 'C' || menuType === 'D'){
      setApplyMenuTypeClickCheck2(true);
    }
    // ---[E] 다른 탭에서 청약정보 탭 클릭시 스타벅스 관심사 프로모션 띄우기 위함 [E]---

    setTimeout(() => {
      $('.bg_body_full').remove();
      uiFixValue.init();

      // url 구분 작업
      if (menuType === 'A') {
        history.push({
          pathname: location.pathname,
          search: `?APY-1000#`
        })
      } else if (menuType === 'C') {
        history.push({
          pathname: location.pathname,
          search: `?PAL-1000#`
        })
      }
    }, 100);
    //}
    uiIpFocus.init();
    uiInputDel.init();
    //e && e.preventDefault();
    // setRecentFocus(true);
    // setSearchResult([]);
    // setSearchVisible(false);

    if (applyMenuType != menuType) {
      setLcUrlItem((
        applyMenuType === 'A' ? 'applyList' :
          applyMenuType === 'B' ? 'applyPredict' :
            applyMenuType === 'C' ? 'applyCalc' :
              applyMenuType === 'D' ? 'applyPayList' : ''
      )
        ,
        (
          menuType === 'A' ? 'applyList' :
            menuType === 'B' ? 'applyPredict' :
              menuType === 'C' ? 'applyCalc' :
                menuType === 'D' ? 'applyPayList' : ''
        )
      );
      //setLcParamItem({});
      setApplyMenuType(menuType);
    }
  }



  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  const backToAptMain = () => {
    setLcUrlItem('applyList', 'aptMain');
    setLcParamItem({});
    $(".srch_list_wrap").removeClass("active");
    $('.map_wrap').show();

    if(typeof props.onApplyListDisplay == 'undefined') {
      window.location.href = '/aptMain'
    } else {
      // url 구분 작업
      history.push({
        pathname: location.pathname,
        search: ``
      });

      props.onApplyListDisplay(false);
    }
  }

  const backToAction = () => {
    const urlMap = getLcUrlItem();

    if (applyMenuType === 'A') {
      setLcUrlItem('applyList', 'aptMain');
      setLcParamItem({});
      $(".srch_list_wrap").removeClass("active");
      $('.map_wrap').show();

      // url 구분 작업
      history.push({
        pathname: location.pathname,
        search: `?APY-1000#`
      })

      if(typeof props.onApplyListDisplay == 'undefined') {
        window.location.href = '/aptMain'
      } else {
        props.onApplyListDisplay(false);
      }
    }
    else {
      //setLcUrlItem(urlMap.currUrl, urlMap.prevUrl);
      //applyList 메인 외 다른 탭에서 진입시 무조건 applyList 메인으로 back
      // selectMenu('A');
      window.location.href = '/aptMain'

    }

    /*if (urlMap.prevUrl === 'applyList') {
      selectMenu('A');
    }
    else if (urlMap.prevUrl === 'applyPredict') {
      selectMenuAuth('B');
    }
    else if (urlMap.prevUrl === 'applyCalc') {
      selectMenu('C');
    }
    else if (urlMap.prevUrl === 'applyPayList') {
      selectMenuAuth('D');
    }
    else if (urlMap.prevUrl === 'applyDtl') {
      setApplyPopupType('P_A');
    }
    else if (urlMap.prevUrl === 'applyMap') {
      setApplyPopupType('P_B');
    }
    else if (urlMap.prevUrl === 'applyPayDtl') {
      setApplyPopupType('P_C');
    }
    else if (urlMap.prevUrl === 'applyPayCalc') {
      setApplyPopupType('P_D');
    }
    else if (urlMap.prevUrl === 'applyPredictDtl') {
      selectMenuAuth('B');
    }

    else if (urlMap.prevUrl === 'rcmdDtl') {
      props.onRcmdPopupType('P_B');
    }
    */
  }

  // 마이페이지 열기
  const openMyPage = async () => {
    const verify = await axiosAuth.verify();

    switch (verify.resVerify.type) {
      case AXIOS_AUTH_RESULT.CHECK_SUCCESS: {
        setMyPageInfo({ maskingPhoneNo: verify.resVerify.maskingPhoneNo, pushData: verify.resVerify.pushData, email: verify.resVerify.email });

        setIsMyPage(true);
        
        mwSale.current.style.display = 'none';

        break;
      }
      case AXIOS_AUTH_RESULT.RE_ISSUE_SUCCESS: {
        let args = { token: verify.resVerify.authorization, ref: localStorage.getItem('ref'), isAuto: true };

        localStorage.setItem('jwtToken', verify.resVerify.authorization);

        callAppInterface('tokenToApp', JSON.stringify(args), result => {});

        const { data, headers, isSuccess } = await axiosAuth.check();

        if (isSuccess) {
          if (data.response.isValid) {
            setMyPageInfo({ maskingPhoneNo: headers.maskingPhoneNo, pushData: data.response.pushData, email: verify.resVerify.email });
            
            setTimeout(() => {
              setIsMyPage(true);

              mwSale.current.style.display = 'none';
            }, 500);
          }
        }
        
        break;
      }
      case AXIOS_AUTH_RESULT.CHECK_FAIL: case AXIOS_AUTH_RESULT.RE_ISSUE_FAIL: {
        setIsMyPage(true);

        mwSale.current.style.display = 'none';

        break;
      }
      default : {}
    }
  }

  // 마이페이지 닫기
  const closeMyPage = () => {
    setIsMyPage(false);
    
    mwSale.current.removeAttribute('style');
  }

  return (
    <>
      {/* //favApply  청약정보 즐겨찾기 */}
      {favApplyListDisplay && <FavApplyList onFavApplyListDisplay={setFavApplyListDisplay} onApplyPopupType={setApplyPopupType}></FavApplyList>}

      {/* // 마이페이지 추가 */}
      {isMyPage && <MyPage myPageInfo={myPageInfo} setMyPageInfo={setMyPageInfo} closeMyPage={closeMyPage} />}

      {
        applyPopupType === '' &&
        <>

          <div id="mw-sale" className={applyMenuType != 'C' ? (applyMenuType === 'B' && isCalcResult) ? "ly_apt_sale ly_filter_detail full" : "ly_apt_sale full" : "ly_apt_sale ly_point_filter full"} ref={mwSale}>
            <div className="wrap">
              <div className="content">
                <div className={applyMenuType ? "header main" : "header"}>
                  {/* 메인개편 추가 - 메인에서만 info_header 노출 */}
                  {applyMenuType && <div className="info_header">
                    <div className="info_header_default">
                      <button className="btn_home" onClick={() => backToAptMain()}><i className="blind">홈으로</i></button>
                      <div className="side">
                        <button type="button" onClick={() => openMyPage()} className="btn_my"><i className="blind">마이페이지</i></button>
                        <button type="button" onClick={() => popupOpen('menu','mwf')} className="btn_menu"><i className="blind">메뉴</i></button>
                      </div>
                    </div>
                  </div>}
                  {/* // */}
                  {/*{applyMenuType !== 'A' && <header>*/}
                  {/*  {(applyMenuType === 'B' && isCalcResult) && <button type="button" onClick={() => selectMenu('A')} className="btn_home" title="홈으로 바로가기"><i></i></button>}*/}
                  {/*  {applyMenuType != 'A' && <button type="button" onClick={() => backToAction()} className="btn_prev GTM-PREV-1" title="뒤로가기"><i></i></button>}*/}
                  {/*  <strong className="tit">청약정보</strong>*/}
                  {/*  {applyMenuType === 'A' && <button type="button" onClick={() => openMyPage()} className="btn_my" title="마이페이지 바로가기"><i></i></button>}*/}
                  {/*</header>}*/}
                  <ul className="menu_wrap">
                    <li className={applyMenuType === 'A' ? 'active' : ''} ><a href="#" onClick={() => selectMenu('A')}>청약정보</a></li>
                    <li className={applyMenuType === 'B' ? 'active' : ''} ><a href="#" onClick={() => selectMenuAuth('B')}>당첨예상</a></li>
                    <li className={applyMenuType === 'C' ? 'active' : ''} ><a href="#" onClick={() => selectMenu('C')}>나의가점</a></li>
                    <li className={applyMenuType === 'D' ? 'active' : ''} ><a href="#" onClick={() => selectMenuAuth('D')}>비용계산</a></li>
                  </ul>
                  {
                    (applyMenuType === 'A' || applyMenuType === 'D') &&
                    <>
                      <div className="apt_srch_wrap">
                        <button type="button" className="btn_srch_full focus GTM-APY-1" onClick={() => {
                          setRecentFocus(true); popupOpen('srch', 'mwf'); uiFilterTabSwipe.init(); uiIpFocus.init(); uiInputDel.init();
                          if (applyMenuType === 'D') { $('#srch_input02').focus() }
                        }}>
                          <span className={keyword ? "val_txt" : ""}>{keyword ? keyword : "청약 정보를 검색해주세요."}</span>
                        </button>
                      </div>
                    </>
                  }
                </div>
                {
                  (applyMenuType === 'A' && refreshState === false) ?
                    <>
                      <div className="ly_content">
                        <div className="apt_list_wrap">
                          <ul>
                            {
                              applyList.map((result, idx) => {
                                return (
                                  idx < limitSize &&
                                  <li className="item" onClick={() => openApplyDtl(result.applyId)}>
                                    <a href="#" className="inner_wrap">
                                      <div className="tit_wrap">
                                        <strong>{result.aptName}</strong>
                                        <span>{result.address}</span>
                                      </div>
                                      <p className="txt">
                                        {
                                          result.notiYn === 'N' ?
                                            <><span>청약예정</span><code></code></>
                                            :
                                            dateDiffMsg(result.notiDate) != '완료' ? <><span>모집공고</span><code>{dateDiffMsg(result.notiDate)}</code></> :
                                              result.spLocalDate != '' && dateDiffMsg(result.spLocalDate) != '완료' ? <><span>특별공급</span><code>{dateDiffMsg(result.spLocalDate)}</code></> :
                                                dateDiffMsg(result.fstLocalDate) != '완료' ? <><span>일반공급</span><code>{dateDiffMsg(result.fstLocalDate)}</code></> :
                                                  dateDiffMsg(result.pbshDate) != '완료' ? <><span>당첨자발표</span><code>{dateDiffMsg(result.pbshDate)}</code></> : <><span>완료</span><code></code></>
                                        }
                                      </p>
                                      <ul className="info">
                                        <li>{dateFormatMsg(result.notiDate)}</li>
                                        <li>{numberWithCommas(result.applyCnt)}세대</li>
                                        {
                                          (result.loanRateCase == 1 || result.loanRateCase == 2) ? <li>투기 과열</li> :
                                            (result.loanRateCase == 3 || result.loanRateCase == 4) ? <li>조정 지역</li> : null
                                        }
                                      </ul>
                                      <span className="price">{numberReplaceWithCodetag(numberWithCounts(result.maxApplyPrice * 10000))}</span>
                                    </a>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div>
                        {/* 메인개편 - 삭제 */}
                        {/* <nav className="gnb_wrap fix btm">
                          <button type="button" className="logo" onClick={() => selectMenuAuth('B')}></button>
                          <div className="btn_wrap">
                            <a href="#" className="btn_info01 " onClick={() => backToAptMain()}>아파트정보</a>
                            <a href="#" className="btn_info02 active" onClick={() => selectMenu('A')}>청약정보</a>
                          </div>
                        </nav> */}
                      </div>
                      <div className="btn_expect_wrap">
                        <button type="button" className="btn GTM-APY-0" onClick={() => selectMenuAuth('B')}>
                          <span className="full"><strong>내 청약당첨가능성</strong> 확인하기</span><span className="simple">당첨예상</span>
                        </button>
                      </div>
                    </> :
                    (applyMenuType === 'B' && refreshState === false) ?
                      <ApplyPredict
                        onRefreshState={setRefreshState}
                        onApplyMenuType={setApplyMenuType}
                        onApplyPopupType={setApplyPopupType}
                        onApplyListDisplay={props.onApplyListDisplay}
                        onIsCalcResult={setIsCalcResult}
                        goback={goback}
                      /> :
                      (applyMenuType === 'C' && refreshState === false) ?
                        <ApplyCalc
                          onRefreshState={setRefreshState}
                          onApplyMenuType={setApplyMenuType}
                          onApplyPopupType={setApplyPopupType}
                          onApplyListDisplay={props.onApplyListDisplay}
                          goback={goback}
                        /> :
                        (applyMenuType === 'D' && refreshState === false) ?
                          <ApplyPayList
                            applyPayList={applyPayList}
                            onRefreshState={setRefreshState}
                            onApplyMenuType={setApplyMenuType}
                            onApplyPopupType={setApplyPopupType}
                            onApplyListDisplay={props.onApplyListDisplay}
                            goback={goback}
                          /> :
                          null
                }
              </div>
            </div>
          </div>
          {
            applyMenuType === 'A' ?
              <>
                <div id="mw-srch" className="ly_pop ly_apt_srch full" style={{ "display": "none" }}>
                  <div className="wrap">
                    <div className="content top_srch_wrap active">
                      <header className="header">
                        <button type="button" onClick={() => { popupClose('srch', 'mwf'); setSearchResult([]); }} className="btn_prev" title="뒤로가기"><i></i></button>
                        <div className="form">
                          <div className="ip_box ip_del">
                            <label for="srch_input02" className="lbl" style={{ "display": "inline" }}>{keyword ? '' : '어느 지역 아파트가 궁금하세요?'}</label>
                            {/* <input type="text" id="srch_input02" className="ip" /> */}
                            <input type="text" autocomplete="off" id="srch_input02" className="ip"
                              onChange={debounceChangeKeyword}
                              onFocus={debounceChangeKeyword}
                              onKeyDown={handleKeydownKeyword}
                              value={keyword}
                            />
                            <button type="button" onClick="" className="del" style={{ "display": "none" }}><i>삭제</i></button>
                          </div>
                        </div>
                      </header>
                      <div className="filter_tab_swipe">
                        <MainFilter filterName={filterName} />
                        <div className="view">
                          <div className="item" style={{ "display": "block" }}>
                            <div className="filter_box_wrap">
                              <button type="button" id="btnStatus1" className={`${status.value === '1' ? "btn active" : "btn"} GTM-APPLY-FILTER-1-1`} onClick={() => handleChangeStatus({ filterType: "STATUS", value: "1", mainName: "전체" })}>전체</button>
                              <button type="button" id="btnStatus2" className={`${status.value === '2' ? "btn active" : "btn"} GTM-APPLY-FILTER-1-2`} onClick={() => handleChangeStatus({ filterType: "STATUS", value: "2", mainName: "진행" })}>진행</button>
                              {/* <button type="button" id="btnStatus3" className={`${status.value === '3' ? "btn active" : "btn"} GTM-APPLY-FILTER-1-3`} onClick={() => handleChangeStatus({ filterType: "STATUS", value: "3", mainName: "예정" })}>예정</button> */}
                              <button type="button" id="btnStatus4" className={`${status.value === '4' ? "btn active" : "btn"} GTM-APPLY-FILTER-1-4`} onClick={() => handleChangeStatus({ filterType: "STATUS", value: "4", mainName: "완료" })}>완료</button>
                            </div>
                          </div>
                          {/*<LocalsFilter onChangeLocals={handleChangeLocals} refresh={isFiltersRefresh} load={isFiltersLoad}  checkLoad={isLocalsLoad} />*/}
                          <TrxAmtFilter onChangeFilter={handleChangeFilter} refresh={isFiltersRefresh} load={isFiltersLoad} checkLoad={isTrxAmtLoad} />
                          <PyFilter onChangeFilter={handleChangeFilter} refresh={isFiltersRefresh} load={isFiltersLoad} checkLoad={isPyLoad} />
                          <HhldFilter onChangeFilter={handleChangeFilter} refresh={isFiltersRefresh} load={isFiltersLoad} checkLoad={isHhldLoad} />
                        </div>
                      </div>
                      <div className="filter_save_wrap">
                        <ul className="btn_list">
                          <li><button type="button" className="btn_bookmark GTM-APPLY-BOOKMARK" onClick={(e) => openFavApplyList(e)}>즐겨찾기</button></li>
                          {/*<li><button type="button" className="btn_save GTM-APPLY-FILTER-SAVE" onClick={(e) => saveFilter(e)}>필터저장</button></li>*/}
                          <li><button type="button" className="btn_reset GTM-APPLY-SEL-RESET" onClick={() => filtersRefresh()}>선택 초기화</button></li>
                        </ul>
                        <button type="button" className="btn_close" onClick={() => { popupClose('srch', 'mwf'); setSearchResult([]); }}>상세 검색 닫기</button>
                      </div>

                      <div className="srch_list_wrap">
                        <div className="inner_wrap">
                          {
                            recentFocus ?
                              <RecentResult
                                recentResult={recentResult}
                                onClearRecent={handleClearRecent}
                                onRemoveRecent={handleRemoveRecent}
                                onSelectRecent={(search) => handleSelectSearch(search)}
                              ></RecentResult>
                              : <SearchResult
                                searchResult={searchResult}
                                searchIndex={searchIndex}
                                searchKeyword={keyword}
                                searchVisible={searchVisible}
                                onKeyDown={handleKeydownKeyword}
                                onSelectSearch={(search) => handleSelectSearch(search)}
                              ></SearchResult>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </> :
              applyMenuType === 'D' ?
                <>
                  <ApplyPaySrch
                    onApplyPayList={setApplyPayList}
                    onKeyword={setKeyword}
                    onApplyPopupType={setApplyPopupType}
                  ></ApplyPaySrch>
                </>
                : null
          }
        </>
      }
      {applyPopupType === 'P_A' && <ApplyDtl onApplyMenuType={setApplyMenuType} onApplyPopupType={setApplyPopupType}></ApplyDtl>}
      {applyPopupType === 'P_B' && <ApplyDtlMap onApplyMenuType={setApplyMenuType} onApplyPopupType={setApplyPopupType}></ApplyDtlMap>}
      {applyPopupType === 'P_C' && <ApplyPayDtl onApplyMenuType={setApplyMenuType} onApplyPopupType={setApplyPopupType}></ApplyPayDtl>}
      {applyPopupType === 'P_D' && <ApplyPayCalc onApplyMenuType={setApplyMenuType} onApplyPopupType={setApplyPopupType}></ApplyPayCalc>}

      {/* 메뉴 - 메인개편 추가 */}
      <MenuPopup page={props.page} />
      {/* // 메뉴 */}
    </>
  );
}

export default ApplyList;