import React, { useRef, useState, useEffect } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import MyPageEmailPopup from './MyPageEmailPopup';
import { popupOpen, popupClose } from 'routes/common/Design';

import $ from 'jquery';
import axios from 'axios';

import { callAppInterface } from 'routes/common/AppInterface';

const MyPage = ({ myPageInfo, closeMyPage, setMyPageInfo }) => {
  const lyAptMypage = useRef(null);

  const [servicePushYN, setServicePushYN] = useState(false);
  const [marketingPushYN, setMarketingPushYN] = useState(false);
  const [init, setInit] = useState(true); // 최초 실행 flag

  const openPage = (type) => {
    switch (type || '') {
      case 'login':   window.location.href = '/login';                      break;
      case 'logout':  window.location.href = '/logout';                     break;
      case 'join':    window.location.href = '/join?siteCode=AB&ctgCode=1'; break;
      default : {
        let args = { type: 'I' };

        switch (type || '') {
          case 'notice':    args = {...args, url: (`${window.location.origin}/notice`) };                                     break;
          case 'faq':       args = {...args, url: (`${window.location.origin}/faq`) };                                        break;
          case 'qna':       args = {...args, url: (`${window.location.origin}/qna`) };                                        break;
          case 'expire':    args = {...args, url: (`${window.location.origin}/expire`) };                                     break;
          case 'agree1':    args = {...args, url: (`${process.env.REACT_APP_APT_WEB_ORIGIN}/apt/terms?type=1&telcoCode=1&loc=N`) };  break;
          case 'location':  args = {...args, url: (`${process.env.REACT_APP_APT_WEB_ORIGIN}/apt/terms?type=6&loc=N`) };  break;
          case 'privacy':   args = {...args, url: `https://www.hectoinnovation.co.kr/sterm/mprivacy?service=apt` };           break;
          case 'license':   args = {...args, url: (`${process.env.REACT_APP_APT_WEB_ORIGIN}/apt/terms?type=7`) };      break;
          case 'appInfo':   args = {...args, url: (`${window.location.origin}/appInfo`) };                                    break;
          default : {}
        }

        if (args.url) {
          callAppInterface('openWebPage', JSON.stringify(args), result => {});
        }
      }
    }
  }

  // PUSH 상태 저장값 변경
  const onChangePushUpdate = (e) => {
    switch (e.target.value) {
      case 'P001':  setServicePushYN(e.target.checked);   break;  // 서비스 PUSH
      case 'P002':  setMarketingPushYN(e.target.checked); break;  // 마케팅정보 PUSH
    }
  };

  // 사용자 email 정보 등록/수정 팝업
  const openEmailPopup = (mode) => {
    switch (mode) {
      case 'R': $('#email').val('');  break;
      case 'M': $('#email').val('');  break;
    }

    $('#terms').prop('checked', false);
    $('.dropdown_content button:eq(0)').trigger('click');

    popupOpen('email', 'mwf', 0);
  };

  // 이메일 정보 삭제
  const deleteEmail = () => {
    if(window.confirm("이메일 정보를 정말 삭제하시겠습니까?")) {
      axios
          .post('apt/delete/deleteUserEmail', {}, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } })
          .then(res => {
            switch (res.data.code) {
              case 'E0000': {
                alert('이메일 삭제가 완료되었습니다.');

                setMyPageInfo({...myPageInfo, 'email': ''});

                popupClose('email', 'mwf');

                break;
              }
              default : {
                alert(res.data.message);

                return false;
              }
            }
          });
    }
  };

  useEffect(() => {
    if(localStorage.getItem('jwtToken') != null) {
      let pushData = myPageInfo?.pushData;
      
      // 앱 실행시 DB에 저장된 푸쉬 알림 상태로 세팅
      if (pushData) {
        pushData.forEach(data => {
          if (data.status === 'Y') {
            switch (data.type) {
              case 'P001':  document.getElementById('service').click();   break;
              case 'P002':  document.getElementById('marketing').click(); break;
            }
          }
        });
      }

      setInit(false);

    }
  }, []);

  // 서비스 PUSH 알림
  useEffect(() => {
    if (!init) {
      if(localStorage.getItem('jwtToken') != null) {
        let servicePushYn = servicePushYN ? 'Y' : 'N';

        // 서버로 PUSH 상태 업데이트 요청
        axios.post('api/app/pushUpdate', { "type": "P001", "status": servicePushYn },
          { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } }).then(res => {
            //console.log("useEffect (2) > " + res.data.result.type + ' / ' + res.data.result.status);
          });
        
      }
    }
  }, [servicePushYN]);

  // 마케팅 정보 PUSH 알림
  useEffect(() => {
    if (!init) {
      if(localStorage.getItem('jwtToken') != null) {
        
        let marketingPushYn = marketingPushYN ? 'Y' : 'N';

        // 서버로 PUSH 상태 업데이트 요청
        axios.post('api/app/pushUpdate', { "type": "P002", "status": marketingPushYn },
          { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } }).then(res => {
            //console.log("useEffect (3) > " + res.data.result.type + ' / ' + res.data.result.status);
          });
      }
    }
  }, [marketingPushYN]);

  return (
    <>
      <div className="ly_apt_mypage full" ref={lyAptMypage}>
        <div className="wrap">
          <div className="content">
            <header className="header">
              <button type="button" className="btn_prev" title="뒤로가기" onClick={closeMyPage}><i></i></button>
              <strong className="tit">마이페이지</strong>
            </header>
            <div className="ly_content">
              {
                (() => {
                  let login = false;

                  if (localStorage.getItem('jwtToken') != null) {
                    if (myPageInfo) {
                      login = (myPageInfo.constructor === Object && Object.keys(myPageInfo).length);
                    }
                  }

                  if (login) {
                    return (
                        <>
                          <section className="my_info">
                            <strong className="sec_tit">회원정보</strong>
                            <div className="inner_wrap">
                              <code className="phone_num">{myPageInfo.maskingPhoneNo}</code>
                              <button type="button" className="btn_txt" onClick={() => openPage('logout')}>로그아웃</button>
                            </div>
                          </section>
                          <section>
                            <strong className="sec_tit">이메일</strong>
                            {
                              (myPageInfo.email) ? (
                                  <div className="inner_wrap">
                                    <p className="email_info">
                                      <em>{myPageInfo.email}</em>
                                    </p>
                                    <div className="email_info_btn">
                                      <button type="button" className="btn_txt" onClick={() => openEmailPopup('M')}>수정</button>
                                      <button type="button" className="btn_txt" onClick={() => deleteEmail()}>삭제</button>
                                    </div>
                                  </div>
                              ) : (
                                  <div className="inner_wrap">
                                    <p className="email_info">이메일 등록 후 <strong>부동산 알림</strong> 받아 보세요!</p>
                                    <div className="email_info_btn">
                                      <button type="button" className="btn_txt type1" onClick={() => openEmailPopup('R')}>등록</button>
                                    </div>
                                  </div>
                              )
                            }
                          </section>
                          <section>
                            <strong className="sec_tit">알림</strong>
                            <ul>
                              <li className="item">
                                <strong className="item_tit">서비스 PUSH 알림</strong>
                                <span className="toggle_chk">
                            <input type="checkbox" id="service" value="P001" onClick={onChangePushUpdate}/>
                          </span>
                              </li>
                              <li className="item">
                                <strong className="item_tit">마케팅 정보 PUSH 알림</strong>
                                <span className="toggle_chk">
                            <input type="checkbox" id="marketing" value="P002" onClick={onChangePushUpdate}/>
                          </span>
                              </li>
                            </ul>
                          </section>
                        </>
                    )
                  } else {
                    return (
                        <>
                          <section className="my_info">
                            <ul className="btn_wrap">
                              <li><button type="button" className="btn_txt_big" onClick={() => openPage('login')}>로그인</button></li>
                              <li><button type="button" className="btn_txt_big" onClick={() => openPage('join')}>회원가입</button></li>
                            </ul>
                          </section>
                        </>
                    )
                  }
                })()
              }
              <section>
                <strong className="sec_tit">고객센터</strong>
                <div className="link_wrap">
                  <a href="#" className="item" onClick={() => openPage('notice')}><strong className="item_tit">공지사항</strong></a>
                  <a href="#" className="item" onClick={() => openPage('faq')}><strong className="item_tit">FAQ</strong></a>
                  <a href="#" className="item" onClick={() => openPage('qna')}><strong className="item_tit">1:1 문의</strong></a>
                  <a href="#" className="item" onClick={() => openPage('expire')}><strong className="item_tit">서비스해지</strong></a>
                </div>
              </section>
              <section>
                <strong className="sec_tit">서비스정보</strong>
                <div className="link_wrap">
                  <a href="#" className="item" onClick={() => openPage('agree1')}><strong className="item_tit">이용약관</strong></a>
                  <a href="#" className="item" onClick={() => openPage('location')}><strong className="item_tit">위치기반서비스 이용약관</strong></a>
                  <a href="#" className="item" onClick={() => openPage('privacy')}><strong className="item_tit">개인정보처리방침</strong></a>
                  <a href="#" className="item" onClick={() => openPage('license')}><strong className="item_tit">오픈소스 라이센스 이용고지</strong></a>
                  <a href="#" className="item" onClick={() => openPage('appInfo')}><strong className="item_tit">앱 정보</strong></a>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <MyPageEmailPopup myPageInfo={myPageInfo} setMyPageInfo={setMyPageInfo} />
    </>
  );
};

export default MyPage;
