import React, { useEffect, useState } from 'react'
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import MainFilter from 'routes/apply/filter/MainFilter';
import LocalsFilter from 'routes/apply/filter/LocalFilter';
import HhldFilter from 'routes/apply/filter/HhldFilter';
import PyFilter from 'routes/apply/filter/PyFilter';
import TrxAmtFilter from 'routes/apply/filter/TrxAmtFilter';

import { AptOverlay } from 'routes/overlay/AptOverlay.js';
import { BjdOverlay } from 'routes/overlay/BjdOverlay.js';

import axios from 'axios';
import $ from 'jquery';

import { searchApply, addRecentItem, getRecentItem, clearRecentItem, setRecentList, addLsItem, getLsItem } from 'routes/common/Search';
import { dateDiffMsg, numberWithCounts, numberWithCommas, numberWithCommas2, dateFormatMsg, dateFormatMsg2, numberReplaceWithStag, textReplaceWithStag, numberReplaceWithCodetag } from 'routes/common/StringUtil';
import { uiFixValue, uiFilterTabSwipe, popupOpen, popupClose, uiIpFocus, uiInputDel, uiAccoScrollFix, uiSelFocus, uiMainSwitch, uiChartTap, uiToggle, uiTopGo } from 'routes/common/Design.js'
import { findIndex } from 'routes/common/ArrayUtil';
import _ from 'lodash';

import { initWebViewEvent, callAppInterface, checkMainPage } from 'routes/common/AppInterface'

import RecentResult from 'routes/common/RecentResult';
import SearchResult from 'routes/common/SearchResult';
import Moment from 'moment';

//// 컴포넌트 사용처 : 부동산정책
const RealtyPolicy = (props) => {

  const [policyList, setPolicyList] = useStateWithCallbackLazy([]);
  const [updateState, setUpdateState] = useStateWithCallbackLazy(false);
  const [limitSize, setLimitSize] = useStateWithCallbackLazy(10);

  useEffect(() => {
    const handleScroll = () => {
      if ((window.innerHeight + window.scrollY) >= $('.bbs_list').innerHeight()) {
        setLimitSize(limitSize => limitSize + 10);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    uiFixValue.init();
    uiFilterTabSwipe.init();
    uiSelFocus.init();
    uiMainSwitch.init();
    uiChartTap.init();
    uiToggle.init();
    uiTopGo.init();

    $(window).scrollTop(0);

    fetchMolitLandNewsList();

  }, []);

  // molitLandNews 
  const fetchMolitLandNewsList = (e) => {
    axios
      .get('apt/realtyPolicy/mblList')
      .then(res => {
        setPolicyList(res.data.response.policyList);
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          alert(error.response.data.message);
          return;
        }
      });
  }



  //--'open' : 현 화면이 다음 화면으로 변경되는 action
  const openPolicy = (newsUrl) => {
    callAppInterface("openWebPage", JSON.stringify({ type: 'O', url: newsUrl }), result => {
      //alert(result);
    });
  }

  return (
    <>
      <div className="ly_content">
        <ul className="bbs_list">
          {
            policyList.length <= 0 ?
              null
              :
              policyList.map((notice, idx) => {
                return (
                  idx < limitSize &&
                  <li>
                    <a href='#' className="tit_wrap" onClick={() => openPolicy(notice.url)}>
                      <strong className="tit">{notice.title}</strong>
                      <code className="date">{notice.bdTime}</code>
                    </a>
                  </li>
                )
              })
          }
        </ul>
      </div>
    </>
  );
}

export default RealtyPolicy;