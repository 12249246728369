import React from "react";

/**
 * @desc 발급예정인 검정색 쿠폰
 * @desc 직전쿠폰 사용완료시 willUsable 을 true로 설정하여 사용하면 됨
 */
export const CouponItemStandby = ({willUsable, couponAlert}) => {

    return (
            <li className="coupon-item standby" onClick={couponAlert}>
                <div className="text-box">
                    {
                        willUsable
                                ? <span className="text-01">익일 발급 가능 D-1</span>
                                : <span className="text-01">보유 쿠폰 사용 시, 1일 후 자동 발급</span>
                    }
                    <span className="text-03"><i className="icon-won"></i> 1,000</span>
                </div>
                <button className="btn-coupon-down">
                    <i className="ico-dash"></i>
                </button>
            </li>
    );
};