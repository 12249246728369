import cryptoJs from 'crypto-js';

/** @type { object } */
const data = { iv: cryptoJs.enc.Utf8.parse(process.env.REACT_APP_AES256_IV), padding: cryptoJs.pad.Pkcs7, mode: cryptoJs.mode.CBC };

/**
 * @param { string } text 평문
 * @returns 암호문
 */
const encrypt = (text) => {
    const cipher = cryptoJs.AES.encrypt(text, cryptoJs.enc.Utf8.parse(process.env.REACT_APP_AES256_SECRET_KEY), data);

    return cipher.toString();
}

/**
 * @param { string } encryptedText 암호문
 * @returns 평문
 */
const decrypt = (encryptedText) => {
    const decipher = cryptoJs.AES.decrypt(encryptedText, cryptoJs.enc.Utf8.parse(process.env.REACT_APP_AES256_SECRET_KEY), data);

    return decipher.toString(cryptoJs.enc.Utf8);
}

export default {
    encrypt
    , decrypt
}