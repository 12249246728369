import React, { Component } from 'react';

const RecentResult = (props) => {

  return (
    <>
      <div className="search_list">
        <strong className="tit">최근검색어</strong>
        <ul className="list_item recent">
          {
            props.recentResult.length > 0 ?
              props.recentResult.map((v, idx) => {
                const searchName = v.info1 ? v.info1 + ' ' + v.searchName : v.searchName;
                return (
                  <li key={idx} >
                    <a href="#" onClick={(e) => { e.preventDefault(); props.onSelectRecent(v); }} >{searchName}</a>
                    <button type="button" className="btn_del" onClick={(e) => { e.preventDefault(); props.onRemoveRecent(v.searchId); }}></button>
                  </li>
                )
              })
              :
              null
          }
        </ul>
        {
          props.recentResult.length === 0 &&
          <p className="noti">최근 검색어가 없습니다.</p>
        }
      </div>
      {
        props.recentResult.length > 0 ?
          <div className="btn_area">
            <button type="button" className="btn_recent"
              onClick={props.onClearRecent} ><u>최근 검색기록 삭제</u></button>
          </div>
          :
          null
      }
    </>
  );

}
export default RecentResult;