import React, { useEffect, useState } from 'react'
import { initWebViewEvent, callAppInterface, checkMainPage } from 'routes/common/AppInterface'

//// 컴포넌트 사용처 : 로그아웃
const Logout = (props) => {

  useEffect(() => {

    localStorage.removeItem('jwtToken');
    localStorage.removeItem('ref');
    localStorage.removeItem('isAutoLogin');

    window.location.href = '/';

    // [SOM.221011] 마이페이지 웹뷰 전환 - 마이페이지 > 로그아웃 후 흰화면 뜨는 증상으로 주석 처리
    // setTimeout(() => {
    //   callLogout();
    // }, 100);

  }, []);


  const callLogout = () => {
    callAppInterface("logout", null, result => {
      //alert(result);
      window.location.href = '/';
    });
  }


  return (
    <>
    </>
  );
}
export default Logout;