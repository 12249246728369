export const COUPON = Object.freeze({
    STATE: Object.freeze({
        USED: 'USED', // 사용완료
        USABLE: 'USABLE', // 사용가능
        EXPIRE: 'EXPIRE', // 만료
        WAIT: 'WAIT' // 발급대기
    }),
    TEST: {
        cpnGrpCd: "TE",
        cpnGrpNm: "기프트레터 상품권",
        goodsId: process.env.GL_GS_GOODS_ID,
        logo: process.env.REACT_APP_ENV_URL + "resources/images/logo_npay_01.png",
        // 로그인 전 무색쿠폰 이미지
        image01: process.env.REACT_APP_ENV_URL + "resources/images/logo_npay_01.png",
        // 로그인 후 유색쿠폰 이미지
        image02: process.env.REACT_APP_ENV_URL + "resources/images/logo_npay_02.png",
        backgroundColor: "#1ec800",
        price: "3,000원",
    },
    NAVER: {
        cpnGrpCd: "NV",
        cpnGrpNm: "네이버페이",
        goodsId: process.env.GL_NAVER_GOODS_ID,
        logo: process.env.REACT_APP_ENV_URL + "resources/images/logo_npay_01.png",
        // 로그인 전 무색쿠폰 이미지
        image01: process.env.REACT_APP_ENV_URL + "resources/images/logo_npay_01.png",
        // 로그인 후 유색쿠폰 이미지
        image02: process.env.REACT_APP_ENV_URL + "resources/images/logo_npay_02.png",
        backgroundColor: "#1ec800",
        price: "3,000원",
    },
    GS: {
        cpnGrpCd: "GS",
        cpnGrpNm: "GS25",
        goodsId: process.env.GL_GS_GOODS_ID,
        logo: process.env.REACT_APP_ENV_URL + "resources/images/logo_gs25_01.png",
        // 로그인 전 무색쿠폰 이미지
        image01: process.env.REACT_APP_ENV_URL + "resources/images/logo_gs25_01.png",
        // 로그인 후 유색쿠폰 이미지
        image02: process.env.REACT_APP_ENV_URL + "resources/images/logo_gs25_02.png",
        backgroundColor: "#007cff",
        price: "3,000원",
    },
    CU: {
        cpnGrpCd: "CU",
        cpnGrpNm: "CU",
        goodsId: "",
        logo: "",
        // 로그인 전 무색쿠폰 이미지
        image01: "",
        // 로그인 후 유색쿠폰 이미지
        image02: "",
        backgroundColor: "",
        price: "",
    }
});

/**
 * @desc 쿠폰그룹코드(cpnGrpCd) 를 받아서 매핑되는 쿠폰상수객체의 키값을 리턴한다.
 * @param cpnGrpCd
 * @return {undefined|string}
 */
export const getCouponKey = (cpnGrpCd) => {
    for (const key in COUPON) {
        if (COUPON[key].cpnGrpCd === cpnGrpCd) {
            return key;
        }
    }

    return undefined;
};