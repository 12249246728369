import React from 'react';

import {NotLogin} from "./coupon/NotLogin";
import {Selected} from "./coupon/Selected";
import {NotSelected} from "./coupon/NotSelected";
import {useUserStore} from "../store/userStore";
import {Pin} from "./coupon/Pin";
import {useCoupon} from "../hooks/useCoupon";

/**
 * 회원
 *   1. 보유 쿠폰(원장, coupon_manage)을 조회한다.
 *      1. 원장이 없을경우 쿠폰 선택화면 이동
 *          1. 쿠폰을 선택하면 원장 발급, 목록화면으로 이동한다
 *      2. 원장이 있을경우 쿠폰 목록화면 이동
 *          1. 원장 기반의 쿠폰 목록을 노출한다. (쿠폰 사용상태, 만료기간에 따라 구분)
 *              1. 목록 노출 데이터를 가져올 때 사용여부 검증 필요함.
 *          2.
 *
 * 비회원
 *   1. 선택 가능한 쿠폰목록, 가입버튼을 노출한다
 *
 */
export const Coupon = () => {
  const {hasCouponItem, hasCoupon} = useCoupon();
  const {user} = useUserStore();

  if (user?.isLoggedIn) {
    if (hasCoupon()) {
      if (hasCouponItem()) {
        return <Pin/>;
      } else {
        return <Selected/>;
      }
    } else {
      return <NotSelected/>;
    }
  } else {
    return <NotLogin/>;
  }

};
