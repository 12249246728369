import React, { useEffect, useState } from 'react'
import { useStateWithCallbackLazy } from 'use-state-with-callback';

import SubFooter from 'routes/SubFooter';

import MainFilter from 'routes/apply/filter/MainFilter';
import LocalsFilter from 'routes/apply/filter/LocalFilter';
import HhldFilter from 'routes/apply/filter/HhldFilter';
import PyFilter from 'routes/apply/filter/PyFilter';
import TrxAmtFilter from 'routes/apply/filter/TrxAmtFilter';

import { AptOverlay } from 'routes/overlay/AptOverlay.js';
import { BjdOverlay } from 'routes/overlay/BjdOverlay.js';

import axios from 'axios';
import $ from 'jquery';

import { searchApply, addRecentItem, getRecentItem, clearRecentItem, setRecentList, addLsItem, getLsItem } from 'routes/common/Search';
import { dateDiffMsg, numberWithCounts, numberWithCommas, numberWithCommas2, dateFormatMsg, dateFormatMsg2, numberReplaceWithStag, textReplaceWithStag, numberReplaceWithCodetag } from 'routes/common/StringUtil';
import { uiFixValue, uiFilterTabSwipe, popupOpen, popupClose, uiIpFocus, uiInputDel, uiAccoScrollFix, uiSelFocus, uiMainSwitch, uiChartTap, uiToggle, uiTopGo } from 'routes/common/Design.js'
import { findIndex } from 'routes/common/ArrayUtil';
import _ from 'lodash';

import RecentResult from 'routes/common/RecentResult';
import SearchResult from 'routes/common/SearchResult';
import Moment from 'moment';
import { callAppInterface } from 'routes/common/AppInterface';

//// 컴포넌트 사용처 : Faq(자주묻는질문)
const Faq = (props) => {

  const [faqType, setFaqType] = useStateWithCallbackLazy('');
  const [faqList, setFaqList] = useStateWithCallbackLazy([]);
  const [updateState, setUpdateState] = useStateWithCallbackLazy(false);
  const [limitSize, setLimitSize] = useStateWithCallbackLazy(15);

  // useEffect(() => {
  //   $(window).on('scroll', function () {
  //     if (updateState === false && ((window.innerHeight + window.scrollY) >= $('.bbs_list').innerHeight())) {
  //       setUpdateState(true);
  //       setLimitSize(limitSize + 10);
  //       setTimeout(() => {
  //         uiToggle.init();
  //         setUpdateState(false);
  //       }, 100);
  //     }
  //     else if (updateState === true) {
  //       return;
  //     }

  //   })
  // });

  useEffect(() => {
    $(window).scrollTop(0);
    window.goback = backToAptMain;
    fetchFaqList();

  }, [faqType]);

  // faq 
  const fetchFaqList = (e) => {
    $('.toggle_wrap').removeClass('active');
    setFaqList([]);

    axios
      .get('apt/faq/mblList?type=' + faqType)
      .then(res => {
        setFaqList(res.data.response.faqList);
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          alert(error.response.data.message);
          return;
        }
      })
      .then(() => {
        uiToggle.init();
      });
  }

  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  //단독페이지들은 location href = / 로 보냄
  const backToAptMain = () => {
    //window.location.href = '/';
    callAppInterface("closeWebPage", JSON.stringify({ url: '/' }), result => {
      //alert(result);
    });
  }

  return (
    <>
      <div id="mw-calculator" className="ly_apt_sale full">
        <div className="wrap">
          <div className="content">
            <div className="header">
              <header>
                <button type="button" onClick={backToAptMain} className="btn_prev" title="뒤로가기"><i></i></button>
                <strong className="tit">고객센터</strong>
                {/* <button type="button" onClick="popupOpen('srch','mwf');" className="btn_srch" title="검색하기"><i></i></button> */}
              </header>
              <ul className="menu_wrap">

                <li><a href="/notice">공지사항</a></li>
                <li className='active'><a href="/faq">FAQ</a></li>
                <li><a href="/qna">1:1문의</a></li>
                <li><a href="/expire">서비스해지</a></li>
              </ul>
            </div>
            <div className="ly_content ly_bbs">
              <ul className="tab_wrap">
                <li><button type="button" className={faqType === '' ? "btn on" : "btn"} onClick={() => setFaqType('')} >전체</button></li>
                <li><button type="button" className={faqType === 'J' ? "btn on" : "btn"} onClick={() => setFaqType('J')} >가입</button></li>
                <li><button type="button" className={faqType === 'S' ? "btn on" : "btn"} onClick={() => setFaqType('S')} >이용</button></li>
                <li><button type="button" className={faqType === 'E' ? "btn on" : "btn"} onClick={() => setFaqType('E')} >해지</button></li>
              </ul>
              {
                faqList.length <= 0 ?
                  <div className="nodata_wrap">
                    <p className="txt">게시글이 없습니다.</p>
                  </div>
                  :
                  <ul className="bbs_list">
                    {
                      faqList.map((faq, idx) => {
                        return (
                          idx < limitSize &&
                          <li className="toggle_wrap">
                            <button type="button" className="tit_wrap btn_toggle">
                              <strong className="tit">{faq.question}</strong>
                              <code className="date">{faq.regDate}</code>
                            </button>
                            <div className="view_toggle">
                              <pre dangerouslySetInnerHTML={{ __html: faq.answer }}>
                              </pre>
                            </div>
                          </li>
                        )
                      })
                    }
                  </ul>
              }
            </div>
            <SubFooter></SubFooter>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faq;