import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";

export const ButtonPrevious = () =>{
    const history = useHistory();

    const goBack = () => {
        if (history.length > 2) {
            history.goBack();
        } else {
            history.replace('/aptMain');
        }
    };

    useEffect(() => {
        window.goback = goBack;
    }, []);

    return (
            <button type="button" onClick={goBack} className="btn_prev" title="뒤로가기"><i></i></button>
    );

}