import {useState} from 'react'

//// 컴포넌트 사용처 : 아파트 추천
const AprFilter = ({ onChangeFilter }) => {

  const filterType = "APR";

  const [filterList, setFilter] = useState([
    { name: '전체', visible: true, min: -1, max: 9999, minTxt: "전체", maxTxt: "전체" },
    { name: '1년 미만', visible: false, min: 0, max: 1, minTxt: "1년 미만", maxTxt: "1년 미만" },
    { name: '1 ~ 3년', visible: false, min: 1, max: 3, minTxt: "1", maxTxt: "3년 미만" },
    { name: '3 ~ 5년', visible: false, min: 3, max: 5, minTxt: "3", maxTxt: "5년 미만" },
    { name: '5 ~ 10년', visible: false, min: 5, max: 10, minTxt: "5", maxTxt: "10년 미만" },
    { name: '10 ~ 15년', visible: false, min: 10, max: 15, minTxt: "10", maxTxt: "15년 미만" },
    { name: '15 ~ 20년', visible: false, min: 15, max: 20, minTxt: "15", maxTxt: "20년 미만" },
    { name: '20년 이상', visible: false, min: 20, max: 9999, minTxt: "20년 이상", maxTxt: "20년 이상" },
  ]);

  const selectBtnList = (idx) => {

    let current = filterList.slice();
    let prevIdx = filterList.findIndex(v => v.visible);

    let visibleCnt = current.filter(v => v.visible).length;
    current.filter((v, i) => i === idx).forEach(v => v.visible = true);

    if (idx === 0) {
      current.filter((v, i) => i !== 0).forEach(v => v.visible = false);
    } else if (prevIdx === 0) {
      current.filter((v, i) => i !== idx).forEach(v => v.visible = false);
    } else if (visibleCnt > 1) {
      current.filter((v, i) => i !== idx).forEach(v => v.visible = false);
    } else if (prevIdx < idx) {
      current.filter((v, i) => i >= prevIdx && i <= idx).forEach(v => v.visible = true);
    } else if (prevIdx >= idx) {
      current.filter((v, i) => i !== idx).forEach(v => v.visible = false);
    }

    // if (current.filter((v, i) => i !== 0).every(v => v.visible)) {
    //   current.filter((v, i) => i === 0).forEach(v => v.visible = true);
    //   current.filter((v, i) => i !== 0).forEach(v => v.visible = false);
    // }

    setFilter(current);
    handleChangeFilter(current);
  }

  const handleChangeFilter = (btnList) => {

    let min = btnList.filter(v => v.visible).map(v => v.min).reduce((prev, curr) => Math.min(prev, curr));
    let max = btnList.filter(v => v.visible).map(v => v.max).reduce((prev, curr) => Math.max(prev, curr));

    let visibleCnt = btnList.filter(v => v.visible).length;
    let filterName;

    if (visibleCnt === 1) {
      if (btnList[1].visible) {
        filterName = `${max}년 미만`;
      } else if (btnList[btnList.length - 1].visible) {
        filterName = `${min}년 이상`;
      } else {
        filterName = btnList.filter(v => v.visible)[0].minTxt + ' ~ ' + btnList.filter(v => v.visible)[0].maxTxt;
      }
    } else {
      if (btnList[1].visible) {
        filterName = btnList.filter(v => v.visible)[visibleCnt - 1].maxTxt;
      } else {
        filterName = btnList.filter(v => v.visible)[0].minTxt + ' ~ ' + btnList.filter(v => v.visible)[visibleCnt - 1].maxTxt;
      }
    }

    let mainName = visibleCnt === 1 && btnList[0].visible ? '전체' : filterName; // 전체 일 경우 : null
    onChangeFilter({ filterType, min, max, mainName });
  }

  return (
    <div className="item">
      <strong className="tit">입주년차</strong>
      <div className="filter_box_wrap">
        {
          filterList.map((v, idx) =>
            <button type="button"
              className={`${v.visible ? "btn active" : "btn"} GTM-REC-APR-FILTER-${idx + 1}`}
              onClick={() => selectBtnList(idx)}>{v.name}
            </button>
          )
        }
      </div>
    </div>
  );


}

export default AprFilter;