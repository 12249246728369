import React from "react";

export const CouponInfo = () => {
    return (
            <dl className="coupon-info">
                <div className="coupon-info-item">
                    <dt>기간</dt>
                    <dd>
                        <p className="desc-01">쿠폰은 발급일로부터 30일간 사용가능</p>
                        <p className="desc-02">* 30일 이내 미발급 시 사용 불가</p>
                    </dd>
                </div>
                <div className="coupon-info-item">
                    <dt>혜택</dt>
                    <dd>
                        <p className="desc-01">선택업체의 매월 3개 쿠폰제공</p>
                    </dd>
                </div>
            </dl>
    );
}