import {useEffect, useState} from 'react'
import {getLsItem} from 'routes/common/LocalStorage'

//// 컴포넌트 사용처 : 청약 정보
const PyFilter = ({ onChangeFilter, refresh, load, checkLoad }) => {

  const filterType = "PY";

  const [filterList, setFilter] = useState([
    { name: '전체', visible: true, min: -1, max: 9999, minTxt: "전체", maxTxt: "전체" },
    { name: '10평 미만', visible: false, min: 0, max: 10, minTxt: "10평 미만", maxTxt: "10평 미만" },
    { name: '10평대', visible: false, min: 10, max: 20, minTxt: "10", maxTxt: "20평 미만" },
    { name: '20평대', visible: false, min: 20, max: 30, minTxt: "20", maxTxt: "30평 미만" },
    { name: '30평대', visible: false, min: 30, max: 40, minTxt: "30", maxTxt: "40평 미만" },
    { name: '40평대', visible: false, min: 40, max: 50, minTxt: "40", maxTxt: "50평 미만" },
    { name: '50평대', visible: false, min: 50, max: 60, minTxt: "50", maxTxt: "60평 미만" },
    { name: '60평대 이상', visible: false, min: 60, max: 9999, minTxt: "60평 이상", maxTxt: "60평 이상" },
  ]);

  useEffect(() => {
    if (refresh === true) {
      setFilter([
        { name: '전체', visible: true, min: -1, max: 9999, minTxt: "전체", maxTxt: "전체" },
        { name: '10평 미만', visible: false, min: 0, max: 10, minTxt: "10평 미만", maxTxt: "10평 미만" },
        { name: '10평대', visible: false, min: 10, max: 20, minTxt: "10", maxTxt: "20평 미만" },
        { name: '20평대', visible: false, min: 20, max: 30, minTxt: "20", maxTxt: "30평 미만" },
        { name: '30평대', visible: false, min: 30, max: 40, minTxt: "30", maxTxt: "40평 미만" },
        { name: '40평대', visible: false, min: 40, max: 50, minTxt: "40", maxTxt: "50평 미만" },
        { name: '50평대', visible: false, min: 50, max: 60, minTxt: "50", maxTxt: "60평 미만" },
        { name: '60평대 이상', visible: false, min: 60, max: 9999, minTxt: "60평 이상", maxTxt: "60평 이상" },
      ]);
    }
  }, [refresh]);

  useEffect(() => {
    if (load && checkLoad) {
      let applySrchFilter = getLsItem('applySrchFilter');
      let min = filterList.findIndex(v => v.min == applySrchFilter.py.min);
      let max = filterList.findIndex(v => v.max == applySrchFilter.py.max);
      setTimeout(() => {
        selectBtnList(min);
        selectBtnList(max);
      }, 100)
    }
  }, [load, checkLoad]);

  const selectBtnList = (idx) => {

    let current = filterList.slice();
    let prevIdx = filterList.findIndex(v => v.visible);

    let visibleCnt = current.filter(v => v.visible).length;
    current.filter((v, i) => i === idx).forEach(v => v.visible = true);

    if (idx === 0) {
      current.filter((v, i) => i !== 0).forEach(v => v.visible = false);
    } else if (prevIdx === 0) {
      current.filter((v, i) => i !== idx).forEach(v => v.visible = false);
    } else if (visibleCnt > 1) {
      current.filter((v, i) => i !== idx).forEach(v => v.visible = false);
    } else if (prevIdx < idx) {
      current.filter((v, i) => i >= prevIdx && i <= idx).forEach(v => v.visible = true);
    } else if (prevIdx >= idx) {
      current.filter((v, i) => i !== idx).forEach(v => v.visible = false);
    }

    // if (current.filter((v, i) => i !== 0).every(v => v.visible)) {
    //   current.filter((v, i) => i === 0).forEach(v => v.visible = true);
    //   current.filter((v, i) => i !== 0).forEach(v => v.visible = false);
    // }

    setFilter(current);
    handleChangeFilter(current);
  }

  const handleChangeFilter = (btnList) => {

    let min = btnList.filter(v => v.visible).map(v => v.min).reduce((prev, curr) => Math.min(prev, curr));
    let max = btnList.filter(v => v.visible).map(v => v.max).reduce((prev, curr) => Math.max(prev, curr));;

    let visibleCnt = btnList.filter(v => v.visible).length;
    let filterName;

    if (visibleCnt === 1) {
      if (btnList[1].visible) {
        filterName = `${max}평 미만`;
      } else if (btnList[btnList.length - 1].visible) {
        filterName = `${min}평 이상`;
      } else {
        filterName = btnList.filter(v => v.visible)[0].minTxt + ' ~ ' + btnList.filter(v => v.visible)[0].maxTxt;
      }
    } else {
      if (btnList[1].visible) {
        filterName = btnList.filter(v => v.visible)[visibleCnt - 1].maxTxt;
      } else {
        filterName = btnList.filter(v => v.visible)[0].minTxt + ' ~ ' + btnList.filter(v => v.visible)[visibleCnt - 1].maxTxt;
      }
    }

    let mainName = visibleCnt === 1 && btnList[0].visible ? '전체' : filterName; // 전체 일 경우 : null
    onChangeFilter({ filterType, min, max, mainName });
  }

  return (
    <div className="item">
      <div className="filter_box_wrap">
        {
          filterList.map((v, idx) =>
            <button type="button"
              className={`${v.visible ? "btn active" : "btn"} GTM-APPLY-FILTER-4-${idx + 1}`}
              onClick={() => selectBtnList(idx)}>{v.name}
            </button>
          )
        }
      </div>
    </div>
  );
}

export default PyFilter;