import React, {useEffect, useRef} from 'react';
import {useStateWithCallbackLazy} from 'use-state-with-callback';

import Swiper from 'swiper/bundle';

import {getLsItem} from 'routes/common/LocalStorage'

import axiosRequest from 'routes/common/axios/services/AxiosRequest';

const MyLocApplyList = (props) => {
  const [list, setList] = useStateWithCallbackLazy([]);

  const mapBottom = useRef(null);

  const loginHandler = () => {
    window.location.href = '/login';
  };

  useEffect(() => {
    if (props.loginFlag && props.locationFlag) {
      let aptLoc = getLsItem('aptLoc');

      let lat = aptLoc && aptLoc.lat ? aptLoc.lat : 37.56600;
      let lng = aptLoc && aptLoc.lng ? aptLoc.lng : 126.97696;

      const { kakao } = window;

      const mapContainer = document.getElementById('locview');  // 지도를 표시할 div 
      const mapOption = {
        // center: new kakao.maps.LatLng(37.48199028596600, 126.79406699851400),  // eslint-disable-line
        center: new kakao.maps.LatLng(lat, lng),  // eslint-disable-line
        level: 3
      };
    
      const map      = new kakao.maps.Map(mapContainer, mapOption);  // eslint-disable-line
      const geocoder = new kakao.maps.services.Geocoder();

      const latlng = map.getCenter();
      // const bounds = map.getBounds();
      // const sw     = bounds.getSouthWest();   // 영역의 남서 좌표
      // const ne     = bounds.getNorthEast();   // 영역의 북동 좌표
    
      let payload = {};

      geocoder.coord2RegionCode(latlng.getLng(), latlng.getLat(), async (result, status) => {
        if (status === kakao.maps.services.Status.OK) {
          payload = result.filter(data => data.region_type === 'B');

          if (payload.length) {
            payload = payload[0];

            console.log(payload);

            switch (payload.code.slice(0, 2)) {
              case '11':  payload['code'] = payload.code.slice(0, 2).padEnd(10, '0'); break;
              default :   payload['code'] = payload.code.slice(0, 4).padEnd(10, '0');
            }

            try {
              const { data } = await axiosRequest.findMyLocApplyList({ config: { params: payload } });

              if (data.response.applyList.length > 0) {
                setList(data.response.applyList.division(3));
      
                const map_bottom_swiper = mapBottom.current.querySelectorAll('.map_bottom_swiper');
              
                map_bottom_swiper.forEach(function (el) {
                  const swipers = new Swiper(el, {
                    spaceBetween: 8,
                    pagination: {
                      el: '.swiper-pagination'
                    },
                  });
                  
                  mapBottom.current.querySelectorAll('[data-tab-target]').forEach(function (el) {
                    el.addEventListener('tab.showing', function () {
                      swipers.update();
                    });
                  });
                });
              }
            } catch (err) {
              console.log(err);
            }
          } else {
            setList([]);
          }
        } else {
          setList([]);
        }
      });
      
      // const data = {
      //   'lat': (latlng.getLat() || 0.0),
      //   'lng': (latlng.getLng() || 0.0),
      //   'swLat': (sw.getLat() || 0.0),
      //   'swLng': (sw.getLng() || 0.0),
      //   'neLat': (ne.getLat() || 0.0),
      //   'neLng': (ne.getLng() || 0.0),
      //   'level': (map.getLevel() || 3),
      //   // 'myLoc': 'location',
      // };
    
      // const payload = {
      //   'lat': (data.lat || 0.0),
      //   'lng': (data.lng || 0.0),
      //   'swLat': (data.swLat || 0.0),
      //   'swLng': (data.swLng || 0.0),
      //   'neLat': (data.neLat || 0.0),
      //   'neLng': (data.neLng || 0.0),
      //   'level': (data.level || 3),
      //   'trxMin': (data.trxMin || -1),
      //   'trxMax': (data.trxMax || 9999),
      //   'pyMin': (data.pyMin || -1),
      //   'pyMax': (data.pyMax || 9999),
      //   'hhldMin': (data.hhldMin || -1),
      //   'hhldMax': (data.hhldMax || 9999),
      //   'aprMin': (data.aprMin || -1),
      //   'aprMax': (data.aprMax || 9999),
      //   'pkMin': (data.pkMin || -1),
      //   'pkMax': (data.pkMax || 9999),
      //   'trxType': (data.trxType || 'D'),
      //   'btType': (data.myLoc || ''),
      //   'os': ((osCheck() === 'Android') ? '1' : '2'),
      // };
      
      // const parameter = Object.entries(payload).map(e => e.join('=')).join('&');
    
      // axios.post('/apt/apply/findMyLocApplyList', JSON.stringify(payload), { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'), 'Content-Type': 'application/json;charset=UTF-8' } }).then(res => {
      //   setList(res.data.response.applyList.division(3));

      //   const map_bottom_swiper = mapBottom.current.querySelectorAll('.map_bottom_swiper');
      
      //   map_bottom_swiper.forEach(function (el) {
      //     const swipers = new Swiper(el, {
      //       spaceBetween: 8,
      //       pagination: {
      //         el: '.swiper-pagination'
      //       },
      //     });
          
      //     mapBottom.current.querySelectorAll('[data-tab-target]').forEach(function (el) {
      //       el.addEventListener('tab.showing', function () {
      //         swipers.update();
      //       });
      //     });
      //   });
      // });
    }
  }, [props.loginFlag, props.locationFlag]);

  return (
    <>
      {/* 내 위치와 가까운 청약 아파트 */}
      <section className="map_section" data-scroll-section="section3" ref={mapBottom}>
        <h3 className="content_title"><img src={process.env.REACT_APP_ENV_URL + "resources/images/icon_sub_location.png"} alt="청약" aria-hidden="true" />내 위치와 가까운 청약 아파트</h3>
        {
          props.loginFlag ?
          props.locationFlag ?
          list.length ? 
          <div className="swiper-container map_bottom_swiper">
            <ul className="swiper-wrapper">
              {list.map(dataDep1 => {
                return (
                  <li className="swiper-slide">
                    <ul className="card_list">
                      {dataDep1.map(dataDep2 => {
                        return (
                          <li onClick={() => props.openApplyDtl(dataDep2.applyId)}>
                            <strong>{dataDep2.aptName}</strong>
                            <ul className="text_pipe lg">
                              {/*<li className="co_blue fw_bold">{(dataDep2.distance / 1000) + 'km'}</li>*/}
                              <li>{dataDep2.bjdName}</li>
                            </ul>
                            {/* <Link to="/" className="link_ab"><span className="blind">@{dataDep2.aptName} 상세보기</span></Link> */}
                            {/*<a href="#/" target="_blank" rel="noreferrer" className="btn_location_kakao"><span className="blind">@{dataDep2.aptName}</span>카카오맵 길찾기</a>*/}
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                )
              })}
            </ul>
            <div className="swiper-pagination"></div>
          </div>
           :
          <div className="info_box">
            <p>고객님의 위치와 근접한<br />주택청약 공고가 없습니다.</p>
          </div>
           :
          <div className="info_box">
            <img src={process.env.REACT_APP_ENV_URL + "resources/images/icon_location_gray.png"} alt="위치정보" aria-hidden="true" />
            <p>청약 아파트 찾기 위해<br />고객님의 위치를 알려주세요.</p>
            {/* 2022.08.05 - GTM CLASS 추가 */}
            <button type="button" className="btn_round GTM-MYLOCATION-APPLY-BTN" onClick={() => props.getLocation()}>현재 위치 등록하기</button>
          </div>
           : 
          <div className="info_box">
            <p>로그인이 필요한 기능입니다.</p>
            <button type="button" className="btn_round" onClick={() => loginHandler()}>로그인</button>
          </div>
        }
        <div className="map_con" id="locview" style={{ "display": "none" }}></div>
      </section>
    </>
  );
}

export default MyLocApplyList;