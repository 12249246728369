import {useEffect, useState} from 'react'
import {getLsItem} from 'routes/common/LocalStorage'

//// 컴포넌트 사용처 : 아파트 정보
const PkFilter = ({ onChangeFilter, refresh, load, checkLoad }) => {

  const filterType = "PK";

  const [filterList, setFilter] = useState([
    { name: '전체', visible: true, min: -1, max: 9999, minTxt: "전체", maxTxt: "전체" },
    { name: '1.0 미만', visible: false, min: 0, max: 10, minTxt: "1.0대 미만", maxTxt: "1.0대 미만" },
    { name: '1.0 ~ 1.1', visible: false, min: 10, max: 11, minTxt: "1.0", maxTxt: "1.1대 미만" },
    { name: '1.1 ~ 1.2', visible: false, min: 11, max: 12, minTxt: "1.1", maxTxt: "1.2대 미만" },
    { name: '1.2 ~ 1.3', visible: false, min: 12, max: 13, minTxt: "1.2", maxTxt: "1.3대 미만" },
    { name: '1.3 ~ 1.4', visible: false, min: 13, max: 14, minTxt: "1.3", maxTxt: "1.4대 미만" },
    { name: '1.4 ~ 1.5', visible: false, min: 14, max: 15, minTxt: "1.4", maxTxt: "1.5대 미만" },
    { name: '1.5 ~ 2.0', visible: false, min: 15, max: 20, minTxt: "1.5", maxTxt: "2.0대 미만" },
    { name: '2.0 이상', visible: false, min: 20, max: 9999, minTxt: "2.0대 이상", maxTxt: "2.0대 이상" },
  ]);

  useEffect(() => {
    if (refresh === true) {
      setFilter([
        { name: '전체', visible: true, min: -1, max: 9999, minTxt: "전체", maxTxt: "전체" },
        { name: '1.0 미만', visible: false, min: 0, max: 10, minTxt: "1.0대 미만", maxTxt: "1.0대 미만" },
        { name: '1.0 ~ 1.1', visible: false, min: 10, max: 11, minTxt: "1.0", maxTxt: "1.1대 미만" },
        { name: '1.1 ~ 1.2', visible: false, min: 11, max: 12, minTxt: "1.1", maxTxt: "1.2대 미만" },
        { name: '1.2 ~ 1.3', visible: false, min: 12, max: 13, minTxt: "1.2", maxTxt: "1.3대 미만" },
        { name: '1.3 ~ 1.4', visible: false, min: 13, max: 14, minTxt: "1.3", maxTxt: "1.4대 미만" },
        { name: '1.4 ~ 1.5', visible: false, min: 14, max: 15, minTxt: "1.4", maxTxt: "1.5대 미만" },
        { name: '1.5 ~ 2.0', visible: false, min: 15, max: 20, minTxt: "1.5", maxTxt: "2.0대 미만" },
        { name: '2.0 이상', visible: false, min: 20, max: 9999, minTxt: "2.0대 이상", maxTxt: "2.0대 이상" },
      ]);
    }
  }, [refresh]);

  useEffect(() => {
    if (load && checkLoad) {
      let aptSrchFilter = getLsItem('aptSrchFilter');
      let min = filterList.findIndex(v => v.min == aptSrchFilter.pk.min);
      let max = filterList.findIndex(v => v.max == aptSrchFilter.pk.max);
      setTimeout(() => {
        selectBtnList(min);
        selectBtnList(max);
      }, 100)
    }
  }, [load, checkLoad]);

  const selectBtnList = (idx) => {

    let current = filterList.slice();
    let prevIdx = filterList.findIndex(v => v.visible);

    let visibleCnt = current.filter(v => v.visible).length;
    current.filter((v, i) => i === idx).forEach(v => v.visible = true);

    if (idx === 0) {
      current.filter((v, i) => i !== 0).forEach(v => v.visible = false);
    } else if (prevIdx === 0) {
      current.filter((v, i) => i !== idx).forEach(v => v.visible = false);
    } else if (visibleCnt > 1) {
      current.filter((v, i) => i !== idx).forEach(v => v.visible = false);
    } else if (prevIdx < idx) {
      current.filter((v, i) => i >= prevIdx && i <= idx).forEach(v => v.visible = true);
    } else if (prevIdx >= idx) {
      current.filter((v, i) => i !== idx).forEach(v => v.visible = false);
    }

    // if (current.filter((v, i) => i !== 0).every(v => v.visible)) {
    //   current.filter((v, i) => i === 0).forEach(v => v.visible = true);
    //   current.filter((v, i) => i !== 0).forEach(v => v.visible = false);
    // }

    setFilter(current);
    handleChangeFilter(current);
  }

  const handleChangeFilter = (btnList) => {

    let min = btnList.filter(v => v.visible).map(v => v.min).reduce((prev, curr) => Math.min(prev, curr));
    let max = btnList.filter(v => v.visible).map(v => v.max).reduce((prev, curr) => Math.max(prev, curr));;

    let visibleCnt = btnList.filter(v => v.visible).length;
    let filterName;

    if (visibleCnt === 1) {
      if (btnList[1].visible) {
        filterName = `${(max / 10).toFixed(1)}대 미만`;
      } else if (btnList[btnList.length - 1].visible) {
        filterName = `${(min / 10).toFixed(1)}대 이상`;
      } else {
        filterName = btnList.filter(v => v.visible)[0].minTxt + ' ~ ' + btnList.filter(v => v.visible)[0].maxTxt;
      }
    } else {
      if (btnList[1].visible) {
        filterName = btnList.filter(v => v.visible)[visibleCnt - 1].maxTxt;
      } else {
        filterName = btnList.filter(v => v.visible)[0].minTxt + ' ~ ' + btnList.filter(v => v.visible)[visibleCnt - 1].maxTxt;
      }
    }

    let mainName = visibleCnt === 1 && btnList[0].visible ? '전체' : filterName; // 전체 일 경우 : null
    onChangeFilter({ filterType, min, max, mainName });
  }

  return (
    <div className="item">
      <div className="filter_box_wrap">
        {
          filterList.map((v, idx) =>
            <button type="button"
              className={`${v.visible ? "btn active" : "btn"} GTM-APT-FILTER-6-${idx + 1}`}
              onClick={() => selectBtnList(idx)}>{v.name}
            </button>
          )
        }
      </div>
    </div>
  );
}

export default PkFilter;