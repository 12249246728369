import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'; // url 구분 작업
import {useStateWithCallbackLazy} from 'use-state-with-callback';

import {MyOverlay} from 'routes/overlay/MyOverlay';
import {AroundOverlay} from 'routes/overlay/AroundOverlay';

import SubFooter from 'routes/SubFooter';

import axios from 'axios';
import {orderBy} from 'lodash';
import $ from 'jquery';
import {
    dateFormatMsgKor,
    numberReplaceWithCodetag,
    numberReplaceWithStag,
    numberWithCommas,
    numberWithCounts
} from 'routes/common/StringUtil';
import {
    popupClose,
    popupOpen,
    uiAccoScrollFix,
    uiChartTap,
    uiFilterTabSwipe,
    uiFixValue,
    uiInputDel,
    uiIpFocus,
    uiMainSwitch,
    uiSelFocus,
    uiToggle,
    uiTopGo
} from 'routes/common/Design.js'

import {getLcParamItem, getLcUrlItem, setLcParamItem, setLcUrlItem} from 'routes/common/ParamStorage';

import RMateCandleStickType from 'routes/common/rMateChart/RMateCandleStickType';
import RMateHalfDonutType from 'routes/common/rMateChart/RMateHalfDonutType';

import {addFavItem, clearFavItem, getFavList} from 'routes/common/AptFavUtil';

let map = {};
let overlayList = [];
let aroundOverlayList = [];
//let trxType = 'D';

//// 컴포넌트 사용처 : 아파트 추천
const RcmdDtl = (props) => {
  const [trxType, setTrxType] = useStateWithCallbackLazy('D');

  const [aptDtl, setAptDtl] = useState([]);
  const [aptArea, setAptArea] = useState([]);
  const [aptAreaId, setAptAreaId] = useState('');
  const [rcmdPayGraphList, setRcmdPayGraphList] = useState([]);

  const [graphList, setGraphList] = useState([]);

  const [aroundLocInfoMap, setAroundLocInfoMap] = useState([]);

  const [onRenderGraph, setOnRenderGraph] = useState(false);

  const [yearPay, setYearPay] = useState(0);
  const [dpsAmt, setDpsAmt] = useState(0);
  const [assets, setAssets] = useState(0);
  const [realLoanAmount, setRealLoanAmount] = useState(0);

  const lcUrlMap = getLcUrlItem();
  const lcParamMap = getLcParamItem();

  // url 구분 작업
  const history = useHistory();
  const location = useLocation();


  useEffect(() => {
    popupOpen('aptdetail', 'mwf');
    uiFixValue.init();
    uiFilterTabSwipe.init();
    uiSelFocus.init();
    uiMainSwitch.init();
    uiChartTap.init();
    uiToggle.init();
    uiTopGo.init();

    uiIpFocus.init();
    uiInputDel.init();

    $(window).scrollTop(0);

    //uiAccoScrollFix.init();

    window.goback = backToRcmdList;
    window.checkMainPage = () => { return false; }

    return () => {
      window.checkMainPage = () => { return true; }
    };
  }, []);

  useEffect(() => {
    //props.onPreventDtl(false);
    setAptDtl([]);
    clearOverlay();
    clearAroundOverlay();

    let lat = 37.57100;
    let lng = 126.97696;

    let mapContainer = document.getElementById('mapviewDtl'); // 지도를 표시할 div 

    let mapOption = {
      center: new kakao.maps.LatLng(lat, lng), // eslint-disable-line
      level: 5
    };

    map = new kakao.maps.Map(mapContainer, mapOption);  // eslint-disable-line
    map.setMinLevel(2);
    map.setMaxLevel(11);

    kakao.maps.event.addListener(map, 'dragend', () => { }); // eslint-disable-line
    kakao.maps.event.addListener(map, 'zoom_changed', () => { });  // eslint-disable-line

    //if (aptDtl != null && aptDtl.applyId != '') {

    //map.relayout();
    //onPanTo(aptDtl.lat, aptDtl.lng);
    //}

    setTimeout(() => {
      fetchAptDtl(lcParamMap.aptId);
    }, 100);

  }, [lcParamMap.aptId]);


  const fetchAptDtl = (paramAptId) => {
    setOnRenderGraph(false);
    const param1 = lcParamMap.rcmdParams.param1;
    const param2 = lcParamMap.rcmdParams.param2;
    const param3Min = lcParamMap.rcmdParams.param3Min;
    const param3Max = lcParamMap.rcmdParams.param3Max;
    const param4Min = lcParamMap.rcmdParams.param4Min;
    const param4Max = lcParamMap.rcmdParams.param4Max;
    const param5 = lcParamMap.rcmdParams.param5;
    const param6 = lcParamMap.rcmdParams.param6;
    const param7 = lcParamMap.rcmdParams.param7;

    const promise = axios
      .get(`apt/rcmd/mblAptDtl?aptId=${paramAptId}&param1=${param1}&param3Min=${param3Min}&param3Max=${param3Max}&param5=${param5}&param6=${param6}&param7=${param7}`)
      .then(res => {
        setAptDtl(res.data.response.aptDtl);
        setOnRenderGraph(true);
        setYearPay((lcParamMap.rcmdParams.param5 * 10000));
        setAssets((lcParamMap.rcmdParams.param6 * 10000));
        setDpsAmt((res.data.response.aptDtl.latestDpsAmt * 10000));
        //realLoanAmount: (res.data.response.aptDtl.loanMap.ltvtype1.totalLoanAmount),
        setRealLoanAmount(
          ((res.data.response.aptDtl.latestDpsAmt * 10000) <= ((lcParamMap.rcmdParams.param6 * 10000) + res.data.response.aptDtl.loanMap.ltvtype1.totalLoanAmount) ?
            (((res.data.response.aptDtl.latestDpsAmt * 10000) - (lcParamMap.rcmdParams.param6 * 10000)) <= 0 ? 0 : ((res.data.response.aptDtl.latestDpsAmt * 10000) - (lcParamMap.rcmdParams.param6 * 10000))) :
            res.data.response.aptDtl.loanMap.ltvtype1.totalLoanAmount));
        setRcmdPayGraphList([
          { "field": "보유자산금액", "amt": (lcParamMap.rcmdParams.param6 * 10000) },
          {
            "field": "대출필요금액", "amt": ((res.data.response.aptDtl.latestDpsAmt * 10000) <= ((lcParamMap.rcmdParams.param6 * 10000) + res.data.response.aptDtl.loanMap.ltvtype1.totalLoanAmount) ?
              (((res.data.response.aptDtl.latestDpsAmt * 10000) - (lcParamMap.rcmdParams.param6 * 10000)) <= 0 ? 0 : ((res.data.response.aptDtl.latestDpsAmt * 10000) - (lcParamMap.rcmdParams.param6 * 10000))) :
              res.data.response.aptDtl.loanMap.ltvtype1.totalLoanAmount)
          }]);


        fetchAptAreaDtl(res.data.response.aptDtl.aptId, lcParamMap.aptAreaId, trxType);
        //fetchAptAreaDtl(res.data.response.aptDtl.aptId, res.data.response.aptDtl.aptAreaId, trxType);
        setTimeout(() => {
          renderMyMarker(res.data.response.aptDtl);
          onPanTo(res.data.response.aptDtl.lat, res.data.response.aptDtl.lng);
          fetchAround(res.data.response.aptDtl.aptId, res.data.response.aptDtl.lat, res.data.response.aptDtl.lng);
        }, 400);

        uiAccoScrollFix.init();
      });

    promise.then((result) => {
      bindSelect();
    });
  }

  const clearOverlay = () => {
    overlayList.forEach(ov => ov.setMap(null));
    overlayList = [];
  }

  const clearAroundOverlay = () => {
    aroundOverlayList.forEach(ov => ov.setMap(null));
    aroundOverlayList = [];
  }

  const renderMyMarker = (data) => {
    overlayList.forEach(ov => ov.setMap(null));
    overlayList = [];

    let overlay = new MyOverlay(data);
    overlay.setMap(map);
    overlayList.push(overlay);
  }

  const renderingAroundDataIcons = (data) => {
    aroundOverlayList.forEach(ov => ov.setMap(null));
    aroundOverlayList = [];

    const dtl = data;

    dtl.schoolList.forEach(v => {
      v.type = 'school';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.departList.forEach(v => {
      v.type = 'ico_mart';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.stationList.forEach(v => {
      v.type = 'ico_subway';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.hospitalList.forEach(v => {
      v.type = 'ico_hospital';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.parkList.forEach(v => {
      v.type = 'ico_park02';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });
  }

  const onPanTo = (paramLat, paramLng) => {
    let moveLatLng = new kakao.maps.LatLng(paramLat, paramLng); // eslint-disable-line
    map.panTo(moveLatLng);
  }

  const moreView = (e) => {
    $(e.target).parent().toggleClass('show');
    $(e.target).parent().find('li').eq(2).nextAll().toggleClass('hide');
  }

  const recentPy = () => {

    if (!aptDtl.aptAreaList || !aptArea) {
      return <></>;
    }
    const aptArea = aptDtl.aptAreaList.find(v => Number(v.aptAreaId) === aptArea.aptAreaId);
    return aptArea ? <>{aptArea.pubAreaPy}평 ({aptArea.pubArea}㎡)</> : <></>;
  }

  const fetchAptAreaDtl = (aptId, aptAreaId, trxType, callback) => {
    setOnRenderGraph(false);
    axios
      .get(`/apt/map/aptAreaDtl?aptId=${aptId}&aptAreaId=${aptAreaId}&trxType=${trxType}`)
      .then(res => {
        setAptArea(res.data.response);
        setAptAreaId(aptAreaId);
        //fetchGraph('tab1');
        axios
          .get(`apt/map/aptDtlGraphList?aptAreaId=${aptAreaId}&trxType=${trxType}&graphTab=tab1`)
          .then(
            (res) => {
              //this.setState({ graphList: res.data.response.graphDataList });
              //setGraphList(res.data.response.graphDataList);
              //graphList = res.data.response.graphDataList;
              setGraphList(res.data.response.graphDataList);
              setOnRenderGraph(true);
            }
          );
        $('ul.chart_tit').find('li').removeClass('current');
        $('ul.chart_tit').find('li').eq(0).addClass('current');
        $('.tab_cont').addClass('current');
      });

  }

  const fetchGraph = (dataTab) => {
    setOnRenderGraph(false);
    axios
      .get(`apt/map/aptDtlGraphList?aptAreaId=${aptAreaId === '' ? lcParamMap.aptAreaId : aptAreaId}&trxType=${trxType}&graphTab=${dataTab}`)
      .then(
        (res) => {
          //this.setState({ graphList: res.data.response.graphDataList });
          //setGraphList(res.data.response.graphDataList);
          //graphList = res.data.response.graphDataList;
          setGraphList(res.data.response.graphDataList);
          setOnRenderGraph(true);
        }
      );
    $(".chart_list .tab_inner").scrollLeft(0);
    $('.tab_cont').addClass('current');
  }

  const fetchAround = (aptId, lat, lng) => {
    axios
      .get(`apt/map/aroundLocInfoList?aptId=${aptId}&lat=${lat}&lng=${lng}`)
      .then(
        (res) => {
          setAroundLocInfoMap(res.data.response.aroundLocInfoMap);
          renderingAroundDataIcons(res.data.response.aroundLocInfoMap);
        }
      );
  }

  const onChangeArea = (aptAreaId) => {
    setOnRenderGraph(false);
    setAptAreaId(aptAreaId);
    fetchAptAreaDtl(lcParamMap.aptId, aptAreaId, trxType);
  }

  const onChangeAreaTrxType = (trxType) => {
    setOnRenderGraph(false);
    setTrxType(trxType);
    fetchAptAreaDtl(lcParamMap.aptId, aptAreaId, trxType);
  }

  const percenTageFix = (value) => {
    if (value >= 100) {
      return 100;
    }
    else if (value <= 0) {
      return 0;
    }
    else {
      return value;
    }
  }

  const bindSelect = () => {

    $(".btn_sel_wrap").each(function (idx) {
      $(this).data("key", idx + 1);
      // btm select popup 호출
      $(this).find(".btn_sel").on("click", function () {
        var key = $(this).parents(".btn_sel_wrap").data("key");
        popupOpen('select0' + key, 'mwf', 0);
      });
    });

    $(".ly_select_wrap").each(function (idx) {
      $(this).data("ctrlKey", idx + 1);
      // btm select 값 선택// btm select 값 선택
      $(this).find(".pop_sel_wrap .list input").on("change", function () {
        var ctrlKey = $(this).parents(".ly_select_wrap").data("ctrlKey");
        var selTxt = $(this).parents(".item").find(".txt").text();

        $(".btn_sel_wrap").eq(ctrlKey - 1).removeClass("focus").find(".lbl").addClass("hide");
        $(".btn_sel_wrap").eq(ctrlKey - 1).find(".sel_txt").text(selTxt);

        //select value 찾도록 추가
        var selAreaId = $(this).parents(".item").find(".txt").attr('data-value');
        onChangeArea(selAreaId);

        popupClose('select0' + ctrlKey, 'mwf', 0);
      });
    });

    //화면진입시 지도에서 선택되어 들어온 평이 선택되어있도록함
    $('#mw-select01 span').each(function () {
      if ($(this).attr('data-value') == lcParamMap.aptAreaId) {
        $(this).parents(".item").find('input[type=radio]').prop('checked', true);
        $('.btn_sel_wrap .sel_txt').text($(this).text());
      }
    });
  }

  const handleFavCheck = (e) => {
    if ($(e.target).hasClass('active')) {
      $(e.target).removeClass('active');
      clearFavItem({ aptId: lcParamMap.aptId })
    }
    else {
      $(e.target).addClass('active');
      addFavItem({ aptId: lcParamMap.aptId, aptAreaId: lcParamMap.aptAreaId, favTime: new Date() });
    }
  }


  //--'open' : 현 화면이 다음 화면으로 변경되는 action
  const openRcmdDtlMap = () => {
    setLcUrlItem('rcmdDtl', 'rcmdDtlMap');
    setLcParamItem({ 'rcmdParams': lcParamMap.rcmdParams, 'aptId': lcParamMap.aptId, 'aptAreaId': lcParamMap.aptAreaId, 'preventDtl': true, 'aptDtl': aptDtl });
    props.onRcmdPopupType('P_C');
  }

  const openRealtyCalc = () => {
    setLcUrlItem('rcmdDtl', 'realtyCalc');
    popupClose('aptdetail', 'mwf');

    props.onRealtyMasterDisplay(true);
    props.onRealtyMenuType('A');
  }

  const openApplyCalc = () => {
    setLcUrlItem('rcmdDtl', 'applyCalc');
    popupClose('aptdetail', 'mwf');

    $('.map_wrap').hide();
    props.onApplyListDisplay(true);
    props.onApplyMenuType('C');
  }


  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  const backToRcmdList = () => {
    setLcUrlItem('rcmdDtl', 'rcmdList');
    popupClose('aptdetail', 'mwf');
    props.onRcmdPopupType('P_A');

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?REC-1200#`
    });
  }

  const backToRcmdSrch = () => {
    setLcUrlItem('rcmdDtl', 'rcmdSrch');
    popupClose('aptdetail', 'mwf');
    props.onRcmdPopupType('');
  }

  const backToAptMain = () => {
    setLcUrlItem('rcmdDtl', 'aptMain');
    popupClose('map', 'mwf');
    props.onRcmdPopupType('');
    setLcParamItem({});
    $('.map_wrap').show();
    props.onRcmdSrchDisplay(false);

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: ``
    });
  }

  return (
    <>
      <div id="mw-aptdetail" className="ly_pop ly_apt_detail full">
        <div className="wrap">
          <span class="bg_body_full"></span>
          <div className="content">
            <header className="header">
              <button type="button" onClick={() => backToAptMain()} className="btn_home" title="홈으로 바로가기"><i></i></button>
              <button type="button" onClick={() => backToRcmdList()} className="btn_prev GTM-REC-PREV-3" title="뒤로가기"><i></i></button>
              <strong className="tit"></strong>
              <button type="button" onClick={() => openRcmdDtlMap()} className="btn_location" title="지도보기"><i></i></button>
            </header>
            <section className="tit_wrap">
              <button type="button" className={getFavList().some(v => v.aptId === props.aptId) ? "tit active" : "tit"}
                //onclick="$(this).toggleClass('active')" 
                onClick={handleFavCheck}
              >{aptDtl.aptName}</button>
              <span className="info_txt">{aptDtl.jibunAddr}</span>
              <ul className="info_list">
                <li>{aptDtl.regYear}년</li>
                <li>{numberWithCounts(aptDtl.dpsAmt * 10000)}</li>
                <li>{numberWithCommas(aptDtl.hhldCnt)}세대</li>
                {
                  (aptDtl) &&
                    (aptDtl.loanRateCase == 1 || aptDtl.loanRateCase == 2) ?
                    <li><span>투기 과열</span></li>
                    :
                    (aptDtl.loanRateCase == 3 || aptDtl.loanRateCase == 4) ?
                      <li><span>조정 지역</span></li>
                      :
                      null
                }
              </ul>
            </section>
            <div className="acco_wrap">
              <section className="item">
                <button type="button" className="btn_acco">아파트 추천 정보</button>
                <div className="acco_view">
                  <section className="apt_d_info">
                    <div className="col_wrap">
                      <div className="col">
                        <div className="tit_wrap">
                          {
                            aptDtl.latestDpsAmt > 0 ?
                              <>
                                <span className="sub_txt">최근 거래 금액</span>
                                <strong className="tit">
                                  <span>{numberReplaceWithCodetag(numberWithCounts(aptDtl.latestDpsAmt * 10000))}</span>
                                  {/* {
                              aptArea.prevDpsAmt === null ? null :
                                aptArea.dpsAmt > aptArea.prevDpsAmt ?
                                  <><em className="up">({numberWithCounts((aptArea.dpsAmt - aptArea.prevDpsAmt) * 10000)}<i></i>)</em></> :
                                  aptArea.dpsAmt < aptArea.prevDpsAmt ?
                                    <><em className="dw">({numberWithCounts((aptArea.prevDpsAmt - aptArea.dpsAmt) * 10000)}<i></i>)</em></> :
                                    null
                            } */}
                                </strong>
                              </>
                              : <p className="nodata">최근 거래 내역 없음</p>
                          }
                        </div>
                        {
                          aptDtl.latestDpsAmt > 0 ?
                            <span className="sub_txt">최근 거래일 : {dateFormatMsgKor(aptDtl.latestTrxDate)}</span>
                            : null
                        }
                      </div>
                      <div className="col">
                        <button type="button" className="btn GTM-REC-8" onClick={() => openRealtyCalc()}>예상 세금 계산하기</button>
                      </div>
                    </div>
                    <div className="chart_loan">
                      <div className="label" style={{ "z-index": "1" }}>
                        <img src={process.env.REACT_APP_ENV_URL + "resources/images/chart_loan_label.png"} alt="임시 이미지" />
                      </div>
                      <div className="chart_info">
                        <div className="chart_area">
                          <div id="rMateDivHalfDonut" style={{ "height": "127px" }}>
                            {
                              onRenderGraph &&
                              <RMateHalfDonutType
                                parentDivId={'rMateDivHalfDonut'}
                                graphList={rcmdPayGraphList}
                              ></RMateHalfDonutType>
                            }
                          </div>
                          <div className="total_price">
                            <span>{<><s>{numberWithCounts(dpsAmt)}</s></>}</span>
                            <p>최근 거래 금액</p>
                          </div>
                        </div>
                        <div className="chart_label">
                          <div className="percent">
                            <span style={{ "z-index": "1" }}>{<><s>{percenTageFix(parseInt(Math.round((assets / dpsAmt) * 100)))}%</s></>}</span>
                            <span style={{ "z-index": "1" }}>{<><s>{percenTageFix(parseInt(Math.round((realLoanAmount / dpsAmt) * 100)))}%</s></>}</span>
                          </div>
                          <div className="price">
                            <span style={{ "z-index": "1" }}>{<><s>{numberWithCounts(assets)}</s></>}</span>
                            <span style={{ "z-index": "1" }}>{<><s>{numberWithCounts(realLoanAmount)}</s></>}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* {
                      aptDtl.loanMap &&
                      <HalfDonut
                        yearPay={(lcParamMap.rcmdParams.param5 * 10000)} //연소득
                        assets={(lcParamMap.rcmdParams.param6 * 10000)} //현재자산
                        totalAssets={(lcParamMap.rcmdParams.param5 * 10000 + lcParamMap.rcmdParams.param6 * 10000)} //총자산(연소득+현재자산))
                        dpsAmt={(aptDtl.latestDpsAmt * 10000)} //아파트가격
                        totalLoanAmount={(aptDtl.loanMap.ltvtype1.totalLoanAmount)} //총대출액(max)
                      ></HalfDonut>
                    } */}
                    <div className="apt_bbs_info chart_info02">
                      <ul className="info">
                        <li>
                          <strong className="th">연 소득액</strong>
                          <div className="td">
                            <span className="sub_txt">{numberReplaceWithStag(numberWithCounts(lcParamMap.rcmdParams.param5 * 10000))}</span>
                          </div>
                        </li>
                        {
                          aptDtl.loanMap != null ?
                            aptDtl.loanMap && aptDtl.loanMap.ltvtype1.yearRepayment > 0 && aptDtl.loanMap.ltvtype1.monthlyRepayment > 0 &&
                            <>
                              <li>
                                <strong className="th">상환액</strong>
                                <div className="td">
                                  <ul>
                                    <li>
                                      <em className="bu">년 단위</em>
                                      <span className="sub_txt">{numberWithCounts(aptDtl.loanMap.ltvtype1.yearRepayment)}</span>
                                    </li>
                                    <li>
                                      <em className="bu">월 단위</em>
                                      <span className="sub_txt">{numberWithCounts(aptDtl.loanMap.ltvtype1.monthlyRepayment)}</span>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </>
                            :
                            null
                        }
                        {
                          aptDtl.loanMap != null ?
                            aptDtl.loanMap && aptDtl.loanMap.ltvtype1.realDti != '-' &&
                            <>

                              <li>
                                <strong className="th">연 대출액 (소득액 대비)</strong>
                                <div className="td">
                                  <span className="sub_txt">{aptDtl.loanMap.ltvtype1.realDti}</span>
                                </div>
                              </li>
                            </>
                            :
                            null
                        }
                      </ul>
                      {
                        aptDtl.loanMap != null ?
                          aptDtl.loanMap && aptDtl.loanMap.ltvtype1.totalLoanAmount > 0 &&
                          <>
                            <ul className="info result">
                              <li>
                                <strong className="th">LTV 기준 총 대출가능액</strong>
                                <div className="td">
                                  <span className="sub_txt">{numberWithCounts(aptDtl.loanMap.ltvtype1.totalLoanAmount)}</span>
                                </div>
                              </li>
                            </ul>
                          </>
                          :
                          null
                      }
                    </div>
                  </section>
                </div>
              </section>
              <section className="item">
                <button type="button" className="btn_acco">최근 실거래가 매매 정보</button>
                <div className="acco_view">
                  <section className="apt_d_info">
                    <div className="col_wrap">
                      <div className="col">
                        <div className="tit_wrap">
                          {
                            aptArea.dpsAmt && aptArea.dpsAmt != '-' ?
                              <>
                                <span className="sub_txt">최근 거래 금액</span>
                                <strong className="tit">
                                  <span>{numberReplaceWithCodetag(numberWithCounts(aptArea.dpsAmt * 10000))}</span>
                                  {
                                    aptArea.prevDpsAmt === null ? null :
                                      (aptArea.dpsAmt * 10000) > (aptArea.prevDpsAmt * 10000) ?
                                        <><em className="up">({numberWithCounts((aptArea.dpsAmt * 10000) - (aptArea.prevDpsAmt * 10000))}<i></i>)</em></> :
                                        (aptArea.dpsAmt * 10000) < (aptArea.prevDpsAmt * 10000) ?
                                          <><em className="dw">({numberWithCounts((aptArea.prevDpsAmt * 10000) - (aptArea.dpsAmt * 10000))}<i></i>)</em></> :
                                          null
                                  }
                                </strong>
                              </>
                              : <p className="nodata">최근 거래 내역 없음</p>
                          }
                        </div>
                        {
                          aptArea.dpsAmt > 0 ?
                            <span className="sub_txt">최근 거래일 : {dateFormatMsgKor(aptArea.trxDate)}</span>
                            : null
                        }
                      </div>
                      <div className="col">
                        <div className="form">
                          <div className="ip_box in btn_sel_wrap">
                            <div className="inner_wrap">
                              <button type="button" className="btn_sel GTM-REC-9">
                                <span className="sel_txt"></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="chart_list">
                      <ul className="chart_tit">
                        <li data-tab="tab1" className="current"><a href="#" className="GTM-REC-10" onClick={(e) => { e.preventDefault(); fetchGraph('tab1') }}>전체 기간</a></li>
                        <li data-tab="tab2"><a href="#" className="GTM-REC-11" onClick={(e) => { e.preventDefault(); fetchGraph('tab2') }}>최근 1년</a></li>
                        <li data-tab="tab3"><a href="#" className="GTM-REC-12" onClick={(e) => { e.preventDefault(); fetchGraph('tab3') }}>최근 3년</a></li>
                      </ul>
                      <div className="tab1 tab_cont current">
                        <div id="rMateDivAptRcmdDtl" style={{ "height": "300px" }} className={!graphList || graphList.length <= 0 ? 'tab_inner dim' : ''}>
                          {
                            onRenderGraph &&
                            <RMateCandleStickType
                              parentDivId={'rMateDivAptRcmdDtl'}
                              graphList={graphList}
                            ></RMateCandleStickType>
                          }
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
              <section className="item">
                <button type="button" className="btn_acco">단지정보</button>
                <div className="acco_view">
                  <ul className="apt_bbs_info">
                    <li>
                      <strong className="th">아파트명</strong>
                      <div className="td">
                        <span className="sub_txt">{aptDtl.aptName}</span>
                      </div>
                    </li>
                    <li>
                      <strong className="th">주소</strong>
                      <div className="td">
                        <span className="sub_txt">{aptDtl.jibunAddr}</span>
                      </div>
                    </li>
                    <li>
                      <strong className="th">건축년도</strong>
                      <div className="td">
                        <span className="sub_txt">{aptDtl.regYear}년</span>
                      </div>
                    </li>
                    <li>
                      <strong className="th">세대수</strong>
                      <div className="td">
                        <span className="sub_txt">{numberWithCommas(aptDtl.hhldCnt)}세대</span>
                      </div>
                    </li>
                    <li>
                      <strong className="th">층수</strong>
                      <div className="td">
                        <span className="sub_txt">{aptDtl.minFlrCnt} ~ {aptDtl.maxFlrCnt}층</span>
                      </div>
                    </li>
                    <li>
                      <strong className="th">주차대수</strong>
                      <div className="td">
                        <span className="sub_txt">총 {numberWithCommas(aptDtl.pkngCnt)}대</span>
                      </div>
                    </li>
                    <li>
                      <strong className="th">용적률</strong>
                      <div className="td">
                        <span className="sub_txt">{aptDtl.vlRate}%</span>
                      </div>
                    </li>
                    <li>
                      <strong className="th">건폐율</strong>
                      <div className="td">
                        <span className="sub_txt">{aptDtl.bcRate}%</span>
                      </div>
                    </li>
                  </ul>
                  {/* <div className="btn_wrap">
                    <button type="button" className="btn" onClick={() => openApplyPay()}>단계별 필요 금액</button>
                    <button type="button" className="btn" onClick={() => openApplyCalc()}>청약가점 확인하기</button>
                  </div> */}
                </div>
              </section>
              <section className="item">
                <button type="button" className="btn_acco">주변정보</button>
                <div className="acco_view">
                  <div className="map_sec" id="mapviewDtl">
                    {/* <img src="resources/images/temp/map.png" alt="" /> */}
                  </div>
                  <ul className="amenities">
                    {
                      (aroundLocInfoMap.stationList != null && aroundLocInfoMap.stationList.length > 0) ?
                        aroundLocInfoMap.stationList &&
                        <li className={aroundLocInfoMap.stationList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="th icon_subway">지하철역</strong>
                          <div className="td sub_list">
                            <ul>
                              {
                                orderBy(aroundLocInfoMap.stationList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (    
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <strong className="sub_th"><span className="tit" onClick={() => onPanTo(result.lat, result.lng)}>{result.locName}</span>({numberWithCommas(result.distance)}m)</strong>
                                      <span className="sub_td">도보 {parseInt(result.distance / 66, 10)} 분</span>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                          </div>
                          {aroundLocInfoMap.stationList.length > 3 && <button type="button" className="btn_more" onClick={(e) => moreView(e)}></button>
                          }
                        </li>
                        :
                        null
                    }
                    {
                      (aroundLocInfoMap.schoolList != null && aroundLocInfoMap.schoolList.length > 0) ?
                        aroundLocInfoMap.schoolList &&
                        <li className={aroundLocInfoMap.schoolList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="th icon_school">학교</strong>
                          <div className="td sub_list">
                            <ul>
                              {
                                orderBy(aroundLocInfoMap.schoolList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (    
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <strong className="sub_th"><span className="tit" onClick={() => onPanTo(result.lat, result.lng)}>{result.locName}</span>({numberWithCommas(result.distance)}m)</strong>
                                      <span className="sub_td">도보 {parseInt(result.distance / 66, 10)} 분</span>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                          </div>
                          {aroundLocInfoMap.schoolList.length > 3 && <button type="button" className="btn_more" onClick={(e) => moreView(e)}></button>
                          }
                        </li>
                        :
                        null
                    }
                    {
                      (aroundLocInfoMap.departList != null && aroundLocInfoMap.departList.length > 0) ?
                        aroundLocInfoMap.departList &&
                        <li className={aroundLocInfoMap.departList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="th icon_mart">마트·백화점</strong>
                          <div className="td sub_list">
                            <ul>
                              {
                                orderBy(aroundLocInfoMap.departList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (    
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <strong className="sub_th"><span className="tit" onClick={() => onPanTo(result.lat, result.lng)}>{result.locName}</span>({numberWithCommas(result.distance)}m)</strong>
                                      <span className="sub_td">도보 {parseInt(result.distance / 66, 10)} 분</span>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                          </div>
                          {aroundLocInfoMap.departList.length > 3 && <button type="button" className="btn_more" onClick={(e) => moreView(e)}></button>
                          }
                        </li>
                        :
                        null
                    }
                    {
                      (aroundLocInfoMap.hospitalList != null && aroundLocInfoMap.hospitalList.length > 0) ?
                        aroundLocInfoMap.hospitalList &&
                        <li className={aroundLocInfoMap.hospitalList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="th icon_hospital">병원</strong>
                          <div className="td sub_list">
                            <ul>
                              {
                                orderBy(aroundLocInfoMap.hospitalList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (    
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <strong className="sub_th"><span className="tit" onClick={() => onPanTo(result.lat, result.lng)}>{result.locName}</span>({numberWithCommas(result.distance)}m)</strong>
                                      <span className="sub_td">도보 {parseInt(result.distance / 66, 10)} 분</span>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                          </div>
                          {aroundLocInfoMap.hospitalList.length > 3 && <button type="button" className="btn_more" onClick={(e) => moreView(e)}></button>
                          }
                        </li>
                        :
                        null
                    }
                    {
                      (aroundLocInfoMap.parkList != null && aroundLocInfoMap.parkList.length > 0) ?
                        aroundLocInfoMap.parkList &&
                        <li className={aroundLocInfoMap.parkList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="th icon_park">공원</strong>
                          <div className="td sub_list">
                            <ul>
                              {
                                orderBy(aroundLocInfoMap.parkList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (    
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <strong className="sub_th"><span className="tit" onClick={() => onPanTo(result.lat, result.lng)}>{result.locName}</span>({numberWithCommas(result.distance)}m)</strong>
                                      <span className="sub_td">도보 {parseInt(result.distance / 66, 10)} 분</span>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                          </div>
                          {aroundLocInfoMap.parkList.length > 3 && <button type="button" className="btn_more" onClick={(e) => moreView(e)}></button>
                          }
                        </li>
                        :
                        null
                    }
                  </ul>
                </div>
              </section>
            </div>
            <SubFooter></SubFooter>
          </div>
        </div>
      </div>

      <div id="mw-select01" className="ly_pop popBtm ly_select_wrap">
        <div className="wrap">
          <button type="button" onClick={() => popupClose('select01', 'mwf', 0)} className="close"><i>닫기</i></button>
          <div className="content pop_sel_wrap">
            <ul className="list">
              {
                aptDtl.aptAreaList &&
                aptDtl.aptAreaList.map((result, idx) => {
                  const radioId = `select01_${idx}`
                  return (
                    <li>
                      <span className="item">
                        <input type="radio" id={radioId} name="select01" />
                        <label for={radioId}>
                          <span className="txt" data-value={result.aptAreaId}>{result.pubAreaPy}평 ({result.pubArea}㎡)</span>
                        </label>
                      </span>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default RcmdDtl;