
//// 컴포넌트 사용처 : 아파트 정보
const MainFilter = ({ filterName }) => {

  // console.log(filterName);

  return (
    <div className="tab">
      <ul className="inner">
        <li className="slide"><a href="#" className={`${filterName.trxType ? "change_txt" : ""} GTM-APT-FILTER-1`} data-name="거래유형">{filterName.trxType ? filterName.trxType : '거래유형'}</a></li>
        <li className="slide"><a href="#" className={`${filterName.trxAmt ? "change_txt" : ""} GTM-APT-FILTER-2`} data-name="실거래가">{filterName.trxAmt ? filterName.trxAmt : '실거래가'}</a></li>
        <li className="slide"><a href="#" className={`${filterName.py ? "change_txt" : ""} GTM-APT-FILTER-3`} data-name="평형">{filterName.py ? filterName.py : '평형'}</a></li>
        <li className="slide"><a href="#" className={`${filterName.hhld ? "change_txt" : ""} GTM-APT-FILTER-4`} data-name="세대수">{filterName.hhld ? filterName.hhld : '세대수'}</a></li>
        <li className="slide"><a href="#" className={`${filterName.apr ? "change_txt" : ""} GTM-APT-FILTER-5`} data-name="입주년차">{filterName.apr ? filterName.apr : '입주년차'}</a></li>
        <li className="slide"><a href="#" className={`${filterName.pk ? "change_txt" : ""} GTM-APT-FILTER-6`} data-name="주차대수">{filterName.pk ? filterName.pk : '주차대수'}</a></li>
      </ul>
    </div>
  );
}

export default MainFilter;