import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ApplyList from 'routes/apply/ApplyList';
import AptMain from 'routes/apt/AptMain';
import AptMain2 from 'routes/apt/AptMain2';

import AppInfo from 'routes/private/AppInfo';

import Notice from 'routes/private/Notice';
import Faq from 'routes/private/Faq';
import Qna from 'routes/private/Qna';
import Join from 'routes/private/Join';
import Expire from 'routes/private/Expire';
import ExpireType2 from 'routes/private/ExpireType2';
import Login from 'routes/private/Login';
import Logout from 'routes/private/Logout';

import axios from 'axios';
import ErrorBoundary from 'routes/error/ErrorBoundary';

import InterestEvent from 'routes/promotion/InterestEvent';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import {Coupon} from "./pages/Coupon";
import {useCoupon} from "./hooks/useCoupon";


export default function App() {
  const {getCoupon} = useCoupon();
  window.rMateChartH5License = process.env.REACT_APP_R_MATE_CHART_H5_LICENSE;

  Array.prototype.division = function (n) {
    let _self = this;

    let len = _self.length;
    let cnt = (Math.floor(len / n) + ((Math.floor(len % n) > 0) ? 1 : 0));
    let tmp = new Array(cnt).fill();

    return tmp.map(arr => arr = _self.splice(0, n));
  };

  String.prototype.ellipsis = function (n) {
    let _self = this;

    let len = _self.length;

    return ((len >= n) ? _self.substr(0, n).concat('...') : _self);
  };

  const exceptObj = [
    {
      code: 400,
      msg: 'Bad Request : 요청이 잘못되었습니다. 관리자에게 문의하세요.'
    },
    {
      code: 404,
      msg: 'Page not found : 페이지를 찾을 수 없습니다. 관리자에게 문의하세요.'
    },
    {
      code: 500,
      msg: 'Internal Server Error : 서버쪽 이상입니다. 관리자에게 문의하세요.'
    },
    {
      code: 504,
      msg: 'Gateway Timeout : 서버쪽 이상입니다. 관리자에게 문의하세요.'
    },
  ]

  useEffect(() => {
    // if (document.location.pathname.search('logout') > -1) {
    //   localStorage.removeItem('jwtToken');
    // }
    // axios.interceptors.request.use(
    //   function (config) {
    //     config.headers["Content-Type"] = "application/json; charset=utf-8";
    //     if (localStorage.getItem('jwtToken') != undefined && localStorage.getItem('jwtToken') != null) {
    //       config.headers["Authorization"] = 'Bearer ' + localStorage.getItem('jwtToken');
    //     }

    //     return config;
    //   },
    //   function (error) {
    //     console.log(error);
    //     return Promise.reject(error);
    //   }
    // );


    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        //if (error.response.status === 401 || error.response.status === 403) {
        //window.location.href = '/login';
        //}
        //else {
        if (typeof error.response.data !== 'undefined') {

          let errMsg;
          let errData = error.response.data;

          if (error.response.status === 401 || error.response.status === 403) {
          }
          else {

            if (errData !== null && errData.message !== null) {
              errMsg = errData.message;
              alert(errMsg);
            }
            else {
              exceptObj.filter((except) => {
                if (except.code === error.status) {
                  errMsg = except.msg;
                  alert(errMsg);
                }
              });
            }

          }
        }
        else {
          exceptObj.filter((except) => {
            if (except.code === error.status) {
              alert(except.msg);
            }
          });
        }
        return Promise.reject(error);
      }
      //}
    )
  });

  const catchPage = (src) => {
    const path = ((document.querySelector(`script[src="${src}"]`)) ? '/aptMain' : '/applyList');

    return (<Redirect to={path} />);
  }

  // [SOM.220530] 스타벅스 관심사 프로모션 '다시보지않기' 버튼 체크 조건 추가
  const neverOpenToken = localStorage.getItem('interestEventPop01Open');

  useEffect(() => {
    getCoupon();
  }, []);

  return (
    <ErrorBoundary>
      <Router>
        <Switch>
          {/* 1. [SOM.220602] 스타벅스 관심사 응답이벤트 팝업 - 다시보지않기 체크 유무에 따른 메인 변경   */}
          {/* <Route exact path="/" component={ neverOpenToken != 'false' ? InterestEvent : () => <Redirect to='/applyList' /> } /> */}
          {/* [SOM.220513] 스타벅스 관심사 응답이벤트 팝업에서 메인으로 이동하기 위해 추가, 이벤트 종료 후에도 주석 금지! */}
           <Route exact path="/applyList" component={ApplyList} />
          {/* [SOM.220513] 스타벅스 관심사 응답이벤트 팝업 ( 위치: 메인 위 )  */}
          {/* <Route exact path="/" component={InterestEvent} /> */}

          {/* 2. [SOM.220418] 메인화면 > 청약 정보 (메인 변경) - 스타벅스 관심사 응답이벤트 종료시 주석 제거 */}
          <Route exact path="/" component={() => catchPage(`https://dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_APPKEY}&libraries=services`)} />

          {/* [SOM.220418] 청약정보 탭(메인화면)에서 아파트 정보 탭 클릭 시 아파트 정보 페이지로 리다이렉트 시키기 위해 추가 */}
          <Route exact path="/aptMain" component={AptMain2} />

          {/* 3. [SOM.220418] 메인화면 > 아파트 정보 (구 메인) */}
          {/* <Route exact path="/" component={AptMain} />  */}

          {/* [SOM.220418] 아파트정보+청약정보 맵 합치기 - 임시 */}
          {/* <Route exact path="/aptMain2" component={AptMain2} /> */}
          <Route exact path="/appInfo" component={AppInfo} />
          <Route exact path="/notice" component={Notice} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/qna" component={Qna} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/join" component={Join} />
          {/* <Route exact path="/join?siteCode=AB&ctgCode=1" component={Join} /> */}
          <Route exact path="/expire" component={Expire} />
          <Route exact path="/expireType2" component={ExpireType2} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/logoutSuccess" component={Logout} />
          <Route exact path="/coupon" render={()=><Coupon/>} />
        </Switch>
      </Router>
    </ErrorBoundary>
  );
}
