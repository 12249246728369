import React, { Component } from 'react';

//// 컴포넌트 사용처 : 청약 당첨 예상 상세
const ExptRate = ({ calcResult }) => {

  const getCatption = scoreType => {
    let caption = '';
    if (scoreType === 'best') {
      caption = <p>
        예상 경쟁률이 높지 않아요.  예상 경쟁률이 높지 않다는건,<br />
        <em>청약 당첨가능성은 높지만 해당 아파트의 수요가 미미하다는 의미로 해석될 수 있어요.</em> 신청에 유의해주세요. 다만, 실거주를 원하는 고객님은 청약신청하시길 추천드려요.
                </p>
    } else if (scoreType === 'good') {
      caption = <p>
        경쟁이 치열하진 않을 것으로 예상되요. 경험 삼아 넣기보다는<br />
        <em>정말 원하는 청약일 경우에만</em> 신청하시는게 좋을 것 같아요.
                </p>
    } else if (scoreType === 'normal') {
      caption = <p>
        예상 경쟁률이 높은 지역으로, 해당 지역에서 관심도가 높은 청약이에요.<br />
        <em>실거주와 투자 모두 만족시킬 수 있는 아파트에요.</em> 당첨 가능성이 있다면 청약 신청하시는게 좋을 것 같아요.
                </p>
    } else if (scoreType === 'bad') {
      caption = <p>
        예상 경쟁률이 엄청나요! 전국적으로 주목 받는 청약 아파트로,<br />
        <em>당첨 가능성이 있다면</em> 지금 바로 청약 신청하시는 것을 적극 추천드려요.
                </p>
    }
    return caption;
  }

  const { applyDtl, exptScore } = calcResult;

  const scoreType = (exptScore != null ? (
    parseInt(exptScore.exptRate) <= 1 ? 'best'
      : (parseInt(exptScore.exptRate) > 1 && parseInt(exptScore.exptRate) <= 5) ? 'good'
        : (parseInt(exptScore.exptRate) > 5 && parseInt(exptScore.exptRate) <= 10) ? 'normal'
          : 'bad') : 'bad');

  return (
    <>
      <h3 className="s_tit"><strong>예상 경쟁률</strong></h3>
      {/* <!-- verygood/good/normal/bad로 색상,이모지 변경 --> */}
      <div className={`ty_graph ani ${scoreType}`} id="ani3">
        <div className="graph">
          <div className="dount_area">
            <div className="circle_chart full" data-percent="100" data-permax="100">
              <div className="chart-box">
                <svg>
                  <defs>
                    <linearGradient id={`dount_${scoreType}`} x1="0" y1="0" x2="1" y2="1">
                      <stop className="color_1" offset="0%" />
                      <stop className="color_2" offset="100%" />
                    </linearGradient>
                  </defs>
                  <circle stroke={`url(#dount_${scoreType})`} r="50%" cx="50%" cy="50%" className="circle_progress" stroke-width="18" stroke-linecap="round"></circle>
                </svg>
                <div className="per-point per"><span><code>{exptScore != null ? exptScore.exptRate : 0}:1</code></span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="caption emoji">
          {
            getCatption(scoreType)
          }
        </div>
        <ul className="people">
          <li>
            <div className="inner">
              <strong>당첨 세대 수</strong>
              <code className="count_v1" per={applyDtl.geCnt}></code>
            </div>
          </li>
          <li>
            <div>
              <strong>예상 청약 신청 수</strong>
              <code className="count_v2" per={exptScore != null ? (Math.round(applyDtl.geCnt * exptScore.exptRate)) : 0}></code>
            </div>
          </li>
        </ul>
        <p className="noti">* 아파트청약케어의 당첨 예상 가점은 참고용이며, 아파트청약케어는 실제 청약 신청 및 결과에 대하여 책임지지 않습니다.</p>
      </div>
    </>
  );
}

export default ExptRate;
