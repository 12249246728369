import React, { useState, useEffect } from 'react';
import { popupOpen, popupClose } from 'routes/common/Design.js';
import {callAppInterface} from "./common/AppInterface";
import $ from "jquery";
import {SideMenuCategory} from "../components/common/SideMenuCategory";
import {SideMenuItem} from "../components/common/SideMenuItem";

const MenuPopup = (props) => {
  const [selectText, setSelectText] = useState('개선항목 선택');

  useEffect(() => {
    window.goback = selectHandler;

    return () => {
      popupClose('menu', 'mwf');
    };
  }, []);


  const selectHandler = (e) => {
    if (e) {
      setSelectText(e.target.getAttribute('title'));

      popupClose('select', 'mwf', 0);
    } else {
      if ($('#mw-chat').length) {
        $('#mw-chat').show();
      }

      popupClose('menu', 'mwf');
    }

  };

  return (
    <>
      {/* 메뉴 */}
      <div id="mw-menu" className="ly_pop ly_menu full">
        <div className="wrap">
          <div className="content">
            <header className="header">
              <h1 className="blind">메뉴</h1>
              <button
                type="button"
                onClick={() => selectHandler()}
                className="btn_close"
                title="닫기"
              >
                <i></i>
              </button>
            </header>
            <section className="ly_menu_content">
              <dl className="dl_menu">
                <dt>
                  <strong>아파트</strong> 정보
                </dt>
                <dd>
                  {/* 2022.08.05 - GTM CLASS 추가 - 이하 9개 */}
                  <a href="" className="GTM-All-MENU-1" onClick={(e) => props.page({ 'searchParams': 'aptMain', e })}>아파트 정보</a>
                </dd>
                <dd>
                  <a href="" className="GTM-All-MENU-2" onClick={(e) => props.page({ 'searchParams': 'REC-1000', e })}>아파트 추천</a>
                </dd>
              </dl>
              <dl className="dl_menu">
                <dt>
                  <strong>청약</strong> 정보
                </dt>
                <dd>
                  <a href="#" className="GTM-All-MENU-3" onClick={(e) => props.page({ 'searchParams': 'APY-1000', e })}>아파트 청약정보</a>
                </dd>
                <dd>
                  <a href="#" className="GTM-All-MENU-4" onClick={(e) => props.page({ 'searchParams': 'PAL-1000', e })}>청약 가점계산기</a>
                </dd>
                <dd>
                  <a href="#" className="GTM-All-MENU-5" onClick={(e) => props.page({ 'searchParams': 'PER-1000', e })}>청약 당첨가능성</a>
                </dd>
                <dd>
                  <a href="#" className="GTM-All-MENU-6" onClick={(e) => props.page({ 'searchParams': 'CAL-1000', e })}>청약 비용계산기</a>
                </dd>
              </dl>
              <dl className="dl_menu">
                <dt>
                  부동산 <strong>계산기</strong>
                </dt>
                <dd>
                <a href="#" className="GTM-All-MENU-7" onClick={(e) => props.page({ 'searchParams': 'AAL-1000', e })}>부동산 계산기</a>
                </dd>
              </dl>
              <dl className="dl_menu">
                <dt>
                  부동산 <strong>정보</strong>
                </dt>
                <dd>
                <a href="#" className="GTM-All-MENU-8" onClick={(e) => props.page({ 'searchParams': 'AEW-1000', e })}>뉴스</a>
                </dd>
                <dd>
                <a href="#" className="GTM-All-MENU-9" onClick={(e) => props.page({ 'searchParams': 'POL-1000', e })}>정책</a>
                </dd>
              </dl>
              <SideMenuCategory categoryName={"혜택"}>
                <SideMenuItem itemName={"쿠폰"} url={"/coupon"}/>
              </SideMenuCategory>
            </section>
            <div className="btn_wrap" style={{ "display": "none" }}>
              <button
                type="button"
                className="btn_regist"
                onClick={() => popupOpen('opinion', 'mwf')}
              >
                개선의견사항 접수
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* 메인개편 - 개선의견사항 접수 */}
      <div id="mw-opinion" className="ly_pop ly_opinion full">
        <div className="wrap">
          <div className="content">
            <header className="header">
              <h1 className="tit">개선의견사항 접수</h1>
              <button
                type="button"
                onClick={() => popupClose('opinion', 'mwf')}
                className="btn_close"
                title="닫기"
              >
                <i></i>
              </button>
            </header>
            <div className="ly_content">
              <div className="item">
                <label htmlFor="improve" className="tit">
                  개선항목
                </label>
                <div className="form_wrap">
                  <div className="form">
                    <div className="ip_box btn_sel_wrap">
                      <div className="inner_wrap">
                        <button
                          type="button"
                          id="improve"
                          className="btn_sel"
                          onClick={() => popupOpen('select', 'mwf')}
                        >
                          <span
                            className="sel_txt"
                            style={{
                              color:
                                selectText === '개선항목 선택' && '#CFCFCF',
                            }}
                          >
                            {selectText}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <label htmlFor="detail" className="tit">
                  상세내용
                </label>
                <div className="form">
                  <div className="ip_box">
                    <div className="inner_wrap">
                      <textarea
                        id="detail"
                        className="textarea"
                        cols="30"
                        rows="10"
                        placeholder="상세내용을 입력해주세요."
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="fix btm">
              <div className="btn_area_half btm">
                <button type="button" className="skip">
                  제출
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 메인개편 - 개선의견사항 접수 */}

      {/* 메인개편 - 개선항목 선택 */}
      <div id="mw-select" className="ly_pop popBtm ly_select_wrap">
        <div className="wrap">
          <button
            type="button"
            onClick={() => popupClose('select', 'mwf', 0)}
            className="close"
          >
            <i>닫기</i>
          </button>
          <div className="content pop_sel_wrap">
            <ul className="list">
              <li>
                <span className="item">
                  <input
                    type="radio"
                    id="select01_01"
                    name="select01"
                    title="아파트 실거래가"
                    onChange={selectHandler}
                  />
                  <label htmlFor="select01_01">
                    <span className="txt">아파트 실거래가</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input
                    type="radio"
                    id="select01_02"
                    name="select01"
                    title="민간청약"
                    onChange={selectHandler}
                  />
                  <label htmlFor="select01_02">
                    <span className="txt">민간청약</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input
                    type="radio"
                    id="select01_03"
                    name="select01"
                    title="공공청약"
                    onChange={selectHandler}
                  />
                  <label htmlFor="select01_03">
                    <span className="txt">공공청약</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input
                    type="radio"
                    id="select01_04"
                    name="select01"
                    title="재개발/재건축 정보"
                    onChange={selectHandler}
                  />
                  <label htmlFor="select01_04">
                    <span className="txt">재개발/재건축 정보</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input
                    type="radio"
                    id="select01_05"
                    name="select01"
                    title="학군 정보"
                    onChange={selectHandler}
                  />
                  <label htmlFor="select01_05">
                    <span className="txt">학군 정보</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input
                    type="radio"
                    id="select01_06"
                    name="select01"
                    title="주택담보대출"
                    onChange={selectHandler}
                  />
                  <label htmlFor="select01_06">
                    <span className="txt">주택담보대출</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input
                    type="radio"
                    id="select01_07"
                    name="select01"
                    title="청약 당첨시 예상 시세 차익"
                    onChange={selectHandler}
                  />
                  <label htmlFor="select01_07">
                    <span className="txt">청약 당첨시 예상 시세 차익</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input
                    type="radio"
                    id="select01_08"
                    name="select01"
                    title="기타"
                    onChange={selectHandler}
                  />
                  <label htmlFor="select01_08">
                    <span className="txt">기타</span>
                  </label>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* 메인개편 - 개선항목 선택 */}
    </>
  );
};

export default MenuPopup;
