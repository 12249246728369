import React, { Component } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import RMateColumnType from 'routes/common/rMateChart/RMateColumnType';
import { numberWithCounts, dateFormatMsg2 } from 'routes/common/StringUtil';
import Moment from 'moment';
import { popupAlert01 } from 'routes/predict/PopupNotice';

//// 컴포넌트 사용처 : 청약 당첨 예상 상세
const PrevTrx = ({ calcResult, onRenderGraph }) => {

  const { applyInfo, bjdInfo, applyDtl, prevTrx, aptRecentAreaPyTrxData, aptOneYearAgoAreaPyTrxData, applyRecentAreaPyTrxData } = calcResult;

  const options = {
    chart: {
      type: 'column',
      height: 300
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: prevTrx.map(v => v.trxDate),
      labels: {
        formatter: function () {
          return Moment(this.value).format('YY/<br/> MM');
        },
        useHtml: true,
        rotation: 0
      },
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        formatter: function () {
          return this.value % 10000 === 0 ? numberWithCounts(this.value * 10000) : '';
        },
        align: 'right',
        x: -5,
        padding: 2
      },
      tickAmount: 10,
    },
    tooltip: {
      formatter: function () {
        return numberWithCounts(this.y * 10000);
      }
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: false
        }
      }
    },
    series: [{
      name: "실거래가",
      data: prevTrx.map(v => v.amt),
      color: 'rgb(94, 206, 197)',
      pointPadding: 0.25,
    }]
  }

  const minData = prevTrx.length > 0 ? prevTrx.filter((v, idx) => idx === 0)[0] : { amt: 0 };
  const maxData = prevTrx.length > 0 ? prevTrx.filter((v, idx) => idx === prevTrx.length - 1)[0] : { amt: 0 };
  const scoreType = maxData.amt > minData.amt ? 'good' : 'bad';
  const percent = prevTrx.length > 0 ? Math.round((maxData.amt - minData.amt) * 100 / maxData.amt) : 0;

  let graphList = new Array();

  prevTrx.map(v => {
    const date = v.trxDate;

    const yyyy = date.substr(2, 2).replace('-', '');
    const mm = date.substr(4, 2).replace('-', '');

    const targetDateStr = `${yyyy}. ${mm}`;
    graphList.push({ 'trxDate': targetDateStr, 'amt': v.amt, 'counts': '억원' });
  });

  return (
    <div className="con ani" id="ani8">
      <div className="tit_wrap">
        <span className="tit"><strong>{bjdInfo.bjdName} 지역 {Math.round(applyDtl.hsArea * 0.3025)}평대</strong> 실거래가</span>
        <button type="button" className="btn_tip GTM-PER-TIP-4" onClick={popupAlert01}>실거래가란?</button>
      </div>
      {/* <!-- verygood/good/normal/bad로 색상,이모지 변경 --> */}
      <div className={`ty_graph ${scoreType}`}>
        <div className="graph">
          <div className="dount_area">
            <div className="circle_chart full" data-percent="100" data-permax="100">
              <div className="chart-box">
                <svg>
                  <defs>
                    <linearGradient id={`dount_${scoreType}`} x1="0" y1="0" x2="1" y2="1">
                      <stop className="color_1" offset="0%" />
                      <stop className="color_2" offset="100%" />
                    </linearGradient>
                  </defs>
                  <circle stroke={`url(#dount_${scoreType})`} r="50%" cx="50%" cy="50%" className="circle_progress" stroke-width="18" stroke-linecap="round"></circle>
                </svg>
                <div className="per-point per"><span><code className="num">{percent > 0 ? `+ ${Math.abs(percent)}%` : `- ${Math.abs(percent)}%`}</code></span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="caption emoji">
          <p>{applyInfo.aptName}가 위치하는 {bjdInfo.bjdName} 지역은 <em>최근 1년간 아파트 시세가 {scoreType === 'good' ? '상승' : '하락'} 중</em>이에요.<br />주변 아파트가 시세가 상승한다는 건 {scoreType === 'good' ? '긍정' : '부정'}적인 시그널로 해석 될 수 있어요.</p>
          {/* <strong>민앤지포레스트 : 10년 전매 제한 아파트</strong> */}
        </div>
      </div>
      <ul className="apt_list">
        <li>
          <div className="tit_wrap">
            <span>아파트 실거래</span>
            <strong>{aptRecentAreaPyTrxData[0] ? aptRecentAreaPyTrxData[0].aptName : '-'}</strong>
          </div>
          <ul className="sub_list">
            <li>
              <strong className="sub_th">전용면적</strong>
              <span className="sub_td">{aptRecentAreaPyTrxData[0] ? aptRecentAreaPyTrxData[0].areaPy + '평' : '-'}</span>
            </li>
            <li>
              <strong className="sub_th">최근 거래가</strong>
              <span className="sub_td">{aptRecentAreaPyTrxData[0] ? numberWithCounts(aptRecentAreaPyTrxData[0].dpsAmt * 10000) : '-'}</span>
            </li>
            <li>
              <strong className="sub_th">공고일</strong>
              <span className="sub_td">{aptRecentAreaPyTrxData[0] ? dateFormatMsg2(aptRecentAreaPyTrxData[0].useAprDay) : '-'}</span>
            </li>
          </ul>
        </li>
        <li>
          <div className="tit_wrap">
            <span>1년대비 비교</span>
            <strong>{aptOneYearAgoAreaPyTrxData[0] ? aptOneYearAgoAreaPyTrxData[0].aptName : '-'}</strong>
          </div>
          <ul className="sub_list">
            <li>
              <strong className="sub_th">전용면적</strong>
              <span className="sub_td">{aptOneYearAgoAreaPyTrxData[0] ? aptOneYearAgoAreaPyTrxData[0].areaPy + '평' : '-'}</span>
            </li>
            <li>
              <strong className="sub_th">최근 거래가</strong>
              <span className="sub_td">{aptOneYearAgoAreaPyTrxData[0] ? numberWithCounts(aptOneYearAgoAreaPyTrxData[0].dpsAmt * 10000) : '-'}</span>
            </li>
            <li>
              <strong className="sub_th">공고일</strong>
              <span className="sub_td">{aptOneYearAgoAreaPyTrxData[0] ? dateFormatMsg2(aptOneYearAgoAreaPyTrxData[0].useAprDay) : '-'}</span>
            </li>
          </ul>
        </li>
        <li>
          <div className="tit_wrap">
            <span>분양권 실거래</span>
            <strong>{applyRecentAreaPyTrxData[0] ? applyRecentAreaPyTrxData[0].aptName : '-'}</strong>
          </div>
          <ul className="sub_list">
            <li>
              <strong className="sub_th">전용면적</strong>
              <span className="sub_td">{applyRecentAreaPyTrxData[0] ? applyRecentAreaPyTrxData[0].areaPy + '평' : '-'}</span>
            </li>
            <li>
              <strong className="sub_th">최근 거래가</strong>
              <span className="sub_td">{applyRecentAreaPyTrxData[0] ? numberWithCounts(applyRecentAreaPyTrxData[0].dpsAmt * 10000) : '-'}</span>
            </li>
            <li>
              <strong className="sub_th">공고일</strong>
              <span className="sub_td">{applyRecentAreaPyTrxData[0] ? dateFormatMsg2(applyRecentAreaPyTrxData[0].notiDate) : '-'}</span>
            </li>
          </ul>
        </li>
      </ul>
      <div className="chart">
        <div id="rMateDiv1">
          {
            onRenderGraph &&
            <RMateColumnType
              parentDivId={'rMateDiv1'}
              graphList={graphList}
            ></RMateColumnType>
          }
        </div>
      </div>
    </div>
  );
}

export default PrevTrx;
