import React from "react";
import {COUPON} from "../../../../consts/coupon";
import {CouponItemDefault} from "../../entities/CouponItemDefault";
import {CouponInfo} from "../../entities/CouponInfo";

/**
 * @desc 비회원 쿠폰 메뉴 내부 위젯
 */
export const DefaultCoupons = () => {

    return (
            <div className="coupon-wrapper-01">
                <div className="coupon-text-box-02">
                    <span className="coupon-text-04">가입자 전용</span>
                    <span className="coupon-text-05">쿠폰 혜택</span>
                </div>
                <ul className="coupon-list">
                    <CouponItemDefault coupon={COUPON.NAVER}/>
                    <CouponItemDefault coupon={COUPON.GS}/>
                </ul>
                <CouponInfo/>
            </div>

    );
};