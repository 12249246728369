/**
 * 10 미만 숫자에 0 채우기
 * @param num
 * @return {String}
 */
export function padZero(num){
    if (num < 10) {
        return `0${num}`;
    } else {
        return num;
    }
}