import React, { Component } from 'react';
import Moment from 'moment';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import RMateAreaLineCurveType from 'routes/common/rMateChart/RMateAreaLineCurveType';
import { popupAlert03 } from 'routes/predict/PopupNotice';

//// 컴포넌트 사용처 : 청약 당첨 예상 상세
const PrevPoint = ({ calcResult, onRenderGraph }) => {

  const { applyInfo, prevPoint } = calcResult;

  const prevPointResult = prevPoint.length > 0 ? prevPoint : [{ notiDate: applyInfo.notiDate, minScore: 0, maxScore: 0 }];

  const maxOptions = {
    chart: {
      type: 'spline',
      height: 250
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: prevPointResult.map(v => Moment(v.notiDate).format('YY. MM'))
    },
    yAxis: {
      title: {
        text: ''
      },
      tickAmount: 8
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false
        }
      }
    },
    series: [{
      name: '최고가점',
      data: prevPointResult.map(v => v.maxScore),
      color: 'rgb(94, 206, 197)'
    }],
    legend: {
      enabled: false
    }
  }

  const minOptions = {
    chart: {
      type: 'spline',
      height: 250
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: prevPointResult.map(v => Moment(v.notiDate).format('YY. MM'))
    },
    yAxis: {
      title: {
        text: ''
      },
      tickAmount: 8
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false
        }
      }
    },
    series: [{
      name: '최저가점',
      data: prevPointResult.map(v => v.minScore),
      color: 'rgb(252, 77, 64)'
    }],
    legend: {
      enabled: false
    }
  }

  const minData = prevPointResult.filter((v, idx) => idx === 0)[0];
  const maxData = prevPointResult.filter((v, idx) => idx === prevPointResult.length - 1)[0];

  let minGraphList = new Array();
  let maxGraphList = new Array();

  let best = Math.max.apply(null, prevPointResult.map(v => v["maxScore"]));
  let lowest = Math.min.apply(null, prevPointResult.map(v => v["minScore"]));

  prevPointResult.map(v => {
    const yyyy = v.notiDate.substr(2, 2).replace('-', '');
    const mm = v.notiDate.substr(4, 2).replace('-', '');

    const targetDateStr = `${yyyy}. ${mm}`;
    minGraphList.push({ 'notiDate': targetDateStr, 'minScore': v.minScore, 'counts': '점', 'lowest': lowest });
  })

  prevPointResult.map(v => {
    const yyyy = v.notiDate.substr(2, 2).replace('-', '');
    const mm = v.notiDate.substr(4, 2).replace('-', '');

    const targetDateStr = `${yyyy}. ${mm}`;
    maxGraphList.push({ 'notiDate': targetDateStr, 'maxScore': v.maxScore, 'counts': '점', 'best': best });
  })

  return (
    <div className="con ani" id="ani10">
      <div className="tit_wrap">
        <span className="tit"><strong>과거 청약 당첨가점</strong></span>
        <button type="button" className="btn_tip GTM-PER-TIP-6" onClick={popupAlert03}>과거 청약 당첨가점 란?</button>
        <span className="info">* {Moment(minData.notiDate).format('YY년 MM월')} 대비</span>
      </div>
      {/* <!-- verygood/good/normal/bad로 색상,이모지 변경 --> */}
      <div className="ty_graph type01 best">
        <div className="graph">
          <div className="dount_area">
            <div className="circle_chart full" data-percent="100" data-permax="100">
              <div className="chart-box">
                <svg>
                  <defs>
                    <linearGradient id="dount_best" x1="0" y1="0" x2="1" y2="1">
                      <stop className="color_1" offset="0%" />
                      <stop className="color_2" offset="100%" />
                    </linearGradient>
                  </defs>
                  <circle stroke="url(#dount_best)" r="50%" cx="50%" cy="50%" className="circle_progress" stroke-width="18" stroke-linecap="round"></circle>
                </svg>
                <div className="per-point"><span className="txt01"><s>{Math.abs(maxData.maxScore - minData.maxScore)}</s>점{maxData.maxScore > minData.maxScore ? '상승' : '하락'}</span><p>최고가점</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="type01 chart">
        <div id="rMateDiv3_1">
          {
            onRenderGraph &&
            <RMateAreaLineCurveType
              parentDivId={'rMateDiv3_1'}
              graphList={maxGraphList}
            ></RMateAreaLineCurveType>
          }
        </div>
      </div>
      {/* <!-- verygood/good/normal/bad로 색상,이모지 변경 --> */}
      <div className="ty_graph type02 bad">
        <div className="graph">
          <div className="dount_area">
            <div className="circle_chart full" data-percent="100" data-permax="100">
              <div className="chart-box">
                <svg>
                  <defs>
                    <linearGradient id="dount_bad" x1="0" y1="0" x2="1" y2="1">
                      <stop className="color_1" offset="0%" />
                      <stop className="color_2" offset="100%" />
                    </linearGradient>
                  </defs>
                  <circle stroke="url(#dount_bad)" r="50%" cx="50%" cy="50%" className="circle_progress" stroke-width="18" stroke-linecap="round"></circle>
                </svg>
                <div className="per-point"><span className="txt01"><s>{Math.abs(maxData.minScore - minData.minScore)}</s>점{maxData.minScore > minData.minScore ? '상승' : '하락'}</span><p>최저가점</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="type02 chart">
        <div id="rMateDiv3_2">
          {
            onRenderGraph &&
            <RMateAreaLineCurveType
              parentDivId={'rMateDiv3_2'}
              graphList={minGraphList}
            ></RMateAreaLineCurveType>
          }
        </div>
      </div>
    </div>
  );
}

export default PrevPoint;
