import React, { useEffect, useState, Component } from 'react'
import axios from 'axios';
import Moment from 'moment';
import $ from 'jquery';

//// 컴포넌트 사용처 : APP 에러핸들링
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false
    };
  }


  componentDidCatch(error, info) {
    $('body').addClass('bgf');

    console.log('에러가 발생했습니다.');
    console.log({
      error,
      info
    });
    this.setState({
      error: true
    });
  }

  refreshMain = () => {
    window.location.href = '/';
  }

  render() {
    return (
      this.state.error ?
        <>
          <div id="mw-error" className="ly_apt_error full">
            <div className="wrap">
              <div className="content">
                <header className="header">
                  <button type="button" onClick={() => this.refreshMain()} className="btn_prev" title="뒤로가기" ><i></i></button>
                  <strong className="tit">아파트청약케어</strong>
                </header>
                <div className="ly_content">
                  <div className="error_wrap">
                    <strong className="tit">잠시 에러가 발생했어요!<br />새로고침을 눌러 페이지를 다시 요청해주세요.</strong>
                    <p className="txt">계속해서 에러가 발생하실 경우 아파트청약케어를 종료 후<br />재실행해주시거나, 고객센터로 연락해주세요.</p>
                  </div>
                </div>
                <div className="btn_area_wide fix btm">
                  <div className="customer_info">
                    <span>고객센터</span>
                    <code>1599-2985</code>
                    <a href="mailto:in.help@hecto.co.kr" className="mail">in.help@hecto.co.kr</a>
                  </div>
                  <button type="button" className="skip" onClick={() => this.refreshMain()}>페이지 새로고침</button>
                </div>
              </div>
            </div>
          </div>
        </>
        :
        this.props.children
    )
  }
}
export default ErrorBoundary;