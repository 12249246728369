import React, {useEffect, useRef, useState, useCallback} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'; /* 2022.06.14 수정 */
import {useStateWithCallbackLazy} from 'use-state-with-callback';
import AprFilter from 'routes/apt/filter/AprFilter';
import HhldFilter from 'routes/apt/filter/HhldFilter';
import MainFilter from 'routes/apt/filter/MainFilter';
import PkFilter from 'routes/apt/filter/PkFilter';
import PyFilter from 'routes/apt/filter/PyFilter';
import TrxAmtFilter from 'routes/apt/filter/TrxAmtFilter';

// 메인개편 - 청약정보
import SubsMainFilter from 'routes/apply/filter/MainFilter';
import SubsHhldFilter from 'routes/apply/filter/HhldFilter';
import SubsPyFilter from 'routes/apply/filter/PyFilter';
import SubsTrxAmtFilter from 'routes/apply/filter/TrxAmtFilter';
import SubsLocalsFilter from 'routes/apply/filter/LocalFilter';

// import PopDiv from 'routes/apt/PopDiv';
import AptDtl from 'routes/apt/AptDtl';

import RcmdSrch from 'routes/apt/rcmd/RcmdSrch';

import ApplyList from 'routes/apply/ApplyList';

import RealtyMaster from 'routes/realty/RealtyMaster';

import FavList from 'routes/apt/fav/FavList';

import {AptOverlay} from 'routes/overlay/AptOverlay.js';
import {BjdOverlay} from 'routes/overlay/BjdOverlay.js';
import {ApplyOverlay} from 'routes/overlay/ApplyOverlay';
import {AroundOverlay} from 'routes/overlay/AroundOverlay.js';

import axios from 'axios';
import $ from 'jquery';

import {
  addRecentItem,
  clearRecentItem,
  getRecentItem,
  searchApply,
  searchAptBjd,
  setRecentList
} from 'routes/common/Search';
import {addLsItem, clearLsItem, getLsItem} from 'routes/common/LocalStorage'
import {
  callGaTag,
  popupClose,
  popupOpen,
  siblings,
  uiChartTap,
  uiFilterTabSwipe,
  uiFixValue, uiInputDel, uiIpFocus,
  uiMainSwitch,
  uiSelFocus,
  uiTab,
  uiToggle,
  uiTopGo
} from 'routes/common/Design.js'

import {callAppInterface} from 'routes/common/AppInterface'

import RecentResult from 'routes/common/RecentResult';
import SearchResult from 'routes/common/SearchResult';
import Moment from 'moment';

import {findIndex} from 'routes/common/ArrayUtil';
import _ from 'lodash';

import {getLcParamItem, getLcUrlItem, setLcParamItem, setLcUrlItem} from 'routes/common/ParamStorage';

import {os, osCheck, version} from 'routes/common/OsUtil';

/* 메인개편 추가 */
import MenuPopup from 'routes/MenuPopup';
import Swiper from 'swiper/bundle'
import SubFooter from 'routes/SubFooter';
/* // 메인개편 추가 */

// 마이페이지 추가
import MyPage from 'routes/mypage/MyPage';

import ApplyInfoList from './applyInfo/ApplyInfoList';
import MarginPriceList from './predict/MarginPriceList';
import AvgApplyScoreList from './predict/AvgApplyScoreList';
import MyLocApplyList from './loc/MyLocApplyList';
import ApplyDtl from 'routes/apply/ApplyDtl';

import axiosAuth from 'routes/common/axios/services/AxiosAuth';
import axiosRequest from 'routes/common/axios/services/AxiosRequest';
import { AXIOS_AUTH_RESULT } from 'routes/common/axios/code/AxiosCode';
import { SERVICE_TYPE, TRX_TYPE } from 'routes/common/Code';
import ApplyDtlMap from "../apply/ApplyDtlMap";

let map = {};

let status = { filterType: "STATUS", value: '1', mainName: '' };
let locals = { filterType: "LOCALS", value: '', mainName: '' };
let trxType = { filterType: "TRXTYPE", value: 'D', mainName: '' };
//let trxType = 'D';
let trxAmt = { filterType: "TRX", min: -1, max: 9999, mainName: '' };
let py = { filterType: "PY", min: -1, max: 9999, mainName: '' };
let hhld = { filterType: "HHLD", min: -1, max: 99999, mainName: '' };
let apr = { filterType: "APR", min: -1, max: 9999, mainName: '' };
let pk = { filterType: "PK", min: -1, max: 9999, mainName: '' };

let applyTrxAmt = { filterType: "TRX", min: -1, max: 9999, mainName: '' };
let applyPy = { filterType: "PY", min: -1, max: 9999, mainName: '' };
let applyHhld = { filterType: "HHLD", min: -1, max: 99999, mainName: '' };

let overlayList = [];
let aroundOverlayList = [];

let aptSrchFilterKeys = [];
let applySrchFilterKeys = [];
//// 컴포넌트 사용처 : 아파트 정보, APP메인화면
const AptMain = () => {

  const [filterName, setFilterName] = useState({});
  const [subFilterName, setSubFilterName] = useState({});

  const [keyword, setKeyword] = useStateWithCallbackLazy('');

  const [recentResult, setRecentResult] = useStateWithCallbackLazy(getRecentItem('apartMap'));
  const [searchResult, setSearchResult] = useStateWithCallbackLazy([]);

  const [searchIndex, setSearchIndex] = useStateWithCallbackLazy(-1);

  const [recentFocus, setRecentFocus] = useStateWithCallbackLazy(true);
  const [searchVisible, setSearchVisible] = useStateWithCallbackLazy(false);

  const [popDivDisplay, setPopDivDisplay] = useStateWithCallbackLazy(true);

  const [aptDtlDisplay, setAptDtlDisplay] = useStateWithCallbackLazy(false);

  const [rcmdSrchDisplay, setRcmdSrchDisplay] = useStateWithCallbackLazy(false);
  const [rcmdListDisplay, setRcmdListDisplay] = useStateWithCallbackLazy(false);

  const [aptId, setAptId] = useStateWithCallbackLazy('');
  const [aptAreaId, setAptAreaId] = useStateWithCallbackLazy('');
  const [aptDtl, setAptDtl] = useStateWithCallbackLazy([]);

  const [applyMenuType, setApplyMenuType] = useStateWithCallbackLazy('A');
  const [applyListDisplay, setApplyListDisplay] = useStateWithCallbackLazy(false);

  const [realtyMasterDisplay, setRealtyMasterDisplay] = useStateWithCallbackLazy(false);
  const [realtyMenuType, setRealtyMenuType] = useStateWithCallbackLazy('A');

  const [favListDisplay, setFavListDisplay] = useStateWithCallbackLazy(false);

  const [appVersionDisplay, setAppVersionDisplay] = useStateWithCallbackLazy(false);

  const [isFiltersLoad, setIsFiltersLoad] = useStateWithCallbackLazy(false);
  const [isFiltersRefresh, setIsFiltersRefresh] = useStateWithCallbackLazy(false);

  const [isStatusLoad, setIsStatusLoad] = useStateWithCallbackLazy(false);
  const [isLocalsLoad, setIsLocalsLoad] = useStateWithCallbackLazy(false);
  const [istrxTypeLoad, setIstrxTypeLoad] = useStateWithCallbackLazy(false);
  const [isTrxAmtLoad, setIsTrxAmtLoad] = useStateWithCallbackLazy(false);
  const [isPyLoad, setIsPyLoad] = useStateWithCallbackLazy(false);
  const [isHhldLoad, setIsHhldLoad] = useStateWithCallbackLazy(false);
  const [isAprLoad, setIsAprLoad] = useStateWithCallbackLazy(false);
  const [isPkLoad, setIsPkLoad] = useStateWithCallbackLazy(false);
  const [isApplyTrxAmtLoad, setIsApplyTrxAmtLoad] = useStateWithCallbackLazy(false);
  const [isApplyPyLoad, setIsApplyPyLoad] = useStateWithCallbackLazy(false);
  const [isApplyHhldLoad, setIsApplyHhldLoad] = useStateWithCallbackLazy(false);

  const [loginFlag, setLoginFlag] = useStateWithCallbackLazy(false);
  const [locationFlag, setLocationFlag] = useStateWithCallbackLazy(false);

  const [applyPopupType, setApplyPopupType] = useStateWithCallbackLazy('');

  const [hMenuDisplay, setHMenuDisplay] = useStateWithCallbackLazy(false);

  const [aptMainEntry, setAptMainEntry] = useStateWithCallbackLazy(false);

  const [isMyPage, setIsMyPage] = useStateWithCallbackLazy(false);
  const [myPageInfo, setMyPageInfo] = useStateWithCallbackLazy({});

  const lcUrlMap = getLcUrlItem();
  const lcParamMap = getLcParamItem();

  /* 메인개편 추가 */
  const [isFilterTab, setIsFilterTab] = useState(0);

  const isFilterTabRef = useRef('');

  // header
  const filter = useRef(null);
  const srchWrap = useRef(null);
  const srchListWrap = useRef(null);
  const btnHome = useRef(null);

  // map, content
  const mapWrap = useRef(null);
  const mapTop = useRef(null);
  const mapBottom = useRef(null);
  const contentNav = useRef(null);
  const infoHeaderNav = useRef(null);

  /* 2022.06.14 수정 */
  const history = useHistory();
  const location = useLocation();
  /* // 2022.06.14 수정 */

  const saveFilter = (e) => {
    // if ($(e.target).hasClass('active')) $(e.target).removeClass('active');
    // else                                $(e.target).addClass('active');

    switch (isFilterTabRef.current) {
      case SERVICE_TYPE.APART: {
        addLsItem('aptSrchFilter', { trxType, trxAmt, py, hhld, apr, pk });

        break;
      }
      case SERVICE_TYPE.APPLY: {
        addLsItem('applySrchFilter', { status, locals: { filterType: "LOCALS", value: '', mainName: '' }, trxAmt: applyTrxAmt, py: applyPy, hhld: applyHhld });

        break;
      }
    }
  }

  const clearOverlay = () => {
    $('.map_con > .map_info_wrap').remove();

    overlayList.forEach(ov => ov.setMap(null));

    overlayList = [];
  }

  const handleChangeTrxType = ({ filterType, value, mainName, exCall }) => {
    const filterObj = { filterType, value, mainName };

    trxType = filterObj;

    filterName.trxType = mainName;

    setFilterName({ ...filterName });

    if (exCall) {
      switch (isFilterTabRef.current) {
        case SERVICE_TYPE.APART: clearLsItem('aptSrchFilter');   break;
        case SERVICE_TYPE.APPLY: clearLsItem('applySrchFilter'); break;
      }
    } else {
      saveFilter();
    }

    _.remove(aptSrchFilterKeys, function (item) {
      let n = '';

      switch (filterType) {
        case 'TRXTYPE':   n = 'trxType'; break;
      }

      return (n === item);
    });

    if (!aptSrchFilterKeys.length) {
      if (!exCall) {
        clearOverlay();
        overlayMap('');
      }
    }
  }

  const handleChangeStatus = ({ filterType, value, mainName, exCall }) => {
    const filterObj = { filterType, value, mainName };

    status = filterObj;

    subFilterName.status = mainName;

    setSubFilterName({ ...subFilterName });

    if (exCall) {
      switch (isFilterTabRef.current) {
        case SERVICE_TYPE.APART: clearLsItem('aptSrchFilter');   break;
        case SERVICE_TYPE.APPLY: clearLsItem('applySrchFilter'); break;
      }
    } else {
      saveFilter();
    }

    _.remove(applySrchFilterKeys, function (item) {
      let n = '';

      switch (filterType) {
        case 'STATUS':   n = 'status'; break;
        case 'LOCALS':   n = 'locals'; break;
      }

      return (n === item);
    });

    if (!applySrchFilterKeys.length) {
      if (!exCall) {
        clearOverlay();
        overlayMap('');
      }
    }
  }

  const handleChangeLocals = ({ filterType, value, mainName, exCall }) => {
    const filterObj = { filterType, value, mainName };

    locals = filterObj;

    subFilterName.locals = mainName;

    setSubFilterName({ ...subFilterName });

    if (exCall) {
      switch (isFilterTabRef.current) {
        case SERVICE_TYPE.APART: clearLsItem('aptSrchFilter');   break;
        case SERVICE_TYPE.APPLY: clearLsItem('applySrchFilter'); break;
      }
    } else {
      saveFilter();
    }

    _.remove(applySrchFilterKeys, function (item) {
      let n = '';

      switch (filterType) {
        case 'LOCALS':   n = 'locals'; break;
      }

      return (n === item);
    });

    if (!applySrchFilterKeys.length) {
      if (!exCall) {
        clearOverlay();
        overlayMap('');
      }
    }
  }

  const handleChangeFilter = ({ filterType, min, max, mainName, exCall }) => {
    const filterObj = { filterType, min, max, mainName };

    switch (filterType) {
      case 'TRX':   trxAmt = filterObj; filterName.trxAmt = mainName; break;
      case 'PY':    py = filterObj;     filterName.py = mainName;     break;
      case 'HHLD':  hhld = filterObj;   filterName.hhld = mainName;   break;
      case 'APR':   apr = filterObj;    filterName.apr = mainName;    break;
      case 'PK':    pk = filterObj;     filterName.pk = mainName;     break;
    }

    setFilterName({ ...filterName });

    if (exCall) {
      switch (isFilterTabRef.current) {
        case SERVICE_TYPE.APART: clearLsItem('aptSrchFilter');   break;
        case SERVICE_TYPE.APPLY: clearLsItem('applySrchFilter'); break;
      }
    } else {
      saveFilter();
    }

    _.remove(aptSrchFilterKeys, function (item) {
      let n = '';

      switch (filterType) {
        case 'TRX':   n = 'trxAmt'; break;
        case 'PY':    n = 'py';     break;
        case 'HHLD':  n = 'hhld';   break;
        case 'APR':   n = 'apr';    break;
        case 'PK':    n = 'pk';     break;
      }

      return (n === item);
    });

    if (!aptSrchFilterKeys.length) {
      if (!exCall) {
        clearOverlay();
        overlayMap('');
      }
    }
  }

  const subHandleChangeFilter = ({ filterType, min, max, mainName, exCall }) => {
    const filterObj = { filterType, mainName, min, max };

    switch (filterType) {
      case 'TRX':   applyTrxAmt = filterObj;  subFilterName.trxAmt = mainName; break;
      case 'PY':    applyPy = filterObj;      subFilterName.py = mainName;     break;
      case 'HHLD':  applyHhld = filterObj;    subFilterName.hhld = mainName;   break;
    }

    setSubFilterName({ ...subFilterName });

    if (exCall) {
      switch (isFilterTabRef.current) {
        case SERVICE_TYPE.APART: clearLsItem('aptSrchFilter');   break;
        case SERVICE_TYPE.APPLY: clearLsItem('applySrchFilter'); break;
      }
    } else {
      saveFilter();
    }

    _.remove(applySrchFilterKeys, function (item) {
      let n = '';

      switch (filterType) {
        case 'TRX':     n = 'trxAmt'; break;
        case 'PY':      n = 'py';     break;
        case 'HHLD':    n = 'hhld';   break;
        case 'LOCALS':  n = 'locals'; break;
      }

      return (n === item);
    });

    if (!applySrchFilterKeys.length) {
      if (!exCall) {
        clearOverlay();
        overlayMap('');
      }
    }
  }

  const handleChangeTrxTypeDebounce = _.debounce(({ filterType, value, mainName, exCall }) => handleChangeTrxType({ filterType, value, mainName, exCall }), 500);
  const handleChangeStatusDebounce = _.debounce(({ filterType, value, mainName, exCall }) => handleChangeStatus({ filterType, value, mainName, exCall }), 500);
  const handleChangeLocalsDebounce = _.debounce(({ filterType, value, mainName, exCall }) => handleChangeLocals({ filterType, value, mainName, exCall }), 500);
  const handleChangeFilterDebounce = _.debounce(({ filterType, min, max, mainName, exCall }) => handleChangeFilter({ filterType, min, max, mainName, exCall }), 500);
  const subHandleChangeFilterDebounce = _.debounce(({ filterType, min, max, mainName, exCall }) =>subHandleChangeFilter({ filterType, min, max, mainName, exCall }), 500);

  const filtersRefresh = () => {
    setKeyword('');

    handleChangeTrxType({ filterType: "TRXTYPE", value: "D", mainName: "", exCall: true });
    handleChangeStatus({ filterType: "STATUS", value: "1", mainName: "", exCall: true });
    handleChangeLocals({ filterType: "LOCALS", value: [], mainName: "", exCall: true });

    handleChangeFilter({ filterType: "TRX", min: -1, max: 9999, mainName: "", exCall: true });
    handleChangeFilter({ filterType: "PY", min: -1, max: 9999, mainName: "", exCall: true });
    handleChangeFilter({ filterType: "HHLD", min: -1, max: 99999, mainName: "", exCall: true });
    handleChangeFilter({ filterType: "APR", min: -1, max: 9999, mainName: "", exCall: true });
    handleChangeFilter({ filterType: "PK", min: -1, max: 9999, mainName: "", exCall: true });

    subHandleChangeFilter({ filterType: "TRX", min: -1, max: 9999, mainName: "", exCall: true });
    subHandleChangeFilter({ filterType: "PY", min: -1, max: 9999, mainName: "", exCall: true });
    subHandleChangeFilter({ filterType: "HHLD", min: -1, max: 99999, mainName: "", exCall: true });

    clearOverlay();
    overlayMap('');

    setIsFiltersRefresh(true);
    setTimeout(() => {
      setIsFiltersRefresh(false);
    }, 200);
  }

  // 좌측 하단 위치 아이콘 클릭시
  const getLocation = () => {
    // 디바이스에서 인식하는 위치 정보(위도,경도) 받아옴
    callAppInterface("getLocation", null, result => {
      // 위치 아이콘 클릭시에만 location 값 전달
      handlePanTo(JSON.parse(result), 'location');
    });
  }

  const handlePanTo = ({ lat, lng }, btLocationCheck) => {
    // 카카오 지도 API 사용해 해당 위치로 이동
    let moveLatLng = new kakao.maps.LatLng(lat, lng); // eslint-disable-line
    map.panTo(moveLatLng);
    // 내 위치 기준 주변 아파트 정보 데이터 가져와 마커 표시
    overlayMap(btLocationCheck);
  }

  const overlayMap = async (btLocationCheck) => {
    // const verify = await axiosAuth.verify();
    //
    // switch (verify.resVerify.type) {
    //   case AXIOS_AUTH_RESULT.RE_ISSUE_SUCCESS: {
    //     const args = { token: verify.resVerify.authorization, ref: localStorage.getItem('ref'), isAuto: true };
    //
    //     localStorage.setItem('jwtToken', verify.resVerify.authorization);
    //
    //     callAppInterface('tokenToApp', JSON.stringify(args), result => {});
    //
    //     setKeyword('');
    //
    //     break;
    //   }
    //   case AXIOS_AUTH_RESULT.CHECK_FAIL: case AXIOS_AUTH_RESULT.RE_ISSUE_FAIL: {
    //     return false;
    //   }
    // }

    const latlng = map.getCenter();
    const bounds = map.getBounds();
    const sw     = bounds.getSouthWest();   // 영역의 남서 좌표
    const ne     = bounds.getNorthEast();   // 영역의 북동 좌표

    const commonPayload = {
      'lat': latlng.getLat(),
      'lng': latlng.getLng(),
      'swLat': sw.getLat(),
      'swLng': sw.getLng(),
      'neLat': ne.getLat(),
      'neLng': ne.getLng(),
      'level': map.getLevel(),
      'btType': (btLocationCheck || ''),              // 위치 정보 버튼 클릭시 파라미터 추가 (위치정보이용내역 저장)
      'os': ((osCheck() === 'Android') ? '1' : '2')
    };

    const aptPayload = {
      'trxMin': trxAmt.min,
      'trxMax': trxAmt.max,
      'pyMin': py.min,
      'pyMax': py.max,
      'hhldMin': hhld.min,
      'hhldMax': hhld.max,
      'aprMin': apr.min,
      'aprMax': apr.max,
      'pkMin': pk.min,
      'pkMax': pk.max,
      'trxType': (trxType.value || 'D')
    };

    const applyPayload = {
      'trxMin': applyTrxAmt.min,
      'trxMax': applyTrxAmt.max,
      'pyMin': applyPy.min,
      'pyMax': applyPy.max,
      'hhldMin': applyHhld.min,
      'hhldMax': applyHhld.max,
      'status': (status.value || '1'),
      'locals': ((locals.value) ? ((locals.value instanceof Array) ? locals.value.join(',') : locals.value) : '')
    };

    try {
      const { data } = await axiosRequest.getMap({ config: { params: { ...commonPayload, ...aptPayload } } });

      const positionList = [];

      if (data.code !== '0000') {
        alert('좌표 값이 정확하지 않습니다.\n재시도 부탁 드립니다.');

        return false;
      }

      if (data.list.length > 0) {
        data.list.forEach(v => {
          positionList.push(new kakao.maps.LatLng(v.lat, v.lng)); // eslint-disable-line

          if (overlayList.find(ov => ov.getPosition() && (ov.getPosition().getLat() === v.lat && ov.getPosition().getLng() === v.lng))) { // dragend 이벤트는 신규 마커만 가져온다.
            return;
          }

          let overlay = map.getLevel() < 5 ? new AptOverlay(v, map.getLevel()) : new BjdOverlay(v, map.getLevel());

          overlay.setMap(map);

          overlayList.push(overlay);

          if (map.getLevel() >= 5) {
            aroundOverlayList.forEach(ov => ov.setMap(null));
            aroundOverlayList = [];
          }

          map.getLevel() < 5 && $('#marker_' + v.markerId).click((e) => {
            e.preventDefault();

            clickMarker(v.markerId, e.currentTarget.dataset.areaId, trxType.value);
          });

          map.getLevel() >= 5 && map.getLevel() < 7 && $('#markerBjd_' + v.markerId).click(() => {
            let moveLatLng = new kakao.maps.LatLng($('#latBjd_' + v.markerId).val(), $('#lngBjd_' + v.markerId).val()); // eslint-disable-line

            map.setCenter(moveLatLng);
            map.setLevel(4);
          });

          map.getLevel() >= 7 && $('#markerBjd_' + v.markerId).click(() => {
            let moveLatLng = new kakao.maps.LatLng($('#latBjd_' + v.markerId).val(), $('#lngBjd_' + v.markerId).val()); // eslint-disable-line

            map.panTo(moveLatLng);
          });
        });
      }

      overlayList.forEach((ov, idx) => {
        if (!positionList.find(p => ov.getPosition() && (p.getLat() === ov.getPosition().getLat() && p.getLng() === ov.getPosition().getLng()))) {
          overlayList.splice(idx, 1);

          ov && ov.getPosition() && ov.setMap(null);
        }
      });

      switch (isFilterTabRef.current) {
        case SERVICE_TYPE.APART: {
          if (_.reject(overlayList, {type: SERVICE_TYPE.APPLY}).length <= 0 && $('.map_con > .map_info_wrap').length < 1) $('.map_con').append('<div class="map_info_wrap"><p class="txt">조건에 맞는 핀이 없습니다.<br>지도를 좀 더 축소하거나 필터를<br>변경해보세요.</p></div>');
          else                                                                                                                    $('.map_con > .map_info_wrap').remove();

          break;
        }
      }

      addLsItem('aptLoc', { lat: data.lat, lng: data.lng });

      if (btLocationCheck === 'location') {
        setLocationFlag(true);
      }
    } catch (err) {
      console.log(err);
    }

    try {
      const { data } = await axiosRequest.findMapApplyList({ config: { params: { ...commonPayload, ...applyPayload } } });

      if (data.response.applyList.length > 0) {
        data.response.applyList.forEach(v => {
          let overlay = new ApplyOverlay({...v, isMap: true});

          overlay.setMap(map);

          overlayList.push(overlay);
        });

        $('[id^="markerApply_"]').off('click').on('click', function(e) {
          e.preventDefault();

          const idAry = $(this).attr('id').split('_');

          openApplyDtl(idAry[1]);

          // if (idAry[2] !== 'completeFlag') {
          //   openApplyDtl(idAry[1]);
          // }
        });
      }

      switch (isFilterTabRef.current) {
        case SERVICE_TYPE.APPLY: {
          if (overlayList.length <= 0 && $('.map_con > .map_info_wrap').length < 1) $('.map_con').append('<div class="map_info_wrap"><p class="txt">조건에 맞는 핀이 없습니다.<br>지도를 좀 더 축소하거나 필터를<br>변경해보세요.</p></div>');
          else                                                                      $('.map_con > .map_info_wrap').remove();

          break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const openApplyList = async (data) => {
    let search = 'APY-1000#';

    switch (data.type) {
      case 'A': search = 'APY-1000#';  break;
      case 'B': search = 'PER-1000#';  break;
      case 'C': search = 'PAL-1000#';  break;
      case 'D': search = 'CAL-1000#';  break;
    }

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?${search}`
    })

    if (data.auth) {
      const verify = await axiosAuth.verify();

      switch (verify.resVerify.type) {
        case AXIOS_AUTH_RESULT.CHECK_SUCCESS: {
          setKeyword('');

          break;
        }
        case AXIOS_AUTH_RESULT.RE_ISSUE_SUCCESS: {
          const args = { token: verify.resVerify.authorization, ref: localStorage.getItem('ref'), isAuto: true };

          localStorage.setItem('jwtToken', verify.resVerify.authorization);

          callAppInterface('tokenToApp', JSON.stringify(args), result => {});

          setKeyword('');

          break;
        }
        case AXIOS_AUTH_RESULT.CHECK_FAIL: case AXIOS_AUTH_RESULT.RE_ISSUE_FAIL: {
          window.location.href = '/login';

          return false;

          // return <Redirect to={{ pathname: '/login', state: { from: location.pathname } }} />;
        }
      }
    }

    setLcUrlItem('aptMain', 'applyList');
    $('.map_wrap').hide();

    setApplyListDisplay(true);
    setApplyMenuType(data.type);
  }

  const openRcmdSrch = async () => {
    const verify = await axiosAuth.verify();

    switch (verify.resVerify.type) {
      case AXIOS_AUTH_RESULT.CHECK_SUCCESS: {
        setLcUrlItem('aptMain', 'rcmdSrch');

        $('.map_wrap').hide();

        // url 구분 작업
        history.push({
          pathname: location.pathname,
          search: `?REC-1000#`
        });

        setRcmdSrchDisplay(true);

        break;
      }
      case AXIOS_AUTH_RESULT.RE_ISSUE_SUCCESS: {
        const args = { token: verify.resVerify.authorization, ref: localStorage.getItem('ref'), isAuto: true };

        localStorage.setItem('jwtToken', verify.resVerify.authorization);

        callAppInterface('tokenToApp', JSON.stringify(args), result => {});

        setLcUrlItem('aptMain', 'rcmdSrch');

        $('.map_wrap').hide();

        setRcmdSrchDisplay(true);

        break;
      }
      case AXIOS_AUTH_RESULT.CHECK_FAIL: case AXIOS_AUTH_RESULT.RE_ISSUE_FAIL: {
        window.location.href = '/login';

        return false;
      }
    }
  }

  const openRealtyCalc = () => {
    setLcUrlItem('aptMain', 'realtyCalc');

    $('.map_wrap').hide();

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?AAL-1000#`
    });

    setRealtyMasterDisplay(true);
    setRealtyMenuType('A');
  }

  const openRealtyNews = () => {
    setLcUrlItem('aptMain', 'realtyNews');

    $('.map_wrap').hide();

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?AEW-1000#`
    });

    setRealtyMasterDisplay(true);
    setRealtyMenuType('B');
  }

  const openRealtyPolicy = () => {
    setLcUrlItem('aptMain', 'realtyPolicy');

    $('.map_wrap').hide();

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?POL-1000#`
    });

    setRealtyMasterDisplay(true);
    setRealtyMenuType('C');
  }

  const openFavList = (e) => {
    if ($(e.target).hasClass('active')) $(e.target).removeClass('active');
    else                                $(e.target).addClass('active');

    $('.map_wrap').hide();

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?AIF-1100#`
    });

    setFavListDisplay(true);
  }

  const openTwcChat = () => {
    setLcUrlItem('aptMain', 'twcChat');

    $('#pageWrap').hide();
    $('#frame').show();

    twcEventBind();

    window.Twc.Chat.open();
  }

  // 마이페이지 열기
  const openMyPage = async () => {
    const verify = await axiosAuth.verify();

    switch (verify.resVerify.type) {
      case AXIOS_AUTH_RESULT.CHECK_SUCCESS: {
        setMyPageInfo({ maskingPhoneNo: verify.resVerify.maskingPhoneNo, pushData: verify.resVerify.pushData, email: verify.resVerify.email });

        setIsMyPage(true);

        mapWrap.current.style.display = 'none';

        break;
      }
      case AXIOS_AUTH_RESULT.RE_ISSUE_SUCCESS: {
        let args = { token: verify.resVerify.authorization, ref: localStorage.getItem('ref'), isAuto: true };

        localStorage.setItem('jwtToken', verify.resVerify.authorization);

        callAppInterface('tokenToApp', JSON.stringify(args), result => {});

        const { data, headers, isSuccess } = await axiosAuth.check();

        if (isSuccess) {
          if (data.response.isValid) {
            setMyPageInfo({ maskingPhoneNo: headers.maskingPhoneNo, pushData: data.response.pushData, email: verify.resVerify.email });

            setTimeout(() => {
              setIsMyPage(true);

              mapWrap.current.style.display = 'none';
            }, 500);
          }
        }

        break;
      }
      case AXIOS_AUTH_RESULT.CHECK_FAIL: case AXIOS_AUTH_RESULT.RE_ISSUE_FAIL: {
        setIsMyPage(true);

        mapWrap.current.style.display = 'none';

        break;
      }
      default : {}
    }
  }

  const openMenu = () => {
    if ($('#mw-chat').length) {
      $('#mw-chat').hide();
    }

    popupOpen('menu','mwf');
  }

  // 마이페이지 닫기
  const closeMyPage = () => {
    setIsMyPage(false);

    mapWrap.current.removeAttribute('style');
  }

  // 메인개편 추가
  const filterTabHandler = (tabIndex) => {
    $('.map_bottom').show();

    if(isFilterTab === tabIndex) {
      setIsFilterTab(0);

      return false;
    }

    setIsFilterTab(tabIndex);

    setTimeout(() => {
      uiFilterTabSwipe.init();
    }, 40)
  }

  //--'open' : 현 화면이 다음 화면으로 변경되는 action
  const openApplyDtl = (applyId) => {
    setLcUrlItem('applyList', 'applyDtl');

    setLcParamItem({ 'applyId': applyId });

    // filtersRefresh();

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?APY-${applyId}#`
    })

    // 청약정보 리스트 아이템 클릭시 GA Script 호출
    callGaTag('apply_' + applyId);

    setApplyPopupType('P_A');
    setAptMainEntry(true);
  }

  const kakaoCall = ({ defaultLevel, minLevel, maxLevel, containerId }) => {
    const aptLoc = getLsItem('aptLoc');

    const lat = aptLoc && aptLoc.lat ? aptLoc.lat : 37.56600;
    const lng = aptLoc && aptLoc.lng ? aptLoc.lng : 126.97696;

    const mapContainer = document.getElementById(containerId); // 지도를 표시할 div
    const mapOption = {
      center: new kakao.maps.LatLng(lat, lng), // eslint-disable-line
      level: defaultLevel
    };

    map = new kakao.maps.Map(mapContainer, mapOption);  // eslint-disable-line

    map.setMinLevel(minLevel);
    map.setMaxLevel(maxLevel);

    kakao.maps.event.addListener(map, 'dragend', () => {// eslint-disable-line
      $('.map_con > .map_info_wrap').remove();

      overlayMap('');
    });
    kakao.maps.event.addListener(map, 'zoom_changed', () => {  // eslint-disable-line
      clearOverlay();

      overlayMap('');
    });
  };

  // const authCall = useCallback(async (callback) => {
  //   const verify = await axiosAuth.verify();
  //
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       if (!callback[verify.resVerify.type] instanceof Function) {
  //         throw false;
  //       }
  //
  //       const { data } = await callback[verify.resVerify.type](verify);
  //
  //       setTimeout(() => { resolve({ data, isSuccess: true }); }, 50);
  //     } catch (err) {
  //       reject({ data: err, isSuccess: false });
  //     }
  //   });
  // }, []);

  const page = ({ searchParams, e }) => {
    let type = null;

    setAptDtlDisplay(false);
    setRcmdSrchDisplay(false);
    setRealtyMasterDisplay(false);
    setApplyListDisplay(false);
    setFavListDisplay(false);

    $('.map_wrap').show();

    if (searchParams) {
      e.preventDefault();

      popupClose('menu', 'mwf');

      type = searchParams;
    } else {
      const sp = new URLSearchParams(window.location.search);

      for (const key of sp.keys()) {
        if (!type) {
          type = key;

          break;
        }
      }
    }

    console.log(`TYPE[ ${type} ]`);

    switch (type) {
      case 'REC-1000':  openRcmdSrch(); break;
      case 'AAL-1000':  openRealtyCalc(); break;
      case 'AEW-1000':  openRealtyNews(); break;
      case 'POL-1000':  openRealtyPolicy(); break;
      case 'APY-1000':  openApplyList({'type': 'A', 'auth': false});  break;
      case 'PAL-1000':  openApplyList({'type': 'C', 'auth': false});  break;
      case 'PER-1000':  openApplyList({'type': 'B', 'auth': true}); break;
      case 'CAL-1000':  openApplyList({'type': 'D', 'auth': true}); break;
      case 'AIF-1100':  openFavList(); break;
      default : {
        // PAL-
        // CAL-1200, 1300
        // REC-1200, AREAID
        // PER-APPLYID
        if (type) {
          const typeSplit = type.split('-');

          switch (typeSplit[0]) {
            case 'APY': {
              if (typeSplit.length === 2) {
                openApplyDtl(typeSplit[1]);
              }

              break;
            }
            case 'AIF': {
              if (typeSplit.length === 4) {
                clickMarker(`${typeSplit[1]}-${typeSplit[2]}`, typeSplit[3]);
              }

              break;
            }
            case 'aptMain': {
              // url 구분 작업
              history.push({
                pathname: location.pathname,
                search: ``
              });
            }
          }
        }
      }
    }
  };

  /* // 메인개편 추가 */

  useEffect(async () => {

    uiFixValue.init();
    // uiFilterTabSwipe.init();
    uiSelFocus.init();
    uiMainSwitch.init();
    uiChartTap.init();
    uiToggle.init();
    uiTopGo.init();

    // $(window).scrollTop(0);
    window.goback = btnClose;

    // [2021.12.29] 지도 클릭시 필터 및 버튼 영역 사라짐

    $("#mapview").on("click", function () {
      $('.map_bottom').show();
      $(".top_srch_wrap").toggleClass("hidden");
      $(".top_srch_wrap, .srch_list_wrap").removeClass("active"); /* 2022.01.20  추가*/
      // $(".top_srch_wrap,.top_srch_wrap *").removeClass("active"); /* 2022.01.20  추가*/
      $(".filter_map_btn_wrap.pos_l").toggleClass("hidden");
      $(".btn_bookmark").toggleClass("hidden");
      $(".btn_location").toggleClass("hidden");
      $(".btn_app_info").toggleClass("hidden");
      uiFilterTabSwipe.init(); /* 2022.01.20  추가*/
      uiInputDel.init();
    });

    // [2021-12-21] 돋보기 아이콘 클릭시 검색 활성화
    // 메인개편 수정 - 돋보기 눌러도 기능 없음.
    // $(".btn_srch").on("click", function () {
    //   $(".top_srch_wrap").addClass("active").addClass("_hide");
    //   $(".top_srch_wrap.active .ip_box .ip").focus();
    //   $(".srch_list_wrap").addClass("active");
    // });

    // 상단 검색 필터
    // $(".filter_tab_swipe").each(function (idx) {
    //   $(this).data("key", idx).find(".view .item").each(function (_idx) {
    //     $(this).data("ctrlKey", _idx);
    //   });
    // });
    // $(".filter_tab_swipe").find(".filter_box_wrap button").on("click", function () {
    //   var key = $(this).parents(".filter_tab_swipe").data("key");
    //   var ctrlKey = $(this).parents(".item").data("ctrlKey");
    //   //var filter_txt = $(this).text();

    //   $(".filter_tab_swipe").eq(key).find(".slide a").eq(ctrlKey).addClass("change_txt");
    //   //$(".filter_tab_swipe").eq(key).find(".slide a").eq(ctrlKey).text(filter_txt);

    //   uiFilterTabSwipe.init();
    // });

    // 상단 검색 필터 닫기
    // $(".filter_save_wrap").find(".btn_close").on("click", function () {
    //   $(".top_srch_wrap").removeClass("active").addClass("focus");
    //   uiFilterTabSwipe.init();
    // });

    // 상단 검색 필터 초기화
    // $(".filter_save_wrap .btn_reset").on("click", function () {
    //   $(".filter_tab_swipe").find(".slide").each(function (idx) {
    //     var reset_txt = $(this).find("a").data("name");
    //     $(".filter_tab_swipe").find(".slide").eq(idx).find("a").text(reset_txt);
    //   });
    //   $(document).find(".top_srch_wrap").removeClass("active").addClass("focus");
    //   $(document).find(".filter_tab_swipe").find(".slide").removeClass("active");
    //   $(document).find(".filter_tab_swipe").find(".slide a").removeClass("change_txt");
    //   uiFilterTabSwipe.init();
    // });

    // 검색 리스트 보기
    $(".top_srch_wrap .ip_box .ip").on("focus", function () {
      $(this).parents(".top_srch_wrap").addClass("active").addClass("_hide");
      $('.map_bottom').hide();
    });
    // 메인개편 추가
    $(".top_srch_wrap .ip_box .ip").on("click", function () {
      if ($(".top_srch_wrap").hasClass('active')) {
        $(".srch_list_wrap").addClass("active");
      }
    });
    $(".top_srch_wrap .ip_box .ip").on("keyup", function () {
      // if (!$.trim($(this).val()) === "") {
        if ($(this).parents().hasClass("top_srch_wrap")) {
          $(this).parents(".top_srch_wrap").addClass("active").addClass("_hide");
          $(".srch_list_wrap").addClass("active");
        }
      // }
    });

    // 검색 리스트 닫기
    $(".srch_list_wrap .inner_wrap").on("click.srch", function () {
      // $(".top_srch_wrap").removeClass("active").removeClass("_hide").addClass("focus");
      $(".top_srch_wrap").removeClass("active").removeClass("_hide");
      $(".srch_list_wrap").removeClass("active");
    });
    $(".srch_box_wrap .btn_back").on("click", function () {
      // $(".top_srch_wrap").removeClass("active").removeClass("_hide").addClass("focus");
      $(".top_srch_wrap").removeClass("active").removeClass("_hide");
      $(".srch_list_wrap").removeClass("active");
      $('.map_bottom').show();
    });

    kakaoCall({ defaultLevel: 3, minLevel: 2, maxLevel: 11, containerId: 'mapview' });

    overlayMap('');

    window.checkMainPage = () => { return false; }
    window.goback = exitToast;
    //popupOpen('toast', 'mwf');

    $('.btn_bookmark').removeClass('active');
    $('.btn_save').removeClass('active');
    $('.btn_reset').removeClass('active');

    // [SOM.220518] 로그인한 유저에게만 위치정보 icon 표시
    const verify = await axiosAuth.verify();

    switch (verify.resVerify.type) {
      case AXIOS_AUTH_RESULT.CHECK_SUCCESS: {
        setLoginFlag(true);

        break;
      }
      case AXIOS_AUTH_RESULT.RE_ISSUE_SUCCESS: {
        const args = { token: verify.resVerify.authorization, ref: localStorage.getItem('ref'), isAuto: true };

        localStorage.setItem('jwtToken', verify.resVerify.authorization);

        callAppInterface('tokenToApp', JSON.stringify(args), result => {});

        setLoginFlag(true);

        break;
      }
    }

    filterTabHandler(1);
  }, []);

  // 메인개편
  useEffect(() => {
    /* 메인개편 추가 */
    uiTab.init();

    const section = mapBottom.current.querySelectorAll('[data-scroll-section]');
    const scrollTrigger = infoHeaderNav.current.querySelectorAll('[data-scroll-trigger]');

    // 필터 열기
    filter.current.addEventListener('click', function () {
      srchWrap.current.classList.add('active');
    })

    // 홈(화살표 아래) 클릭 시
    btnHome.current.addEventListener('click', function () {
      if(!mapBottom.current.classList.contains('.scroll_start')) {
        window.scroll({
          behavior: 'smooth',
          top: 450
        })
      }
      window.scroll({
        behavior: 'smooth',
        top: 0
      })
      infoHeaderNav.current.scroll({
        left: 0
      })
    })

    // swiper
    const map_bottom_swiper = mapBottom.current.querySelectorAll('.map_bottom_swiper');
    map_bottom_swiper.forEach(function (el) {
      const swipers = new Swiper(el, {
        spaceBetween: 8,
        pagination: {
          el: ".swiper-pagination"
        },
      })
      mapBottom.current.querySelectorAll('[data-tab-target]').forEach(function (el) {
        el.addEventListener('tab.showing', function () {
          swipers.update();
        })
      })
    })

    // 스크롤 이벤트
    window.history.scrollRestoration = 'manual'; // 스크롤 캐시 안남게
    window.scrollTo(0, 450); // 첫 진입 시 스크롤 위치
    const scrollEvent = function () {
      // 컴포넌트 전환시 스크롤 이벤트 1회 발생하는 부분 방지용
      if(!mapBottom.current) {
        return;
      }
      if (window.scrollY > 25) {
        srchWrap.current.classList.remove('hidden');
        mapBottom.current.classList.add('scroll_start');
        contentNav.current.classList.remove('simple');
      } else {
        mapBottom.current.classList.remove('scroll_start');
        contentNav.current.classList.add('simple');
      }

      // 258: .map_bottom_content 첫번째 section 높이,
      // 67: .info_header의 높이.
      if (window.scrollY >= mapTop.current.offsetHeight + 258 - 67 && !mapWrap.current.classList.contains('header_mode')) {
        mapWrap.current.classList.add('header_mode');
        srchWrap.current.classList.remove('active');
        srchListWrap.current.classList.remove('active');
        infoHeaderNav.current.scroll({
          left: 0
        })
      } else if (window.scrollY < mapTop.current.offsetHeight + 258 - 67 && mapWrap.current.classList.contains('header_mode')) {
        mapWrap.current.classList.remove('header_mode');
      }

      const section1 = window.pageYOffset + section[0].getBoundingClientRect().top - 67;
      const section2 = window.pageYOffset + section[1].getBoundingClientRect().top - 67;
      const section3 = window.pageYOffset + section[2].getBoundingClientRect().top - 67;
      const section4 = window.pageYOffset + section[3].getBoundingClientRect().top - 67;

      if (window.scrollY > section1 && window.scrollY < section2) {
        if (!scrollTrigger[0].classList.contains('active')) {
          siblings(scrollTrigger[0]).forEach(function (el) {
            el.classList.remove('active');
          })
          infoHeaderNav.current.scroll({
            behavior: 'smooth',
            left: scrollTrigger[0].offsetLeft - 40
          })
          scrollTrigger[0].classList.add('active');
        }
      } else if (window.scrollY > section2 && window.scrollY < section3) {
        if (!scrollTrigger[1].classList.contains('active')) {
          siblings(scrollTrigger[1]).forEach(function (el) {
            el.classList.remove('active');
          })
          infoHeaderNav.current.scroll({
            behavior: 'smooth',
            left: scrollTrigger[1].offsetLeft - 40
          })
          scrollTrigger[1].classList.add('active');
        }
      } else if (window.scrollY > section3 && window.scrollY < section4) {
        if (!scrollTrigger[2].classList.contains('active')) {
          siblings(scrollTrigger[2]).forEach(function (el) {
            el.classList.remove('active');
          })
          infoHeaderNav.current.scroll({
            behavior: 'smooth',
            left: scrollTrigger[2].offsetLeft - 40
          })
          scrollTrigger[2].classList.add('active');
        }
      } else if (window.scrollY > section4) {
        if (!scrollTrigger[3].classList.contains('active')) {
          siblings(scrollTrigger[3]).forEach(function (el) {
            el.classList.remove('active');
          })
          infoHeaderNav.current.scroll({
            behavior: 'smooth',
            left: scrollTrigger[3].offsetLeft - 40
          })
          scrollTrigger[3].classList.add('active');
        }
      } else {
        scrollTrigger[0].classList.remove('active');
      }
    }

    window.addEventListener('scroll', scrollEvent);

    // [data-scroll-trigger] 클릭 시 해당 섹션으로
    scrollTrigger.forEach(function (el) {
      el.addEventListener('click', function () {
        window.scroll({
          behavior: 'smooth',
          top: window.pageYOffset + document.querySelector('[data-scroll-section="' + el.dataset.scrollTrigger + '"]').getBoundingClientRect().top - 60
        })
      })
    })

    // dim 영역 클릭 시 Y 스크롤 0
    mapBottom.current.addEventListener('click', function (e) {
      if (e.target.classList.contains('scroll_start')) {
        window.scroll({
          behavior: 'smooth',
          top: 0
        })
      }
    })

    return ()=>{
      window.removeEventListener("scroll", scrollEvent);
    }

  }, []);

  useEffect(() => {
    if (!applyPopupType) {
      page({});
    }
  }, [applyPopupType]);

  useEffect(() => {
    switch (isFilterTab) {
      case 1: isFilterTabRef.current = SERVICE_TYPE.APART;  break;
      case 2: isFilterTabRef.current = SERVICE_TYPE.APPLY;  break;
    }

    switch (isFilterTabRef.current) {
      case SERVICE_TYPE.APART: {
        let aptSrchFilter = getLsItem('aptSrchFilter');

        if (aptSrchFilter && Object.keys(aptSrchFilter).length > 0) {
          // aptSrchFilterKeys = Object.keys(aptSrchFilter);
          aptSrchFilterKeys = [];

          if (aptSrchFilter.trxAmt) {
            if (aptSrchFilter.trxAmt.mainName) {
              setIsTrxAmtLoad(true);
            }
          }

          if (aptSrchFilter.py) {
            if (aptSrchFilter.py.mainName) {
              setIsPyLoad(true);
            }
          }

          if (aptSrchFilter.hhld) {
            if (aptSrchFilter.hhld.mainName) {
              setIsHhldLoad(true);
            }
          }

          if (aptSrchFilter.apr) {
            if (aptSrchFilter.apr.mainName) {
              setIsAprLoad(true);
            }
          }

          if (aptSrchFilter.pk) {
            if (aptSrchFilter.pk.mainName) {
              setIsPkLoad(true);
            }
          }

          setIsFiltersLoad(true);

          setTimeout(() => {
            setIsFiltersLoad(false);

            setTimeout(() => {
              trxType = { filterType: 'TRXTYPE', value: aptSrchFilter.trxType.value, mainName: aptSrchFilter.trxType.mainName };

              handleChangeTrxType(trxType);
            }, 200);
          }, 200);
        }

        break;
      }
      case SERVICE_TYPE.APPLY: {
        let applySrchFilter = getLsItem('applySrchFilter');

        if (applySrchFilter && Object.keys(applySrchFilter).length > 0) {
          // applySrchFilterKeys = Object.keys(applySrchFilter);
          applySrchFilterKeys = [];

          if (applySrchFilter.locals) {
            if (applySrchFilter.locals.mainName) {
              setIsLocalsLoad(true);
            }
          }

          if (applySrchFilter.trxAmt) {
            if (applySrchFilter.trxAmt.mainName) {
              setIsApplyTrxAmtLoad(true);
            }
          }

          if (applySrchFilter.py) {
            if (applySrchFilter.py.mainName) {
              setIsApplyPyLoad(true);
            }
          }

          if (applySrchFilter.hhld) {
            if (applySrchFilter.hhld.mainName) {
              setIsApplyHhldLoad(true);
            }
          }

          setIsFiltersLoad(true);

          setTimeout(() => {
            setIsFiltersLoad(false);

            setTimeout(() => {
              status = { filterType: 'STATUS', value: applySrchFilter.status.value, mainName: applySrchFilter.status.mainName };

              handleChangeStatus(status);

              // locals = { filterType: 'LOCALS', value: applySrchFilter.locals.value, mainName: applySrchFilter.locals.mainName };
              //
              // handleChangeLocals(locals);
            }, 200);
          }, 200);
        }

        break;
      }
    }
  }, [isFilterTab]);

  const exitToast = () => {
    popupOpen('toast', 'mwf');
    window.checkMainPage = () => { return true; }
    setTimeout(() => {
      popupClose('toast', 'mwf');
      window.checkMainPage = () => { return false; }
    }, 1000);
  }

  const btnClose = (e) => {
    $('.top_srch_wrap').removeClass('active').addClass('focus');

    // uiFilterTabSwipe.init();
  };

  const clickMarker = (markerId, aptAreaId, trxType) => {
    callGaTag('apt_' + markerId); // GA Script 호출

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?AIF-${markerId}-${aptAreaId}#`
    });

    setAptId(markerId);
    setAptAreaId(aptAreaId);
    setAptDtlDisplay(true);
  }

  const debounceChangeKeyword = (e) => {
    const { target: { value } } = e;

    switch (isFilterTabRef.current) {
      case SERVICE_TYPE.APPLY: {
        setRecentFocus(false);
      }
    }

    setKeyword(value);
    setSearchVisible(true);

    handleChangeKeyword(value);
  }

  const handleChangeKeyword = _.debounce(async (keyword) => {
    switch (isFilterTabRef.current) {
      case SERVICE_TYPE.APART: {
        if (keyword) {
          try {
            const { data } = await axiosRequest.findAptList({ config: { params: { keyword: keyword, searchType: ['A', 'B'] } } });

            if (data) {
              keyword && setSearchResult(data);
              setSearchIndex(-1);
              setRecentFocus(false);
            }
          } catch (err) {
            console.log(err);
          }
        } else {
          let recentData = getRecentItem('apartMap');

          setSearchResult([]);
          setSearchIndex(-1);
          setRecentFocus(true);
          setRecentResult(recentData);
        }

        break;
      }
      case SERVICE_TYPE.APPLY: {
        const recentData = getRecentItem('applyMap');

        if (keyword) {
          const payload = {
            'trxMin': (trxAmt.min || -1),
            'trxMax': (trxAmt.max || 9999),
            'pyMin': (py.min || -1),
            'pyMax': (py.max || 9999),
            'hhldCntMin': (hhld.min || -1),
            'hhldCntMax': (hhld.max || 9999),
            'status': (status.value || '1'),
            'locals': ((locals.value) ? ((locals.value instanceof Array) ? locals.value.join(',') : locals.value) : ''),
            'aptName': (keyword || ''),
          };

          const { data } = await axiosRequest.findApplyList({ config: { params: payload } });

          keyword && setSearchResult(data.response.applyList);
          setSearchIndex(-1);
          setRecentFocus(false);
          setRecentResult(recentData);
        } else {
          let recentData = getRecentItem('applyMap');

          setSearchResult([]);
          setSearchIndex(-1);
          setRecentFocus(true);
          setRecentResult(recentData);
        }

        break;
      }
    }
  }, 300);

  const handleKeydownKeyword = (e) => {
    const keyCode = e.keyCode;

    if (!searchResult.length > 0) {
      return;
    }

    switch (keyCode) {
      case 13: {  // enterKey
        if (searchIndex === -1) handleSelectSearch(searchResult[0]);
        else                    handleSelectSearch(searchResult[searchIndex]);

        break;
      }
      case 38: {  // uparrowKey
        setSearchIndex((searchIndex) ? (searchIndex - 1) : 0);

        break;
      }
      case 40: {  // downarrowKey
        const maxIndex = (searchResult.length - 1);

        setSearchIndex((searchIndex >= maxIndex) ? maxIndex : searchIndex + 1);

        break;
      }
    }
  }

  const handleSelectSearch = async (search) => {
    search['regTime'] = Moment(new Date()).format('YYYY. MM. DD');

    try {
      switch (isFilterTabRef.current) {
        case SERVICE_TYPE.APART: {
          if (!search.lat || !search.lng) {
            clearRecentItem('apartMap');

            alert('잘못된 접근 입니다. 다시 시도해 주세요.');

            window.location.href = '/';

            return false;
          }

          addRecentItem('apartMap', search);

          const { data } = await axiosRequest.findAptList({ config: { params: { keyword: search.searchName, searchType: ['A', 'B'] } } });

          if (data) {
            search.searchName && setSearchResult(data);
            setKeyword(search.searchName);
            setSearchVisible(false);

            clearOverlay();

            let moveLatLng = new kakao.maps.LatLng(search.lat, search.lng); // eslint-disable-line

            map.panTo(moveLatLng);

            $('.btn_back').click();

            overlayMap('');

            switch (search.searchType) {
              case 'A': {
                if (search.searchId) {
                  let recentArr = null;

                  switch (trxType.value) {
                    case TRX_TYPE.D: recentArr = search.recentD.split('_');  break;
                    case TRX_TYPE.R: recentArr = search.recentR.split('_');  break;
                  }

                  clickMarker(search.searchId, recentArr[1], recentArr[0]);
                }

                break;
              }
            }
          }

          break;
        }
        case SERVICE_TYPE.APPLY: {
          if (!search.lat || !search.lng) {
            clearRecentItem('applyMap');

            alert('잘못된 접근 입니다. 다시 시도해 주세요.');

            window.location.href = '/';

            return false;
          }

          addRecentItem('applyMap', search);

          const payload = {
            'trxMin': (trxAmt.min || -1),
            'trxMax': (trxAmt.max || 9999),
            'pyMin': (py.min || -1),
            'pyMax': (py.max || 9999),
            'hhldCntMin': (hhld.min || -1),
            'hhldCntMax': (hhld.max || 9999),
            'status': (status.value || '1'),
            'locals': ((locals.value) ? ((locals.value instanceof Array) ? locals.value.join(',') : locals.value) : ''),
            'aptName': (keyword || ''),
          };

          const { data } = await axiosRequest.findApplyList({ config: { params: payload } });

          if (data.response.applyList.length > 0) {
            search.searchName && setSearchResult(data.response.applyList);
            setKeyword(search.searchName);
            setSearchVisible(false);

            clearOverlay();

            let moveLatLng = new kakao.maps.LatLng(search.lat, search.lng); // eslint-disable-line

            map.panTo(moveLatLng);

            $('.btn_back').click();

            overlayMap('');

            if (search.searchId) {
              openApplyDtl(search.applyId);
            }
          }

          break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleClearRecent = () => {
    let serviceMap = '';

    switch (isFilterTabRef.current) {
      case SERVICE_TYPE.APART: serviceMap = 'apartMap'; break;
      case SERVICE_TYPE.APPLY: serviceMap = 'applyMap'; break;
    }

    clearRecentItem(serviceMap);

    setRecentResult([]);
  }

  const handleRemoveRecent = (searchId) => {
    let serviceMap = '';

    switch (isFilterTabRef.current) {
      case SERVICE_TYPE.APART: serviceMap = 'apartMap'; break;
      case SERVICE_TYPE.APPLY: serviceMap = 'applyMap'; break;
    }

    let recentList = getRecentItem(serviceMap);

    if (recentList.some(f => f.searchId === searchId)) {
      recentList.splice(findIndex(recentList, f => f.searchId === searchId), 1);
    }

    setRecentList(serviceMap, recentList);

    setRecentResult(recentList);
  }

  // const handleSelectSearch = (search) => {
  //   search.regTime = Moment(new Date()).format('YYYY. MM. DD');
  //   addRecentItem('apartMap', search);
  //
  //   searchAptBjd(search.searchName, (data) => {
  //     search.searchName && //this.setState({ searchResult: data, keyword: search.searchName, searchVisible: false });
  //       setSearchResult(data);
  //     setKeyword(search.searchName);
  //     setSearchVisible(false);
  //     clearOverlay();
  //     //overlayMap('');
  //
  //     let moveLatLng = new kakao.maps.LatLng(search.lat, search.lng); // eslint-disable-line
  //     map.panTo(moveLatLng);
  //     $('.btn_back').click();
  //     overlayMap('');
  //
  //     //map && overlayMap(map, () => {
  //     if (search.searchId != null && search.searchType === 'A') {
  //       let recentArr = null;
  //       if (trxType.value == 'D') {
  //         recentArr = search.recentD.split('_');
  //       }
  //       else if (trxType.value == 'R') {
  //         recentArr = search.recentR.split('_');
  //       }
  //       clickMarker(search.searchId, recentArr[1], recentArr[0]);
  //     }
  //     //});
  //   })
  //
  //   //this.props.onSearch(search);
  // }

  const fetchAptDtl = (aptId, trxType, callback) => {
    if (aptDtl && aptDtl.length > 0) return;
    else {
      axios
        .get(`apt/map/aptDtl?aptId=${aptId}&trxType=${trxType}`)
        .then(callback);
    }
  }

  const renderingAroundDataIcons = (aptDtl) => {

    aroundOverlayList.forEach(ov => ov.setMap(null));
    aroundOverlayList = [];

    aptDtl.schoolList.forEach(v => {
      v.type = 'school';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    aptDtl.departList.forEach(v => {
      v.type = 'ico_mart';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    aptDtl.stationList.forEach(v => {
      v.type = 'ico_subway';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    aptDtl.hospitalList.forEach(v => {
      v.type = 'ico_hospital';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    aptDtl.parkList.forEach(v => {
      v.type = 'ico_park02';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    //$('.map_marker_icon').click((e) => this.handlePanTo(e.currentTarget.dataset));

  }

  //--'open' : 현 화면이 다음 화면으로 변경되는 action
  const openPopDiv = () => {
    setPopDivDisplay(true);
  }

  const openAppVersion = () => {
    $('.map_wrap').hide();
    setAppVersionDisplay(true);
  }

  const openApplyPredict = () => {
    let os = osCheck() == 'Android' ? "A" : "I";
    axios.get('apt/authorized/check',
      { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }, timeout: 1000 }).then(res => {
        if (res.data.response.isValid) {
          setLcUrlItem('aptMain', 'applyPredict');
          $('.map_wrap').hide();
          setApplyMenuType('B');
          setApplyListDisplay(true);
          setTimeout(() => {
            $('.bg_body_full').remove();
            uiFixValue.init();
          }, 100);
        }
        else {
          window.location.href = '/login';
          //const param = { type: 'I', url: '/login' }
          //callAppInterface('openWebPage', JSON.stringify(param), result => {
          //alert(result);
          //});
        }
      }).catch(err => {
        if (localStorage.getItem('isAutoLogin') == 'true') {
          // 자동로그인 적용시 사용
          if (err.response.status === 401 || err.response.status === 403) {
            axios.post('apt/authorized/reIssue',
              { 'ref': localStorage.getItem('ref'), 'moLogin': true },
              { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } }).then(res => {
                if (res.status === 200) {
                  localStorage.setItem('jwtToken', res.headers.authorization);

                  callAppInterface("tokenToApp", JSON.stringify({
                    token: res.headers.authorization,
                    ref: localStorage.getItem('ref'),
                    isAuto: true,
                  }), result => {

                  });

                  setLcUrlItem('aptMain', 'applyPredict');
                  $('.map_wrap').hide();
                  setApplyMenuType('B');
                  setApplyListDisplay(true);
                  setTimeout(() => {
                    $('.bg_body_full').remove();
                    uiFixValue.init();
                  }, 100);
                }
                else {
                  window.location.href = '/login';
                  //const param = { type: 'I', url: '/login' }
                  //callAppInterface('openWebPage', JSON.stringify(param), result => {
                  //alert(result);
                  //});
                }
              }).catch(err => {
                window.location.href = '/login';
                //const param = { type: 'I', url: '/login' }
                //callAppInterface('openWebPage', JSON.stringify(param), result => {
                //alert(result);
                //});
              });
          }
        }
        else {
          window.location.href = '/login';
          //const param = { type: 'I', url: '/login' }
          //callAppInterface('openWebPage', JSON.stringify(param), result => {
          //alert(result);
          //});
        }
      });
  }

  /*
                <a href="#/" className="btn" onClick={getLocation} >위치정보</a>
                <a href="#/" className="btn" onClick={getPushToken} >토큰정보</a>
                <a href="#/" className="btn" onClick={openInWebPage} >새창열기(앱내)</a>
                <a href="#/" className="btn" onClick={openOutWebPage} >새창열기(앱외)</a>
  */

  const getPushToken = () => {
    callAppInterface("getPushToken", null, result => {
      alert(result);
    });
  }

  const openInWebPage = () => {
    callAppInterface("openWebPage", JSON.stringify({ type: 'I', url: 'https://www.naver.com' }), result => {
      alert(result);
    });
  }

  const openOutWebPage = () => {
    callAppInterface("openWebPage", JSON.stringify({ type: 'O', url: 'https://www.naver.com' }), result => {
      alert(result);
    });
  }

  const logout = () => {
    window.location.href = '/logout';
  }

  /////
  // const pushUpdate = () => {
  //   axios.post('api/app/pushUpdate', { "type": "P001", "status": "N" }).then(res => {
  //     alert(res.data.result.type + '' + res.data.result.status);
  //   });
  // }

  const twcEventBind = () => {
    /**
     * 웹챗 오픈
     * */
    window.Twc.Chat.on('OpenChatroom', function () {console.log('OpenChatroom');});

    /**
     * 메시지(NEW) 뱃지
     * */
    window.Twc.Chat.on('NewMessage', function (val) {console.log('NewMessage');});

    /**
     * 상담종료
     * */
    window.Twc.Chat.on('EndCounsel', function () {
      setLcUrlItem('twcChat', 'aptMain');

      setTimeout(() => {
        $('#pageWrap').show();
        $('#frame').hide();
      }, 1000);
    });

    /**
     * 상담사 연결
     * */
    window.Twc.Chat.on('OnCounselor', function () {console.log('OnCounselor');});
  }

  // [SOM.220530] 스타벅스 관심사 프로모션 '다시보지않기' 버튼 체크 조건 추가
  //const neverOpenToken = localStorage.getItem('interestEventPop01Open');

  //console.log('Render!!!!!!!' + isFilterTabRef.current);
  return (
    <>
      {/* //mainPopup 메인 팝업 */}
      {/* {popDivDisplay && <PopDiv onPopDivDisplay={setPopDivDisplay} onRcmdSrchDisplay={setRcmdSrchDisplay} onRcmdListDisplay={setRcmdListDisplay} onApplyListDisplay={setApplyListDisplay} onApplyMenuType={setApplyMenuType} ></PopDiv>} */}

      {/* //aptDtl 아파트정보 상세 */}
      {aptDtlDisplay && <AptDtl aptId={aptId} aptAreaId={aptAreaId} trxType={trxType.value} onAptDtlDisplay={setAptDtlDisplay} onApplyListDisplay={setApplyListDisplay} onApplyMenuType={setApplyMenuType} />}

      {/* //rcmd 아파트추천 관련 */}
      {rcmdSrchDisplay && <RcmdSrch onRcmdSrchDisplay={setRcmdSrchDisplay} onRealtyMasterDisplay={setRealtyMasterDisplay} onRealtyMenuType={setRealtyMenuType} onApplyListDisplay={setApplyListDisplay} onApplyMenuType={setApplyMenuType} />}

      {/* //realty 부동산계산기/뉴스/정책 3종 */}
      {realtyMasterDisplay && <RealtyMaster onRealtyMasterDisplay={setRealtyMasterDisplay} realtyMenuType={realtyMenuType} />}

      {/* //apply 청약정보 관련 */}
      {applyListDisplay && <ApplyList onApplyListDisplay={setApplyListDisplay} applyMenuType={applyMenuType} page={page} />}

      {/* // 마이페이지 추가 */}
      {isMyPage && <MyPage isMyPage={isMyPage} myPageInfo={myPageInfo} setMyPageInfo={setMyPageInfo} closeMyPage={closeMyPage} />}

      {/* //fav 즐겨찾기 */}
      {favListDisplay && <FavList onFavListDisplay={setFavListDisplay} onMoveDtl={clickMarker} />}

      {/* //appVersion 앱버전정보 */}
      {/* {appVersionDisplay && <AppInfo></AppInfo>} */}



      {/* //아파트정보 지도 등 */}
      <div className="map_wrap" ref={mapWrap}>
        <section className="fix top">
          <div className="top_srch_wrap type1" ref={srchWrap}>
            <div className="info_header">
              <div className="info_header_default">
                <button className="btn_home" ref={btnHome}><i className="blind">홈으로</i></button>
                <div className="side">
                  {/* 2022.08.05 - GTM CLASS 추가 */}
                  <button type="button" onClick={() => openMyPage()} className="btn_my GTM-MAIN-MYPAGE"><i className="blind">마이페이지</i></button>
                  <button type="button" onClick={() => openMenu()} className="btn_menu GTM-MAIN-MENU"><i className="blind">메뉴</i></button>
                </div>
              </div>
              <nav className="info_header_nav">
                <div className="info_header_nav_scroll" ref={infoHeaderNav}>
                  {/* 2022.08.05 - GTM CLASS 추가 */}
                  <button type="button" className="btn_round GTM-MAIN-SUBNAV-SCROLL-1" data-scroll-trigger="section1">최근 청약 정보</button>
                  <button type="button" className="btn_round GTM-MAIN-SUBNAV-SCROLL-2" data-scroll-trigger="section2">청약예상시세차익</button>
                  <button type="button" className="btn_round GTM-MAIN-SUBNAV-SCROLL-3" data-scroll-trigger="section3">내 위치와 가까운 청약 아파트</button>
                  <button type="button" className="btn_round GTM-MAIN-SUBNAV-SCROLL-4" data-scroll-trigger="section4">입지 정보 랭킹</button>
                </div>
              </nav>
            </div>
            <div className="srch_box_wrap">
              {/* <button type="button" className="btn_allim"><i>알림</i></button> */}
              <button type="button" className="btn_back"><i>뒤로가기</i></button>
              <div className="inner_wrap">
                <div className="form">
                  <div className="ip_box ip_del">
                    {/* 2022.08.05 - GTM CLASS 추가 */}
                    <input type="text" autoComplete="off" id="srch_input" className="ip GTM-MAIN-SEARCH"
                      placeholder={keyword ? '' : '어느 지역 아파트가 궁금하세요?'}
                      onChange={debounceChangeKeyword}
                      onFocus={debounceChangeKeyword}
                      onKeyDown={handleKeydownKeyword}
                      value={keyword}
                    />
                    <button type="button" className="del"><i>삭제</i></button>
                  </div>
                </div>
                <span className="btn_srch"><i>검색</i></span>
                <button type="button" className="btn_filter" ref={filter}><i>필터</i></button>
              </div>
              {/* 2022.08.05 - GTM CLASS 추가 */}
              <a href="#" className="recomm GTM-MAIN-APT-RECOMM" onClick={() => openRcmdSrch()}>아파트<strong>추천</strong></a>
            </div>
            <div className="filter_check_wrap">
              {/* 2022.08.05 - GTM CLASS 추가 */}
              <button type="button" className={isFilterTab === 1 ? 'check_btn active GTM-MAIN-FILTER-APT-INFO' : 'check_btn GTM-MAIN-FILTER-API-INFO' } onClick={() => filterTabHandler(1)}>아파트정보</button>
              <button type="button" className={isFilterTab === 2 ? 'check_btn active GTM-MAIN-FILTER-APPLY-INFO' : 'check_btn GTM-MAIN-FILTER-API-INFO' } onClick={() => filterTabHandler(2)}>청약정보</button>
            </div>
            {/* 아파트정보 */}
            {isFilterTab === 1 && <div className="filter_tab_swipe">
              <MainFilter filterName={filterName} />
              <div className="view">
                <div className="item" style={{ "display": "block" }}>
                  <div className="filter_box_wrap">
                    <button type="button" id="btnTrxTypeD" className={`${trxType.value === 'D' ? "btn active" : "btn"}`} onClick={() => handleChangeTrxTypeDebounce({ filterType: "TRXTYPE", value: "D", mainName: "매매" })}>매매</button>
                    <button type="button" id="btnTrxTypeR" className={`${trxType.value === 'R' ? "btn active" : "btn"}`} onClick={() => handleChangeTrxTypeDebounce({ filterType: "TRXTYPE", value: "R", mainName: "전·월세" })}>전·월세</button>
                  </div>
                </div>
                <TrxAmtFilter onChangeFilter={handleChangeFilterDebounce} refresh={isFiltersRefresh} load={isFiltersLoad} checkLoad={isTrxAmtLoad} />
                <PyFilter onChangeFilter={handleChangeFilterDebounce} refresh={isFiltersRefresh} load={isFiltersLoad} checkLoad={isPyLoad} />
                <HhldFilter onChangeFilter={handleChangeFilterDebounce} refresh={isFiltersRefresh} load={isFiltersLoad} checkLoad={isHhldLoad} />
                <AprFilter onChangeFilter={handleChangeFilterDebounce} refresh={isFiltersRefresh} load={isFiltersLoad} checkLoad={isAprLoad} />
                <PkFilter onChangeFilter={handleChangeFilterDebounce} refresh={isFiltersRefresh} load={isFiltersLoad} checkLoad={isPkLoad} />
              </div>
            </div>}
            {/* 청약정보 */}
            {isFilterTab === 2 && <div className="filter_tab_swipe">
              <SubsMainFilter filterName={subFilterName} />
              <div className="view">
                <div className="item" style={{ "display": "block" }}>
                  <div className="filter_box_wrap">
                    <button type="button" id="btnStatus1" className={`${status.value === '1' ? "btn active" : "btn"}`} onClick={() => handleChangeStatusDebounce({ filterType: "STATUS", value: "1", mainName: "전체" })}>전체</button>
                    <button type="button" id="btnStatus2" className={`${status.value === '2' ? "btn active" : "btn"}`} onClick={() => handleChangeStatusDebounce({ filterType: "STATUS", value: "2", mainName: "진행" })}>진행</button>
                    {/*<button type="button" id="btnStatus3" className={`${status.value === '3' ? "btn active" : "btn"}`} onClick={() => handleChangeStatusDebounce({ filterType: "STATUS", value: "3", mainName: "예정" })}>예정</button>*/}
                    <button type="button" id="btnStatus4" className={`${status.value === '4' ? "btn active" : "btn"}`} onClick={() => handleChangeStatusDebounce({ filterType: "STATUS", value: "4", mainName: "완료" })}>완료</button>
                  </div>
                </div>
                {/*<SubsLocalsFilter onChangeLocals={handleChangeLocals} refresh={isFiltersRefresh} load={isFiltersLoad} checkLoad={isLocalsLoad} />*/}
                <SubsTrxAmtFilter onChangeFilter={subHandleChangeFilterDebounce} refresh={isFiltersRefresh} load={isFiltersLoad} checkLoad={isApplyTrxAmtLoad} />
                <SubsPyFilter onChangeFilter={subHandleChangeFilterDebounce} refresh={isFiltersRefresh} load={isFiltersLoad} checkLoad={isApplyPyLoad} />
                <SubsHhldFilter onChangeFilter={subHandleChangeFilterDebounce} refresh={isFiltersRefresh} load={isFiltersLoad} checkLoad={isApplyHhldLoad} />
              </div>
            </div>}
            <div className="filter_save_wrap">
              <ul className="btn_list">
                <li><button type="button" className="btn_bookmark" onClick={(e) => openFavList(e)}>즐겨찾기</button></li>
                {/*<li><button type="button" className="btn_save" onClick={(e) => saveFilter(e)}>필터저장</button></li>*/}
                <li><button type="button" className="btn_reset" onClick={() => filtersRefresh()}>선택 초기화</button></li>
              </ul>
              <button type="button" className="btn_close" onClick={(e) => btnClose(e)}>상세 검색 닫기</button>
            </div>
            <div className="srch_list_wrap" ref={srchListWrap}>
              <div className="inner_wrap">
                {
                  recentFocus ?
                    <RecentResult
                      recentResult={recentResult}
                      onClearRecent={handleClearRecent}
                      onRemoveRecent={handleRemoveRecent}
                      onSelectRecent={handleSelectSearch}
                    ></RecentResult>
                    : <SearchResult
                      searchResult={searchResult}
                      searchIndex={searchIndex}
                      searchKeyword={keyword}
                      searchVisible={searchVisible}
                      onSelectSearch={handleSelectSearch}></SearchResult>
                }
              </div>
            </div>
          </div>
        </section>

        {/* //버튼 등 영역 */}
        <div id="content">
          {/* <!-- 지도 api 영역 --> */}
          {/* 메인개편 .map_top 추가 */}
          <div className="map_top" ref={mapTop}>
            <div className="map_con" id="mapview"></div>
            {/* 2022.07.18 추가 */}
            { loginFlag && <><button type="button" className="btn_location" onClick={getLocation}>위치정보</button></> }
          </div>
          {/* <!-- //지도 api 영역 --> */}

          {/* 하단 콘텐츠 - 메인개편 추가 */}
          <div className="map_bottom" ref={mapBottom}>
            <div className="map_bottom_content">
              {/* 청약 */}
              <section>
                <h2 className="content_title lg">청약</h2>
                <nav className="content_nav" ref={contentNav}>
                  <ul>
                    <li>
                      {/* 2022.08.05 - GTM CLASS 추가 - 이하 4개 */}
                      <button type="button" className="content_nav_link bg_type1 GTM-MAIN-SUBSNAV-1" onClick={() => openApplyList({'type': 'A', 'auth': false})}>
                        <img src={process.env.REACT_APP_ENV_URL + "resources/images/icon_doc.png"} alt="문서" className="img_default" aria-hidden="true" />
                        <img src={process.env.REACT_APP_ENV_URL + "resources/images/icon_doc_black.png"} alt="문서" className="img_simple" aria-hidden="true" />
                        <strong>청약정보</strong>
                        <p>최근 청약<br />모집공고를 한눈에</p>
                      </button>
                    </li>
                    <li>
                      <button type="button" className="content_nav_link bg_type2 GTM-MAIN-SUBSNAV-2" onClick={() => openApplyList({'type': 'B', 'auth': true})}>
                        <img src={process.env.REACT_APP_ENV_URL + "resources/images/icon_percent.png"} alt="퍼센트" className="img_default" aria-hidden="true" />
                        <img src={process.env.REACT_APP_ENV_URL + "resources/images/icon_percent_black.png"} alt="퍼센트" className="img_simple" aria-hidden="true" />
                        <strong>당첨가능성</strong>
                        <p>나에게 가장 유리한<br />청약은 어디?</p>
                      </button>
                    </li>
                    <li>
                      <button type="button" className="content_nav_link GTM-MAIN-SUBSNAV-3" onClick={() => openApplyList({'type': 'D', 'auth': true})}>
                        <img src={process.env.REACT_APP_ENV_URL + "resources/images/icon_won.png"} alt="화폐:원" className="img_default img_simple" aria-hidden="true" />
                        <strong>비용계산기</strong>
                        <p>청약 당첨되면<br />얼마나 필요할까?</p>
                      </button>
                    </li>
                    <li>
                      <button type="button" className="content_nav_link GTM-MAIN-SUBSNAV-4" onClick={() => openApplyList({'type': 'C', 'auth': false})}>
                        <img src={process.env.REACT_APP_ENV_URL + "resources/images/icon_calc.png"} alt="계산기" className="img_default img_simple" aria-hidden="true" />
                        <strong>가점계산기</strong>
                        <p>내 청약 가점은<br />몇 점?</p>
                      </button>
                    </li>
                    <li>
                      <button type="button" className="content_nav_link" onClick={()=>{history.push("/coupon");}}>
                        <img src={process.env.REACT_APP_ENV_URL + "resources/images/icon_coupon.png"} alt="계산기" className="img_default img_simple" aria-hidden="true"/>
                          <strong>쿠폰혜택</strong>
                          <p>매월 최대<br/>3,000원 쿠폰 제공!</p>
                      </button>
                    </li>
                  </ul>
                </nav>
              </section>
              <ApplyInfoList openApplyDtl={openApplyDtl}/>
              <MarginPriceList />
              <MyLocApplyList openApplyDtl={openApplyDtl} getLocation={getLocation} loginFlag={loginFlag} locationFlag={locationFlag} />
              <AvgApplyScoreList />
              <SubFooter />
            </div>
          </div>
          {/* // 하단 콘텐츠 */}
        </div>

      </div>

      <div id="mw-toast" className="ly_pop ly_toast popBtm ndim">
        <div className="wrap">
          <div className="content">
            <p>뒤로가기를 한번 더 누르시면 앱이 종료됩니다.</p>
          </div>
        </div>
      </div>

      {applyPopupType === 'P_A' && <ApplyDtl onApplyMenuType={setApplyMenuType} onApplyPopupType={setApplyPopupType} aptMainEntry={aptMainEntry}></ApplyDtl>}
      {applyPopupType === 'P_B' && <ApplyDtlMap onApplyMenuType={setApplyMenuType} onApplyPopupType={setApplyPopupType} aptMainEntry={aptMainEntry}></ApplyDtlMap>}

      {/* 메뉴 - 메인개편 추가 */}
      <MenuPopup page={page} />
      {/* // 메뉴 */}

      {/* 챗봇 - 2022.08.23 추가 */}
      {
        (!aptDtlDisplay && !rcmdSrchDisplay && !realtyMasterDisplay && !applyListDisplay && !favListDisplay && !isMyPage) ?
        (!(os() === 'Apple iOS' && version()[0] < 15)) && <div id="mw-chat" className="ly_chat">
          <div className="cont">
            <button type="button" onClick={() => popupClose('chat', 'mwf')} className="popclose"></button>
            <a href="#" onClick={() => openTwcChat()}><img src={process.env.REACT_APP_ENV_URL + "resources/images/ico_chat.png"} alt="챗봇" /></a>
          </div>
        </div>
            :
        null
      }
      {/* // 챗봇 */}
    </>
  );
}

export default AptMain;