import React, { Component } from 'react';

import { R_MATE_CHART_H5_LAYOUT } from './layout/Layout';
import { numberWithCounts } from 'routes/common/StringUtil';
import Moment from 'moment';

export default class RMateChartH5 extends Component {

  constructor(props) {
    super(props);

    this.chart = window.rMateChartH5;
  }

  componentDidMount() {
    setTimeout(() => {
      this.create();

      if (this.props.layout) this.setLayout(this.initLayout());
      if (this.props.data)   this.setData(this.initData());
    }, 500);
  }

  componentDidUpdate() {
    setTimeout(() => {
      if (this.props.layout) this.setLayout(this.initLayout());
      if (this.props.data)   this.setData(this.initData());
    }, 500);
  }

  componentWillUnmount() {
    if (this.chart.get(this.props.chartId + this.props.layout)) {
      this.destroy();
    }
  }

  create() {
    this.chart.create((this.props.chartId + this.props.layout), this.props.chartId, this.props.chartVars, this.props.width, this.props.height);
  }

  call(...params) {
    this.chart.call((this.props.chartId + this.props.layout), ...params);
  }

  destroy() {
    this.call('destroy', (this.props.chartId + this.props.layout));
  }

  setLayout(layout) {
    this.call('setLayout', layout);
  }

  setData(data) {
    this.call('setData', data);
  }

  initLayout() {
    const props = this.props;

    const weight = 0.2;

    let paramObj = {};

    let minimum = 0;
    let maximum = 0;

    switch (props.chartId) {
      case 'rMateDivAptDtl': case 'rMateDivAptRcmdDtl': {
        const vAxisKey = ['actCnt', 'maxDpsAmt'];

        vAxisKey.map(axisItem => {
          maximum = Math.max(...(props.data.map(dataItem => (axisItem === 'maxDpsAmt') ? dataItem[axisItem] / 10000 : dataItem[axisItem])));

          if (Math.abs(maximum) === Infinity) maximum = 100;
          else                                maximum += (maximum * weight);

          paramObj = { ...paramObj, [('minimum'.concat(axisItem))]: minimum, [('maximum'.concat(axisItem))]: maximum };
        });

        if (props.data) {
          if (props.data.length > 10) paramObj = { ...paramObj, 'visibleItemSize': 10 , 'linear': 'Linear', 'renderer': 'ScrollableAxis' };
          else                        paramObj = { ...paramObj, 'visibleItemSize': props.data.length, 'linear': '', 'renderer': 'Axis2D' };
        }

        break;
      }
      case 'rMateDiv4': {
        const vAxisKey = ['totalReqCnt', 'totalGeCnt'];

        vAxisKey.map(axisItem => {
          maximum = Math.max(...(props.data.map(dataItem => dataItem[axisItem])));

          if (Math.abs(maximum) === Infinity) maximum = 100;
          else                                maximum += (maximum * weight);

          paramObj = { ...paramObj, [('minimum'.concat(axisItem))]: minimum, [('maximum'.concat(axisItem))]: maximum };
        });

        if (props.data) {
          if (props.data.length > 10) paramObj = { ...paramObj, 'visibleItemSize': 10 , 'linear': 'Linear', 'renderer': 'ScrollableAxis' };
          else                        paramObj = { ...paramObj, 'visibleItemSize': props.data.length, 'linear': '', 'renderer': 'Axis2D' };
        }

        break;
      }
      case 'rMateDiv13_1': {
        const vAxisKey = ['trxDateMonthlyCnt'];

        vAxisKey.map(axisItem => {
          maximum = Math.max(...(props.data.map(dataItem => dataItem[axisItem])));

          if (Math.abs(maximum) === Infinity) maximum = 100;
          else                                maximum += (maximum * weight);

          paramObj = { ...paramObj, [('minimum'.concat(axisItem))]: minimum, [('maximum'.concat(axisItem))]: maximum };
        });

        if (props.data) {
          if (props.data.length > 12) paramObj = { ...paramObj, 'visibleItemSize': 12 , 'linear': 'Linear', 'renderer': 'ScrollableAxis' };
          else                        paramObj = { ...paramObj, 'visibleItemSize': props.data.length, 'linear': '', 'renderer': 'Axis2D' };
        }

        break;
      }
      default : {}
    }

    return new Function(Object.keys(paramObj).join(','), `return \`${R_MATE_CHART_H5_LAYOUT[props.layout].replaceAll('$^', '$')}\`;`)(...(Object.values(paramObj)));
  }

  initData() {
    let data = [];

    switch (this.props.chartId) {
      case 'rMateDivAptDtl': case 'rMateDivAptRcmdDtl': {
        this.props.data.map(v => {
          data.push({ 'actYearMonth': v.actYearMonth.replace('-', '.'), 'actCnt': v.actCnt, 'maxDpsAmt': (v.maxDpsAmt / 10000), 'maxDpsAmtCount': numberWithCounts(v.maxDpsAmt * 10000) });
        });

        break;
      }
      case 'rMateDiv4': {
        this.props.data.map(v => {
          data.push({ 'notiDate': Moment(v.notiDate).format('YY. MM'), 'totalReqCnt': v.totalReqCnt, 'totalGeCnt': v.totalGeCnt });
        });

        break;
      }
      case 'rMateDiv13_1': {
        this.props.data.map(v => {
          data.push({ 'trxDateMonthly': Moment(v.trxDateMonthly).format('YY. MM'), 'trxDateMonthlyCnt': v.trxDateMonthlyCnt });
        });

        break;
      }
      default : {
        data = this.props.data;
      }
    }

    return data;
  }

  render() {
    return (<></>);
  }
}