import $ from 'jquery'
import { TweenMax, TimelineMax, Linear, Power0 } from 'gsap'
import Swiper from 'swiper/bundle'

var UI = UI || {};

UI.$window = $(window);
UI.$body = $("body");


// 상단,하단 고정
export const uiFixValue = UI.fixValue = {
  init: function () {
    var t = $("[class*='ly_apt_'] .header").outerHeight(),
      b = $("[class*='ly_apt_'] .fix.btm").outerHeight(),
      w = $("[class*='ly_apt_'] .content");

    if ($(".fix.btm").hasClass("goOut")) {
      $(".goOut").css("bottom", -b);
    }

    w.css("padding-top", t);
    w.css("padding-bottom", b);

    //가상 BG
    $("[class*='ly_apt_']").each(function (idx) {
      if ($("[class*='ly_apt_']").eq(idx).hasClass("ly_pop")) {
      } else {
        $("[class*='ly_apt_']").eq(idx).find(".content").before('<span class="bg_body_full"></span>');
        var topHeight = $("[class*='ly_apt_']").eq(idx).find(".header").outerHeight(true);
        $('.bg_body_full').css('top', topHeight);
        function progress() {
          var windowScrollTop = $(window).scrollTop();
          if (windowScrollTop < 0) {
            if ($(document).find("[class*='ly_apt_']").eq(idx).find(".content").hasClass("bg_fix")) {
              $('.bg_body_full').css('top', (topHeight + 5) + Math.abs(windowScrollTop));
            } else {
              $('.bg_body_full').css('top', topHeight * 1.5 + Math.abs(windowScrollTop));
            }
          }
        }
        progress();
        $(document).on('scroll', progress);
      }
    })
  }
}

// Tab Slide 
export const uiFilterTabSwipe = UI.filterTabSwipe = {
  init: function () {
    UI.$filterTabSwipe = UI.$body.find(".filter_tab_swipe");
    UI.$slide = UI.$filterTabSwipe.find(".slide");
    UI.$slideCtrl = UI.$slide.find("a");
    UI.$tabSwipeWrap = UI.$filterTabSwipe.find(".view");
    UI.$tabSwipeView = UI.$filterTabSwipe.find(".view .item");
    UI.swipeArr = [];
    this.addEvents();
  },
  addEvents: function () {
    var _this = this;
    UI.$filterTabSwipe.each(function (idx) {
      $(this).data("key", idx).find(UI.$slideCtrl).each(function (_idx) {
        var thatWidth = $(this).outerWidth(true);
        $(this).data("ctrlKey", _idx);
        $(this).parent().width(thatWidth);
      });
      UI.tabSwiper = new Swiper($(this).find(".tab")[0], {
        wrapperClass: "inner",
        slideClass: "slide",
        slidesPerView: "auto",
        freeMode: true,
        freeModeMomentumRatio: .3,
        observer: true,
        observeParents: true,
        a11y: false
      });
      UI.swipeArr.push(UI.tabSwiper);
    });
    UI.$slideCtrl.on("click.swipe", _this.handleTabSwipeClick);
  },
  handleTabSwipeClick: function (e) {
    e.stopImmediatePropagation();
    var key = $(this).parents(".filter_tab_swipe").data("key");
    var ctrlKey = $(this).data("ctrlKey");
    var thatWidth = $(this).parent().width();

    $(this).parents(".top_srch_wrap").addClass("active").removeClass("focus").removeClass("_hide");

    UI.$filterTabSwipe.eq(key).find(UI.$slide).not($(this).parent()).removeClass("active");
    $(this).parent().addClass("active");

    // 상세 내용 보기
    if ($(this).parents(".top_srch_wrap").hasClass("full")) {
      var offset = UI.$filterTabSwipe.eq(key).find(UI.$tabSwipeView).eq(ctrlKey).position().top;
      $("html,body").animate({ scrollTop: offset }, 100);
    } else {
      if (UI.$filterTabSwipe.eq(key).find(UI.$tabSwipeWrap).length > 0) {
        UI.$filterTabSwipe.eq(key).find(UI.$tabSwipeView).not(UI.$tabSwipeWrap.eq(key).find(UI.$tabSwipeView).eq(ctrlKey)).hide();
        UI.$filterTabSwipe.eq(key).find(UI.$tabSwipeView).eq(ctrlKey).show();
        return false;
      }
    }

  }
}


var scrollHeight = 0;
scrollHeight = $(document).scrollTop();

export const popupOpen = (obj, ty, timer) => {
  var tar = $('#mw-' + obj);
  $('body').css('overflow', 'hidden');
  function mwOpen() {
    if (timer >= 0) {
      setTimeout(function () {
        if (tar.hasClass('popBtm')) {
          tar.addClass('show');
        } else {
          tar.show().addClass('show');
        }
      }, timer);
    } else {
      tar.show().addClass('show');
      if (tar.hasClass('ndim')) {
        tar.css('left', 0);
      }
      tar.find('.content').scrollTop(0);
    }
    // 영역 외 클릭 닫기
    tar.click(function (e) {
      if (!$(this).hasClass('ndim')) {
        if (!$('[class*="ly"]').find('.wrap').has(e.target).length) {
          if ($(this).hasClass('popBtm')) {
            $('body').css('overflow', '');
            $('[class*="ly"]').removeClass('show');
            window.onscroll = function () { };
          } else {
            popupClose(obj, 'mw');
          }
        }
      }
    });
  }
  function mwOpenFix() {
    if (timer >= 0) {
      setTimeout(function () {
        if (tar.hasClass('popBtm')) {
          tar.addClass('show');
        } else {
          tar.show().addClass('show');
        }
      }, timer);
    } else {
      tar.show().addClass('show');
      if (tar.hasClass('ndim')) {
        tar.css('left', 0);
      }
      tar.find('.content').scrollTop(0);
    }
    // 영역 외 클릭 닫기
    tar.click(function (e) {
      if (!$(this).hasClass('ndim')) {
        if (!$('[class*="ly"]').find('.wrap').has(e.target).length) {
          popupClose(obj, 'mwf');
        }
      }
    });
    scrollHeight = $(document).scrollTop();
    $('#wrap').css('position', 'fixed');
    $('#wrap').css('top', - scrollHeight);
  }
  if (ty === 'pc') { //pc
    tar.show();
  } else if (ty === 'mw') { //mobile 기본
    mwOpen();
    var x = window.scrollX, y = window.scrollY;
    window.onscroll = function () { window.scrollTo(x, y) };
  } else if (ty === 'mwb') {
    mwOpen();
    $('html,body').css('position', 'fixed');
  } else if (ty === 'mwf') { //mobile > #wrap을 fixed 시키는 방식
    mwOpenFix();
  }
}

export const popupClose = (obj, ty) => {
  var tar = $('#mw-' + obj);
  $('body').css('overflow', '');
  if (ty === 'pc') {
    tar.hide().removeClass('show');
  } else if (ty === 'mw') {
    if (tar.hasClass('popBtm')) {
      tar.removeClass('show');
    } else {
      tar.hide().removeClass('show');;
    }
    $('html,body').css('position', '');
    window.onscroll = function () { };
  } else if (ty === 'mwf') {
    if (tar.hasClass('popBtm')) {
      tar.removeClass('show');
    } else {
      tar.hide().removeClass('show');;
    }
    $('#wrap').css('top', 0);
    $('#wrap').css('position', 'relative');
    $(document).scrollTop(scrollHeight);
  }

  if(!$('.ly_pop').hasClass('show')) {
    $('body').css('overflow', '');
  }
}


export const uiAccoScrollFix = UI.accoScrollFix = {
  init: function () {
    $(".ly_apt_detail .content").scroll(function () {
      var t = $(this).find("section.tit_wrap").offset().top;
      var title = $(this).find("section.tit_wrap .tit").text();
      if (t < 50) {
        $(this).find(".header .tit").text(title);
      } else {
        $(this).find(".header .tit").text("");
      }
      var fixH = 50;
      $(".acco_wrap .item").each(function (idx) {
        var offset = $(".acco_wrap .item").eq(idx).offset().top;
        var h = $(".acco_wrap .item").eq(idx).find(".btn_acco").outerHeight();
        var b = $(".acco_wrap .item").eq(idx).find('.acco_view').outerHeight();
        if (offset < fixH + (h * idx)) {
          $(".acco_wrap .item").eq(idx).addClass("_fix").find(".btn_acco").css("top", Math.ceil(fixH + (h * idx)));
        } else if (offset > fixH) {
          $(".acco_wrap .item").eq(idx).removeClass("_fix").find(".btn_acco").css("top", 0);
        }
      });
    }).trigger("scroll");

    var _this = this;
    _this.$accoWrap = UI.$body.find(".acco_wrap").find(".item");
    _this.$btn = _this.$accoWrap.find(".btn_acco");
    _this.arr = [];
    _this.$accoWrap.each(function (idx) {
      _this.arr.pop();
      setTimeout(function () {
        _this.arr.push(_this.$accoWrap.eq(idx).find(".btn_acco").offset().top - (40 * (idx + 1)));
      }, 300);
      _this.$accoWrap.eq(idx).find(".btn_acco").on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        $(".ly_apt_detail .content").animate({ scrollTop: _this.arr[idx] }, 200);
        _this.$accoWrap.find(".toggle_wrap").addClass("active");
      });
    });
  }
}


export const uiIpFocus = UI.ipFocus = {
  init: function () {
    this.$inputWrap = UI.$body.find(".ip_box");
    this.$ip = this.$inputWrap.find(".ip");
    this.addEvents();
  },
  addEvents: function () {
    var _this = this;
    _this.$ip.each(function (idx) {
      var _input = $(this), _par = $(this).parent();
      _input.on("focus", function () {
        _par.addClass('focus');
        $("html").addClass("static");
        if ($("[class*='ly_apt_'].full").hasClass("ly_apt_filter") == false || $("[class*='ly_apt_'].full").hasClass("ly_apt_join") == false) {
          var top = $("[class*='ly_apt_'] .header").outerHeight();
          var target = $(this).parent().offset().top - 35;
          setTimeout(function () {
            $("html,body").animate({ scrollTop: target - top }, 0);
          }, 400);
        }
      }).on("blur", function (e) {
        _par.removeClass('focus');
        $("html").removeClass("static");
        if (_par.children('textarea').length) {
          _par.addClass('textarea');
        }
        if (!$.trim($(this).val()) == "") {
          _par.find('.lbl').hide();
        } else {
          _par.find('.lbl').show();
        }
      }).blur();
      _input.on("keyup", function () {
        if (!$.trim($(this).val()) == "") {
          _par.find('.lbl').hide();
        }
      });
    });
  }
}

export const uiInputDel = UI.inputDel = {
  init: function () {
    var _input = UI.$body.find(".ip_del input");
    _input.on("focus keyup", function () {
      if ($.trim($(this).val()) == "") {
        $(this).parents(".ip_del").find("button.del").hide();
        $(this).parents(".ip_del").find(".btn_txt").removeClass("on");
      } else {
        $(this).parents(".ip_del").find("button.del").show();
        $(this).parents(".ip_del").find("button.btn_srch").hide();
        $(this).parents(".ip_del").find(".btn_txt").removeClass("on");
      }
    }).on("blur", function (e) {
      setTimeout(function () {
        $(e.target).parents(".ip_del").find("button.del").hide();
        $(e.target).parents(".ip_del").find(".btn_txt").removeClass("on");
      }, 250);
    }).blur();
    _input.parents(".ip_del").find("button.del").on("click", function () {
      $(this).hide().parents(".ip_del").find("input").val('').trigger("change").focus();
      $(this).parents(".ip_del").find('.lbl').show();
      $(this).parents(".ip_del").find("button.btn_srch").show();
    });
  }
}

export const uiSelFocus = UI.selFocus = {
  init: function () {
    this.$inputWrap = UI.$body.find(".ip_box");
    this.$select = this.$inputWrap.find("select");
    this.addEvents();
  },
  addEvents: function () {
    var _this = this;
    _this.$select.each(function (idx) {
      if ($(document).find("[class*='ly_apt_']").hasClass("ly_apt_detail") || $(document).find(".ly_detail").hasClass("analyze_wrap")) {
        $(this).val();
      } else {
        $(this).val(-1);
      }
      var _input = $(this), _par = $(this).parent().parent();
      _input.on("focus", function () {
        _par.removeClass('in');
        _par.addClass('focus');
      }).on("blur", function (e) {
        if (!$(this).val() == "") {
          _par.find('.lbl').removeClass('show');
          _par.find('.lbl').hide();
        } else {
          _par.find('.lbl').addClass('show');
          _par.find('.lbl').show();
        }
        _par.addClass('in');
      }).blur();
      _input.on("change", function () {
        _par.find('.lbl').addClass('hide');
        _par.addClass('in');
        if (_par.hasClass('in') & _par.hasClass('focus')) {
          _par.removeClass('in');
        }
      });
    });
  }
}

export const uiSelFocusHsType = {
  init: function () {
    this.$inputWrap = UI.$body.find(".ip_box");
    //this.$select = this.$inputWrap.find("select");
    this.$select = $("#select01");
    this.addEvents();
  },
  addEvents: function () {
    var _this = this;
    _this.$select.each(function (idx) {
      if ($(document).find("[class*='ly_apt_']").hasClass("ly_apt_detail") || $(document).find(".ly_detail").hasClass("analyze_wrap")) {
        $(this).val();
      } else {
        $(this).val(-1);
      }
      var _input = $(this), _par = $(this).parent().parent();
      _input.on("focus", function () {
        _par.removeClass('in');
        _par.addClass('focus');
      }).on("blur", function (e) {
        if (!$(this).val() == "") {
          _par.find('.lbl').removeClass('show');
          _par.find('.lbl').hide();
        } else {
          _par.find('.lbl').addClass('show');
          _par.find('.lbl').show();
        }
        _par.addClass('in');
      }).blur();
      _input.on("change", function () {
        _par.find('.lbl').addClass('hide');
        _par.addClass('in');
        if (_par.hasClass('in') & _par.hasClass('focus')) {
          _par.removeClass('in');
        }
      });
    });
  }
}


export const uiSelFocusFlrNo = {
  init: function () {
    this.$inputWrap = UI.$body.find(".ip_box");
    //this.$select = this.$inputWrap.find("select");
    this.$select = $("#select02");
    this.addEvents();
  },
  addEvents: function () {
    var _this = this;
    _this.$select.each(function (idx) {
      if ($(document).find("[class*='ly_apt_']").hasClass("ly_apt_detail") || $(document).find(".ly_detail").hasClass("analyze_wrap")) {
        $(this).val();
      } else {
        $(this).val(-1);
      }
      var _input = $(this), _par = $(this).parent().parent();
      _input.on("focus", function () {
        _par.removeClass('in');
        _par.addClass('focus');
      }).on("blur", function (e) {
        if (!$(this).val() == "") {
          _par.find('.lbl').removeClass('show');
          _par.find('.lbl').hide();
        } else {
          _par.find('.lbl').addClass('show');
          _par.find('.lbl').show();
        }
        _par.addClass('in');
      }).blur();
      _input.on("change", function () {
        _par.find('.lbl').addClass('hide');
        _par.addClass('in');
        if (_par.hasClass('in') & _par.hasClass('focus')) {
          _par.removeClass('in');
        }
      });
    });
  }
}

export const uiMainSwitch = UI.mainSwitch = {
  init: function () {
    $('.main_switch .tab_wrap').on('click', function () {
      var _this = $('.main_switch .tab_wrap'),
        _parents = _this.parents('.main_switch');
      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        _parents.removeClass('active');
      } else {
        $(this).addClass('active');
        _parents.addClass('active');
      }

      setTimeout(function () {
        if (_parents.hasClass('active')) {
          _parents.find('.main_wrap').find("[class*='main_']").removeClass("active");
          _parents.find('.main_con02').addClass('active');
        } else {
          _parents.find('.main_wrap').find("[class*='main_']").removeClass("active");
          _parents.find('.main_con01').addClass('active');
        }
      }, 300);
    });
  }
}

export const uiChartTap = UI.chartTap = {
  init: function () {
    $(this).each(function (idx) {
      var _this = $('.chart_tit li');
      _this.click(function () {
        var tab = $(this).attr('data-tab');
        _this.removeClass('current');
        $('.tab_cont').removeClass('currendt');
        $(this).addClass('current');
        $('.' + tab).addClass('current');
      });
    })
  }
}


export const uiToggle = UI.toggle = {
  init: function () {
    // 마크 선택
    $(".btn_toggle").off('click').on('click', function () {
      if ($(this).parents(".toggle_wrap").hasClass('active')) {
        $(this).parents(".toggle_wrap").removeClass('active');
      } else {
        $(this).parents(".toggle_wrap").addClass('active');
        if ($(".ly_detail").hasClass("analyze_wrap")) {
          $("html,body").animate({ scrollTop: $(this).offset().top - 102 }, 200);
        }
        if ($(".ly_content").hasClass("ly_bbs")) {
          $("html,body").animate({ scrollTop: $(this).offset().top - 120 }, 200);
        }
      }
    });
  }
}

export const uiTopGo = UI.topGo = {
  init: function () {
    $(window).on("scroll", function () {
      if ($('.btn_top').length > 0) {
        if ($(this).scrollTop() > $('html,body').offset().top) {
          $('.btn_top').addClass('active');
        } else {
          $('.btn_top').removeClass('active');
        }
      }
    });
    $(document).on('click', '.btn_top', function () {
      var $body = $("body");
      $("html,body").animate({ 'scrollTop': $body.offset().top }, 350);
    });
  }
}

function counting(tar, delay) {
  var _this = $('.count_' + tar),
    per = _this.attr('per');

  _this.attr('per', 0);
  setTimeout(function () {
    $({ animatedValue: 0 }).animate({ animatedValue: per }, {
      duration: 900,
      step: function () {
        _this.attr('per', Math.floor(this.animatedValue));
      },
      complete: function () {
        _this.attr('per', Math.floor(this.animatedValue));
      }
    });
  }, delay * 100);
}


export const chartCircle = {

  call: function () {

    var chart_circle,
      intervals,
      per_num;
    intervals = [];
    per_num = [];
    $(this).each(function (idx) {
      chart_circle = {
        items: $('.circle_chart'),
        set: function (obj, per, max, index) {
          var path,
            pathLen,
            perLen;
          path = $('.circle_progress').get(index);
          pathLen = Math.round((107) * 3.14);
          perLen = Math.round(pathLen - (per * (pathLen / max)));
          path.setAttribute('stroke-dasharray', pathLen);
          path.setAttribute('stroke-dashoffset', pathLen);
          setTimeout(function () {
            chart_circle.num_evt(per, obj, index);
            chart_circle.init(obj, path, perLen);
          }, 0);
        },
        init: function (obj, path, perLen) {
          obj.addClass('active');
          path.setAttribute('stroke-dashoffset', perLen);
        },
        num_evt: function (per, obj, index) {
          chart_circle.num_interval(per, obj, index);
        },
        num_interval: function (per, obj, index) {
          var interv, per_box;

          per_num[index] = 0;
          interv = 1000 / per;

          // very slowly interv
          if (interv > 50) {
            interv = 50;
          }
          clearInterval(intervals[index]);
          per_box = obj.find('.per-num span');
          if (per_box.attr('rank') == null) { //건설사순위는 num_set하지않는다
            intervals[index] = setInterval(function () {
              chart_circle.num_set(obj, per_box, per, index);
            }, interv);
          }
        },
        num_set: function (obj, per_box, per, index) {
          if (per_num[index] >= per) {
            clearInterval(intervals[index]);
          }

          per_box.text(per_num[index]);
          per_num[index]++;
        },
        call: function (idx) {
          var per, max;
          max = chart_circle.items.eq(idx).attr('data-permax');
          per = chart_circle.items.eq(idx).attr('data-percent');
          chart_circle.set(chart_circle.items.eq(idx), per, max, idx);
        }
      }
    });

    // 스크롤 애니메이션
    $("body").find(".multi").find('.point').not('.my').each(function (idx) {
      var num = $(this).attr("data-point");
      TweenMax.to($(this), .0, { left: $(this).attr("data-point") + "%" });
      $(this).find('.point').find('span').find('s').text(num);
    });

    var _this = this;
    _this.$animateWrap = $("body").find(".analyze_wrap");
    _this.$animate = _this.$animateWrap.find(".ani");

    var ani1, ani2, ani3, ani4, ani5, ani6, ani7, ani8, ani9, ani10, ani11;
    var status;
    var handler = {
      ani1: function ANI1_ANIMATE() {
        if (ani1) return;
        ani1 = true;
        var $container = $('#ani1'), tl = new TimelineMax();
        tl.add([
          TweenMax.to($container.find('.graph'), .7, { y: 0, opacity: 1, delay: .2 }),
          TweenMax.to($container.find('.caption'), .7, { y: 0, opacity: 1, delay: .6 })
        ]);
        chart_circle.call(0);
      },
      ani2: function ANI2_ANIMATE() {
        if (ani2) return;
        ani2 = true;
        var $container = $('#ani2'), tl = new TimelineMax();
        tl.add([
          TweenMax.to($container, .7, {
            y: 0, opacity: 1, onComplete: function () {
              var my = $("body").find(".multi").find('.my');
              var my_num = my.attr("data-point");
              TweenMax.to(my, .5, { left: my.attr("data-point") + "%", delay: .3 });
              my.find('span').find('s').text(my_num);
            }
          })
        ])
      },
      ani3: function ANI3_ANIMATE() {
        if (ani3) return;
        ani3 = true;
        var $container = $('#ani3'), tl = new TimelineMax();
        tl.add([
          TweenMax.to($container.find('.graph'), .7, { y: 0, opacity: 1, delay: .2 }),
          TweenMax.to($container.find('.caption'), .7, { y: 0, opacity: 1, delay: .6 }),
          TweenMax.to($container.find('.people'), .7, { y: 0, opacity: 1, delay: .9 }),
          TweenMax.to($container.find('.noti'), .7, { y: 0, opacity: 1, delay: 1.2 })
        ]);
        counting('v1', 10);
        counting('v2', 10);
        chart_circle.call(1);
      },
      ani4: function ANI4_ANIMATE() {
        if (ani4) return;
        ani4 = true;
        var $container = $('#ani4'), tl = new TimelineMax();
        tl.add([
          TweenMax.to($container.find('.graph'), .7, { y: 0, opacity: 1, delay: .2 }),
          TweenMax.to($container.find('.caption'), .7, { y: 0, opacity: 1, delay: .6 }),
          TweenMax.to($container.find('.chart'), .7, { y: 0, opacity: 1, delay: .9 })
        ]);
        chart_circle.call(2);
      },
      ani5: function ANI5_ANIMATE() {
        if (ani5) return;
        ani5 = true;
        var $container = $('#ani5'), tl = new TimelineMax();
        tl.add([
          TweenMax.to($container.find('.graph'), .7, { y: 0, opacity: 1, delay: .2 }),
          TweenMax.to($container.find('.caption'), .7, { y: 0, opacity: 1, delay: .6 })
        ]);
        chart_circle.call(3);
      },
      ani6: function ANI6_ANIMATE() {
        var $container = $('#ani6'), tl = new TimelineMax();
        if ($container.hasClass("active")) {
          if (ani6) return;
          ani6 = true;
          tl.add([
            TweenMax.to($container.find('.bubble .high'), .7, { y: 0, opacity: 1, delay: .3 }),
            TweenMax.to($container.find('.bubble .low'), .7, { y: 0, opacity: 1, delay: .8 }),
            TweenMax.to($container.find('.tb_apt'), .7, { y: 0, opacity: 1, delay: 1.2 })
          ])
        }
      },
      ani7: function ANI7_ANIMATE() {
        var $container = $('#ani7'), tl = new TimelineMax();
        if ($container.hasClass("active")) {
          if (ani7) return;
          ani7 = true;
          tl.add([
            TweenMax.to($container.find('.graph'), .7, { y: 0, opacity: 1, delay: .3 }),
            TweenMax.to($container.find('.facility_info'), .7, { y: 0, opacity: 1, delay: .7 }),
            TweenMax.to($container.find('.noti'), .7, { y: 0, opacity: 1, delay: .9 })
          ]);
          chart_circle.call(4);
        }
      },
      ani8: function ANI8_ANIMATE() {
        var $container = $('#ani8'), tl = new TimelineMax();
        if ($container.parents(".toggle_wrap").hasClass("active")) {
          if (ani8) return;
          ani8 = true;
          tl.add([
            TweenMax.to($container.find('.graph'), .7, { y: 0, opacity: 1, delay: .3 }),
            TweenMax.to($container.find('.caption'), .7, { y: 0, opacity: 1, delay: .6 }),
            TweenMax.to($container.find('.apt_list'), .7, { y: 0, opacity: 1, delay: .9 }),
            TweenMax.to($container.find('.chart'), .7, { y: 0, opacity: 1, delay: 1.3 })
          ]);
          chart_circle.call(5);
        }
      },
      ani9: function ANI9_ANIMATE() {
        var $container = $('#ani9'), tl = new TimelineMax();
        if ($container.parents(".toggle_wrap").hasClass("active")) {
          if (ani9) return;
          ani9 = true;
          tl.add([
            TweenMax.to($container.find('.graph'), .7, { y: 0, opacity: 1, delay: .3 }),
            TweenMax.to($container.find('.caption'), .7, { y: 0, opacity: 1, delay: .6 }),
            TweenMax.to($container.find('.chart'), .7, { y: 0, opacity: 1, delay: .9 })
          ]);
          chart_circle.call(6);
        }
      },
      ani10: function ANI10_ANIMATE() {
        var $container = $('#ani10'), tl = new TimelineMax();
        if ($container.parents(".toggle_wrap").hasClass("active")) {
          if (ani10) return;
          ani10 = true;
          tl.add([
            TweenMax.to($container.find('.type01 .graph'), .7, { y: 0, opacity: 1, delay: .3 }),
            TweenMax.to($container.find('.type01.chart'), .7, { y: 0, opacity: 1, delay: .6 }),
            TweenMax.to($container.find('.type02 .graph'), .7, { y: 0, opacity: 1, delay: 1 }),
            TweenMax.to($container.find('.type02.chart'), .7, { y: 0, opacity: 1, delay: 1.3 })
          ]);
          chart_circle.call(7);
          chart_circle.call(8);
        }
      },
      ani11: function ANI11_ANIMATE() {
        var $container = $('#ani11'), tl = new TimelineMax();
        if ($container.parents(".toggle_wrap").hasClass("active")) {
          if (ani11) return;
          ani11 = true;
          tl.add([
            TweenMax.to($container.find('.caption_wrap'), .7, { y: 0, opacity: 1, delay: .3 }),
            TweenMax.to($container.find('.chart'), .7, { y: 0, opacity: 1, delay: .6 })
          ])
        }
      }
    }
    $(window).on("scroll", function () {
      var viewTop = $(this).scrollTop(),
        viewHeight = $(this).outerHeight(true) / 1.2,
        viewBottom = viewTop + viewHeight;

      _this.$animate.each(function () {
        var elementTop = $(this).offset().top,
          elementHeight = $(this).outerHeight(),
          elementBottom = elementTop + elementHeight;

        if (viewTop < elementBottom && viewBottom > elementTop) {
          var id = $(this).attr('id');
          handler[id]();
        }
      });
    }).trigger("scroll");

  }
}

export const asyncCounting = (tar, delay, prevScore, completeScore) => {
  var _this = $('.count_' + tar),
    per = _this.attr('per');
  _this.attr('per', prevScore);

  setTimeout(function () {
    $({ animatedValue: prevScore }).animate({ animatedValue: per }, {
      duration: 900,
      step: function () {
        _this.attr('per', Math.floor(this.animatedValue));
      },
      complete: function () {
        _this.attr('per', completeScore);
      }
    });
  }, delay * 100);
}

export const joinInfoEvent = {
  call: function () {
    var _this = this;
    _this.$animateWrap = $("body").find(".ly_apt_info .info_wrap");
    _this.$animate = _this.$animateWrap.find(".ani");

    var ani1, ani2, ani3;
    var status;
    var handler = {
      ani1: function ANI1_ANIMATE() {
        if (ani1) return;
        ani1 = true;
        var $container = $('#ani1'), tl = new TimelineMax();
        tl.to($container, .5, { y: 0, opacity: 1, ease: Linear.easeOut, delay: .3 });
      },
      ani2: function ANI2_ANIMATE() {
        if (ani2) return;
        ani2 = true;
        var $container = $('#ani2'), tl = new TimelineMax();
        tl.to($container, .5, { y: 0, opacity: 1, ease: Linear.easeOut });
      },
      ani3: function ANI3_ANIMATE() {
        if (ani3) return;
        ani3 = true;
        var $container = $('#ani3'), tl = new TimelineMax();
        tl.to($container, .5, { y: 0, opacity: 1, ease: Linear.easeOut });
      }
    }
    $(window).on("scroll", function () {
      var viewTop = $(this).scrollTop();
      var viewHeight = $(this).outerHeight(true) / 1.3;
      var viewBottom = viewTop + viewHeight;

      _this.$animate.each(function () {
        var elementTop = $(this).offset().top;
        var elementHeight = $(this).outerHeight();
        var elementBottom = elementTop + elementHeight;

        if (viewTop < elementBottom && viewBottom > elementTop) {
          var id = $(this).attr('id');
          handler[id]();
        }
      });
    }).trigger("scroll");
  }
}

export const uiInfoPopSwiper01 = UI.infoPopSwiper01 = {
  init: function () {
    UI.$infoSwiper01 = $(".pop_info_swiper");
    UI.infoSwiper01 = new Swiper('.pop_info_swiper', {
      observer: true,
      observeParents: true,
      centeredSlides: true,
      allowTouchMove: false,
      slidesPerView: 1,
      speed: 1000,
      pagination: {
        el: '.swiper-pagination',
      },
      on: {
        init: function () {
          var _this = this;
          _this.tween01 = new TimelineMax();
          _this.tween02 = new TimelineMax();
          _this.tween03 = new TimelineMax();

          var currentSlide = this.realIndex;

          //reset
          UI.$infoSwiper01.find(".swiper-slide").find(".form_ani").removeClass("on");
          UI.$infoSwiper01.find(".swiper-slide").find(".selet_detail .item").removeClass("on");
          UI.$infoSwiper01.find(".swiper-slide").find(".form_ani .box").removeClass("on");
          // tween clear
          _this.tween01.set(".swiper-slide *", { clearProps: "all" });
          _this.tween02.set(".swiper-slide *", { clearProps: "all" });
          _this.tween03.set(".swiper-slide *", { clearProps: "all" });

          setTimeout(function () {
            UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".form_ani").addClass("on");
          }, 800);
          _this.tween01.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .2, {
            opacity: .4, delay: 1.5, ease: Linear.easeIn, onComplete: function () {
              _this.tween01.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                x: -50, y: -20, ease: Linear.easeIn, onComplete: function () {
                  _this.tween01.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                    opacity: .6, scale: .98, ease: Linear.easeIn, onComplete: function () {
                      _this.tween01.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                        opacity: .4, scale: 1, ease: Linear.easeIn, onComplete: function () {
                          UI.infoSwiper01.slideTo(1, 1000, false);
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        },
        slideChange: function () {
          //reset
          UI.$infoSwiper01.find(".swiper-slide").find(".form_ani").removeClass("on");
          UI.$infoSwiper01.find(".swiper-slide").find(".selet_detail .item").removeClass("on");
          UI.$infoSwiper01.find(".swiper-slide").find(".form_ani .box").removeClass("on");

          var _this = this;
          var currentSlide = this.realIndex;
          if (currentSlide == 0) {
            setTimeout(function () {
              UI.$infoSwiper01.find(".swiper-slide").eq(0).find(".form_ani").addClass("on");
            }, 800);
            _this.tween01.restart();
          }
          if (currentSlide == 1) {
            setTimeout(function () {
              UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".form_ani .box").addClass("on");
              UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".selet_detail").slideDown(400);
            }, 800);
            _this.tween02.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".selet_detail .item:eq(0)"), .3, {
              className: "+=item on", delay: 1.5, onComplete: function () {
                UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".form_ani .box").removeClass("on");
                UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".selet_detail").slideUp(400, function () {
                  UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".form_ani").addClass("on");
                  _this.tween02.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .2, {
                    opacity: .4, delay: .3, ease: Linear.easeIn, onComplete: function () {
                      _this.tween02.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                        x: -50, y: -20, ease: Linear.easeIn, onComplete: function () {
                          _this.tween02.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                            opacity: .6, scale: .98, ease: Linear.easeIn, onComplete: function () {
                              _this.tween02.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                                opacity: .4, scale: 1, ease: Linear.easeIn, onComplete: function () {
                                  UI.infoSwiper01.slideTo(2, 1000, false);
                                }
                              });
                            }
                          });
                        }
                      });
                    }
                  });
                });
              }
            });
          }
          if (currentSlide == 2) {
            setTimeout(function () {
              UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".form_ani .box").addClass("on");
              UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".selet_detail").slideDown(400);
            }, 800);
            _this.tween03.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".selet_detail .item:eq(1)"), .3, {
              className: "+=item on", delay: 1.5, onComplete: function () {
                UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".form_ani .box").removeClass("on");
                UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".selet_detail").slideUp(400, function () {
                  UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".form_ani").addClass("on");
                  _this.tween03.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .2, {
                    opacity: .4, delay: .3, ease: Linear.easeIn, onComplete: function () {
                      _this.tween03.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                        x: -50, y: -20, ease: Linear.easeIn, onComplete: function () {
                          _this.tween03.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                            opacity: .6, scale: .98, ease: Linear.easeIn, onComplete: function () {
                              _this.tween03.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                                opacity: .4, scale: 1, ease: Linear.easeIn, onComplete: function () {
                                  UI.$infoSwiper01.parents(".ly_info_swiper").find(".btn_info_reset").show();
                                }
                              });
                            }
                          });
                        }
                      });
                    }
                  });
                });
              }
            });
          }
        }
      }
    });
    UI.$infoSwiper01.parents(".ly_info_swiper").find(".btn_info_reset").on("click", function (e) {
      e.stopImmediatePropagation();
      var _this = $(this);
      TweenMax.set(_this, { rotation: 0 });
      TweenMax.to(_this, .3, {
        rotation: 360, ease: Power0.easeNone, onComplete: function () {
          _this.hide();
        }
      });
      UI.infoSwiper01.destroy(false);
      UI.infoPopSwiper01.init();
    });
  }
}

export const uiInfoPopSwiper02 = UI.infoPopSwiper02 = {
  init: function () {
    UI.$infoSwiper02 = $(".pop_info_swiper02");
    UI.infoSwiper02 = new Swiper('.pop_info_swiper02', {
      observer: true,
      observeParents: true,
      centeredSlides: true,
      allowTouchMove: false,
      speed: 1000,
      pagination: {
        el: '.swiper-pagination',
      },
      on: {
        init: function () {
          var _this = this;
          _this.tween01 = new TimelineMax();
          _this.tween02 = new TimelineMax();
          _this.tween03 = new TimelineMax();

          var currentSlide = this.realIndex;

          //reset
          UI.$infoSwiper02.find(".swiper-slide").find(".form_ani").removeClass("on");
          UI.$infoSwiper02.find(".swiper-slide").find(".selet_detail .item").removeClass("on");
          UI.$infoSwiper02.find(".swiper-slide").find(".form_ani .box").removeClass("on");
          // tween clear
          _this.tween01.set(".swiper-slide *", { clearProps: "all" });
          _this.tween02.set(".swiper-slide *", { clearProps: "all" });
          _this.tween03.set(".swiper-slide *", { clearProps: "all" });

          setTimeout(function () {
            UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".form_ani").addClass("on");
          }, 800);
          _this.tween01.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .2, {
            opacity: .4, delay: 3, ease: Linear.easeIn, onComplete: function () {
              _this.tween01.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                x: -50, y: -20, ease: Linear.easeIn, onComplete: function () {
                  _this.tween01.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                    opacity: .6, scale: .98, ease: Linear.easeIn, onComplete: function () {
                      _this.tween01.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                        opacity: .4, scale: 1, ease: Linear.easeIn, onComplete: function () {
                          UI.infoSwiper02.slideTo(1, 1000, false);
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        },
        slideChange: function () {
          //reset
          UI.$infoSwiper02.find(".swiper-slide").find(".form_ani").removeClass("on");
          UI.$infoSwiper02.find(".swiper-slide").find(".selet_detail .item").removeClass("on");
          UI.$infoSwiper02.find(".swiper-slide").find(".form_ani .box").removeClass("on");

          var _this = this;
          var currentSlide = this.realIndex;
          if (currentSlide == 0) {
            setTimeout(function () {
              UI.$infoSwiper02.find(".swiper-slide").eq(0).find(".form_ani").addClass("on");
            }, 1000);
            _this.tween01.restart();
          }
          if (currentSlide == 1) {
            setTimeout(function () {
              UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .box").addClass("on");
              UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .selet_detail").slideDown(400);
            }, 800);
            _this.tween02.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .selet_detail .item:eq(0)"), .3, {
              className: "+=item on", delay: 1.5, onComplete: function () {
                UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .box").removeClass("on");
                UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .selet_detail").slideUp(400, function () {
                  _this.tween02.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .form_ani"), .2, {
                    className: "+=form_ani on", onComplete: function () {
                      UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .box").addClass("on");
                      UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .selet_detail").slideDown(400);
                      _this.tween02.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .selet_detail .item:eq(1)"), .3, {
                        className: "+=item on", delay: .5, onComplete: function () {
                          UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .selet_detail").slideUp(400, function () {
                            UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .box").removeClass("on");
                            _this.tween02.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .form_ani"), .2, {
                              className: "+=form_ani on", onComplete: function () {
                                _this.tween02.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .2, {
                                  opacity: .4, delay: .1, ease: Linear.easeIn, onComplete: function () {
                                    _this.tween02.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                                      x: -50, y: -20, ease: Linear.easeIn, onComplete: function () {
                                        _this.tween02.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                                          opacity: .6, scale: .98, ease: Linear.easeIn, onComplete: function () {
                                            _this.tween02.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                                              opacity: .4, scale: 1, ease: Linear.easeIn, onComplete: function () {
                                                UI.infoSwiper02.slideTo(2, 1000, false);
                                              }
                                            });
                                          }
                                        });
                                      }
                                    });
                                  }
                                });
                              }
                            });
                          });
                        }
                      });
                    }
                  });
                });
              }
            });
          }
          if (currentSlide == 2) {
            setTimeout(function () {
              UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .box").addClass("on");
              UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .selet_detail").slideDown(400);
            }, 800);
            _this.tween03.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .selet_detail .item:eq(0)"), .3, {
              className: "+=item on", delay: 1.5, onComplete: function () {
                UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .box").removeClass("on");
                UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .selet_detail").slideUp(400, function () {
                  _this.tween03.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .form_ani"), .2, {
                    className: "+=form_ani on", onComplete: function () {
                      UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .box").addClass("on");
                      UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .selet_detail").slideDown(400);
                      _this.tween03.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .selet_detail .item:eq(1)"), .3, {
                        className: "+=item on", delay: .5, onComplete: function () {
                          UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .selet_detail").slideUp(400, function () {
                            UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .box").removeClass("on");
                            _this.tween03.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .form_ani"), .2, {
                              className: "+=form_ani on", onComplete: function () {
                                _this.tween03.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .2, {
                                  opacity: .4, delay: .1, ease: Linear.easeIn, onComplete: function () {
                                    _this.tween03.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                                      x: -50, y: -20, ease: Linear.easeIn, onComplete: function () {
                                        _this.tween03.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                                          opacity: .6, scale: .98, ease: Linear.easeIn, onComplete: function () {
                                            _this.tween03.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                                              opacity: .4, scale: 1, ease: Linear.easeIn, onComplete: function () {
                                                UI.$infoSwiper02.parents(".ly_info_swiper").find(".btn_info_reset").show();
                                              }
                                            });
                                          }
                                        });
                                      }
                                    });
                                  }
                                });
                              }
                            });
                          });
                        }
                      });
                    }
                  });
                });
              }
            });
          }
        }
      }
    });
    UI.$infoSwiper02.parents(".ly_info_swiper").find(".btn_info_reset").on("click", function (e) {
      e.stopImmediatePropagation();
      var _this = $(this);
      TweenMax.set(_this, { rotation: 0 });
      TweenMax.to(_this, .3, {
        rotation: 360, ease: Power0.easeNone, onComplete: function () {
          _this.hide();
        }
      });
      UI.infoSwiper02.destroy(false);
      UI.infoPopSwiper02.init();
    });
  }
}

// GA Script 호출 ( 1차 - 아파트정보 > 마커클릭 / 청약정보 > 최근청약정보 데이터 클릭 )
export const callGaTag = (objId) => {
    //console.log("app Design.js > callGaTag - objId : ["+objId+"]");
    window.gtag('event', objId, {
          'send_to': 'G-ZNLLSSXSB4'
    });
}
// 2022.04.26 추가
/**
 * 형제요소
 * @param {Element} node
 */
export function siblings(node) {
  var children = node.parentElement.children;
  var tempArr = [];

  for (var i = 0; i < children.length; i++) {
      tempArr.push(children[i]);
  }

  return tempArr.filter(function (e) {
      return e !== node;
  });
}

/**
* 탭
*/
export const uiTab = UI.tab = {
  init() {
    this.tabTrigger = document.querySelectorAll('[data-tab-trigger]');
    this.isTransitioning = false;

    this.tabTrigger.forEach(trigger => {
      const target = document.querySelector(trigger.dataset.tabTrigger);

      if(trigger.classList.contains('on')) {
        target.classList.add('shown');
      } else {
        target.classList.add('hidden');
      }

      trigger.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();

        if (!trigger.classList.contains('on')) {
          UI.tab.show(target);
        }
      });
    });
  },
  show(target) {
    const trigger = document.querySelector('[data-tab-trigger="#'+ target.getAttribute('id') +'"]');
    const tab = trigger.closest('[data-tab]');

    if (this.isTransitioning) return false;
    this.isTransitioning = true;

    siblings(trigger).forEach(trs => {
      trs.classList.remove('on');
    });

    trigger.classList.add('on');

    siblings(target).forEach(group => {
      const groupComplete = () => {
        group.classList.remove('hiding');
        group.classList.add('hidden');

        const hidden = new CustomEvent('tab.hidden');
        group.dispatchEvent(hidden);
        target.classList.remove('hidden');

        target.classList.add('showing');
        target.classList.add('shown');
        
        const showing = new CustomEvent('tab.showing');
        target.dispatchEvent(showing);
      }

      if(group.classList.contains('shown')) {
        group.classList.add('hiding');
        group.classList.remove('shown');

        const hiding = new CustomEvent('tab.hiding');
        group.dispatchEvent(hiding);

        if(tab.dataset.tabAnimation === 'false') {
          groupComplete();
        } else {
          group.addEventListener('animationend', function animationend() {
            groupComplete();
            group.removeEventListener('animationend', animationend);
          });
        }
      }
    });

    const targetComplete = () => {
      target.classList.remove('showing');
      target.classList.add('shown');

      const shown = new CustomEvent('tab.shown');
      target.dispatchEvent(shown);

      this.isTransitioning = false;
    }

    if(tab.dataset.tabAnimation === 'false') {
      targetComplete();
    } else {
      target.addEventListener('animationend', function animationend() {
        targetComplete();
        target.removeEventListener('animationend', animationend);
      });
    }
  }
};

/**
 * 드롭다운
 */

 export const uiDropdown = UI.dropdown = {
  init() {
    const dropdown = document.querySelectorAll('[data-dropdown]');

    dropdown.forEach(function(el) {
      const trigger = el.querySelector('[data-dropdown-trigger]');
      const select = el.querySelector('[data-dropdown-select]');

      trigger.addEventListener('click', function() {
        el.classList.toggle('on');
      });

      select.addEventListener('click', function(e) {
        if (e.target.nodeName === 'BUTTON') {
          trigger.textContent = e.target.textContent;

          siblings(e.target).forEach(function(btns) {
            btns.classList.remove('selected');
          })
          e.target.classList.add('selected');
          
          el.classList.remove('on');
        }
      });
    })
  }
}