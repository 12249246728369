import React from "react";
import {useHistory} from "react-router-dom";

/**
 * 가입하기 버튼인데 누르면 로그인 화면으로 이동한다고 함.
 */
export const ButtonJoin = () => {
    const history = useHistory();
    const gotoJoin = () => {
        history.push("/login");
    };

    return (
            <button type="button" className="btn-join" onClick={gotoJoin}>
                유료 가입하고 받기
            </button>
    );
};