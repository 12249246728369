import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'; // url 구분 작업
import {useStateWithCallbackLazy} from 'use-state-with-callback';

import axios from 'axios';
import $ from 'jquery';
import {
    popupClose,
    popupOpen,
    uiChartTap,
    uiFilterTabSwipe,
    uiFixValue,
    uiInfoPopSwiper02,
    uiInputDel,
    uiIpFocus,
    uiMainSwitch,
    uiSelFocus,
    uiToggle,
    uiTopGo
} from 'routes/common/Design.js'

import {getLcParamItem, getLcUrlItem, setLcParamItem, setLcUrlItem} from 'routes/common/ParamStorage';

//// 컴포넌트 사용처 : 청약 비용 계산기
const ApplyPayList = (props) => {

  const [applyPayList, setApplyPayList] = useState([]);
  const [updateState, setUpdateState] = useStateWithCallbackLazy(false);

  const lcUrlMap = getLcUrlItem();
  const lcParamMap = getLcParamItem();

  // url 구분 작업
  const history = useHistory();
  const location = useLocation();

  // const goback = () => {
  //   selectMenu('A');
  //   window.goback = popupClose();
  // }

  //props.applyPayList

  useEffect(() => {
    uiFixValue.init();
    uiFilterTabSwipe.init();
    uiSelFocus.init();
    uiMainSwitch.init();
    uiChartTap.init();
    uiToggle.init();
    uiTopGo.init();

    uiIpFocus.init();
    uiInputDel.init();

    //uiAccoScrollFix.init();

    fetchApplyPayRecent();

    $(window).scrollTop(0);

    window.goback = backToApplyList;

    if (localStorage.getItem('infoPop02Open') != 'false') {
      infoPopOpen();
    }

  }, []);

  useEffect(() => {
    if (updateState === false && (props.applyPayList != null && props.applyPayList.length > 0 && props.applyPayList != applyPayList)) {
      setUpdateState(true);
      setApplyPayList(props.applyPayList);
      setTimeout(() => {
        setUpdateState(false);
      }, 100);
    }
  });

  const fetchApplyPayRecent = () => {
    axios.get('/apt/apply/recentList')
      .then(res => {
        setApplyPayList(res.data.response);
      });
  }

  // [2021-08-23] 안내 팝업 오픈
  const infoPopOpen = () => {
    popupOpen('costinfo', 'mwf', 0);
    uiInfoPopSwiper02.init();
  }

  // [2021-08-23] 안내 팝업 닫기
  const infoPopClose = () => {
    popupClose('costinfo', 'mwf');
    //uiInfoPopSwiper02.infoSwiper02.destroy(false);
  }

  function infoPopCloseNeverOpen() {
    localStorage.setItem('infoPop02Open', false);
    popupClose('saleinfo', 'mwf');
  }


  //--'open' : 현 화면이 다음 화면으로 변경되는 action
  const openApplyPayDtl = (applyId) => {
    setLcUrlItem('applyPayList', 'applyPayDtl');
    setLcParamItem({ 'applyId': applyId });
    props.onApplyPopupType('P_C');
    //props.onApplyPayIdAndApplyDtlDisplay(applyId);
    //props.onApplyPayDtlDisplay(true);

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?CAL-1200#`
    });
  }


  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  const backToAptMain = () => {
    setLcUrlItem('applyPayList', 'aptMain');
    setLcParamItem({});
    
    $(".srch_list_wrap").removeClass("active");

    $('.map_wrap').show();
    props.onApplyMenuType('A');
    props.onApplyListDisplay(false);
  }

  const backToApplyList = () => {
    setLcUrlItem('applyPredict', 'applyList');
    setTimeout(() => {
      $('.bg_body_full').remove();
      uiFixValue.init();
    }, 100);
    props.onApplyMenuType('A');
    props.onApplyPopupType('');
  }


  return (
    <>
      <div className="ly_content">
        {/* <div className="apt_srch_wrap">
          <button type="button" className="btn_srch_full focus" onClick={() => { popupOpen('srch', 'mwf'); uiFilterTabSwipe.init(); uiIpFocus.init(); uiInputDel.init(); }}>
            <span>청약 정보를 검색해주세요.</span>
          </button>
        </div> */}
        <div className="apt_list_wrap">
          <ul>
            {
              applyPayList && applyPayList.map((v, idx) =>
                <li className="item link">
                  <a href="#" className="inner_wrap" onClick={() => openApplyPayDtl(v.applyId)}>
                    <div className="tit_wrap">
                      <strong>{v.aptName}</strong>
                      <span>{v.juso}</span>
                    </div>
                  </a>
                </li>
              )
            }
          </ul>
        </div>
      </div>

      <div id="mw-costinfo" className="ly_pop popBtm ly_info_swiper ndim">
        <div className="wrap">
          <div className="pop_info_swiper02 swiper-container swiper_reset">
            <div className="swiper-pagination"></div>
            <div className="swiper-wrapper">
              <section className="swiper-slide slide01">
                <h1 className="slide_tit">아파트 청약 당첨 시<br /> <strong>해당아파트는 얼마일까?</strong></h1>
                <p className="sub_txt">계약부터 입주까지,<br />총 비용이 궁금한 청약아파트를 검색해주세요.</p>
                <div className="form_ani srch_ani">
                  <div className="box">
                    <p className="show01">청약정보 검색</p>
                    <p className="show02"><code>민앤지포레나퍼스트시티</code></p>
                  </div>
                </div>
                <div className="btn_wrap">
                  <button type="button" className="btn_next">다음<i className="cursor"></i></button>
                </div>
                <button type="button" className="btn_txt" onClick={infoPopClose}>다시보지않기</button>
              </section>
              <section className="swiper-slide slide02">
                <h1 className="slide_tit">아파트 청약 당첨 시<br /> <strong>단계별 필요 금액은 얼마일까?</strong></h1>
                <p className="sub_txt">타입 및 층수별로 총 비용이 달라져요!<br />검색한 아파트 타입 및 층수를 선택해주세요.</p>
                <div className="select_ani_wrap">
                  <div className="select_ani ani01">
                    <div className="form_ani">
                      <div className="box">
                        <p className="show01">유형</p>
                        <p className="show02">59.7700B</p>
                      </div>
                    </div>
                    <div className="selet_detail">
                      <div className="inner_wrap">
                        <button type="button" className="item">
                          <span>59.7700B</span>
                        </button>
                        <button type="button" className="item">
                          <span>49.9400A</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="select_ani ani02">
                    <div className="form_ani">
                      <div className="box">
                        <p className="show01">층수</p>
                        <p className="show02">10층</p>
                      </div>
                    </div>
                    <div className="selet_detail">
                      <div className="inner_wrap">
                        <button type="button" className="item">
                          <span>8층</span>
                        </button>
                        <button type="button" className="item">
                          <span>10층</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn_wrap">
                  <button type="button" className="btn_next">다음<i className="cursor"></i></button>
                </div>
                <button type="button" className="btn_txt" onClick={infoPopClose}>다시보지않기</button>
              </section>
              <section className="swiper-slide slide03">
                <h1 className="slide_tit">아파트 청약 당첨 시<br /> <strong>단계별 필요 금액은 얼마일까?</strong></h1>
                <p className="sub_txt">발코니 확장 여부 선택 및 앞트 유상옵션 금액을<br />입력해주세요.</p>
                <div className="select_ani_wrap">
                  <div className="select_ani ani01">
                    <div className="form_ani">
                      <div className="box">
                        <p className="show01">발코니 확장</p>
                        <p className="show02">확장</p>
                      </div>
                    </div>
                    <div className="selet_detail">
                      <div className="inner_wrap">
                        <button type="button" className="item">
                          <span>확장</span>
                        </button>
                        <button type="button" className="item">
                          <span>비확장</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="select_ani ani02">
                    <div className="form_ani">
                      <div className="box">
                        <p className="show01">유상옵션 금액</p>
                        <p className="show02">미선택</p>
                      </div>
                    </div>
                    <div className="selet_detail">
                      <div className="inner_wrap">
                        <button type="button" className="item">
                          <span>선택</span>
                        </button>
                        <button type="button" className="item">
                          <span>미선택</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn_wrap">
                  <button type="button" className="btn_next" onClick={infoPopClose}>비용계산 하러가기<i className="cursor"></i></button>
                </div>
                <button type="button" className="btn_txt" onClick={infoPopCloseNeverOpen}>다시보지않기</button>
              </section>
            </div>
          </div>
          <button type="button" className="btn_info_reset"><i>새로고침</i></button>
          <button type="button" className="close" onClick={infoPopClose}><i>닫기</i></button>
        </div>
      </div>

    </>
  );
}
export default ApplyPayList;