import create from 'zustand';

/**
 * @desc 컴포넌트 분기용 로그인 정보
 */
export const useUserStore = create(set => ({
    user: {},
    setUser: paramUser => set({
        user: paramUser,
    }),
}));