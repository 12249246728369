export const TELCO_TYPE = Object.freeze({
  SKT: '1',
  KT: '2',
  LG: '3',
});

export const SERVICE_TYPE = Object.freeze({
  APART: 'apart', // 아파트
  APPLY: 'apply', // 청약
});

export const STATUS_TYPE = Object.freeze({
  ALL: '1',         // 전체
  PROGRESS: '2',    // 진행
  SCHEDULE: '3',    // 예정
  COMPLETION: '4',  // 완료
});

export const TRX_TYPE = Object.freeze({
  D: 'D', // 매매
  R: 'R', // 전/월세
});

export const APART_TYPE = Object.freeze({
  A: 'A', // 아파트
  P: 'P', // 분양(X)
  R: 'R', // 단지
  T: 'T', // 단독
});