import React, {useCallback, useEffect, useRef} from 'react';
import {useStateWithCallbackLazy} from 'use-state-with-callback';
import _ from 'lodash';

import {numberWithCommas} from 'routes/common/StringUtil';

import axiosRequest from 'routes/common/axios/services/AxiosRequest';

// import useMore from '../hooks/useMore';

const MarginPriceList = () => {
  // const {csList: csList, csRef: csRef, initHandler: initHandler, moreHandler: moreHandler} = useMore({key: 'marginPriceList', http: '/apt/apply/predict/findMarginPriceList'});
  const [list, setList] = useStateWithCallbackLazy([]);
  const [csList, setCsList] = useStateWithCallbackLazy([]);
  const [csListIdx, setCsListIdx] = useStateWithCallbackLazy(5);
  const [isLoading, setIsLoading] = useStateWithCallbackLazy(false); // 혹시 데이터 불러오는데 느리면 활용해야함.

  const csRef = useRef();

  const moreHandler = useCallback(() => {
    const limit = 5;
    
    setCsListIdx(csListIdx + limit);
    
    if (list.length < csListIdx + limit) {
      // data 최대 길이를 넘었을 때
      for (let i = csListIdx; i < list.length; i++) {
        setCsList((prevState) => [...prevState, list[i]]);
      }

      csRef.current.classList.add('scroll_start');
      // csRef.current.classList.remove('scroll_start');
      // csRef.current.classList.add('scroll_end');
    } else {
      // default
      for (let i = csListIdx; i < csListIdx + limit; i++) {
        setCsList((prevState) => [...prevState, list[i]]);
      }

      csRef.current.classList.add('scroll_start');
    }

    setTimeout(() => {
      // 클릭할 때마다 스크롤 맨 아래로...
      csRef.current.scroll({
        behavior: 'smooth',
        top: csRef.current.scrollHeight,
      });
    }, 50);
  }, [csListIdx, list]);

  useEffect(async () => {
    // initHandler();

    try {
      const { status, data } = await axiosRequest.findMarginPriceList();

      switch (status) {
        case 200: {
          let objList = data.response.marginPriceList;

          if (objList.length > 0) {
            objList = _.chain(objList).map(function(obj, index) {
              return {...obj, rank: (index + 1)};
            }).value();
  
            setList(objList);
  
            // 초기 데이터가 6개가 안되면...
            if (objList.length < 6) {
              for (let i = 0; i < objList.length; i++) {
                setCsList((prevState) => [...prevState, objList[i]]);
              }
              csRef.current.classList.add('scroll_end');
            } else {
              for (let i = 0; i < 5; i++) {
                setCsList((prevState) => [...prevState, objList[i]]);
              }
            }
          }

          break;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      {/* 청약 예상시세차익 */}
      <section className="map_section" data-scroll-section="section2">
        <h3 className="content_title"><img src={process.env.REACT_APP_ENV_URL + "resources/images/icon_sub_chart.png"} alt="차트" aria-hidden="true" />청약 예상시세차익<span className="text_label type1">Beta</span></h3>
        <p>2020년도 이후 주택청약 공고 기준</p>
        <div className="cs_tbl">
          <div className="cs_tbl_header">
            <strong>순위</strong>
            <strong>위치</strong>
            <strong>평당가 차익</strong>
          </div>
          <div className="cs_tbl_body">
            {/* .cs_tbl_body > ol */}
            {/* ol에 .scroll_start가 붙어야 스크롤 가능 */}
            {/* ol에 .scroll_end가 붙으면 하얀 딤, 화살표가 사라짐. = 데이터를 다 불러왔을 경우... */}
            {/* 예시 소스 입니다... 해당소스 사용하셔도 되고, 새로 작성하셔도 됩니다. */}
            <ol ref={csRef}>
              {csList.map(data => {
                return (
                  <li key={data.applyId}>
                    <span title="순위">{data.rank}</span>
                    <span title="위치">{data.bjdName1 + ' ' + data.bjdName2 + ' ' + data.bjdName3}</span>
                    <span title="평당가 차익">{numberWithCommas(data.marginPricePy) + '원'}</span>
                  </li>
                )
              })}
            </ol>
            {/* 2022.08.05 - GTM CLASS 추가 */}
            <button type="button" className="cs_tbl_more GTM-MORE-MARGINPRICE" onClick={moreHandler}><span className="blind">더보기</span></button>
          </div>
        </div>
        <p className="text_mark"><span>※</span>아파트청약케어는 부동산 흐름을 예측할 수 있는 참고자료를 제공하는 것이며, 최종 투자의 모든 책임은 투자자에게 있습니다.</p>
      </section>
    </>
  );
}

export default MarginPriceList;