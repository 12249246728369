import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'; // url 구분 작업
import { useStateWithCallbackLazy } from 'use-state-with-callback';

import SubFooter from 'routes/SubFooter';

import MainFilter from 'routes/apply/filter/MainFilter';
import LocalsFilter from 'routes/apply/filter/LocalFilter';
import HhldFilter from 'routes/apply/filter/HhldFilter';
import PyFilter from 'routes/apply/filter/PyFilter';
import TrxAmtFilter from 'routes/apply/filter/TrxAmtFilter';

import { AptOverlay } from 'routes/overlay/AptOverlay.js';
import { BjdOverlay } from 'routes/overlay/BjdOverlay.js';

import axios from 'axios';
import $ from 'jquery';

import { searchApply, addRecentItem, getRecentItem, clearRecentItem, setRecentList, addLsItem, getLsItem } from 'routes/common/Search';
import { dateDiffMsg, numberWithCounts, numberWithCommas, numberWithCommas2, dateFormatMsg, dateFormatMsg2, numberReplaceWithStag, textReplaceWithStag, numberReplaceWithCodetag } from 'routes/common/StringUtil';
import { uiFixValue, uiFilterTabSwipe, popupOpen, popupClose, uiIpFocus, uiInputDel, uiAccoScrollFix, uiSelFocus, uiMainSwitch, uiChartTap, uiToggle, uiTopGo } from 'routes/common/Design.js'
import { findIndex } from 'routes/common/ArrayUtil';
import _ from 'lodash';

import RecentResult from 'routes/common/RecentResult';
import SearchResult from 'routes/common/SearchResult';
import Moment from 'moment';

import RealtyCalc from 'routes/realty/RealtyCalc';
import RealtyNews from 'routes/realty/RealtyNews';
import RealtyPolicy from 'routes/realty/RealtyPolicy';

import { setLcUrlItem, getLcUrlItem, setLcParamItem, getLcParamItem } from 'routes/common/ParamStorage';

//// 컴포넌트 사용처 : 부동산계산기,부동산정책,부동산뉴스 master페이지
const RealtyMaster = (props) => {
  // url 구분 작업
  const history = useHistory();
  const location = useLocation();

  const [realtyMenuType, setRealtyMenuType] = useStateWithCallbackLazy('');

  useEffect(() => {
    if (props.realtyMenuType != '') {
      setRealtyMenuType(props.realtyMenuType);
    }

    window.goback = backToAptMain;
    window.checkMainPage = () => { return false; }

    return () => {
      window.checkMainPage = () => { return true; }
    }

    $(window).scrollTop(0);

  }, []);



  const selectMenu = (menuType, e) => {
    e && e.preventDefault();
    setRealtyMenuType(menuType);

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: menuType === 'A' ? `?AAL-1000#` : menuType === 'B' ? `?AEW-1000#` : `?POL-1000#`
    });
  }


  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  const backToAptMain = () => {
    setLcUrlItem('realtyCalc', 'aptMain');
    //setLcParamItem({});
    $('.map_wrap').show();
    props.onRealtyMasterDisplay(false);

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: ``
    })
  }

  return (
    <>
      <div id="mw-calculator" className="ly_apt_sale full">
        <div className="wrap">
          <div className="content">
            <div className="header">
              <header>
                <button type="button" onClick={() => backToAptMain()} className="btn_prev" title="뒤로가기"><i></i></button>
                <strong className="tit">부동산 정보</strong>
                {/* <button type="button" onClick="popupOpen('srch','mwf');" className="btn_srch" title="검색하기"><i></i></button> */}
              </header>
              <ul className="menu_wrap">

                <li className={realtyMenuType === 'A' ? 'active' : ''} ><a href="#" onClick={(e) => selectMenu('A', e)}>부동산계산기</a></li>
                <li className={realtyMenuType === 'B' ? 'active' : ''} ><a href="#" onClick={(e) => selectMenu('B', e)}>부동산뉴스</a></li>
                <li className={realtyMenuType === 'C' ? 'active' : ''} ><a href="#" onClick={(e) => selectMenu('C', e)}>부동산정책</a></li>
              </ul>
            </div>
            {
              realtyMenuType === 'A' ?
                <RealtyCalc></RealtyCalc> :
                realtyMenuType === 'B' ?
                  <RealtyNews></RealtyNews> :
                  realtyMenuType === 'C' ?
                    <RealtyPolicy></RealtyPolicy> : null
            }
            <SubFooter></SubFooter>
          </div>
        </div>
      </div>
    </>
  );
}

export default RealtyMaster;