import {AptOverlay} from "./AptOverlay";

export function AroundOverlay (data) {
  this.position = new kakao.maps.LatLng(data.lat, data.lng);    // eslint-disable-line
  let node = this.node = document.createElement('div');
  node.className = 'map_marker_icon';
  node.setAttribute('data-lat', data.lat);
  node.setAttribute('data-lng', data.lng);
  //클릭시 class에 active 부여 (APP한정, 현재 주석)
  /*node.onclick = function () {
    node.className = 'map_marker_icon active';
  }*/

  let icoDivNode = document.createElement('div');
  if (data.type === 'school') {
    let infoDivNode = document.createElement('div');
    infoDivNode.className = 'marker_info';


    let infoPNode = document.createElement('p');
    infoPNode.className = 'name';
    infoPNode.textContent = data.locName;
    infoDivNode.appendChild(infoPNode);
    node.appendChild(infoDivNode);

    icoDivNode.className = 'marker_icon school';

    node.appendChild(icoDivNode);

    let pNode = document.createElement('p');
    let pNodeText =
        (data.locName.slice(-1) === '초' || data.locName.slice(-4) === '초등학교') ? '초' :
            (data.locName.slice(-1) === '중' || data.locName.slice(-3) === '중학교') ? '중' :
                (data.locName.slice(-1) === '고' || data.locName.slice(-4) === '고등학교') ? '고' : ''

    pNode.textContent = pNodeText;
    icoDivNode.appendChild(pNode);

  } else {

    let infoDivNode = document.createElement('div');
    infoDivNode.className = 'marker_info';


    let infoPNode = document.createElement('p');
    infoPNode.className = 'name';
    infoPNode.textContent = data.locName;
    infoDivNode.appendChild(infoPNode);
    node.appendChild(infoDivNode);

    icoDivNode.className = 'marker_icon';
    node.appendChild(icoDivNode);

    let icoNode = document.createElement('i');
    icoNode.className = data.type;
    icoDivNode.appendChild(icoNode);

  }

  let shadowNode = document.createElement('div');
  shadowNode.className = 'marker_shadow';
  icoDivNode.appendChild(shadowNode);
}

if (window.kakao) {
  AroundOverlay.prototype = new window.kakao.maps.AbstractOverlay;

  AroundOverlay.prototype.onAdd = function () {
    var panel = this.getPanels().overlayLayer;
    panel.appendChild(this.node);
  }

  AroundOverlay.prototype.onRemove = function () {
    this.node.parentNode && this.node.parentNode.removeChild(this.node);
  }

  AroundOverlay.prototype.draw = function () {
    // 화면 좌표와 지도의 좌표를 매핑시켜주는 projection객체
    var projection = this.getProjection();

    // overlayLayer는 지도와 함께 움직이는 layer이므로
    // 지도 내부의 위치를 반영해주는 pointFromCoords를 사용합니다.
    var point = projection.pointFromCoords(this.position);

    // 내부 엘리먼트의 크기를 얻어서
    var width = this.node.offsetWidth;
    var height = this.node.offsetHeight;

    // 해당 위치의 정중앙에 위치하도록 top, left를 지정합니다.
    // marker 특성 고려 말풍선꼭지부분이 중앙에 오도록 재조정 (left+10, top-10)
    this.node.style.left = (point.x - width / 2) + 10 + "px";
    this.node.style.top = (point.y - height / 2) - 10 + "px";
  }

// 좌표를 반환하는 메소드
  AroundOverlay.prototype.getPosition = function () {
    return this.position;
  }
}

// export class AroundOverlay extends kakao.maps.AbstractOverlay {    // eslint-disable-line
//
//   constructor(data) {
//     super();
//     this.position = new kakao.maps.LatLng(data.lat, data.lng);    // eslint-disable-line
//     let node = this.node = document.createElement('div');
//     node.className = 'map_marker_icon';
//     node.setAttribute('data-lat', data.lat);
//     node.setAttribute('data-lng', data.lng);
//     //클릭시 class에 active 부여 (APP한정, 현재 주석)
//     /*node.onclick = function () {
//       node.className = 'map_marker_icon active';
//     }*/
//
//     let icoDivNode = document.createElement('div');
//     if (data.type === 'school') {
//       let infoDivNode = document.createElement('div');
//       infoDivNode.className = 'marker_info';
//
//
//       let infoPNode = document.createElement('p');
//       infoPNode.className = 'name';
//       infoPNode.textContent = data.locName;
//       infoDivNode.appendChild(infoPNode);
//       node.appendChild(infoDivNode);
//
//       icoDivNode.className = 'marker_icon school';
//
//       node.appendChild(icoDivNode);
//
//       let pNode = document.createElement('p');
//       let pNodeText =
//         (data.locName.slice(-1) === '초' || data.locName.slice(-4) === '초등학교') ? '초' :
//           (data.locName.slice(-1) === '중' || data.locName.slice(-3) === '중학교') ? '중' :
//             (data.locName.slice(-1) === '고' || data.locName.slice(-4) === '고등학교') ? '고' : ''
//
//       pNode.textContent = pNodeText;
//       icoDivNode.appendChild(pNode);
//
//     } else {
//
//       let infoDivNode = document.createElement('div');
//       infoDivNode.className = 'marker_info';
//
//
//       let infoPNode = document.createElement('p');
//       infoPNode.className = 'name';
//       infoPNode.textContent = data.locName;
//       infoDivNode.appendChild(infoPNode);
//       node.appendChild(infoDivNode);
//
//       icoDivNode.className = 'marker_icon';
//       node.appendChild(icoDivNode);
//
//       let icoNode = document.createElement('i');
//       icoNode.className = data.type;
//       icoDivNode.appendChild(icoNode);
//
//     }
//
//     let shadowNode = document.createElement('div');
//     shadowNode.className = 'marker_shadow';
//     icoDivNode.appendChild(shadowNode);
//   }
//
//   onAdd() {
//     var panel = super.getPanels().overlayLayer;
//     panel.appendChild(this.node);
//   }
//
//   onRemove() {
//     this.node.parentNode && this.node.parentNode.removeChild(this.node);
//   };
//
//   draw() {
//     // 화면 좌표와 지도의 좌표를 매핑시켜주는 projection객체
//     var projection = super.getProjection();
//
//     // overlayLayer는 지도와 함께 움직이는 layer이므로
//     // 지도 내부의 위치를 반영해주는 pointFromCoords를 사용합니다.
//     var point = projection.pointFromCoords(this.position);
//
//     // 내부 엘리먼트의 크기를 얻어서
//     var width = this.node.offsetWidth;
//     var height = this.node.offsetHeight;
//
//     // 해당 위치의 정중앙에 위치하도록 top, left를 지정합니다.
//     // marker 특성 고려 말풍선꼭지부분이 중앙에 오도록 재조정 (left+10, top-10)
//     this.node.style.left = (point.x - width / 2) + 10 + "px";
//     this.node.style.top = (point.y - height / 2) - 10 + "px";
//   };
//
//   // 좌표를 반환하는 메소드
//   getPosition() {
//     return this.position;
//   };
// }