import React from 'react';

import {CouponRoot} from "./layout/CouponRoot";
import {CouponInfo} from "../../components/coupon/entities/CouponInfo";
import {NotSelectedCoupons} from "../../components/coupon/widgets/couponList/NotSelectedCoupons";
import {useCoupon} from "../../hooks/useCoupon";

/**
 * @desc 로그인 회원 쿠폰 화면(쿠폰 선택안된 상태)
 */
export const NotSelected = () => {
    const {hasCouponManage} = useCoupon();

    return (
            <>
                {
                        !hasCouponManage() &&
                        <CouponRoot>
                            <NotSelectedCoupons/>
                            <CouponInfo/>
                        </CouponRoot>
                }
            </>
    );
};
