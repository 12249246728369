export const setLcUrlItem = (preventUrl, currentUrl) => {
  ////console.log('move URL :::::::::: ' + preventUrl + '->' + currentUrl);

  let lc = window.localStorage;
  lc.setItem('urlMap', JSON.stringify({ 'prevUrl': preventUrl, 'currUrl': currentUrl }));
}

export const getLcUrlItem = () => {
  let lc = window.localStorage;
  return JSON.parse(lc.getItem('urlMap'));
}


export const setLcParamItem = (paramMap) => {
  let lc = window.localStorage;
  lc.setItem('paramsMap', JSON.stringify(paramMap));
}

export const getLcParamItem = () => {
  let lc = window.localStorage;
  let currentParam = JSON.parse(lc.getItem('paramsMap'));
  return currentParam;
}


// export const setLcScoreItem = (houseScore, familyScore, accountScore, totalScore) => {
//   let lc = window.localStorage;
//   lc.setItem('scoreMap', JSON.stringify({ 'houseScore': houseScore, 'familyScore': familyScore, 'accountScore': accountScore, 'totalScore': totalScore }));
// }

// export const getLcScoreItem = () => {
//   let lc = window.localStorage;
//   return JSON.parse(lc.getItem('scoreMap'));
// }