import React, { Component } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

//// 컴포넌트 사용처 : 청약 당첨 예상 상세
const AnaylyzeInfo = ({ calcResult }) => {

  const { score, applyInfo, applyDtl } = calcResult;

  return (
    <section className="analyze_info">
      <ul>
        <li>
          <strong>나의 청약 가점</strong>
          <p>
            <span><code>{score.total}</code>점</span>
          </p>
        </li>
        <li>
          <strong>청약 희망 아파트</strong>
          <p>{applyInfo.aptName}</p>
        </li>
        <li>
          <strong>희망타입</strong>
          <p>{applyDtl.hsType} / {Math.round(applyDtl.hsArea * 0.3025)}평형</p>
        </li>
      </ul>
    </section>
  );
}

export default AnaylyzeInfo;
