import React, {useEffect, useState} from 'react'

import {dateFormatMsg, numberWithCommas} from 'routes/common/StringUtil'
import {clearLcApplyFavItem, getLcApplyFavList} from 'routes/common/AptFavUtil'
import {setLcParamItem} from 'routes/common/ParamStorage'
import {uiFixValue} from 'routes/common/Design'
import axios from 'axios';
import $ from 'jquery';
import {findIndex} from 'routes/common/ArrayUtil';
import {orderBy} from 'lodash';

//// 컴포넌트 사용처 : 청약 정보 즐겨찾기
const FavApplyList = (props) => {

  const [favResult, setFavResult] = useState([]);

  useEffect(() => {
    uiFixValue.init();
    fetchFavResult();

    $(window).scrollTop(0);

    window.goback = backToApplyList;
    window.checkMainPage = () => { return false; }

    return () => {
      window.checkMainPage = () => { return true; }
    }

  }, []);

  const fetchFavResult = () => {
    setFavResult([]);

    const favList = getLcApplyFavList();
    const param = favList.map(f => `applyId=${f.applyId}`).join('&');
    axios
      .get('/apt/map/getFavApplyList?' + param)
      .then(res => {
        let resData = res.data;
        resData.forEach(v => {
          if (favList.some(x => x.applyId === v.applyId)) {
            const itemIdx = findIndex(favList, x => x.applyId === v.applyId);
            v.favTime = favList[itemIdx].favTime;
          }
        });
        setFavResult(orderBy(resData, ["favTime"], ["desc"]));
      });
  }

  const delFav = (e, fav) => {
    e.preventDefault();
    clearLcApplyFavItem(fav);
    fetchFavResult();
  }

  const selFav = (e, fav) => {
    e.preventDefault();
    setLcParamItem({ 'applyId': fav.applyId });
    props.onFavApplyListDisplay(false);
    props.onApplyPopupType('P_A');
    //props.onMoveDtl(fav.aptId, fav.aptAreaId);
  }

  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  const backToApplyList = () => {
    //setLcUrlItem('aptDtl', 'aptMap');
    //popupClose('aptdetail', 'mwf');
    props.onFavApplyListDisplay(false);

    $('#mw-sale').show();
    setTimeout(() => {
      $('.bg_body_full').remove();
      uiFixValue.init();
      $(window).scrollTop(0);
    }, 100);
    //$('.bg_body_full').remove();
    //uiFixValue.init();
    //uiFilterTabSwipe.init();
    $('#mw-srch').show();
    //$('.map_wrap').show();
  }

  return (
    <>
      <div id="mw-bookmark" className="ly_apt_list full">
        <div className="wrap">
          <div className="content">
            <header className="header">
              <button type="button" onClick={() => backToApplyList()} className="btn_prev" title="뒤로가기"><i></i></button>
              <strong className="tit">청약정보 즐겨찾기</strong>
              <button type="button" onClick={() => backToApplyList()} className="btn_location" title="지도보기"><i></i></button>
            </header>
            <div className="ly_content">
              {
                favResult.length === 0 ?
                  <div className="nodata_wrap">
                    <p className="txt">아직 즐겨찾기 된 아파트가 없어요.</p>
                  </div>
                  :
                  <>
                    <ul className="apt_list_wrap">
                      {
                        favResult.map(v => {
                          const numbers = (v.maxApplyPrice * 10000).toString().replace(/\B(?=(\d{4})+(?!\d))/g, ",").split(',');
                          let number1 = '';
                          let number2 = '';

                          number1 = numbers[0].toString().replace(/(^0+)/, "");
                          if (numbers.length > 1) {
                            number2 = numbers[1].toString().replace(/(^0+)/, "");
                          }

                          const amtStr = (v.maxApplyPrice) > 10000 ? <><s>{number1}</s> 억 <s>{number2}</s></> : <><s>{number1}</s></>;

                          return (
                            <li className="item">
                              <button type="button" className="btn_del" onClick={(e) => delFav(e, v)}>삭제</button>
                              <a href="#" className="inner_wrap" onClick={(e) => selFav(e, v)}>
                                <div className="tit_wrap">
                                  <strong>{v.aptName}</strong>
                                  <span>{v.address}</span>
                                </div>
                                <p className="price">{amtStr}</p>
                                <ul className="info">
                                  {/* <li>{formatYmd(v.trxDate, "comma1")}거래</li>
                                  <li>{v.useAprDay}년</li> */}
                                  <li>{dateFormatMsg(v.notiDate)} / {numberWithCommas(v.applyCnt)}세대</li>
                                  <li>{
                                    (v.loanRateCase === '1' || v.loanRateCase === '2') ?
                                      '투기 과열'
                                      :
                                      (v.loanRateCase === '3' || v.loanRateCase === '4') ?
                                        '조정 지역'
                                        :
                                        null
                                  }</li>
                                </ul>
                              </a>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FavApplyList;