import React, { Component } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

//// 컴포넌트 사용처 : 청약 당첨 예상 상세
const RecentResult = ({ calcResult }) => {

  const { maxScoreList, minScoreList, recentList } = calcResult;

  return (
    <div className="view_toggle">
      <div className="bubble">
        <strong className="tit_high">당첨 최고 가점 <code>TOP 3</code></strong>
        <ul className="high">
          {
            maxScoreList.map((v, idx) => {

              return <li key={idx}>
                <strong className="rank">TOP 0{idx + 1}</strong>
                <p>{v.aptName}</p>
                <span>최고 <s>{v.maxScore}</s></span>
              </li>;
            })
          }
        </ul>
        <strong className="tit_low">당첨 최저 가점 <code>TOP 3</code></strong>
        <ul className="low">
          {
            minScoreList.map((v, idx) => {
              return <li key={idx}>
                <strong className="rank">TOP 0{idx + 1}</strong>
                <p>{v.aptName}</p>
                <span>최저 <s>{v.minScore}</s></span>
              </li>
            })
          }
        </ul>
      </div>
      <div className="tb_apt">
        <table>
          <colgroup>
            <col style={{ "width": "auto" }} />
            <col style={{ "width": "55px" }} />
            <col style={{ "width": "60px" }} />
            <col style={{ "width": "75px" }} />
          </colgroup>
          <thead>
            <tr>
              <th>아파트명</th>
              <th>지역</th>
              <th>최고 · 최저</th>
              <th>경쟁률</th>
            </tr>
          </thead>
          <tbody>
            {
              recentList.map((v, idx) => {
                return <tr key={idx}>
                  <td><span>{v.aptName}</span></td>
                  <td>{v.juso}</td>
                  <td className="high_low"><span>{v.maxScore} · </span><span>{v.minScore}</span></td>
                  <td>{v.rate}:1</td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RecentResult;
