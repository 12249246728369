import React from "react";
import {Link} from "react-router-dom";


export const SideMenuItem = (props) =>{

    return (
            <dd>
                <Link to={props.url}>{props.itemName}</Link>
            </dd>
    );

}