import React from "react";

/**
 * @desc 사용 가능한 유색쿠폰
 */
export const CouponItemNotSelected = ({coupon, selectCoupon}) => {

    return (
            <li className="coupon-item" style={{backgroundColor: coupon.backgroundColor}}>
                <div className="logo-box">
                    {
                        coupon.cpnGrpCd === "GS"
                                ? <img src={coupon.image02} alt="" className="img-logo" style={{height: '14px'}}/> // GS 쿠폰의 경우 높이 조절 속성이 들어감
                                : <img src={coupon.image02} alt="" className="img-logo"/>
                    }
                </div>
                {
                    coupon.cpnGrpCd === "TE"
                            ? <span className="text-05">테스트</span>
                            : <span className="text-05">{coupon.price}</span>
                }
                <button className="btn-coupon-down" onClick={() => selectCoupon(coupon)}>
                    <i className="icon-download"></i>
                </button>
            </li>
    );
};