

var isWebViewEventReady = false;

export const initWebViewEvent = () => {
  window.addEventListener("flutterInAppWebViewPlatformReady", (event) => {
    isWebViewEventReady = true;
  })
}

export const callAppInterface = (interfaceName, args, callback) => {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler(interfaceName, args).then(callback);
  } else {
    switch (interfaceName) {
      case 'closeWebPage':  window.location.href = '/';         break;
      default :   alert(window.flutter_inappwebview);
    }
  }
}

// export const callAppInterface = (interfaceName, args, callback) => {
//   if (interfaceName != 'closeWebPage') {  // [SOM.220323] 통신사 문자 해지 링크 > 해지페이지로 인입시 'undefined' 메세지 출력 막기
//     if (!window.flutter_inappwebview) alert(window.flutter_inappwebview);
//     window.flutter_inappwebview.callHandler(interfaceName, args).then(callback);
//   }
// }
