import React, { useEffect, useState, useRef } from 'react'
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import axios from 'axios';
import Moment from 'moment';
import $ from 'jquery';

import { uiFixValue, uiFilterTabSwipe, popupOpen, popupClose, uiIpFocus, uiInputDel, uiAccoScrollFix, uiSelFocus, uiMainSwitch, uiChartTap, uiToggle, uiTopGo, joinInfoEvent } from 'routes/common/Design.js'
import { initWebViewEvent, callAppInterface, checkMainPage } from 'routes/common/AppInterface'

import { osCheck } from 'routes/common/OsUtil';

//// 컴포넌트 사용처 : 앱버전정보
const AppInfo = (props) => {

  const [version, setVersion] = useState('');
  let os = "";

  useEffect(() => {

    $(window).scrollTop(0);
    window.goback = backToAptMain;

    os = osCheck() == 'Android' ? "A" : "I";

    axios
      .get(`/apt/appVersion?os=${os}`)
      .then(res => {
        setVersion(res.data.response.appLatestVersion);
      });

  }, []);

  const move = () => {
    let url = '';
    os = osCheck() == 'Android' ? "A" : "I";

    if (os == "A") {
      url = 'https://play.google.com/store/apps/details?id=com.minwise.apartcare';
      const param = { 'url': url };
      callAppInterface('openWebPage', JSON.stringify(param), result => {
      });
    }
    else {
      url = 'https://apps.apple.com/kr/app/id1575170352';
      const param = { 'url': url };
      callAppInterface('openWebPage', JSON.stringify(param), result => {
      });
    }

  }


  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  //단독페이지들은 location href = / 로 보냄
  const backToAptMain = () => {
    //window.location.href = '/';
    callAppInterface("closeWebPage", JSON.stringify({ url: '/' }), result => {
      setTimeout(() => {
        window.location.href = '/';
      }, 2000);
    });
  }

  return (
    <>
      <div id="mw-ver" className="ly_apt_ver full">
        <div className="wrap">
          <div className="content">
            <header className="header">
              <button type="button" onClick={() => {window.location.href="/"}} className="btn_home" title="홈으로 바로가기"><i></i></button>
              <button type="button" onClick={backToAptMain} className="btn_prev" title="뒤로가기"><i></i></button>
              <strong className="tit">앱정보</strong>
            </header>
            <div className="ly_content">
              <div className="ver_wrap">
                <p className="txt">최신 버전을 사용 중입니다.<span>현재 버전 {version}</span></p>
              </div>
            </div>
            <div className="btn_area_wide fix btm">
              <button type="button" className="skip" onClick={move} >최신버전 업데이트</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AppInfo;