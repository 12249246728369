import {popupOpen} from 'routes/common/Design.js'
import $ from 'jquery';

//// 컴포넌트 사용처 : 청약 당첨 예상 상세
export const popupAlert01 = () => {

  if ($('#mw-alert01').length > 0) {
    popupOpen('alert01', 'mwf', 0);

  } else {
    $('#pageWrap').append(`
      <div id="mw-alert01" class="ly_pop popBtm ly_alert_b">
        <div class="wrap">        
          <div class="content">
              <strong class="tit">무주택자 점수 계산?</strong>
              <p class="txt">
                  * 입주자모집공고일 기준 청약통장 명의자와 주민등록등본에 등재된 명의자 및 세대원 전원이 무주택자여야 해당<br>
                  (세대원의 범위 : 배우자(주민등록이 분리된 배우자 및 그 세대원 포함), 직계존속(배우자의 직계존속 포함), 직계비속)<br>
                  <br>
                  * 청약통장 명의자 및 배우자를 대상으로 무주택기간 산정<br>
                  (무주택기간은 만 30세가 가산점이며, 30세 이전에 혼인한 경우 혼인신고 한 날부터 가산)<br>
                  <br>
                  * 만 30세 미만이면서 미혼인 무주택자의 가점은 '0'점
              </p>
              <button type="button" onclick="popupClose('alert01','mwf',0);" class="close"><i>닫기</i></button>
          </div>
        </div>
      </div>
  `);

    popupOpen('alert01', 'mwf', 0);
  }
}

export const popupAlert02 = () => {

  if ($('#mw-alert02').length > 0) {
    popupOpen('alert02', 'mwf', 0);

  } else {
    $('#pageWrap').append(`
      <div id="mw-alert02" class="ly_pop popBtm ly_alert_b">
        <div class="wrap">        
            <div class="content">
                <strong class="tit">부양가족 점수 계산?</strong>
                <p class="txt">
                    * 부양가족 인정 대상자 : 입주자모집공고일 현재 청약통장 명의자의 주민등록등본에 등재된 세대원<br>
                    <br>
                    ※ (세대원의 범위 : 배우자(주민등록이 분리된 배우자 및 그 세대원 포함), 직계존속(배우자의 직계존속 포함), 직계비속(미혼인 자녀에 한하며, 부모가 사망한 경우에는 손자, 손녀를 포함))<br>
                    <br>
                    - 직계존속을 부양하는 경우에는 세대주로서 3년이상 계속 부양<br>
                    <br>
                    - 30세 이상의 미혼자녀는 입주자모집공고일 기준으로 최근 1년 이상 계속하여 동일한 주민등록표상에 등재시에만 부양가족 점수를 받음
                </p>
                <button type="button" onclick="popupClose('alert02','mwf',0);" class="close"><i>닫기</i></button>
            </div>
        </div>
    </div>
    `);

    popupOpen('alert02', 'mwf', 0);
  }
}

export const popupAlert03 = () => {

  if ($('#mw-alert03').length > 0) {
    popupOpen('alert03', 'mwf', 0);

  } else {
    $('#pageWrap').append(`
    <div id="mw-alert03" class="ly_pop popBtm ly_alert_b">
        <div class="wrap">        
            <div class="content">
                <strong class="tit">청약통장 점수 계산?</strong>
                <p class="txt">
                    입주자모집공고일 기준 청약자의 청약통장 가입기간이 기준<br>
                    <br>
                    - 청약통장 전환, 예치금액변경 및 명의변경을 한 경우, 최초가입일(순위기산일) 기준으로 가입기간 산정<br>
                    <br>
                    ※ 실제 인터넷청약 시, 청약통장 가입기간 및 해당 점수를 자동 산정하여 부여
                </p>
                <button type="button" onclick="popupClose('alert03','mwf',0);" class="close"><i>닫기</i></button>
            </div>
        </div>
    </div>
    `);

    popupOpen('alert03', 'mwf', 0);
  }
}