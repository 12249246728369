import React from "react";

/**
 * @desc 유의사항 컴포넌트
 *
 * - 미가입자
 * - 쿠폰 미선택자
 * - 쿠폰 재선택자
 * -> 매월 (오늘날짜-1일) 까지 발급 가능, 매월 (오늘날짜)에 재선택 가능
 *
 * - 쿠폰 선택자
 * -> 매월 (쿠폰선택일자-1일) 까지 발급 가능, 매월 (쿠폰선택일자)에 재선택 가능
 * (재선택 일자가 지날경우 미가입자 기준을 따른다)
 */
export const CouponGSNotice = () => {

    return (
            <div className="coupon-notice">
                <strong className="coupon-notice-title">※ 유의사항</strong>
                <ul className="coupon-notice-list">
                    <li className={"coupon-notice-item"}>바코드 인식이 안될 경우, 바코드 하단의 번호를 입력하여 결제 가능</li>
                    <li className={"coupon-notice-item"}>기업 경품 및 프로모션으로 수령받은 상품권은 현금으로 교환 및 잔액 환불 불가</li>
                    <li className={"coupon-notice-item"}>상품권의 유료기간 초과시 사용이 불가합니다.</li>
                    <li className={"coupon-notice-item"}>교환처가 지정된 경우 해당 교환처에서만 상품권을 사용하실 수 있습니다.</li>
                    <li className={"coupon-notice-item"}>사용가능일자가 지정된 경우 해당 일자에만 상품권을 사용하실 수 있습니다.</li>
                    <li className={"coupon-notice-item"}>사용조건이 지정된 경우 해당 조건 미 충족 시 상품권 사용이 불가합니다.</li>
                    <li className={"coupon-notice-item"}>기업 프로모션 형태의 모바일 상품권은 유효기간 연장 및 환불 불가합니다.</li>
                </ul>
            </div>
    );
};