import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'; // url 구분 작업
import {formatAmt, formatYmd, numberWithCommas} from 'routes/common/StringUtil'
import {clearFavItem, getFavList} from 'routes/common/AptFavUtil'
import {uiFixValue} from 'routes/common/Design'
import axios from 'axios';
import $ from 'jquery';
import {findIndex} from 'routes/common/ArrayUtil';
import {orderBy} from 'lodash';

//// 컴포넌트 사용처 : 아파트 정보 즐겨찾기
const FavList = (props) => {
  // url 구분 작업
  const history = useHistory();
  const location = useLocation();

  const [favResult, setFavResult] = useState([]);

  useEffect(() => {
    uiFixValue.init();
    fetchFavResult();

    $(window).scrollTop(0);

    window.goback = backToAptMap;
    window.checkMainPage = () => { return false; }

    return () => {
      window.checkMainPage = () => { return true; }
    }

  }, []);

  const fetchFavResult = () => {
    setFavResult([]);
    const favList = getFavList();
    const param = favList.map(f => `aptId=${f.aptId}&aptAreaId=${f.aptAreaId}`).join('&');
    axios
      .get('/apt/map/getFavAptList?' + param)
      .then(res => {
        let resData = res.data;
        resData.forEach(v => {
          if (favList.some(x => x.aptId === v.aptId)) {
            const itemIdx = findIndex(favList, x => x.aptId === v.aptId);
            v.favTime = favList[itemIdx].favTime;
          }
        });
        //this.setState({ favResult: orderBy(resData, ["favTime"], ["desc"]) });
        setFavResult(orderBy(resData, ["favTime"], ["desc"]));
      })
  }

  const delFav = (e, fav) => {
    e.preventDefault();
    clearFavItem(fav);
    fetchFavResult();
  }

  const selFav = (e, fav) => {
    e.preventDefault();
    props.onFavListDisplay(false);
    props.onMoveDtl(fav.aptId, fav.aptAreaId);
  }

  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  const backToAptMap = () => {
    //setLcUrlItem('aptDtl', 'aptMap');
    //popupClose('aptdetail', 'mwf');
    props.onFavListDisplay(false);
    $('.map_wrap').show();

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: ``
    });
  }

  return (
    <>
      <div id="mw-bookmark" className="ly_apt_list full">
        <div className="wrap">
          <div className="content">
            <header className="header">
              <button type="button" onClick={() => backToAptMap()} className="btn_prev" title="뒤로가기"><i></i></button>
              <strong className="tit">아파트정보 즐겨찾기</strong>
              <button type="button" onClick={() => backToAptMap()} className="btn_location" title="지도보기"><i></i></button>
            </header>
            <div className="ly_content">
              {
                favResult.length === 0 ?
                  <div className="nodata_wrap">
                    <p className="txt">아직 즐겨찾기 된 아파트가 없어요.</p>
                  </div>
                  :
                  <>
                    <ul className="apt_list_wrap">
                      {
                        favResult.map(v => {
                          const amt = v.amt;
                          const amtStr = amt > 10000 ? <><code>{formatAmt(amt, 'amt4')}</code>억 <code>{formatAmt(amt, 'amt5')}</code></> : <><code>{formatAmt(amt, 'amt5')}</code></>;

                          return (
                            <li className="item">
                              <button type="button" className="btn_del" onClick={(e) => delFav(e, v)}>삭제</button>
                              <a href="#" className="inner_wrap" onClick={(e) => selFav(e, v)}>
                                <div className="tit_wrap">
                                  <strong>{v.aptName}</strong>
                                  <span>{v.jibunAddr}</span>
                                </div>
                                <p className="price">{amtStr}</p>
                                <ul className="info">
                                  <li>{formatYmd(v.trxDate, "comma1")}거래</li>
                                  <li>{v.useAprDay}년</li>
                                  <li>{numberWithCommas(v.hhldCnt)}세대</li>
                                  <li>{
                                    (v.loanRateCase === '1' || v.loanRateCase === '2') ?
                                      '/ 투기과열'
                                      :
                                      (v.loanRateCase === '3' || v.loanRateCase === '4') ?
                                        '/ 조정지역'
                                        :
                                        null
                                  }</li>
                                </ul>
                              </a>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FavList;