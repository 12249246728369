import React, { useEffect, useState } from 'react'
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import axios from 'axios';
import Moment from 'moment';
import $ from 'jquery';

import { uiFixValue, uiFilterTabSwipe, popupOpen, popupClose, uiIpFocus, uiInputDel, uiAccoScrollFix, uiSelFocus, uiMainSwitch, uiChartTap, uiToggle, uiTopGo, joinInfoEvent } from 'routes/common/Design.js'
import { callAppInterface } from 'routes/common/AppInterface';

import queryString from 'query-string';

//// 컴포넌트 사용처 : 해지 (단축URL접근시)
const ExpireType2 = (props) => {

  const [expireSuccess, setExpireSucess] = useState(false);
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    window.goback = close;
  }, []);

  const close = () => {
    window.close();
  }

  const submitExpireType2 = async (e) => {

    const { search } = props.location;	// 문자열 형식으로 결과값이 반환된다.
    const queryObj = queryString.parse(search);	// 문자열의 쿼리스트링을 Object로 변환

    let paramForm = new FormData();
    paramForm.append('expOneUrl', queryObj.oneUrl);

    if (progress) return;

    setProgress(true);

    await axios
      .post('apt/expire/submitType2', paramForm)
      .then(res => {
        if (res.data.code === "E0000") {
          setExpireSucess(true);
          alert("해지가 완료되었습니다.");
          //window.location.reload();
          localStorage.removeItem('jwtToken');
          localStorage.removeItem('ref');
          localStorage.removeItem('isAutoLogin');
        }
        else {
          alert(res.data.message);
        }
      });

    setProgress(false);
  }

  return (
    <>
      <div id="mw-error" className="ly_apt_error full">
        <div className="wrap">
          <div className="content">
            <header className="header">
              <strong className="tit tac">아파트청약케어</strong>
            </header>
            <div className="ly_content">
              {
                expireSuccess == false &&
                <div className="ivr_wrap">
                  <strong className="tit">서비스 해지</strong>
                  <p className="txt">서비스 해지 시 아파트청약케어에서<br />아파트 청약정보를 확인하실 수 없습니다.</p>
                </div>
              }
              {
                expireSuccess == true &&
                <div class="ivr_wrap">
                  <strong class="tit">서비스 해지 완료</strong>
                  <p class="txt">서비스 해지가 완료되었습니다.<br />그동안 이용해주셔서 감사합니다.</p>
                </div>
              }
            </div>
            <div className="fix btm">
              <ul className="customer_info">
                <li>
                  <span className="th">고객센터</span>
                  <span className="td"><code>1599-2985</code></span>
                </li>
                <li>
                  <span className="th">운영시간</span>
                  <span className="td"><code>09:00 ~ 18:00</code> (평일)</span>
                </li>
              </ul>
              <div className="btn_area_half btm">
                {
                  expireSuccess == false &&
                  <>
                    <button type="button" className="cancel txt_s" onClick={() => close()}>취소</button>
                    <button type="button" className="skip txt_s" onClick={() => submitExpireType2()}>서비스 해지하기</button>
                  </>
                }
                {
                  expireSuccess == true &&
                  <button type="button" class="skip" onClick={() => close()}>확인</button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ExpireType2;