import React, { Component, Fragment } from 'react';


class RMateColumnType extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount = () => {
    setTimeout(() => { this.renderCharts(this.props.graphList) }, 10)
  }


  componentDidUpdate = () => {

  }

  renderCharts = (graphList) => {

    const parentDivId = this.props.parentDivId;
    const rMateChartH5 = window.rMateChartH5;

    // -----------------------차트 설정 시작-----------------------

    // rMateChart 를 생성합니다.
    // 파라메터 (순서대로)
    //  1. 차트의 id ( 임의로 지정하십시오. )
    //  2. 차트가 위치할 div 의 id (즉, 차트의 부모 div 의 id 입니다.)
    //  3. 차트 생성 시 필요한 환경 변수들의 묶음인 chartVars
    //  4. 차트의 가로 사이즈 (생략 가능, 생략 시 100%)
    //  5. 차트의 세로 사이즈 (생략 가능, 생략 시 100%)
    rMateChartH5.create(("RMateColumnType" + parentDivId), parentDivId, "", "100%", "300px");





    // rMateChartH5.calls 함수를 이용하여 차트의 준비가 끝나면 실행할 함수를 등록합니다.
    //
    // argument 1 - rMateChartH5.create시 설정한 차트 객체 아이디 값
    // argument 2 - 차트준비가 완료되면 실행할 함수 명(key)과 설정될 전달인자 값(value)
    // 
    // 아래 내용은 
    // 1. 차트 준비가 완료되면 첫 전달인자 값을 가진 차트 객체에 접근하여
    // 2. 두 번째 전달인자 값의 key 명으로 정의된 함수에 value값을 전달인자로 설정하여 실행합니다.
    rMateChartH5.calls(("RMateColumnType" + parentDivId), {
      "setLayout": this.getChartBody('trxDate', 'amt', '실거래가', 'rgb(94, 206, 197)'),
      "setData": this.getChartData(graphList)
    });

    // -----------------------차트 설정 끝 -----------------------
  }

  getChartBody = (cateFieldStr, yFieldStr, disFieldStr, yFieldColor) => {

    // 스트링 형식으로 레이아웃 정의.
    var layoutStr =
      `<rMateChart backgroundColor="#FFFFFF" borderStyle="none">
        <Options>
          <Caption text=" "/>
        </Options>
        <NumberFormatter id="numFmt" useThousandsSeparator="true"/>
        <Column2DChart showDataTips="true" dataTipJsFunction="commonChartJsDataTip" columnWidthRatio="0.4">
          <horizontalAxis>
            <CategoryAxis id="hAxis" categoryField="${cateFieldStr}">
              <Stroke>
                <Stroke color="#f9bd03" weight="2"/>
              </Stroke>
            </CategoryAxis>
          </horizontalAxis>
          <verticalAxis>
            <LinearAxis formatter="{numFmt}"/>
          </verticalAxis>
          <series>
            <Column2DSeries labelPosition="outside" yField="${yFieldStr}" displayName="${disFieldStr}" showValueLabels="[]" padding="0.3" 
              fill="${yFieldColor}" 
              itemRollOverColor="${yFieldColor}" 
              itemSelectionColor="${yFieldColor}" />
          </series>
          <horizontalAxisRenderers>
                <Axis2DRenderer placement="bottom" axis="{hAxis}" showLine="true" tickLength="2" />
              </horizontalAxisRenderers>
        </Column2DChart>
        <Box horizontalAlign="center" width="100%">
          <SubLegend direction="horizontal" borderStyle="none">
            <LegendItem fill="${yFieldColor}" label="${disFieldStr}" itemRenderer="CircleItemRenderer"/>
          </SubLegend>
        </Box>
      </rMateChart>`;

    return layoutStr;
  }

  getChartData = (graphList) => {
    // 차트 데이터
    var chartData =
      graphList;
    return chartData;
  }


  dataTipJsFunc = (seriesId, seriesName, index, xName, yName, data, values) => {

    // if (seriesName == "ROI") {
    //   return data['Month'] + "ROI: " + " < b > " + data['roi'] + " %</b > ";
    // }
    // else {
    //   return data['Month'] + "Sales: " + " < b > $" + data['sales'] + "M</b > ";
    // }
  }

  render() {


    return (
      <>

      </>

    );
  }
}
export default RMateColumnType;