import React, { useState, useEffect } from 'react';

import SubFooter from 'routes/SubFooter';

import $ from 'jquery';

import AnaylyzeInfo from './calc/AnaylyzeInfo';
import ExptScore from './calc/ExptScore';
import ExptRate from './calc/ExptRate';
import AnaylyzePoint from './calc/AnaylyzePoint';
import BuildRank from './calc/BuildRank';
import RecentResult from './calc/RecentResult';
import LocMap from './calc/LocMap';
import PrevTrx from './calc/PrevTrx';
import PopInOut from './calc/PopInOut';
import PrevPoint from './calc/PrevPoint';
import PrevGeCnt from './calc/PrevGeCnt';
import { uiToggle, uiTopGo } from 'routes/common/Design.js'

import { popupSecInfo01, popupSecInfo02, popupSecInfo03, popupSecInfo04 } from 'routes/predict/PopupNotice';
import MarginPrice from "./calc/MarginPrice";
import AptTrxMonthly from "./calc/AptTrxMonthly";

//// 컴포넌트 사용처 : 청약 당첨 예상 상세
const ApplyPredictDtl = ({ scoreCheckFlag, score, calcResult, applyDtl, onApplyMenuType, onApplyPopupType, onRefreshState }) => {
  const [toggleMap, setToggleMap] = useState(false);
  const [onRenderGraph, setOnRenderGraph] = useState(false);

  useEffect(() => {
    window.chartCall();

    uiToggle.init();
    uiTopGo.init();

    $(window).scrollTop(0);

    window.goback = openApplyPredict;
  }, []);

  const showApplyMap = () => {
    setToggleMap(true);
  }

  const showArounds = () => {
    setOnRenderGraph(true);
  }

  //--'open' : 현 화면이 다음 화면으로 변경되는 action
  const openApplyPredict = () => {
    onRefreshState(true);

    setTimeout(() => {
      onRefreshState(false);
    }, 100);
  }

  const calc = { ...calcResult, applyDtl, score, scoreCheckFlag };

  // const { applyInfo, bjdInfo, applyCalendar, exptScore, constructList, buildRank, maxScoreList, minScoreList, recentList, locCnt, locList, applyScore, prevTrx, popInOut, prevPoint, prevGeCnt, aptRecentAreaPyTrxData, aptOneYearAgoAreaPyTrxData, applyRecentAreaPyTrxData } = calcResult;
  // const calc = { applyInfo, bjdInfo, applyDtl, applyCalendar, exptScore, score, constructList, buildRank, maxScoreList, minScoreList, recentList, locCnt, locList, applyScore, prevTrx, popInOut, prevPoint, prevGeCnt, aptRecentAreaPyTrxData, aptOneYearAgoAreaPyTrxData, applyRecentAreaPyTrxData };

  return (
      <>
        <div className="ly_detail analyze_wrap">
          <AnaylyzeInfo calcResult={ calc } />
          <section className="sec">
            <h2 className="tit"><code>1.</code>당첨 가능성 및 예상 경쟁률<button type="button" className="btn_tip GTM-PER-TIP-1" onClick={ () => popupSecInfo01() }>당첨 가능성 및 예상 경쟁률이란?</button></h2>
            <ExptScore onApplyMenuType={ onApplyMenuType } onApplyPopupType={ onApplyPopupType } calcResult={ calc } />
            <ExptRate calcResult={ calc } />
          </section>

          <section className="sec">
            <h2 className="tit"><code>2.</code>아파트 가격 적합성<button type="button" className="btn_tip GTM-PER-TIP-4" onClick={ () => popupSecInfo04() }>아파트 가격 적합성이란?</button></h2>
            <div className="apt_info_wrap" style={{ 'display': 'block', 'margin': '0 2rem' }}>
              <MarginPrice calcResult={ calc } />
            </div>
          </section>

          <section className="sec">
            <h2 className="tit"><code>3.</code>나의 가점 분석<button type="button" className="btn_tip GTM-PER-TIP-2" onClick={ () => popupSecInfo02() }>나의 가점 분석이란?</button></h2>
            <AnaylyzePoint onApplyMenuType={ onApplyMenuType } calcResult={ calc } />
          </section>

          <section className="sec sec03">
            <h2 class="tit"><code>4.</code>아파트 건설사 순위<button type="button" class="btn_tip GTM-PER-TIP-3" onClick={ () => popupSecInfo03() }>아파트 건설사 순위란?</button></h2>
            <BuildRank calcResult={ calc } />
          </section>

          <section className="sec ani toggle_wrap" id="ani6">
            <hr />
            <button type="button" className="btn_toggle GTM-PER-4">최근 청약 결과</button>
            <RecentResult calcResult={ calc } />
          </section>

          <section className="sec ani toggle_wrap" id="ani7">
            <hr />
            <button type="button" className="btn_toggle GTM-PER-5" onClick={ showApplyMap }>청약 아파트 입지 분석 </button>
            <LocMap toggleMap={ toggleMap } calcResult={ calc } />
          </section>

          <section className="sec toggle_wrap">
            <hr />
            <button type="button" className="btn_toggle GTM-PER-6" onClick={ showArounds }>주변시세 / 과거 청약결과 / 전입·전출 인구</button>
            <div className="view_toggle apt_info_wrap">
              <PrevTrx onRenderGraph={ onRenderGraph } calcResult={ calc } />
              <AptTrxMonthly onRenderGraph={ onRenderGraph } calcResult={ calc } />
              <PopInOut onRenderGraph={ onRenderGraph } calcResult={ calc } />
              <PrevPoint onRenderGraph={ onRenderGraph } calcResult={ calc } />
              <PrevGeCnt onRenderGraph={ onRenderGraph } calcResult={ calc } />
            </div>
          </section>
        </div>
        <SubFooter></SubFooter>
        <button type="button" className="btn_top"><span className="blind">상단으로 이동</span></button>
      </>
  );

}
export default ApplyPredictDtl;