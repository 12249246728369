import React from 'react';

import {dateFormatMsg, numberWithCounts, numberWithCountsDesc} from 'routes/common/StringUtil';

//// 컴포넌트 사용처 : 청약 당첨 예상 상세
const MarginPrice = ({ calcResult }) => {

  const { applyInfo, bjdInfo, findMarginPriceInfo } = calcResult;

  const findMarginPrice = findMarginPriceInfo[0] || [];

  let aptStatList = findMarginPrice.aptStatList || [];

  const marginPriceList = findMarginPrice.marginPriceList || [];

  const marginPricePy = (marginPriceList[0]) ? marginPriceList[0].marginPricePy : null;

  // const marginPricePy = (marginPriceList[0]) ? Math.round(Math.random() * (10000 + 10000) - 10000) : null;

  const key = (marginPricePy) ? ((marginPricePy >= 0) ? 'POSITIVE' : 'NEGATIVE') : 'UNKNOWN';

  const status = {
    NEGATIVE: { code: 'bad', priceDesc: '손실 금액' },
    POSITIVE: { code: 'good', priceDesc: '손익 금액' },
    UNKNOWN: { code: 'normal', priceDesc: '측정 불가' },
  };

  aptStatList = aptStatList.slice(0, 3);

  return (
      <>
        <div className="con ani" id="ani12">
          <div className={ `ty_graph ${ status[key].code }` }>
            <div className="graph">
              <div className="dount_area">
                <div className="circle_chart full" data-percent="100" data-permax="100">
                  <div className="chart-box">
                    <svg>
                      <defs>
                        <linearGradient id={ `dount_${ status[key].code }` } x1="0" y1="0" x2="1" y2="1">
                          <stop className="color_1" offset="0%" />
                          <stop className="color_2" offset="100%" />
                        </linearGradient>
                      </defs>
                      <circle stroke={ `url(#dount_${ status[key].code })` } r="50%" cx="50%" cy="50%" className="circle_progress" stroke-width="18" stroke-linecap="round"></circle>
                    </svg>
                    <div className="per-point"><span className="txt01">{ status[key].priceDesc }</span><p>{ (key === 'UNKNOWN') ? '' : `${ numberWithCountsDesc(marginPricePy) }` }</p></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="caption emoji">
              {
                (() => {
                  switch (key) {
                    case 'NEGATIVE':  return <p>{ applyInfo.aptName }는 <em>주위 실거래가보다 가격이 다소 높게 측정되어 있어요.</em><br />신청하시기 전 주위 아파트 시세를 확인해보고 신청하시는 것을 추천드려요.</p>
                    case 'POSITIVE':  return <p>{ applyInfo.aptName }는 <em>당첨시 손익금을 확보할 수 있어요.</em><br />손익금을 확보할 수 있는 좋은 기회로 신청하시는 것을 추천드려요.</p>
                    default :         return <p>{ applyInfo.aptName }가 위치하는 { bjdInfo.bjdName } 지역은 <em>가격 적합성을 비교할 수 있는 아파트가 없어요.</em><br />주변 지역의 아파트 가격을 잘 확인해보고 신청하시는 것을 추천드려요.</p>
                  }
                })()
              }
            </div>
          </div>
          {
              aptStatList.length ?
              <ul className="apt_list">
                {
                  aptStatList.map((data, index) => {
                    return (
                        <li>
                          <div className="tit_wrap">
                            { !index && <span>동일 지역 내 비교 아파트</span> }
                            <strong>{ (data) ? data.aptName : '-' }</strong>
                          </div>
                          <ul className="sub_list">
                            <li>
                              <strong className="sub_th">전용 면적</strong>
                              <span className="sub_td">{ (data) ? `${ data.areaPy }평` : '-' }</span>
                            </li>
                            <li>
                              <strong className="sub_th">사용 승인일</strong>
                              <span className="sub_td">{ (data) ? dateFormatMsg(data.useAprDay) : '-' }</span>
                            </li>
                            <li>
                              <strong className="sub_th">최근 거래일</strong>
                              <span className="sub_td">{ (data) ? dateFormatMsg(data.trxDate) : '-' }</span>
                            </li>
                            <li>
                              <strong className="sub_th">최근 거래가</strong>
                              <span className="sub_td">{ (data) ? `${ numberWithCountsDesc(data.amt) }` : '-' }</span>
                            </li>
                            <li>
                              <strong className="sub_th">평단가</strong>
                              <span className="sub_td">{ (data) ? `${ numberWithCountsDesc(data.aptPricePy) }` : '-' }</span>
                            </li>
                          </ul>
                        </li>
                    )
                  })
                }
              </ul>
               :
              <></>
          }
          <p className="noti">* 아파트청약케어는 부동산 흐름을 예측할 수 있는 참고자료를 제공하는 것이며, 최종 투자의 모든 책임은 투자자에게 있습니다.</p>
        </div>
      </>
  );

}

export default MarginPrice;
