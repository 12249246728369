import React from "react";

export const CouponItemDefault = ({coupon}) => {

    return (
            <li className="coupon-item var-01">
                <div className="logo-box">
                    {
                        coupon.cpnGrpCd === "GS"
                                ? <img src={coupon.image01} alt="" className="img-logo" style={{height: '14px'}}/> // GS 쿠폰의 경우 높이 조절 속성이 들어감
                                : <img src={coupon.image01} alt="" className="img-logo"/>
                    }
                </div>
                <span className="text-04">{coupon.price}</span>
                <span className="label">COUPON</span>
            </li>
    );
};