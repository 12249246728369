import React from "react";

/**
 * @desc 유의사항 컴포넌트
 *
 * - 미가입자
 * - 쿠폰 미선택자
 * - 쿠폰 재선택자
 * -> 매월 (오늘날짜-1일) 까지 발급 가능, 매월 (오늘날짜)에 재선택 가능
 *
 * - 쿠폰 선택자
 * -> 매월 (쿠폰선택일자-1일) 까지 발급 가능, 매월 (쿠폰선택일자)에 재선택 가능
 * (재선택 일자가 지날경우 미가입자 기준을 따른다)
 */
export const CouponNaverNotice = () => {

    return (
            <div className="coupon-notice">
                <strong className="coupon-notice-title">※ 유의사항</strong>
                <ul className="coupon-notice-list">
                    <li className={"coupon-notice-item"}>교환 유효기간은 발행일로부터 30일입니다.</li>
                    <li className={"coupon-notice-item"}>사용방법(PC&모바일) : 네이버페이 홈을 방문 후 쿠폰을 등록하시면 네이버페이 포인트로 전환됩니다. 쿠폰 등록 경로는 아래와 같습니다.</li>
                    <li className={"coupon-notice-item"}>1) 네이버페이 홈 (pay.naver.com) 접속</li>
                    <li className={"coupon-notice-item"}>2) 네이버ID와 패스워드로 로그인(네이버페이 이용약관 동의 필수)</li>
                    <li className={"coupon-notice-item"}>3) PC : 홈 > 혜택, 쿠폰 > 쿠폰 > 네이버페이 포인트 쿠폰 등록 > 쿠폰번호 입력</li>
                    <li className={"coupon-notice-item"}>　모바일 : 홈 > 메뉴 > 이벤트 > 쿠폰 > 네이버페이 포인트 쿠폰 등록 > 쿠폰번호 입력</li>
                    <li className={"coupon-notice-item"}>※제휴사 상품 운영정책에 따라 포인트로 충전 후 현금으로 전환하거나 환불이 불가합니다. 자세한 내용은 네이버페이 이용약관을 참고하여 주시기 바랍니다.</li>
                </ul>
            </div>
    );
};