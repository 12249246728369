import React from 'react'
import {useStateWithCallbackLazy} from 'use-state-with-callback';
import $ from 'jquery';

import {addRecentItem, clearRecentItem, getRecentItem, searchApplyPay, setRecentList} from 'routes/common/Search';
import {popupClose} from 'routes/common/Design.js'
import {findIndex} from 'routes/common/ArrayUtil';
import _ from 'lodash';

import RecentResult from 'routes/common/RecentResult';
import SearchResult from 'routes/common/SearchResult';
import Moment from 'moment';

import {getLcParamItem, getLcUrlItem, setLcParamItem, setLcUrlItem} from 'routes/common/ParamStorage';
import {useHistory, useLocation} from "react-router-dom";

//// 컴포넌트 사용처 : 청약 비용 계산기
const ApplyPaySrch = (props) => {
  const [refreshState, setRefreshState] = useStateWithCallbackLazy(false);
  const [keyword, setKeyword] = useStateWithCallbackLazy('');

  const [recentResult, setRecentResult] = useStateWithCallbackLazy(getRecentItem('applyMap'));
  const [searchResult, setSearchResult] = useStateWithCallbackLazy([]);

  const [searchIndex, setSearchIndex] = useStateWithCallbackLazy(-1);

  const [recentFocus, setRecentFocus] = useStateWithCallbackLazy(false);
  const [searchVisible, setSearchVisible] = useStateWithCallbackLazy(false);

  //const [applyPayList, setApplyPayList] = useState([]);

  const lcUrlMap = getLcUrlItem();
  const lcParamMap = getLcParamItem();

  // url 구분 작업
  const history = useHistory();
  const location = useLocation();

  const handleClearRecent = () => {
    clearRecentItem('applyMap');

    setRecentResult([]);
  }

  const handleRemoveRecent = (searchId) => {
    let recentList = getRecentItem('applyMap');

    if (recentList.some(f => f.searchId === searchId)) {
      recentList.splice(findIndex(recentList, f => f.searchId === searchId), 1);
    }

    setRecentList('applyMap', recentList);

    setRecentResult(recentList);
  }

  const handleSelectSearch = (search) => {
    $('#srch_input02').click();

    search['regTime'] = Moment(new Date()).format('YYYY. MM. DD');

    addRecentItem('applyMap', search);

    searchApplyPay(search.searchName, (data) => {
      let searchArr = [];

      if ((data.response).length > 0) {
        props.onApplyPayList(data.response);

        (data.response).forEach(v => searchArr.push({ searchId: v.searchId, searchName: v.searchName }));
      }
      search.searchName && setSearchResult(searchArr);

      setKeyword(search.searchName);

      props.onKeyword(search.searchName);

      setSearchVisible(false);

      $('.btn_prev').click();

      if (search.searchId) {
        openApplyPayDtl(search.searchId);
      }
    })
  }

  const handleChangeKeyword = _.debounce((keyword) => {
    let recentData = getRecentItem('applyMap');
    if (keyword) {
      searchApplyPay(keyword, (data) => {
        let searchArr = [];
        if ((data.response).length > 0) {
          props.onApplyPayList(data.response);
          (data.response).forEach(v => searchArr.push({ searchId: v.searchId, searchName: v.searchName }));
        }
        keyword &&  //this.setState({ searchResult: data, recentResult: [], searchIndex: -1, recentFocus: false });
          setSearchResult(searchArr);
        setRecentResult(recentData);
        setSearchIndex(-1);
        setRecentFocus(false);
      })
    } else {
      //this.setState({ searchResult: [], recentResult: recentData, searchIndex: -1, recentFocus: true });
      setSearchResult([]);
      setRecentResult(recentData);
      setSearchIndex(-1);
      setRecentFocus(true);
    }
  }, 300);

  const debounceChangeKeyword = (e) => {

    const { target: { value } } = e;
    //this.setState({ keyword: value, searchVisible: true });
    setKeyword(value);
    props.onKeyword(value);
    setSearchVisible(true);
    setRecentFocus(false);
    handleChangeKeyword(value);
  }

  const handleKeydownKeyword = (e) => {
    let keyCode = e.keyCode;
    if (!searchResult.length > 0) {
      return;
    }
    if (keyCode === 38) {     // uparrowKey
      //this.setState({ searchIndex: this.state.searchIndex <= 0 ? 0 : this.state.searchIndex - 1 });
      setSearchIndex(searchIndex <= 0 ? 0 : searchIndex - 1);
    } else if (keyCode === 40) {     // downarrowKey
      let maxIndex = searchResult.length - 1;
      //this.setState({ searchIndex: this.state.searchIndex >= maxIndex ? maxIndex : this.state.searchIndex + 1 });
      setSearchIndex(searchIndex >= maxIndex ? maxIndex : searchIndex + 1);
    } else if (keyCode === 13) {      // enterKey
      if (searchIndex === -1) {
        //this.setState({ searchResult: [], searchIndex: -1, recentFocus: false, searchVisible: false, keyword: this.state.searchResult[0].searchName });
        //setSearchResult([]);
        setSearchIndex(-1);
        setRecentFocus(false);
        setSearchVisible(false);
        setKeyword(searchResult[0].searchName);
        props.onKeyword(searchResult[0].searchName);

        searchResult[0].regTime = Moment(new Date()).format('YYYY. MM. DD');
        addRecentItem('applyMap', searchResult[0]);
        //$('.apt_wrap').removeClass('dim');
        //this.props.onGotoFavApplyDtl(this.state.searchResult[0].searchId);
      } else {
        let search = searchResult[searchIndex];
        //this.setState({ searchResult: [], searchIndex: -1, recentFocus: false, searchVisible: false, keyword: search.searchName });
        //setSearchResult([]);
        setSearchIndex(-1);
        setRecentFocus(false);
        setSearchVisible(false);
        setKeyword(search.searchName);
        props.onKeyword(search.searchName);

        search.regTime = Moment(new Date()).format('YYYY. MM. DD');
        addRecentItem('applyMap', search);
        //$('.apt_wrap').removeClass('dim');
        //this.props.onGotoFavApplyDtl(search.searchId);
      }
    }
  }

  const openApplyPayDtl = (applyId) => {
    setLcUrlItem('applyPayList', 'applyPayDtl');
    setLcParamItem({ 'applyId': applyId });

    props.onApplyPopupType('P_C');

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?CAL-1200#`
    });
  }

  return (
    <>
      <div id="mw-srch" className="ly_pop ly_apt_srch full">
        <div className="wrap">
          <div className="content">
            <header className="header">
              <button type="button" onClick={() => { popupClose('srch', 'mwf'); setSearchResult([]); }} className="btn_prev" title="뒤로가기"><i></i></button>
              <div className="form">
                <div className="ip_box ip_del">
                  <label for="srch_input02" className="lbl" style={{ "display": "inline" }}>{keyword ? '' : '어느 지역 아파트가 궁금하세요?'}</label>
                  <input type="text" autocomplete="off" id="srch_input02" className="ip"
                    onChange={debounceChangeKeyword}
                    onFocus={debounceChangeKeyword}
                    onKeyDown={handleKeydownKeyword}
                    value={keyword}
                  />
                  <button type="button" onClick="" className="del" style={{ "display": "none" }}><i>삭제</i></button>
                </div>
              </div>
            </header>
            <div className="srch_list_wrap active">
              <div className="inner_wrap">
                {
                  recentFocus ?
                    <RecentResult
                      recentResult={recentResult}
                      onClearRecent={handleClearRecent}
                      onRemoveRecent={handleRemoveRecent}
                      onSelectRecent={(search) => handleSelectSearch(search)}
                    ></RecentResult>
                    : <SearchResult
                      searchResult={searchResult}
                      searchIndex={searchIndex}
                      searchKeyword={keyword}
                      searchVisible={searchVisible}
                      onKeyDown={handleKeydownKeyword}
                      onSelectSearch={(search) => handleSelectSearch(search)}
                    ></SearchResult>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplyPaySrch;