import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'; // url 구분 작업
import axios from 'axios';
import { uiFixValue, uiSelFocus, uiInputDel, popupOpen, popupClose, uiIpFocus, chartCircle, uiInfoPopSwiper01 } from 'routes/common/Design.js'
import ApplyPredictDtl from './ApplyPredictDtl';
import $ from 'jquery';
import { dateDiffMsg, numberWithCounts, numberWithCommas, numberWithCommas2, dateFormatMsg, dateFormatMsg2, numberReplaceWithStag, textReplaceWithStag, numberReplaceWithCodetag } from 'routes/common/StringUtil';
import { setLcUrlItem, getLcUrlItem, setLcParamItem, getLcParamItem } from 'routes/common/ParamStorage';

//// 컴포넌트 사용처 : 청약 당첨 예상
const ApplyPredict = (props) => {

  const [scoreCheckFlag, setScoreCheckFlag] = useState(false); // 청약가점점수 null값 여부 체크 flag
  const [score, setScore] = useState({});
  const [applyWishList, setApplyWishList] = useState([]);
  const [applyDtlList, setApplyDtlList] = useState([]);
  const [applyInfo, setApplyInfo] = useState({});
  const [applyDtl, setApplyDtl] = useState({});
  const [calcResult, setCalcResult] = useState(null);

  const lcUrlMap = getLcUrlItem();
  const lcParamMap = getLcParamItem();

  // url 구분 작업
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {

    uiIpFocus.init();
    uiInputDel.init();
    uiSelFocus.init();

    $(window).scrollTop(0);

    props.onIsCalcResult(false);

    const promise = axios
      .get('/apt/apply/predict/applyWishList')
      .then(res => {
        setApplyWishList(res.data.response);
      });

    // promise.then(() => {
    //   bindSelect01(applyWishList);
    // });

    if (localStorage.getItem('infoPop01Open') != 'false') {
      infoPopOpen();
    }

    window.goback = backToApplyList;
  }, []);

  useEffect(() => {
    uiSelFocus.init();
    bindSelect01();

    // 나의 청약가점 가져오기 (DB)
    axios
      .get('apt/apply/applyCalc/getApplyMyScore', { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } })
      .then(res => {
        if(res.data.code === "E0000") {
          if (res.data.response != null) {
            $(".ly_content .ip_del").find("label").hide();
            setScore({ houseScore: res.data.response.houseScore, familyScore: res.data.response.familyScore, accountScore: res.data.response.accountScore, total: res.data.response.totalScore });
            $('#ip01').attr('disabled', true);
          } else {
            // 저장된 내 가점점수가 없을 경우
            setScoreCheckFlag(true);
          }
          $('.del').remove();

        } else {
          alert(res.data.message);
          return false;
        } 
      })

    if (lcParamMap && lcParamMap.applyId) {
      setTimeout(() => {
        //$(".ly_content .select_apt").find("select").val(lcParamMap.applyId).change();
        $('#mw-select01 span.txt').each(function () {
          if ($(this).attr('data-value') == lcParamMap.applyId) {
            $('#' + $(this).parents().eq(1).find('input').attr('id')).click();
            //console.log($(this).attr('data-value'));
          }
        })
        onChangeApplyId(lcParamMap.applyId);
      }, 400);
    }

    // else {
    //   if (lcParamMap && lcParamMap.score && lcParamMap.applyInfo && lcParamMap.applyDtl) {
    //     setScore(lcParamMap.score);
    //     setApplyInfo(lcParamMap.applyInfo);
    //     setApplyDtl(lcParamMap.applyDtl);
    //     setTimeout(() => {
    //       submitApplyPredict();
    //     }, 400);
    //   }
    // }
    // else if (lcParamMap.applyId != '') {
    //   setTimeout(() => {
    //     $(".ly_content .select_apt").find("select").val(lcParamMap.applyId).change();
    //     onChangeApplyId(lcParamMap.applyId);
    //   }, 400);
    // }

  }, [applyWishList]);

  useEffect(() => {
    bindSelect02(applyDtlList);
    $(".select_type").find("select").val(-1);
    $(".select_type").find("label").removeClass('hide').addClass('show');
  }, [applyDtlList]);

  const validApplyScore = (e) => {
    const max = e.target.getAttribute('maxLength');
    let value = e.target.value;

    if (value.length > max) value = value.slice(0, max);
    e.target.value = value.replace(/[^0-9]/g, '').replace(/(^0+)/, "");

    if (value > 84) {
      e.target.value = 84;
    }
    setScore({ total: e.target.value });
  }

  const onChangeApplyId = (value) => {
    const applyId = value;
    const apply = applyWishList.find(v => v.applyId == applyId);
    setApplyInfo(apply);

    const promise2 = axios
      .get(`/apt/apply/predict/applyDtlList?applyId=${applyId}`)
      .then(res => {
        setApplyDtlList(res.data.response);
        setApplyDtl(null);
      });
  }

  const onChangeApplyDtlId = (value) => {
    const applyDtl = applyDtlList.find(v => v.applyDtlId == value);
    setApplyDtl(applyDtl);
  }

  // [2021-08-23] 안내 팝업 오픈
  function infoPopOpen() {
    popupOpen('saleinfo', 'mwf', 0);
    uiInfoPopSwiper01.init();
  }

  // [2021-08-23] 안내 팝업 닫기
  function infoPopClose() {
    popupClose('saleinfo', 'mwf');
    //uiInfoPopSwiper01.infoSwiper01.destroy(false);
  }

  function infoPopCloseNeverOpen() {
    localStorage.setItem('infoPop01Open', false);
    popupClose('saleinfo', 'mwf');
  }

  const bindSelect01 = () => {
    $(".btn_sel_wrap").each(function (idx) {
      $(this).data("key", idx + 1);
      // btm select popup 호출
      $(this).find(".btn_sel").on("click", function () {
        var key = $(this).parents(".btn_sel_wrap").data("key");
        popupOpen('select0' + key, 'mwf', 0);
      });
    });

    $(".ly_select_wrap").each(function (idx) {
      $(this).data("ctrlKey", idx + 1);
      // btm select 값 선택// btm select 값 선택
      $(this).find(".pop_sel_wrap .list input").on("change", function () {
        var ctrlKey = $(this).parents(".ly_select_wrap").data("ctrlKey");
        var selTxt = $(this).parents(".item").find(".txt").text();

        $(".btn_sel_wrap").eq(ctrlKey - 1).removeClass("focus").find(".lbl").addClass("hide");
        $(".btn_sel_wrap").eq(ctrlKey - 1).find(".sel_txt").text(selTxt);

        //select value 찾도록 추가
        if (ctrlKey === 1) {
          var selVal = $(this).parents(".item").find(".txt").attr('data-value');
          onChangeApplyId(selVal);
        }

        popupClose('select0' + ctrlKey, 'mwf', 0);
      });
    });
  }

  const bindSelect02 = () => {
    $(".btn_sel_wrap").each(function (idx) {
      $(this).data("key", idx + 1);
      // btm select popup 호출
      $(this).find(".btn_sel").on("click", function () {
        var key = $(this).parents(".btn_sel_wrap").data("key");
        popupOpen('select0' + key, 'mwf', 0);
      });
    });

    $(".ly_select_wrap").each(function (idx) {
      $(this).data("ctrlKey", idx + 1);
      // btm select 값 선택// btm select 값 선택
      $(this).find(".pop_sel_wrap .list input").on("change", function () {
        var ctrlKey = $(this).parents(".ly_select_wrap").data("ctrlKey");
        var selTxt = $(this).parents(".item").find(".txt").text();

        $(".btn_sel_wrap").eq(ctrlKey - 1).removeClass("focus").find(".lbl").addClass("hide");
        $(".btn_sel_wrap").eq(ctrlKey - 1).find(".sel_txt").text(selTxt);

        //select value 찾도록 추가
        if (ctrlKey === 2) {
          var selVal = $(this).parents(".item").find(".txt").attr('data-value');
          onChangeApplyDtlId(selVal);
        }

        popupClose('select0' + ctrlKey, 'mwf', 0);
      });
    });
  }



  //--'open' : 현 화면이 다음 화면으로 변경되는 action
  const openApplyCalc = () => {
    setLcUrlItem('applyPredict', 'applyCalc');
    setTimeout(() => {
      $('.bg_body_full').remove();
      uiFixValue.init();
    }, 100);
    props.onApplyMenuType('C');

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `PAL-1000#`
    })
  }

  //submit 도 open의 역할을 함 (입력화면 > 상세)  
  const submitApplyPredict = (e) => {

    if (!score || !score.total) {
      alert("청약 점수를 입력하세요.");
      $("#ip01").focus();
      e.stopPropagation();
      return false;
    }
    if (!applyInfo || !applyInfo.applyId) {
      alert('청약 희망 아파트를 선택하세요');
      return false;
    }
    if (!applyDtl || !applyDtl.applyDtlId) {
      alert('희망 타입을 선택하세요');
      return false;
    }

    axios
      .get(`/apt/apply/predict/calc?applyId=${applyInfo.applyId}&applyDtlId=${applyDtl.applyDtlId}`)
      .then(res => {
        setCalcResult(res.data.response);
        setLcUrlItem('applyPredict', 'applyPredictDtl');
        //setLcParamItem({ 'score': score, 'applyInfo': applyInfo, 'applyDtl': applyDtl });
        props.onIsCalcResult(true);
      });

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `PER-${applyInfo.applyId}#`
    })
  }


  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  const backToAptMain = () => {
    setLcUrlItem('applyPredict', 'aptMain');
    setLcParamItem({});
    $(".srch_list_wrap").removeClass("active");

    $('.map_wrap').show();
    props.onApplyMenuType('A');
    props.onApplyListDisplay(false);
  }

  const backToApplyList = () => {
    setLcUrlItem('applyPredict', 'applyList');
    setTimeout(() => {
      $('.bg_body_full').remove();
      uiFixValue.init();
    }, 100);
    props.onApplyMenuType('A');
    props.onApplyPopupType('');
  }


  return (
    <>
      {
        !calcResult &&
        <>
          <div className="ly_content">
            <div className="view">
              <div className="item">
                <strong className="tit">나의 청약 가점</strong>
                <div className="form_wrap">
                  <div className="form">
                    <div className="ip_box ip_del">
                      <label for="ip01" className="lbl">청약 가점을 입력해주세요.</label>
                      <input type="number" id="ip01" className="ip code" autocomplete="off" maxlength="2"
                        onChange={validApplyScore}
                        value={score.total}
                      />
                      <button type="button" onClick="" className="del"><i>삭제</i></button>
                      <em className="txt">점</em>
                    </div>
                  </div>
                </div>
                <button type="button" className="btn_calc" onClick={() => openApplyCalc()}>청약가점계산 바로가기</button>
              </div>
              <div className="item">
                <strong className="tit">청약 희망 아파트</strong>
                <div className="form_wrap">
                  <div className="form">
                    <div className="ip_box btn_sel_wrap">
                      <div className="inner_wrap">
                        <button type="button" className="btn_sel">
                          <span className="lbl">청약 희망 아파트를 선택해주세요.</span>
                          <span className="sel_txt"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <strong className="tit">희망타입</strong>
                <div className="form_wrap">
                  <div className="form">
                    <div className="ip_box btn_sel_wrap">
                      <div className="inner_wrap">
                        <button type="button" className="btn_sel">
                          <span className="lbl">희망타입을 선택해주세요.</span>
                          <span className="sel_txt"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn_area_wide fix btm">
            <button type="button" className="skip" onClick={submitApplyPredict}>청약 당첨 예상</button>
            {score && score.total > 0 && <button type="button" className="btn_txt" onClick={() => { openApplyCalc() }}><u>청약 가점 재 입력</u></button>}
          </div>

          <div id="mw-select01" className="ly_pop popBtm ly_select_wrap">
            <div className="wrap">
              <button type="button" onClick={() => popupClose('select01', 'mwf', 0)} className="close"><i>닫기</i></button>
              <div className="content pop_sel_wrap">
                <ul className="list">
                  {
                    applyWishList.map((v, idx) => {
                      const radioId = `select01_${idx}`
                      return (
                        <li>
                          <span className="item">
                            <input type="radio" id={radioId} name="select01" />
                            <label for={radioId}>
                              <span className="txt" data-value={v.applyId}>{v.aptName}</span>
                              <code>{dateFormatMsg2(v.notiDate)}</code>
                            </label>
                          </span>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
          </div>

          <div id="mw-select02" className="ly_pop popBtm ly_select_wrap">
            <div className="wrap">
              <button type="button" onClick={() => popupClose('select02', 'mwf', 0)} className="close"><i>닫기</i></button>
              <div className="content pop_sel_wrap">
                <ul className="list">
                  {
                    applyDtlList.map((v, idx) => {
                      const radioId = `select02_${idx}`
                      return (
                        <li>
                          <span className="item">
                            <input type="radio" id={radioId} name="select02" />
                            <label for={radioId}>
                              <span className="txt" data-value={v.applyDtlId}>{v.hsType} ({Math.round(v.hsArea * 0.3025)}평형)</span>
                            </label>
                          </span>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
          </div>

          <div id="mw-saleinfo" className="ly_pop popBtm ly_info_swiper ndim">
            <div className="wrap">
              <div className="pop_info_swiper swiper-container">
                <div className="swiper-pagination"></div>
                <div className="swiper-wrapper">
                  <section className="swiper-slide slide01">
                    <h1 className="slide_tit">나의 <strong>청약가점</strong>을 알고 있다면<br /> <strong>직접 입력</strong> 해주세요.</h1>
                    <p className="sub_txt">아파트청약케어에서 가점을 계산하셨다면,<br />청약 가점이 자동으로 입력되요!</p>
                    <div className="form_ani">
                      <div className="box">
                        <p className="show01">청약 가점</p>
                        <p className="show02"><code>50</code></p>
                        <em>점</em>
                      </div>
                    </div>
                    <div className="btn_wrap">
                      <button type="button" className="btn_next">다음<i className="cursor"></i></button>
                    </div>
                    <button type="button" className="btn_txt" onClick={infoPopClose}>다시보지않기</button>
                  </section>
                  <section className="swiper-slide slide02">
                    <h1 className="slide_tit">청약가점을 입력하셨다면,<br /> <strong>희망 아파트</strong>를 선택해주세요.</h1>
                    <p className="sub_txt">청약 예정인 아파트만 청약당첨예상 확인이 가능해요.</p>
                    <div className="select_ani">
                      <div className="form_ani">
                        <div className="box">
                          <p className="show01">희망 아파트</p>
                          <p className="show02">힐스테이트</p>
                        </div>
                      </div>
                      <div className="selet_detail">
                        <div className="inner_wrap">
                          <button type="button" className="item">
                            <span>힐스테이트</span>
                            <code>21. 08. 13</code>
                          </button>
                          <button type="button" className="item">
                            <span>트리마제 양산 2단지</span>
                            <code>21. 08. 06</code>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="btn_wrap">
                      <button type="button" className="btn_next">다음<i className="cursor"></i></button>
                    </div>
                    <button type="button" className="btn_txt" onClick={infoPopClose}>다시보지않기</button>
                  </section>
                  <section className="swiper-slide slide03">
                    <h1 className="slide_tit">아파트를 선택하셨다면,<br /> <strong>희망 타입(평)</strong>을 선택해주세요.</h1>
                    <p className="sub_txt">희망하는 타입에 따라 청약당첨예상 결과가 달라져요!</p>
                    <div className="select_ani">
                      <div className="form_ani">
                        <div className="box">
                          <p className="show01">희망 타입</p>
                          <p className="show02">59.6481A / 25평형</p>
                        </div>
                      </div>
                      <div className="selet_detail">
                        <div className="inner_wrap">
                          <button type="button" className="item">
                            <span>105.3774A / 46평형</span>
                          </button>
                          <button type="button" className="item">
                            <span>59.6481A / 25평형</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="btn_wrap">
                      <button type="button" className="btn_next" onClick={infoPopClose}>당첨예상 계산하러 가기<i className="cursor"></i></button>
                    </div>
                    <button type="button" className="btn_txt" onClick={infoPopCloseNeverOpen}>다시보지않기</button>
                  </section>
                </div>
              </div>
              <button type="button" className="btn_info_reset"><i>새로고침</i></button>
              <button type="button" className="close" onClick={infoPopClose}><i>닫기</i></button>
            </div>
          </div>
        </>
      }
      {
        calcResult &&
        <ApplyPredictDtl
          scoreCheckFlag={scoreCheckFlag}
          score={score}
          onRefreshState={props.onRefreshState}
          onApplyMenuType={props.onApplyMenuType}
          onApplyPopupType={props.onApplyPopupType}
          calcResult={calcResult}
          applyDtl={applyDtl}
        ></ApplyPredictDtl>
      }

    </>
  );
}

export default ApplyPredict;