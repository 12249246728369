import React, { useEffect, useState, useRef } from 'react'
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import axios from 'axios';
import Moment from 'moment';
import $ from 'jquery';

import { uiFixValue, uiFilterTabSwipe, popupOpen, popupClose, uiIpFocus, uiInputDel, uiAccoScrollFix, uiSelFocus, uiMainSwitch, uiChartTap, uiToggle, uiTopGo, joinInfoEvent } from 'routes/common/Design.js'
import { initWebViewEvent, callAppInterface, checkMainPage } from 'routes/common/AppInterface'
import {useHistory} from "react-router-dom";
import aes256 from 'routes/common/crypto/Aes256';

//// 컴포넌트 사용처 : 로그인
const Login = (props) => {

  const [loginStep, setLoginStep] = useState(1);

  const [telcoCode, setTelcoCode] = useState('1');

  const [captchaAnswer, setCapchaAnswer] = useState('');
  const [savedPhoneNo, setSavedPhoneNo] = useState(false);
  const [savedUser, setSavedUser] = useState(false);
  const [token, setToken] = useState('');

  const [authTimer, setAuthTimer] = useState(null);

  const phoneNumberInput = useRef(null);
  const authCodeInput = useRef(null);
  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('ref');
    localStorage.removeItem('isAutoLogin');
    //joinInfoEvent.call();

    //uiFixValue.init();
    window.captcha.init.event();
    uiFilterTabSwipe.init();
    uiSelFocus.init();
    uiMainSwitch.init();
    uiChartTap.init();
    uiToggle.init();
    uiTopGo.init();

    uiIpFocus.init();
    uiInputDel.init();

    $(window).scrollTop(0);
    window.goback = backToAptMain;

    if (localStorage.getItem('savedPhoneNo') !== '') {
      phoneNumberInput.current.value = localStorage.getItem('savedPhoneNo')
      //input change업데이트 강제실행
      phoneNumberInput.current.setAttribute('value', localStorage.getItem('savedPhoneNo'));
      phoneNumberInput.current.dispatchEvent(new Event('change', { bubbles: true }));
      uiIpFocus.init();
    }

    $(".btn_sel_wrap").each(function (idx) {
      $(this).data("key", idx + 1);
      // btm select popup 호출
      $(this).find(".btn_sel").on("click", function () {
        var key = $(this).parents(".btn_sel_wrap").data("key");
        popupOpen('select0' + key, 'mwf', 0);
      });
    });

    $(".ly_select_wrap").each(function (idx) {
      $(this).data("ctrlKey", idx + 1);
      // btm select 값 선택// btm select 값 선택
      $(this).find(".pop_sel_wrap .list input").on("change", function () {
        var ctrlKey = $(this).parents(".ly_select_wrap").data("ctrlKey");
        var selTxt = $(this).parents(".item").find(".txt").text();

        $(".btn_sel_wrap").eq(ctrlKey - 1).removeClass("focus").find(".lbl").addClass("hide");
        $(".btn_sel_wrap").eq(ctrlKey - 1).find(".sel_txt").text(selTxt);

        //telco value 찾도록 추가
        var telcoVal = $(this).parents(".item").find(".txt").attr('data-value');
        onChangeTelco(telcoVal);

        popupClose('select0' + ctrlKey, 'mwf', 0);
      });
    });

  }, []);

  const stepLogin = () => {
    // 하단 확인 버튼 클릭시 step 변환

    //$(".btn_login").on("click", function () {
    //count++;

    if (loginStep === 1) { // checkPhone
      checkPhone(loginStep, 'N');
    }
    else if (loginStep === 2) { // agree
      if (authCodeInput.current.value) {
        submitAuth();
      }
      else {
        checkPhone(loginStep, 'N');
      }
    }
    else if (loginStep === 3) { // submitRequest
      submitAuth();
    }
    //});
  }

  const onChangeTelcoCode = (e) => {
    setTelcoCode(e.target.value);
  }

  const onChangeTelco = (code) => {
    setTelcoCode(code);
  }

  const onChangePhoneNo = (e) => {
    let value = e.target.value;
    let currentLength = e.target.value.length;
    let maxLength = e.target.getAttribute('maxLength');

    if (maxLength < currentLength) {
      value = e.target.value.slice(0, maxLength);
      e.target.value = value;
    }
  }
  const onChangeAuthCode = (e) => {
    let value = e.target.value;
    let currentLength = e.target.value.length;
    let maxLength = e.target.getAttribute('maxLength');

    if (maxLength < currentLength) {
      value = e.target.value.slice(0, maxLength);
      $('#authCode').val(value);
    }

    authCodeInput.current.value = e.target.value;
    setLoginStep(3);
    // this.setState({
    //   authCode: e.target.value,
    // });
  }
  const onChangeCaptchaAnswer = (e) => {
    let value = e.target.value;
    let currentLength = e.target.value.length;
    let maxLength = e.target.getAttribute('maxLength');

    if (maxLength < currentLength) {
      value = e.target.value.slice(0, maxLength);
      $('#answer_captcha').val(value);
    }

    setCapchaAnswer(e.target.value);
    // this.setState({
    //   captchaAnswer: e.target.value,
    // });
  }
  const onChangeSavedPhoneNo = (e) => {
    let checked = e.target.checked;
    setSavedPhoneNo(checked);
  }
  const onChangeSavedUser = (e) => {
    let checked = e.target.checked;
    setSavedUser(checked);
  }
  const commonUtil = {
    //authTimer: null,
    displayTimer(str) {
      var html = $("#" + str).html();
      var min = html.substr(0, 2);
      var sec = html.substr(3, 2);

      if (sec === "00") {
        min = "0" + (parseInt(min) - 1);
        sec = "59";

      } else {
        if (html.substr(3, 1) === "0") {
          sec = html.substr(4, 1);
        }
        var tmp = parseInt(sec) - 1;
        if (tmp < 10) {
          sec = "0" + tmp;
        } else {
          sec = tmp;
        }
      }
      if (min === '00' && sec === '00') {
        $("#" + str).html("입력시간 만료");
        $("#authCode").attr('disabled', true);

      } else {
        $("#" + str).html(min + ":" + sec);
        setAuthTimer(setTimeout(() => {
          this.displayTimer(str);
        }, 1000));
      }
    }
  }
  //login
  const checkPhone = (loginStep, resendYn) => {
    if (telcoCode === '') {
      alert('통신사를 선택해주세요.');
      return false;
    }
    else if (!phoneNumberInput.current.value) {
      alert('휴대폰번호를 입력해주세요.');
      phoneNumberInput.current.focus();
      return false;
    }
     // 인증번호 최초 요청완료 후(step2) 인증번호 입력하지 않고 유료가입 버튼 클릭했을 경우 예외처리
    // ( 보안문자 입력창 없을때 + 재전송 버튼 클릭시엔 체크 안되도록 )
    else if (loginStep === 2 && authCodeInput.current.value === '' && resendYn == 'N' && !$('#section_stepB').is(':visible')) {
      alert('인증번호를 입력해주세요.');
      authCodeInput.current.focus();
      return false;
    }
    
    if (authTimer != null) {
      window.clearTimeout(authTimer);
      $("#auth_timer").html("");
    }

    // APP <-> 서버일 경우 휴대폰번호 구간 암호화
    const encPhoneNo = aes256.encrypt(phoneNumberInput.current.value);

    let paramForm = new FormData();
    paramForm.append('phoneNo', encPhoneNo);
    paramForm.append('telcoCode', telcoCode);
    paramForm.append('siteCode', 'AB'); //아파트청약케어 APP가입창 가입코드 : AB (app차후배포시 수정)
    paramForm.append('ctgCode', '1');
    paramForm.append('captchaAnswer', captchaAnswer);
    paramForm.append('appCheck', true);

    axios
      .post('apt/user/checkPhone', paramForm)
      .then(res => {
        if (res.data.code === "E0000") {
          alert("휴대폰으로 인증번호가 발송되었습니다.");
          if ($('#section_stepB').css('display') == 'block') {
            window.captcha.close();
          }
          //$('input[name=telcoCode]').attr("disabled", "disabled");
          //$('#btn_setend').attr("disabled", "disabled");
          $('.btn_sel_wrap button').attr('disabled', true);
          $('#section_stepB').hide();
          $("#section_auth").show();
          //$("#phoneNo1").attr("disabled", "disabled");
          //$("#phoneNo2").attr("disabled", "disabled");
          $('#ip01').attr('disabled', true);
          $("#btn_reque").show();
          $("#authCode").removeAttr("disabled").focus();
          $('#authCode').val('');
          authCodeInput.current.value = '';
          setCapchaAnswer('');  // [SOM.230113] 재전송시에도 캡챠 뜨도록 보안문자값 초기화

          $("#auth_timer").show();
          $("#auth_timer").html("03:00");

          setAuthTimer(setTimeout(() => {
            commonUtil.displayTimer('auth_timer');
          }));
          setLoginStep(2);
          //$("button.skip").text('로그인');
          $('.btn_txt').eq(0).attr('disabled', true);
        }
        else {
          if (res.data.code === "EW101" || res.data.code === "EW102") {
            if (res.data.code === "EW102") alert(res.data.message);
            setLoginStep(2);
            //$("button.skip").text('로그인');

            window.captcha.phoneNo = phoneNumberInput.current.value;
            $("#newSafeNum").click();
            $('#section_auth').hide();
            $("#section_stepB").show();
            $("#answer_captcha").val('');
            setCapchaAnswer('');
            $('#authCode').val('');
            authCodeInput.current.value = '';

            //this.setState({
            //  captchaAnswer: '',
            //});

          } else {
            alert(res.data.message);
            window.location.reload();
          }
        }
      });
  }

  const submitAuth = (e) => {
    if (!authCodeInput.current.value) {
      alert('인증번호를 입력해주세요.');
      authCodeInput.current.focus();
      return false;
    }

    // APP <-> 서버일 경우 휴대폰번호 구간 암호화
    const encPhoneNo = aes256.encrypt(phoneNumberInput.current.value);

    let paramForm = new FormData();
    paramForm.append('phoneNo', encPhoneNo);
    paramForm.append('telcoCode', telcoCode);
    paramForm.append('authCode', authCodeInput.current.value);
    paramForm.append('siteCode', 'AB'); //아파트청약케어 APP가입창 가입코드 : AB (app차후배포시 수정)
    paramForm.append('ctgCode', '1');
    paramForm.append('marketingYn', 'N');
    paramForm.append('isAutoLogin', savedUser);
    paramForm.append('moLogin', true);
    paramForm.append('appCheck', true);

    axios
      .post('apt/user/submitAuth', paramForm)
      .then(res => {

        if (res.data.code === "E0000") {
          localStorage.setItem('jwtToken', res.headers.authorization);
          if (savedUser === true) {
            localStorage.setItem('ref', res.data.response.ref);
          }

          alert("로그인 완료되었습니다.");

          if (savedPhoneNo === true) { localStorage.setItem('savedPhoneNo', phoneNumberInput.current.value); }
          else { localStorage.removeItem('savedPhoneNo'); }

          if (savedUser === true) { localStorage.setItem('isAutoLogin', true); }
          else { localStorage.removeItem('isAutoLogin'); }

          callAppInterface("tokenToApp", JSON.stringify({
            token: res.headers.authorization,
            ref: res.data.response.ref,
            isAuto: savedUser,
          }), result => {

          });

          // history.push("/aptMain");
          window.location.href = "/aptMain";

          // if (document.referrer && document.referrer.indexOf(window.location.hostname) !== -1)  history.goBack();
          // else                                                                                  window.location.href = '/';
        }
        else {
          if (res.data.code === "EW012"){ // 인증번호가 일치하지 않습니다. 다시 입력해주세요.
            authCodeInput.current.value = '';
          }

          alert(res.data.message);
        }
      });
  }

  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  //단독페이지들은 location href = / 로 보냄
  const backToAptMain = () => {
    //window.location.href = '/';
    callAppInterface("closeWebPage", JSON.stringify({ url: '/' }), result => {
      //alert(result);

    });
    window.location.href = '/';
  }

  const openJoin = () => {
    window.location.href = '/join?siteCode=AB&ctgCode=1';
  }

  return (
    <>
      <div id="mw-info" className="ly_apt_sale ly_apt_join full">
        <div className="wrap">
          <div className="content">
            <div className="header ty_left">
              <header>
                <button type="button" onClick={backToAptMain} className="btn_prev" title="뒤로가기"><i></i></button>
                <strong className="tit">로그인</strong>
              </header>
            </div>
            <div className="ly_content">
              <section className="view_txt_list">
                <ul>
                  <li>아파트청약케어 이용을 위해 로그인해주세요.</li>
                  <li>가입하셨던 휴대폰번호로도 로그인이 가능해요!</li>
                </ul>
              </section>
              <div className="view">
                <div className="item">
                  <strong className="tit">통신사</strong>
                  <div className="form_wrap">
                    <div className="form">
                      {/* <div className="ip_box in">
                        <div className="select_box select_apt">
                          <select className="code" id="select01" onChange={onChangeTelcoCode}>
                            <option value="1">SKT</option>
                            <option value="2">KT</option>
                            <option value="3">LGU</option>
                          </select>
                        </div>
                      </div> */}
                      <div className="ip_box btn_sel_wrap">
                        <div className="inner_wrap">
                          <button className="button" className="btn_sel">
                            <span className="sel_txt">SKT</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <strong className="tit">휴대폰번호</strong>
                  <div className="form_wrap">
                    <div className="form">
                      <div className="ip_box ip_del">
                        <label for="ip01" className="lbl code">0102345678</label>
                        <input type="number" id="ip01" autocomplete="off" className="ip code" maxLength="11" onChange={onChangePhoneNo} ref={phoneNumberInput} />
                        <button type="button" className="del"><i>삭제</i></button>
                        <button type="button" id="btn_setend" className="btn_txt" onClick={() => checkPhone(loginStep, 'N')}>인증요청</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="section_auth" className="item cert_wrap" style={{ "display": "none" }}>
                  <strong className="tit">인증번호</strong>
                  <div className="form_wrap">
                    <div className="form col_wrap">
                      <div className="ip_box ip_del">
                        <label for="authCode" className="lbl code">인증번호를 입력해주세요.</label>
                        <input type="number" id="authCode" name="authCode" className="ip code" maxLength="6" onChange={onChangeAuthCode} disabled ref={authCodeInput}/>
                        <span id="auth_timer" className="time" style={{ "display": "none" }}>03:00</span>
                        <button type="button" onClick="" className="del"><i>삭제</i></button>
                      </div>
                      <button type="button" className="btn_txt" onClick={() => checkPhone(loginStep, 'Y')}>재전송</button>
                    </div>
                  </div>
                </div>
                <div id="section_stepB" className="item col_wrap" style={{ "display": "none" }}>
                  <div className="form_wrap security_wrap">
                    <img id="img_captcha" src={process.env.REACT_APP_ENV_URL + "resources/images/temp/temp.png"} alt="" />
                    <button id="newSafeNum" type="button" className="btn_reset">새로고침</button>
                  </div>
                  <div className="form_wrap">
                    <strong className="tit">보안문자</strong>
                    <div className="form">
                      <div className="ip_box ip_del">
                        <label for="security" className="lbl code"></label>
                        <input type="text" id="answer_captcha" className="ip code" name="security" maxLength="6" onChange={onChangeCaptchaAnswer} />
                        <button type="button" onClick="" className="del"><i>삭제</i></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="phone_save">
                  <span className="checkbox i_all">
                    <input type="checkbox" name="savedPhoneNo" id="savedPhoneNo" value="" onChange={onChangeSavedPhoneNo} />
                    <label for="savedPhoneNo"><i></i>휴대폰번호 저장</label>
                  </span>
                  <span className="checkbox i_all">
                    <input type="checkbox" name="savedUser" id="savedUser" value="" onChange={onChangeSavedUser} />
                    <label for="savedUser"><i></i>자동 로그인</label>
                  </span>
                </div>
              </div>
            </div>
            <div className="btn_area_wide fix btm">
              <button type="button" className="skip" id="submit_captcha" onClick={() => stepLogin()}>로그인</button>
              <button type="button" className="skip line" onClick={() => openJoin()}>회원가입</button>
            </div>
          </div>
        </div>
      </div>

      <div id="mw-select01" className="ly_pop popBtm ly_select_wrap">
        <div className="wrap">
          <button type="button" onClick={() => popupClose('select01', 'mwf', 0)} className="close"><i>닫기</i></button>
          <div className="content pop_sel_wrap">
            <ul className="list">
              <li>
                <span className="item">
                  <input type="radio" id="select01_01" name="select01" />
                  <label for="select01_01">
                    <span className="txt" data-value="1" >SKT</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select01_02" name="select01" />
                  <label for="select01_02">
                    <span className="txt" data-value="2">KT</span>
                  </label>
                </span>
              </li>
              <li>
                <span className="item">
                  <input type="radio" id="select01_03" name="select01" />
                  <label for="select01_03">
                    <span className="txt" data-value="3">LGU{'+'}</span>
                  </label>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login;