import axios from "axios";
import {CONFIG} from "../consts/jwt";
import {getCouponKey} from "../consts/coupon";
import {useCouponStore} from "../store/couponStore";
import {useUserStore} from "../store/userStore";
import moment from "moment";
import {padZero} from "../utils/string";
import _ from "lodash";

export const useCoupon = () => {
    const {coupon, couponItem, setCoupon, setCouponItem} = useCouponStore();
    const {user, setUser} = useUserStore();
    let issueCouponLoading = false;
    let expireCouponLoading = false;

    const getCoupon = () =>{
        axios.get("/apt/coupon", {
            ...CONFIG,
            timeout: 5000
        }).then(response => {
            if (response.data.code === 'E0000') {
                const res = response.data.response;
                if (res.isLoggedIn) {
                    setUser({
                        isLoggedIn: res.isLoggedIn,
                        regTime: moment(res.regTime).tz('Asia/Seoul').toDate(),
                    });

                    if (res.couponManage) {
                        const key = getCouponKey(res.couponManage.cpnGrpCd);

                        setCoupon({
                            key: key,
                            couponManage: res.couponManage,
                            couponList: res.couponList,
                        });
                    }
                } else {
                    setUser(null);
                }

            } else {
                setCoupon(null);
                setUser(null);
            }
        }).catch(reason => {
            setCoupon(null);
            setUser(null);
        });
    }

    const selectCoupon = (paramCoupon) => {
        const msg = `${paramCoupon.cpnGrpNm} 업체의 쿠폰을 받으시겠습니까?\n※  업체 재선택은 ${padZero(user.regTime.getDate())}일에 가능합니다.`;

        if (window.confirm(msg)) {
            axios.get("/apt/coupon/select", {
                ...CONFIG,
                params: {
                    cpnGrpCd: paramCoupon.cpnGrpCd,
                },
            }).then(response => {
                if (response.data.code === 'E0000') {
                    const res = response.data.response;

                    alert("쿠폰 발급이 완료되었습니다.");
                    const key = getCouponKey(res.couponManage.cpnGrpCd);

                    setCoupon({
                        key: key,
                        couponManage: res.couponManage,
                        couponList: [],
                    });
                }
            }).catch(reason => {
                alert("쿠폰 발급 실패");
            });
        }
    }

    const issueCoupon = () => {

        if (!issueCouponLoading) {
            issueCouponLoading = true;
            window.UI.spinner.show();
            axios.post("/apt/coupon/issue", null, {...CONFIG, timeout: 5000}).then((response) => {
                if (response.data.code === 'E0000') {
                    const res = response.data.response;

                    setCouponItem({
                        pinNo: res.coupon.pinNo,
                        cpnEndDttm: moment(res.coupon.cpnEndDttm).format("YY.MM.DD"),
                        cpnGrpNm: res.coupon.cpnGrpNm,
                        cpnGrpCd: res.coupon.cpnGrpCd,
                        key: getCouponKey(res.coupon.cpnGrpCd),
                    });
                }

            }).finally(() => {
                issueCouponLoading = false;
                window.UI.spinner.hide();
            });
        }
    };

    const expireCoupon = () => {
        if (!expireCouponLoading) {
            expireCouponLoading = true;
            axios.post("/apt/coupon/expire", null, {...CONFIG, timeout: 5000}).then((response) => {
                if (response.data.code === 'E0000') {
                    setCouponItem(null);
                }
            }).finally(() => {
                expireCouponLoading = false;
            });
        }
    };

    const hasCoupon = () => {
        return !_.isEmpty(coupon);
    };

    const hasCouponManage = () => {
        return !_.isEmpty(coupon?.couponManage);
    };

    const hasCouponItem = () => {
        return !_.isEmpty(couponItem);
    };

    const couponItemAlert = {
        exist: () => {
            alert("발급된 쿠폰을 사용해주세요");
        },
        used: () => {
            alert("사용이 완료된 쿠폰입니다");
        },
        wait: () => {
            alert("추가 쿠폰은 1일 후 발급 가능합니다");
        },
        expired: () => {
            alert("기한이 만료된 쿠폰입니다.");
        },
        allUsed: () => {
            alert(`이번 달 쿠폰을 모두 사용하였습니다.\n※ 업체 재선택 및 쿠폰 리셋은 ${padZero(user.regTime.getDate())}일에 가능합니다.`);
        },
    };


    return {coupon, user, selectCoupon, getCoupon, hasCoupon, hasCouponManage, issueCoupon, hasCouponItem, expireCoupon, couponItemAlert};
};