
import { popupCompany } from 'routes/predict/PopupNotice';

//// 컴포넌트 사용처 : 청약 당첨 예상 상세
const BuildRank = ({ calcResult }) => {

  const getCatption = (buildRank, constComp) => {
    let caption = '';
    if (buildRank === null) {
      caption = <p>
        <em>아쉽게도 {constComp}는 2022</em>년 기준 건설협회 시공능력평가순위 100위권내에 없어요.<br />
        해당순위는 2022년 대한 건설협회 시공능력평가순위 기준으로 산출되요.
                </p>
    } else {
      caption = <p>
        <em>{constComp}은 2022년</em>기준 국내 건설사 100위 안에 포함된 건실한 건설사로 확인되요.<br />
        해당순위는 2022년 대한 건설협회 시공능력평가순위 기준으로 산출되요.
                </p>
    }
    return caption;
  }

  const { applyInfo, constructList, buildRank } = calcResult;

  const scoreType = (
    buildRank === null ? 'bad' : (
      (buildRank.rank >= 71) ? 'bad' :
        (buildRank.rank >= 41 && buildRank.rank < 71) ? 'normal' :
          (buildRank.rank >= 21 && buildRank.rank < 41) ? 'good' :
            (buildRank.rank < 21) ? 'best' : ''));

  return (
    <div className={`ty_graph ani ${scoreType}`} id="ani5">
      <div className="graph">
        <div className="dount_area">
          <div className="circle_chart" data-percent={buildRank === null ? 100 : (101 - buildRank.rank)} data-permax="100">
            <div className="chart-box">
              <svg>
                <defs>
                  <linearGradient id={`dount_${scoreType}`} x1="0" y1="0" x2="1" y2="1">
                    <stop className="color_1" offset="0%" />
                    <stop className="color_2" offset="100%" />
                  </linearGradient>
                </defs>
                <circle stroke={`url(#dount_${scoreType})`} r="50%" cx="50%" cy="50%" className="circle_progress" stroke-width="18" stroke-linecap="round"></circle>
                <filter id="shadow">
                  <feDropShadow dx="0" dy="0" stdDeviation="2"
                    flood-color="#CDCDCD" />
                </filter>
                <circle className="marker" r="11" cx="50%" cy="107" style={{ 'filter': 'url(#shadow)' }}></circle>
              </svg>
              <div className="per-num rank"><span rank={buildRank === null ? 100 : buildRank.rank}>
                {buildRank === null ? 100 : buildRank.rank}
              </span><s>위</s></div>
            </div>
          </div>
        </div>
        <div className="btn_inner">
          <button type="button" className="btn_line GTM-PER-3" onClick={() => popupCompany(constructList, buildRank, applyInfo.constComp)}> 건설사 순위 전체보기</button>
        </div>
      </div>
      <div className="caption emoji">
        {
          getCatption(buildRank, applyInfo.constComp)
        }
      </div>
    </div>
  );
}

export default BuildRank;
