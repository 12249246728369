import {useEffect, useState} from 'react'
import {getLsItem} from 'routes/common/LocalStorage'

//// 컴포넌트 사용처 : 아파트 정보
const HhldFilter = ({ onChangeFilter, refresh, load, checkLoad }) => {

  const filterType = "HHLD";

  const [filterList, setFilter] = useState([
    { name: '전체', visible: true, min: -1, max: 99999, text: <>전체</>, minTxt: "전체", maxTxt: "전체" },
    { name: '100 ~ 300세대', visible: false, min: 100, max: 300, text: <>100 ~<br />300세대</>, minTxt: "100", maxTxt: "300세대 미만" },
    { name: '300 ~ 500세대', visible: false, min: 300, max: 500, text: <>300 ~<br />500세대</>, minTxt: "300", maxTxt: "500세대 미만" },
    { name: '500 ~ 700세대', visible: false, min: 500, max: 700, text: <>500 ~<br />700세대</>, minTxt: "500", maxTxt: "700세대 미만" },
    { name: '700 ~ 1000세대', visible: false, min: 700, max: 1000, text: <>700 ~<br />1000세대</>, minTxt: "700", maxTxt: "1000세대 미만" },
    { name: '1000 ~ 2000세대', visible: false, min: 1000, max: 2000, text: <>1000 ~<br />2000세대</>, minTxt: "1000", maxTxt: "2000세대 미만" },
    { name: '2000 ~ 3000세대', visible: false, min: 2000, max: 3000, text: <>2000 ~<br />3000세대</>, minTxt: "2000", maxTxt: "3000세대 미만" },
    { name: '3000세대 이상', visible: false, min: 3000, max: 99999, text: <>3000세대 이상</>, minTxt: "3000세대 이상", maxTxt: "3000세대 이상" }
  ]);

  useEffect(() => {
    if (refresh === true) {
      setFilter([
        { name: '전체', visible: true, min: -1, max: 99999, text: <>전체</>, minTxt: "전체", maxTxt: "전체" },
        { name: '100 ~ 300세대', visible: false, min: 100, max: 300, text: <>100 ~<br />300세대</>, minTxt: "100", maxTxt: "300세대 미만" },
        { name: '300 ~ 500세대', visible: false, min: 300, max: 500, text: <>300 ~<br />500세대</>, minTxt: "300", maxTxt: "500세대 미만" },
        { name: '500 ~ 700세대', visible: false, min: 500, max: 700, text: <>500 ~<br />700세대</>, minTxt: "500", maxTxt: "700세대 미만" },
        { name: '700 ~ 1000세대', visible: false, min: 700, max: 1000, text: <>700 ~<br />1000세대</>, minTxt: "700", maxTxt: "1000세대 미만" },
        { name: '1000 ~ 2000세대', visible: false, min: 1000, max: 2000, text: <>1000 ~<br />2000세대</>, minTxt: "1000", maxTxt: "2000세대 미만" },
        { name: '2000 ~ 3000세대', visible: false, min: 2000, max: 3000, text: <>2000 ~<br />3000세대</>, minTxt: "2000", maxTxt: "3000세대 미만" },
        { name: '3000세대 이상', visible: false, min: 3000, max: 99999, text: <>3000세대 이상</>, minTxt: "3000세대 이상", maxTxt: "3000세대 이상" }
      ]);
    }
  }, [refresh]);

  useEffect(() => {
    if (load && checkLoad) {
      let aptSrchFilter = getLsItem('aptSrchFilter');
      let min = filterList.findIndex(v => v.min == aptSrchFilter.hhld.min);
      let max = filterList.findIndex(v => v.max == aptSrchFilter.hhld.max);
      setTimeout(() => {
        selectBtnList(min);
        selectBtnList(max);
      }, 100)
    }
  }, [load, checkLoad]);

  const selectBtnList = (idx) => {

    let current = filterList.slice();
    let prevIdx = filterList.findIndex(v => v.visible);

    let visibleCnt = current.filter(v => v.visible).length;
    current.filter((v, i) => i === idx).forEach(v => v.visible = true);

    if (idx === 0) {
      current.filter((v, i) => i !== 0).forEach(v => v.visible = false);
    } else if (prevIdx === 0) {
      current.filter((v, i) => i !== idx).forEach(v => v.visible = false);
    } else if (visibleCnt > 1) {
      current.filter((v, i) => i !== idx).forEach(v => v.visible = false);
    } else if (prevIdx < idx) {
      current.filter((v, i) => i >= prevIdx && i <= idx).forEach(v => v.visible = true);
    } else if (prevIdx >= idx) {
      current.filter((v, i) => i !== idx).forEach(v => v.visible = false);
    }

    // if (current.filter((v, i) => i !== 0).every(v => v.visible)) {
    //   current.filter((v, i) => i === 0).forEach(v => v.visible = true);
    //   current.filter((v, i) => i !== 0).forEach(v => v.visible = false);
    // }

    setFilter(current);
    handleChangeFilter(current);
  }

  const handleChangeFilter = (btnList) => {

    let min = btnList.filter(v => v.visible).map(v => v.min).reduce((prev, curr) => Math.min(prev, curr));
    let max = btnList.filter(v => v.visible).map(v => v.max).reduce((prev, curr) => Math.max(prev, curr));;

    let visibleCnt = btnList.filter(v => v.visible).length;
    let filterName;

    if (visibleCnt === 1) {
      if (btnList[1].visible) {
        filterName = `${max}세대 미만`;
      } else if (btnList[btnList.length - 1].visible) {
        filterName = `${min}세대 이상`;
      } else {
        filterName = btnList.filter(v => v.visible)[0].minTxt + ' ~ ' + btnList.filter(v => v.visible)[0].maxTxt;
      }
    } else {
      if (btnList[1].visible) {
        filterName = btnList.filter(v => v.visible)[visibleCnt - 1].maxTxt;
      } else {
        filterName = btnList.filter(v => v.visible)[0].minTxt + ' ~ ' + btnList.filter(v => v.visible)[visibleCnt - 1].maxTxt;
      }
    }

    let mainName = visibleCnt === 1 && btnList[0].visible ? '전체' : filterName; // 전체 일 경우 : null
    onChangeFilter({ filterType, min, max, mainName });
  }

  return (
    <div className="item">
      <div className="filter_box_wrap">
        {
          filterList.map((v, idx) =>
            <button type="button"
              className={`${v.visible ? "btn active" : "btn"} GTM-APT-FILTER-4-${idx + 1}`}
              onClick={() => selectBtnList(idx)}>{v.text}
            </button>
          )
        }
      </div>
    </div>
  );
}

export default HhldFilter;