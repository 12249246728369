import React from "react";
import {COUPON} from "../../../consts/coupon";
import {useCouponStore} from "../../../store/couponStore";

/**
 * @desc 사용한 회색쿠폰
 */
export const CouponItemUsed = ({isExpired = false, couponAlert}) => {
    const {coupon} = useCouponStore();

    return (
            <li className="coupon-item used" onClick={couponAlert}>
                <div className="text-box">
                    <span className="text-01">{COUPON[coupon.key].cpnGrpNm} 1천원 쿠폰</span>
                    {
                        isExpired
                                ? <span className="text-02">쿠폰 기간 만료</span>
                                : <span className="text-02">쿠폰 사용 완료</span>
                    }
                </div>
                <button className="btn-coupon-down">
                    <i className="ico-dash"></i>
                </button>
            </li>
    );
};