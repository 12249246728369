import React from 'react';

import {ButtonJoin} from "../../components/common/ButtonJoin";
import {JoinDescription} from "../../components/common/JoinDescription";
import {CouponRoot} from "./layout/CouponRoot";
import {DefaultCoupons} from "../../components/coupon/widgets/couponList/DefaultCoupons";

export const NotLogin = () => {

    return (
            <>
                <CouponRoot>
                    <DefaultCoupons/>
                    <ButtonJoin/>
                    <JoinDescription/>
                </CouponRoot>
            </>
    );
};
