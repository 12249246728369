import React, {useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'; // url 구분 작업

import {RcmdAptOverlay} from 'routes/overlay/RcmdAptOverlay';
import {AroundOverlay} from 'routes/overlay/AroundOverlay';
import $ from 'jquery';
import {
    popupClose,
    popupOpen,
    uiChartTap,
    uiFilterTabSwipe,
    uiFixValue,
    uiInputDel,
    uiIpFocus,
    uiMainSwitch,
    uiSelFocus,
    uiToggle,
    uiTopGo
} from 'routes/common/Design.js'

import {getLcParamItem, getLcUrlItem, setLcParamItem, setLcUrlItem} from 'routes/common/ParamStorage';


let map = {};
let overlayList = [];
let aroundOverlayList = [];

//// 컴포넌트 사용처 : 아파트 추천
const RcmdDtlMap = (props) => {

  const lcUrlMap = getLcUrlItem();
  const lcParamMap = getLcParamItem();

  // url 구분 작업
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    popupOpen('map', 'mwf');
    uiFixValue.init();
    uiFilterTabSwipe.init();
    uiSelFocus.init();
    uiMainSwitch.init();
    uiChartTap.init();
    uiToggle.init();
    uiTopGo.init();

    uiIpFocus.init();
    uiInputDel.init();

    $(window).scrollTop(0);

    //let aptLoc = getLsItem('aptLoc');
    //let lat = aptLoc && aptLoc.lat ? aptLoc.lat : 37.57100;
    //let lng = aptLoc && aptLoc.lng ? aptLoc.lng : 126.97696;
    let lat = 37.57100;
    let lng = 126.97696;

    let mapContainer = document.getElementById('mapviewRcmd'); // 지도를 표시할 div 
    let mapOption = {
      center: new kakao.maps.LatLng(lat, lng), // eslint-disable-line
      level: 3
    };

    map = new kakao.maps.Map(mapContainer, mapOption);  // eslint-disable-line
    map.setMinLevel(2);
    map.setMaxLevel(11);

    kakao.maps.event.addListener(map, 'dragend', () => { }); // eslint-disable-line
    kakao.maps.event.addListener(map, 'zoom_changed', () => { });  // eslint-disable-line

    if (lcParamMap.aptList != null) {
      renderMarkerList();
      setTimeout(() => {
        ////console.log(lcParamMap.aptList[0].lat);
        ////console.log(lcParamMap.aptList[0].lng);

        onPanTo(lcParamMap.aptList[0].lat, lcParamMap.aptList[0].lng);
      }, 200);
      //$('#marker_' + props.aptList[0].aptId).click();
    }

    if (lcParamMap.preventDtl) {
      if (lcParamMap.aptDtl != null && lcParamMap.aptDtl.aptId != '') {
        renderMarker();
        setTimeout(() => {
          onPanTo(lcParamMap.aptDtl.lat, lcParamMap.aptDtl.lng);
        }, 200);
        //$('#marker_' + props.aptDtl.aptId).click();
      }
    }

    // $('#marker_' + v.markerId).click((e) => {
    //   e.preventDefault();

    //   $(this).each(function (idx) {
    //     if ($(this).children().hasClass('type_apt')) {
    //       if ($(this).hasClass('active')) {
    //         $(this).removeClass('active');
    //       } else {
    //         $(this).addClass('active');
    //       }
    //     }
    //   });


    // });

    window.goback = lcParamMap.preventDtl ? backToRcmdDtl : backToRcmdList;
    window.checkMainPage = () => { return false; }

    return () => {
      window.checkMainPage = () => { return true; }
    }


  }, []);

  const clickMarker = (markerId, aptAreaId, trxType) => {

    ////console.log(`clickMarker : ${markerId} ${aptAreaId} ${trxType}`);

    aptAreaId = aptAreaId ? aptAreaId : '';

    //fetchAptDtl(markerId, trxType, (res) => {

    //const aptDtl = res.data.response;
    //let moveLatLng = new kakao.maps.LatLng(aptDtl.lat, aptDtl.lng); // eslint-disable-line
    //map.setCenter(moveLatLng);
    //map && overlayMap(map);

    //setAptDtl(aptDtl);
    //renderingAroundDataIcons(aptDtl);

    //})

    popupClose('map', 'mwf');
    setLcParamItem({ 'aptId': markerId, 'aptAreaId': aptAreaId, 'rcmdParams': lcParamMap.rcmdParams });
    props.onRcmdPopupType('P_B');


    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?REC-${aptAreaId}#`
    });
  }



  const clearOverlay = () => {
    overlayList.forEach(ov => ov.setMap(null));
    overlayList = [];
  }

  const clearAroundOverlay = () => {
    aroundOverlayList.forEach(ov => ov.setMap(null));
    aroundOverlayList = [];
  }

  const renderMarkerList = () => {
    clearOverlay();
    clearAroundOverlay();

    (lcParamMap.aptList).forEach(element => {

      let overlay = new RcmdAptOverlay(element);
      overlay.setMap(map);

      //renderingAroundDataIcons(element);

      $('#marker_' + element.aptId).click(function () {


        $(this).each(function (idx) {
          if ($(this).children().hasClass('type_apt')) {
            if ($(this).hasClass('active')) {
              $(this).removeClass('active');
            } else {
              $(this).addClass('active');
            }
          }
        });

        clickMarker(element.aptId, element.aptAreaId, 'D');
      });



    });
  }

  const renderMarker = () => {
    clearOverlay();
    clearAroundOverlay();
    let overlay = new RcmdAptOverlay(lcParamMap.aptDtl);
    overlay.setMap(map);
    $('#marker_' + lcParamMap.aptDtl.aptId).click(function () {


      $(this).each(function (idx) {
        if ($(this).children().hasClass('type_apt')) {
          if ($(this).hasClass('active')) {
            $(this).removeClass('active');
          } else {
            $(this).addClass('active');
          }
        }
      });

      clickMarker(lcParamMap.aptDtl.aptId, lcParamMap.aptDtl.aptAreaId, 'D');
    });
    renderingAroundDataIcons(lcParamMap.aptDtl);
  }

  const renderingAroundDataIcons = (data) => {
    aroundOverlayList.forEach(ov => ov.setMap(null));
    aroundOverlayList = [];

    const dtl = data;

    dtl.schoolList.forEach(v => {
      v.type = 'school';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.departList.forEach(v => {
      v.type = 'ico_mart';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.stationList.forEach(v => {
      v.type = 'ico_subway';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.hospitalList.forEach(v => {
      v.type = 'ico_hospital';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.parkList.forEach(v => {
      v.type = 'ico_park02';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });
  }

  const onPanTo = (paramLat, paramLng) => {
    let moveLatLng = new kakao.maps.LatLng(paramLat, paramLng); // eslint-disable-line
    map.panTo(moveLatLng);
  }


  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  const backToRcmdDtl = () => {
    setLcUrlItem('rcmdDtlMap', 'rcmdDtl');
    popupClose('map', 'mwf');
    props.onRcmdPopupType('P_B');
  }

  const backToRcmdList = () => {
    setLcUrlItem('rcmdDtlMap', 'rcmdList');
    popupClose('map', 'mwf');
    props.onRcmdPopupType('P_A');
  }

  const backToRcmdSrch = () => {
    setLcUrlItem('rcmdDtlMap', 'rcmdSrch');
    popupClose('map', 'mwf');
    props.onRcmdPopupType('');
  }

  const backToAptMain = () => {
    setLcUrlItem('rcmdDtlMap', 'aptMain');
    popupClose('map', 'mwf');
    props.onRcmdPopupType('');
    setLcParamItem({});
    $('.map_wrap').show();
    props.onRcmdSrchDisplay(false);

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: ``
    });
  }

  return (
    <>
      <div id="mw-map" className="ly_pop ly_apt_detail full">
        <div className="wrap">
          <div className="content">
            <header className="header">
              <button type="button" onClick={() => backToAptMain()} className="btn_home" title="홈으로 바로가기"><i></i></button>
              <button type="button" onClick={() => { lcParamMap.preventDtl ? backToRcmdDtl() : backToRcmdList() }} className="btn_prev" title="뒤로가기"><i></i></button>
              {lcParamMap.preventDtl ?
                (lcParamMap.preventDtl && lcParamMap.aptDtl) && <strong className="tit">{lcParamMap.aptDtl.aptName}</strong> : <strong className="tit">아파트추천</strong>}
            </header>
            <div className="ly_content">
              <div className="map_con" id="mapviewRcmd">

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RcmdDtlMap;