import React, {useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'; // url 구분 작업
import {ApplyOverlay} from 'routes/overlay/ApplyOverlay';
import {AroundOverlay} from 'routes/overlay/AroundOverlay';
import $ from 'jquery';
import {
    popupClose,
    popupOpen,
    uiChartTap,
    uiFilterTabSwipe,
    uiFixValue,
    uiInputDel,
    uiIpFocus,
    uiMainSwitch,
    uiSelFocus,
    uiToggle,
    uiTopGo
} from 'routes/common/Design.js'

import {getLcParamItem, getLcUrlItem, setLcUrlItem} from 'routes/common/ParamStorage';

let map = {};
let overlayList = [];
let aroundOverlayList = [];

//// 컴포넌트 사용처 : 청약 정보
const ApplyDtlMap = (props) => {

  const lcUrlMap = getLcUrlItem();
  const lcParamMap = getLcParamItem();

  // url 구분 작업
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    popupOpen('map', 'mwf');
    uiFixValue.init();
    uiFilterTabSwipe.init();
    uiSelFocus.init();
    uiMainSwitch.init();
    uiChartTap.init();
    uiToggle.init();
    uiTopGo.init();

    uiIpFocus.init();
    uiInputDel.init();

    $(window).scrollTop(0);


    //let aptLoc = getLsItem('aptLoc');
    //let lat = aptLoc && aptLoc.lat ? aptLoc.lat : 37.57100;
    //let lng = aptLoc && aptLoc.lng ? aptLoc.lng : 126.97696;
    let lat = 37.57100;
    let lng = 126.97696;

    let mapContainer = document.getElementById('mapviewApply'); // 지도를 표시할 div 
    let mapOption = {
      center: new kakao.maps.LatLng(lat, lng), // eslint-disable-line
      level: 3
    };

    map = new kakao.maps.Map(mapContainer, mapOption);  // eslint-disable-line
    map.setMinLevel(2);
    map.setMaxLevel(11);

    kakao.maps.event.addListener(map, 'dragend', () => { }); // eslint-disable-line
    kakao.maps.event.addListener(map, 'zoom_changed', () => { });  // eslint-disable-line


    if (lcParamMap.applyDtl != null && lcParamMap.applyDtl.applyId != '') {
      renderMarker();
      onPanTo(lcParamMap.applyDtl.lat, lcParamMap.applyDtl.lng);
    }

    // 마크 선택
    // $(".map_marker").on('click', function () {
    //   $(this).each(function (idx) {
    //     if ($(this).children().hasClass('type_apt')) {
    //       if ($(this).hasClass('active')) {
    //         $(this).removeClass('active');
    //       } else {
    //         $(this).addClass('active');
    //       }
    //     }
    //   });
    // });

    window.goback = backToApplyDtl;
    window.checkMainPage = () => { return false; }

    return () => {
      window.checkMainPage = () => { return true; }
    }

  }, []);

  const clickMarker = (markerId) => {

    //console.log(`clickMarker : ${markerId}`);

    //fetchAptDtl(markerId, trxType, (res) => {

    //const aptDtl = res.data.response;
    //let moveLatLng = new kakao.maps.LatLng(aptDtl.lat, aptDtl.lng); // eslint-disable-line
    //map.setCenter(moveLatLng);
    //map && overlayMap(map);

    //setAptDtl(aptDtl);
    //renderingAroundDataIcons(aptDtl);

    //})
    setLcUrlItem('applyDtlMap', 'applyDtl');
    popupClose('map', 'mwf');
    props.onApplyPopupType('P_A');
  }



  const clearOverlay = () => {
    overlayList.forEach(ov => ov.setMap(null));
    overlayList = [];
  }

  const clearAroundOverlay = () => {
    aroundOverlayList.forEach(ov => ov.setMap(null));
    aroundOverlayList = [];
  }

  const renderMarker = () => {
    clearOverlay();
    clearAroundOverlay();
    let overlay = new ApplyOverlay({ ...lcParamMap.applyDtl, isMap: true });
    overlay.setMap(map);

    $('[id^="markerApply_"]').off('click').on('click', function(e) {
      e.preventDefault();

      const idAry = $(this).attr('id').split('_');

      $(this).each(function (idx) {
        if ($(this).children().hasClass('type_apt')) {
          if ($(this).hasClass('active')) $(this).removeClass('active');
          else                            $(this).addClass('active');
        }
      });

      clickMarker(idAry[1]);
    });

    // $('#markerApply_' + lcParamMap.applyDtl.applyId).click(function () {
    //
    //
    //   $(this).each(function (idx) {
    //     if ($(this).children().hasClass('type_apt')) {
    //       if ($(this).hasClass('active')) {
    //         $(this).removeClass('active');
    //       } else {
    //         $(this).addClass('active');
    //       }
    //     }
    //   });
    //
    //   clickMarker(lcParamMap.applyDtl.applyId);
    // });

    renderingAroundDataIcons(lcParamMap.applyDtl);
  }

  const renderingAroundDataIcons = (data) => {
    aroundOverlayList.forEach(ov => ov.setMap(null));
    aroundOverlayList = [];

    const dtl = data;

    dtl.schoolList.forEach(v => {
      v.type = 'school';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.departList.forEach(v => {
      v.type = 'ico_mart';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.stationList.forEach(v => {
      v.type = 'ico_subway';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.hospitalList.forEach(v => {
      v.type = 'ico_hospital';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.parkList.forEach(v => {
      v.type = 'ico_park02';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });
  }

  const onPanTo = (paramLat, paramLng) => {
    let moveLatLng = new kakao.maps.LatLng(paramLat, paramLng); // eslint-disable-line
    map.panTo(moveLatLng);
  }



  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  const backToApplyList = () => {
    setLcUrlItem('applyDtlMap', 'applyList');
    popupClose('map', 'mwf');
    setTimeout(() => {
      $('.bg_body_full').remove();
      uiFixValue.init();
    }, 100);

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?APY-1000#`
    });

    props.onApplyMenuType('A');
    props.onApplyPopupType('');
  }

  const backToApplyDtl = () => {
    setLcUrlItem('applyDtlMap', 'applyDtl');
    popupClose('map', 'mwf');
    props.onApplyPopupType('P_A');
  }

  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  const backToAptMap = () => {
    $('.map_wrap').show();
    setLcUrlItem('aptDtl', 'aptMap');
    popupClose('aptdetail', 'mwf');
    props.onApplyPopupType('');

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: ``
    });
  }

  return (
    <>
      <div id="mw-map" className="ly_pop ly_apt_detail full">
        <div className="wrap">
          <div className="content">
            <header className="header">
              <button type="button" onClick={() => ((props.aptMainEntry) ? backToAptMap() : backToApplyList())} className="btn_home" title="홈으로 바로가기"><i></i></button>
              <button type="button" onClick={() => backToApplyDtl()} className="btn_prev" title="뒤로가기"><i></i></button>
              <strong className="tit">{lcParamMap.applyDtl.aptName}</strong>
            </header>
            <div className="ly_content">
              <div className="map_con" id="mapviewApply">

                {/* <div className="map_marker">
                  <div className="marker_inner type_schedule">
                    <div className="data">
                      <p className="schedule">청약예정</p>
                      <p className="day">2020. 01</p>
                    </div>
                    <div className="marker_shadow"></div>
                  </div>
                </div> */}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplyDtlMap;