import React, { Component } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import RMateColumnMSType from 'routes/common/rMateChart/RMateColumnMSType';
import { uiFixValue, uiSelFocus, uiInputDel, uiIpFocus, chartCircle } from 'routes/common/Design.js'
import $ from 'jquery';

import { setLcUrlItem, getLcUrlItem, setLcParamItem, getLcParamItem } from 'routes/common/ParamStorage';
import {useHistory, useLocation} from "react-router-dom";

//// 컴포넌트 사용처 : 청약 당첨 예상 상세
const AnaylyzePoint = ({ calcResult, onApplyMenuType }) => {

  const lcUrlMap = getLcUrlItem();
  const lcParamMap = getLcParamItem();

  // url 구분 작업
  const history = useHistory();
  const location = useLocation();

  const { score, scoreCheckFlag } = calcResult;

  let scoreData = [0, 0, 0];

  if(score) {
    scoreData = [Number(score.houseScore), Number(score.familyScore), Number(score.accountScore)];
  }

  const options = {
    chart: {
      type: 'column',
      height: 250
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: [
        '무주택',
        '부양가족',
        '청약통장'
      ],
      labels: {
        useHTML: true
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: ''
      },
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true
        },
        groupPadding: 0.4
      }
    },
    series: [{
      color: 'rgb(225, 225, 225)',
      pointPadding: 0.2,
      name: '가점 최고점',
      data: [32, 35, 17],
      dataLabels: {
        color: 'gray'
      }

    }, {
      color: 'rgb(255, 73, 59)',
      name: '내 가점 점수',
      pointPadding: 0.2,
      data: scoreData,
      dataLabels: {
        color: 'rgb(255, 73, 59)'
      }
    }]
  }


  //--'open' : 현 화면이 다음 화면으로 변경되는 action
  const openApplyCalc = () => {
    setLcUrlItem('applyPredictDtl', 'applyCalc');
    setLcParamItem({ 'applyInfo': calcResult.applyInfo });
    setTimeout(() => {
      $('.bg_body_full').remove();
      uiFixValue.init();
    }, 100);
    onApplyMenuType('C');

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `PAL-1000#`
    })
  }

  const scoreType = (
    (score.total < 26) ? 'bad' :
      (score.total >= 26 && score.total < 43) ? 'normal' :
        (score.total >= 43 && score.total < 68) ? 'good' :
          (score.total >= 68) ? 'best' : '');

  let graphList;

  if (score) {
    graphList = [
      { "xAxis": "무주택", "yAxis1": 32, "yAxis2": Number(score.houseScore) },
      { "xAxis": "부양가족", "yAxis1": 35, "yAxis2": Number(score.familyScore) },
      { "xAxis": "청약통장", "yAxis1": 17, "yAxis2": Number(score.accountScore) }
    ];
  }
  else {
    graphList = [
      { "xAxis": "무주택", "yAxis1": 32, "yAxis2": 0 },
      { "xAxis": "부양가족", "yAxis1": 35, "yAxis2": 0 },
      { "xAxis": "청약통장", "yAxis1": 17, "yAxis2": 0 }
    ];
  }

  return (
    <div className={`ty_graph ani ${scoreType}`} id="ani4">
      <div className="graph">
        <div className="dount_area">
          <div className="circle_chart" data-percent={score.total} data-permax="84">
            <div className="chart-box">
              <svg>
                <defs>
                  <linearGradient id={`dount_${scoreType}`} x1="0" y1="0" x2="1" y2="1">
                    <stop className="color_1" offset="0%" />
                    <stop className="color_2" offset="100%" />
                  </linearGradient>
                </defs>
                <circle stroke={`url(#dount_${scoreType})`} r="50%" cx="50%" cy="50%" className="circle_progress" stroke-width="18" stroke-linecap="round"></circle>
                <filter id="shadow">
                  <feDropShadow dx="0" dy="0" stdDeviation="2"
                    flood-color="#CDCDCD" />
                </filter>
                <circle className="marker" r="11" cx="50%" cy="107" style={{ 'filter': 'url(#shadow)' }}></circle>
              </svg>
              <div className="per-num"><span>{score.total}</span>/<s>84</s></div>
            </div>
          </div>
        </div>
        <div className="btn_inner">
          <button type="button" className="btn_line GTM-PER-2" onClick={() => openApplyCalc()}>가점 다시 계산하기</button>
        </div>
      </div>
      {
        scoreCheckFlag === true &&
        //   <div className="caption emoji">
        //     <p>
        //       고객님은 총점 <em>84점 중 {84 - score.total}점이 부족</em>하네요.  무주택, 부양가족, 청약통장 모든 부분이 부족해요.<br />
        //       혹시나 잘못 기입한 부분이 있나 확인해보시거나 추첨을 노려보시는 건 어떨까요?
        // </p>
        //   </div>
        //:
        <div className="caption emoji error">
          <p><em>본인의 청약가점을 정확하게 입력하셨나요?</em><br />매년 1만명 이상이 본인의 청약가점을 잘못 입력하여 청약 당첨이 취소! 부적격자로 당첨 취소 시 1년간 청약신청 불가!</p>
        </div>
      }
      {/* <div id="rMateDiv0" className="chart my_point" style={{ "width": "100%", "height": "100%" }}> */}
      <div className="chart my_point">
        {
          scoreCheckFlag === true &&
          <div className="before_wrap" style={{ "z-index": "1" }}>
            <div className="inner_wrap">
              <p className="txt">청약 신청 전<br />내 가점을 정확하게 계산하세요!</p>
              <button type="button" className="btn" onClick={() => openApplyCalc()}>청약 가점 계산하기</button>
            </div>
          </div>
        }
        <div id="rMateDiv0" style={{ "height": "300px" }}>
          {
            //onRenderGraph &&
            < RMateColumnMSType
              parentDivId={'rMateDiv0'}
              graphList={graphList}
            ></RMateColumnMSType>
          }
        </div>
      </div>
    </div >
  );

}

export default AnaylyzePoint;