import React, {useCallback, useEffect, useRef} from 'react';
import {useStateWithCallbackLazy} from 'use-state-with-callback';
import _ from 'lodash';

import axiosRequest from 'routes/common/axios/services/AxiosRequest';

// import useMore from '../hooks/useMore';

const AvgApplyScoreList = () => {
  // const {csList: csList, csRef: csRef, initHandler: initHandler, moreHandler: moreHandler} = useMore({key: 'avgApplyScoreList', http: '/apt/apply/predict/findAvgApplyScoreList'});
  const [list, setList] = useStateWithCallbackLazy([]);
  const [csList, setCsList] = useStateWithCallbackLazy([]);
  const [csListIdx, setCsListIdx] = useStateWithCallbackLazy(5);
  const [isLoading, setIsLoading] = useStateWithCallbackLazy(false); // 혹시 데이터 불러오는데 느리면 활용해야함.
  
  const csRef = useRef();

  const moreHandler = useCallback(() => {
    const limit = 5;
    
    setCsListIdx(csListIdx + limit);
    
    if (list.length < csListIdx + limit) {
      // data 최대 길이를 넘었을 때
      for (let i = csListIdx; i < list.length; i++) {
        setCsList((prevState) => [...prevState, list[i]]);
      }

      csRef.current.classList.add('scroll_start');
      // csRef.current.classList.remove('scroll_start');
      // csRef.current.classList.add('scroll_end');
    } else {
      // default
      for (let i = csListIdx; i < csListIdx + limit; i++) {
        setCsList((prevState) => [...prevState, list[i]]);
      }

      csRef.current.classList.add('scroll_start');
    }

    setTimeout(() => {
      // 클릭할 때마다 스크롤 맨 아래로...
      csRef.current.scroll({
        behavior: 'smooth',
        top: csRef.current.scrollHeight,
      });
    }, 50);
  }, [csListIdx, list]);

  useEffect(async () => {
    // initHandler();

    try {
      const { status, data } = await axiosRequest.findAvgApplyScoreList();

      switch (status) {
        case 200: {
          let objList = data.response.avgApplyScoreList;

          if (objList.length > 0) {
            objList = _.chain(objList).filter(function(obj) {
              const excludeList = [
                {'name1': '구리시', 'name2': '수택동'}
                , {'name1': '평택시', 'name2': '통복동'}
                , {'name1': '경산시', 'name2': '중방동'}
                , {'name1': '부천시', 'name2': '심곡본동'}
                , {'name1': '부천시', 'name2': '역곡동'}
                , {'name1': '의정부시', 'name2': '신곡동'}
                , {'name1': '김해시', 'name2': '구산동'}
                , {'name1': '평택시', 'name2': '서정동'}
                , {'name1': '경산시', 'name2': '중산동'}
                , {'name1': '군포시', 'name2': '당동'}
                , {'name1': '과천시', 'name2': '과천시'}
                , {'name1': '시흥시', 'name2': '시흥시'}
                , {'name1': '화성시', 'name2': '화성시'}
                , {'name1': '하남시', 'name2': '하남시'}
              ];
  
              const compareObj = {'name1': obj.bjdNameDep1, 'name2': obj.bjdNameDep2};
  
              return !_.some(excludeList, compareObj);
            }).map(function(obj) {
              return {...obj, sumAvg: (obj.subwayScoreAvg + obj.departmentScoreAvg + obj.elementarySchoolScoreAvg + obj.middleSchoolScoreAvg + obj.parkScoreAvg + obj.woodsScoreAvg + obj.mcdonaldScoreAvg + obj.starbucksScoreAvg + obj.hospitalScoreAvg)};
            }).orderBy(['sumAvg'], ['desc']).map(function(obj, index) {
              return {...obj, rank: (index + 1)};
            }).value();
  
            setList(objList);
  
            // 초기 데이터가 6개가 안되면...
            if (objList.length < 6) {
              for (let i = 0; i < objList.length; i++) {
                setCsList((prevState) => [...prevState, objList[i]]);
              }
              csRef.current.classList.add('scroll_end');
            } else {
              for (let i = 0; i < 5; i++) {
                setCsList((prevState) => [...prevState, objList[i]]);
              }
            }
          }

          break;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      {/* 입지 정보 랭킹 */}
      <section className="map_section last" data-scroll-section="section4">
        <h3 className="content_title"><img src={process.env.REACT_APP_ENV_URL + "resources/images/icon_sub_rank.png"} alt="랭킹" aria-hidden="true" />입지 정보 랭킹</h3>
        <p className="lg"><strong>1순위 {csList.slice(0, 1).map(data => {return data.bjdNameDep2})}</strong> 주변 상권 정보</p>
        <div className="cs_tbl">
          <div className="cs_tbl_header">
            <strong>순위</strong>
            <strong>위치</strong>
            <strong>점수</strong>
          </div>
          {/* .scroll_end가 붙으면 하얀 딤, 화살표가 사라짐. */}
          <div className="cs_tbl_body">
            <ol ref={csRef}>
              {csList.map(data => {
                return (
                  <li key={data.applyId}>
                    <span title="순위">{data.rank}</span>
                    <span title="위치">{data.bjdNameDep1 + ' ' + data.bjdNameDep2}</span>
                    <span title="점수">{data.sumAvg + '점'}</span>
                  </li>
                )
              })}
            </ol>
            {/* 2022.08.05 - GTM CLASS 추가 */}
            <button type="button" className="cs_tbl_more GTM-MORE-APPLYSCORE-MORE" onClick={moreHandler}><span className="blind">더보기</span></button>
          </div>
        </div>
        <p className="text_mark"><span>※</span>입지 정보의 경우 2020년도 이후 주택청약 공고의 평균치이며 부동산 효율을 위한 참고자료를 제공하는 것입니다. 최종 투자의 모든 책임은 투자자에게 있습니다.</p>
      </section>
    </>
  );
}

export default AvgApplyScoreList;