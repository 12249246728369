import axiosCall from 'routes/common/axios/AxiosCall';
import { AXIOS_AUTH_RESULT } from 'routes/common/axios/code/AxiosCode';

const check = async (obj) => {
    const defaultObj = {
        config: { headers: { 'Authorization': ('Bearer ' + localStorage.getItem('jwtToken')) }, timeout: 1000 }
    };

    try {
        const { status, data, headers } = await axiosCall.check(((obj || {}).config || defaultObj.config));

        return Promise.resolve({ status, data, headers, isSuccess: true });
    } catch (err) {
        const { status, data = null } = err.response;

        return Promise.reject({ status, data, isSuccess: false });
    }
}

const reIssue = async (obj) => {
    const defaultObj = {
        config: { headers: { 'Authorization': ('Bearer ' + localStorage.getItem('jwtToken')) } },
        data: { 'ref': localStorage.getItem('ref'), 'moLogin': true },
    };

    try {
        const { status, data, headers } = await axiosCall.reIssue(((obj || {}).data || defaultObj.data), ((obj || {}).config || defaultObj.config));

        return Promise.resolve({ status, data, headers, isSuccess: true });
    } catch (err) {
        const { status, data = null } = err.response;

        return Promise.reject({ status, data, isSuccess: false });
    }
}

const verify = async (obj) => {
    let resVerify = { 'type': '' };

    try {
        const checkRes = await check(obj);

        if (checkRes.data.response.isValid) {
            resVerify = { ...resVerify, 'type': AXIOS_AUTH_RESULT.CHECK_SUCCESS };

            if (checkRes.data.response.pushData)    resVerify = { ...resVerify, 'pushData': checkRes.data.response.pushData };
            if (checkRes.headers.maskingphoneno)    resVerify = { ...resVerify, 'maskingPhoneNo': checkRes.headers.maskingphoneno };
            if (checkRes.data.response.email)    resVerify = { ...resVerify, 'email': checkRes.data.response.email };
        } else {
            resVerify = { ...resVerify, 'type': AXIOS_AUTH_RESULT.CHECK_FAIL };
        }

        return Promise.resolve({ resVerify, isSuccess: true });
    } catch (err) {
        /** 자동로그인 적용시 사용 */
        if (localStorage.getItem('isAutoLogin') === 'true') {
            switch (err.status) {
                case 401: case 403: {
                    try {
                        const reIssueRes = await reIssue(obj);

                        resVerify = { ...resVerify, 'type': AXIOS_AUTH_RESULT.RE_ISSUE_SUCCESS, 'authorization': reIssueRes.headers.authorization };

                        return Promise.resolve({ resVerify, isSuccess: true });
                    } catch (err) {
                        resVerify = { ...resVerify, 'type': AXIOS_AUTH_RESULT.RE_ISSUE_FAIL };

                        return Promise.resolve({ resVerify, isSuccess: false });
                    }
                }
                default : {
                    resVerify = { ...resVerify, 'type': AXIOS_AUTH_RESULT.CHECK_FAIL };
                }
            }
        } else {
            resVerify = { ...resVerify, 'type': AXIOS_AUTH_RESULT.CHECK_FAIL };
        }

        return Promise.resolve({ resVerify, isSuccess: false });
    }
}

export default {
    check,
    reIssue,
    verify,
}