import React, {useEffect, useState} from "react";
import {CouponItemUsed} from "../../entities/CouponItemUsed";
import {CouponItemStandby} from "../../entities/CouponItemStandby";
import {CouponItemUsable} from "../../entities/CouponItemUsable";
import {COUPON} from "../../../../consts/coupon";
import {useCouponStore} from "../../../../store/couponStore";
import moment from "moment";
import _ from "lodash";
import {useCoupon} from "../../../../hooks/useCoupon";

/**
 * @desc 회원 쿠폰 메뉴 내부 위젯 (쿠폰 다운로드 이후)
 */
export const SelectedCoupons = () => {
    const {coupon, couponItem} = useCouponStore();
    const {couponItemAlert} = useCoupon();
    const [totalPrice, setTotalPrice] = useState('3,000');
    const [couponListState, setCouponListState] = useState(<></>);
    const {getCoupon} = useCoupon();

    const useEndDttm = moment(coupon.couponManage.useEndDttm).tz('Asia/Seoul');
    const now = moment.now();
    // 원장 만료일까지 남은 일수
    const useDiffDate = moment.duration(useEndDttm.diff(now)).asDays();

    // 직전 쿠폰이 전일에 사용 되었는가? -> 당일쿠폰 발급 가능
    useEffect(() => {

        // 발급받은 쿠폰이 없는 경우
        const hasNoCoupon = () => {

            /**
             * 발급받은 쿠폰 없음
             * 원장의 만료일 까지 2일 이상 남음
             */
            if (useDiffDate >= 2) {
                setTotalPrice('3,000');

                return (
                        <>
                            <CouponItemUsable/>
                            <CouponItemStandby willUsable={false} couponAlert={couponItemAlert.exist}/>
                            <CouponItemStandby willUsable={false} couponAlert={couponItemAlert.exist}/>
                        </>
                );
            }

            /**
             * 발급받은 쿠폰 없음
             * 원장의 만료일 까지 1일 이상 남음
             */
            if (useDiffDate >= 1) {
                setTotalPrice('2,000');

                return (
                        <>
                            <CouponItemUsable/>
                            <CouponItemStandby willUsable={false} couponAlert={couponItemAlert.exist}/>
                            <CouponItemUsed isExpired={true} couponAlert={couponItemAlert.expired}/>
                        </>
                );
            }

            /**
             * 발급받은 쿠폰 없음
             * 원장의 만료 까지 1일 미만(하루) 남음 - 만료일 지나면 원장 재발급
             */
            if (useDiffDate < 1) {
                setTotalPrice('1,000');

                return (
                        <>
                            <CouponItemUsable/>
                            <CouponItemUsed isExpired={true} couponAlert={couponItemAlert.expired}/>
                            <CouponItemUsed isExpired={true} couponAlert={couponItemAlert.expired}/>
                        </>
                );
            }

        };

        // 발급받은 쿠폰이 없거나 하나인 경우
        const hasOneCoupon = (couponList) => {
            const cpnUseYn = couponList[0].cpnUseYn;
            const cpnRedeemDttm = moment(couponList[0].cpnRedeemDttm).tz('Asia/Seoul').toDate();
            const midnight = new Date(cpnRedeemDttm.getFullYear(), cpnRedeemDttm.getMonth(), cpnRedeemDttm.getDate() + 1, 0, 0, 0);

            /**
             * 발급받은 쿠폰이 하나, 쿠폰 미사용
             * 원장의 만료일 까지 2일 이상 남음
             */
            if (cpnUseYn === 'N' && useDiffDate >= 2) {
                setTotalPrice('3,000');

                return (
                        <>
                            <CouponItemUsable/>
                            <CouponItemStandby willUsable={false} couponAlert={couponItemAlert.exist}/>
                            <CouponItemStandby willUsable={false} couponAlert={couponItemAlert.exist}/>
                        </>
                );
            }

            /**
             * 발급받은 쿠폰이 하나, 쿠폰 미사용
             * 원장의 만료일 까지 1일 이상 남음
             */
            if (cpnUseYn === 'N' && useDiffDate >= 1) {
                setTotalPrice('2,000');

                return (
                        <>
                            <CouponItemUsable/>
                            <CouponItemStandby willUsable={false} couponAlert={couponItemAlert.exist}/>
                            <CouponItemUsed isExpired={true} couponAlert={couponItemAlert.expired}/>
                        </>
                );
            }

            /**
             * 발급받은 쿠폰이 하나, 쿠폰 미사용
             * 원장의 만료 까지 1일 미만(하루) 남음 - 만료일 지나면 원장 재발급
             */
            if (cpnUseYn === 'N' && useDiffDate < 1) {
                setTotalPrice('1,000');

                return (
                        <>
                            <CouponItemUsable/>
                            <CouponItemUsed isExpired={true} couponAlert={couponItemAlert.expired}/>
                            <CouponItemUsed isExpired={true} couponAlert={couponItemAlert.expired}/>
                        </>
                );
            }

            /**
             * 발급받은 쿠폰이 하나, 쿠폰 사용
             * 원장의 만료일 까지 2일 이상 남음
             */
            if ((cpnUseYn === 'Y' || cpnUseYn === 'C') && useDiffDate >= 2) {

                setTotalPrice('2,000');
                // 마지막 쿠폰 사용일로부터 자정이 지남
                if (Date.now() > midnight) {
                    return (
                            <>
                                <CouponItemUsed couponAlert={couponItemAlert.used}/>
                                <CouponItemUsable/>
                                <CouponItemStandby willUsable={false} couponAlert={couponItemAlert.exist}/>
                            </>
                    );
                } else {
                    return (
                            <>
                                <CouponItemUsed couponAlert={couponItemAlert.used}/>
                                <CouponItemStandby willUsable={true} couponAlert={couponItemAlert.wait}/>
                                <CouponItemStandby willUsable={false} couponAlert={couponItemAlert.exist}/>
                            </>
                    );
                }
            }

            /**
             * 발급받은 쿠폰이 하나, 쿠폰 사용
             * 원장의 만료일 까지 1일 이상 남음
             */
            if ((cpnUseYn === 'Y' || cpnUseYn === 'C') && useDiffDate >= 1) {
                setTotalPrice('1,000');

                if (Date.now() > midnight) {
                    return (
                            <>
                                <CouponItemUsed couponAlert={couponItemAlert.used}/>
                                <CouponItemUsable/>
                                <CouponItemUsed isExpired={true} couponAlert={couponItemAlert.expired}/>
                            </>
                    );
                } else {
                    return (
                            <>
                                <CouponItemUsed couponAlert={couponItemAlert.used}/>
                                <CouponItemStandby willUsable={true} couponAlert={couponItemAlert.wait}/>
                                <CouponItemUsed isExpired={true} couponAlert={couponItemAlert.expired}/>
                            </>
                    );
                }
            }

            /**
             * 발급받은 쿠폰이 하나, 쿠폰 사용
             * 원장의 만료 까지 1일 미만(하루) 남음 - 만료일 지나면 원장 재발급
             */
            if ((cpnUseYn === 'Y' || cpnUseYn === 'C') && useDiffDate < 1) {

                if (Date.now() > midnight) {
                    setTotalPrice('1,000');

                    return (
                            <>
                                <CouponItemUsed couponAlert={couponItemAlert.used}/>
                                <CouponItemUsable/>
                                <CouponItemUsed isExpired={true} couponAlert={couponItemAlert.expired}/>
                            </>
                    );
                }else {
                    setTotalPrice('0');

                    return (
                            <>
                                <CouponItemUsed couponAlert={couponItemAlert.allUsed}/>
                                <CouponItemUsed isExpired={true} couponAlert={couponItemAlert.allUsed}/>
                                <CouponItemUsed isExpired={true} couponAlert={couponItemAlert.allUsed}/>
                            </>
                    );
                }
            }

        };

        // 발급받은 쿠폰이 두개인 경우
        const hasTwoCoupon = (couponList) => {
            const cpnUseYn = couponList[1].cpnUseYn;
            const cpnRedeemDttm = moment(couponList[1].cpnRedeemDttm).tz('Asia/Seoul').toDate();
            const midnight = new Date(cpnRedeemDttm.getFullYear(), cpnRedeemDttm.getMonth(), cpnRedeemDttm.getDate() + 1, 0, 0, 0);

            /**
             * 발급받은 쿠폰이 둘, 쿠폰 미사용
             * 원장의 만료일 까지 2일 이상 남음
             */
            if (cpnUseYn === 'N' && useDiffDate >= 2) {
                setTotalPrice('2,000');

                return (
                        <>
                            <CouponItemUsed couponAlert={couponItemAlert.used}/>
                            <CouponItemUsable/>
                            <CouponItemStandby willUsable={false} couponAlert={couponItemAlert.exist}/>
                        </>
                );
            }

            /**
             * 발급받은 쿠폰이 둘, 쿠폰 미사용
             * 원장의 만료일 까지 1일 이상 남음
             */
            if (cpnUseYn === 'N' && useDiffDate >= 1) {
                setTotalPrice('1,000');

                return (
                        <>
                            <CouponItemUsed couponAlert={couponItemAlert.used}/>
                            <CouponItemUsable/>
                            <CouponItemUsed isExpired={true} couponAlert={couponItemAlert.expired}/>
                        </>
                );
            }

            /**
             * 발급받은 쿠폰이 둘, 쿠폰 미사용
             * 원장의 만료 까지 1일 미만(하루) 남음 - 만료일 지나면 원장 재발급
             */
            if (cpnUseYn === 'N' && useDiffDate < 1) {
                setTotalPrice('1,000');

                return (
                        <>
                            <CouponItemUsed couponAlert={couponItemAlert.used}/>
                            <CouponItemUsable/>
                            <CouponItemUsed isExpired={true} couponAlert={couponItemAlert.expired}/>
                        </>
                );
            }

            /**
             * 발급받은 쿠폰이 둘, 쿠폰 사용
             * 원장의 만료일 까지 2일 이상 남음
             */
            if ((cpnUseYn === 'Y' || cpnUseYn === 'C') && useDiffDate >= 2) {
                setTotalPrice('1,000');

                if (Date.now() > midnight) {
                    return (
                            <>
                                <CouponItemUsed couponAlert={couponItemAlert.used}/>
                                <CouponItemUsed couponAlert={couponItemAlert.used}/>
                                <CouponItemUsable/>
                            </>
                    );
                }else {
                    return (
                            <>
                                <CouponItemUsed couponAlert={couponItemAlert.used}/>
                                <CouponItemUsed couponAlert={couponItemAlert.used}/>
                                <CouponItemStandby willUsable={true} couponAlert={couponItemAlert.wait}/>
                            </>
                    );
                }
            }

            /**
             * 발급받은 쿠폰이 둘, 쿠폰 사용
             * 원장의 만료일 까지 1일 이상 남음
             */
            if ((cpnUseYn === 'Y' || cpnUseYn === 'C') && useDiffDate >= 1) {
                if (Date.now() > midnight) {
                    setTotalPrice('1,000');

                    return (
                            <>
                                <CouponItemUsed couponAlert={couponItemAlert.used}/>
                                <CouponItemUsed couponAlert={couponItemAlert.used}/>
                                <CouponItemUsable/>
                            </>
                    );
                }else {
                    setTotalPrice('0');

                    return (
                            <>
                                <CouponItemUsed couponAlert={couponItemAlert.allUsed}/>
                                <CouponItemUsed couponAlert={couponItemAlert.allUsed}/>
                                <CouponItemUsed isExpired={true} couponAlert={couponItemAlert.allUsed}/>
                            </>
                    );
                }
            }

            /**
             * 발급받은 쿠폰이 둘, 쿠폰 사용
             * 원장의 만료 까지 1일 미만(하루) 남음 - 만료일 지나면 원장 재발급
             */
            if ((cpnUseYn === 'Y' || cpnUseYn === 'C') && useDiffDate < 1) {

                if (Date.now() > midnight) {
                    setTotalPrice('1,000');

                    return (
                            <>
                                <CouponItemUsed couponAlert={couponItemAlert.used}/>
                                <CouponItemUsed couponAlert={couponItemAlert.used}/>
                                <CouponItemUsable/>
                            </>
                    );
                }else {
                    setTotalPrice('0');

                    return (
                            <>
                                <CouponItemUsed couponAlert={couponItemAlert.allUsed}/>
                                <CouponItemUsed couponAlert={couponItemAlert.allUsed}/>
                                <CouponItemUsed isExpired={true} couponAlert={couponItemAlert.allUsed}/>
                            </>
                    );
                }
            }
        }

        // 발급받은 쿠폰이 세개인 경우
        const hasThreeCoupon = (couponList) => {
            const cpnUseYn = couponList[2].cpnUseYn;

            /**
             * 발급받은 쿠폰이 셋, 쿠폰 미사용
             */
            if (cpnUseYn === 'N') {
                setTotalPrice('1,000');

                return (
                        <>
                            <CouponItemUsed couponAlert={couponItemAlert.used}/>
                            <CouponItemUsed couponAlert={couponItemAlert.used}/>
                            <CouponItemUsable/>
                        </>
                );
            }

            /**
             * 발급받은 쿠폰이 셋, 쿠폰 사용
             */
            if ((cpnUseYn === 'Y' || cpnUseYn === 'C')) {
                setTotalPrice('0');

                return (
                        <>
                            <CouponItemUsed couponAlert={couponItemAlert.allUsed}/>
                            <CouponItemUsed couponAlert={couponItemAlert.allUsed}/>
                            <CouponItemUsed couponAlert={couponItemAlert.allUsed}/>
                        </>
                );
            }

        };

        function getCouponList() {
            const couponList = coupon.couponList;

            if (_.isEmpty(couponList)) return hasNoCoupon();
            if (couponList.length === 1)  return hasOneCoupon(couponList);
            if (couponList.length === 2)  return hasTwoCoupon(couponList);
            if (couponList.length === 3)  return hasThreeCoupon(couponList);
            return hasThreeCoupon(couponList);
        }

        setCouponListState(getCouponList());
        // return () => {
        //     setCoupon(null);
        // };
    }, [coupon, couponItem]);

    useEffect(() => {
        getCoupon();
    }, []);

    return (
            <>
                {
                    !coupon
                            ? <></>
                            : <>
                                <p className="coupon-desc-01">쿠폰을 다운받아서 사용하세요.</p>
                                <div className="coupon-list-box">
                                    <div className="coupon-list-header">
                                        <img src={COUPON[coupon.key].logo} alt="" className="img-logo"/>
                                        <div className="coupon-text-box-01">
                                            {
                                                totalPrice.replaceAll(',', '') > 0
                                                        ?
                                                        <>
                                                            <span className="coupon-text-02">{totalPrice}</span>
                                                            <span className="coupon-text-03">원</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span className="coupon-text-01">사용완료</span>
                                                        </>
                                            }
                                        </div>
                                    </div>
                                    <ul className="coupon-list">
                                        {couponListState}
                                    </ul>
                                </div>
                            </>
                }
            </>
    );
};