import React, { Component } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import RMateCandleStickType from 'routes/common/rMateChart/RMateCandleStickType';
import Moment from 'moment';
import { popupAlert04 } from 'routes/predict/PopupNotice';
import { numberWithCommas } from 'routes/common/StringUtil';
import RMateChartH5 from "../../common/rMateChart/RMateChartH5";

//// 컴포넌트 사용처 : 청약 당첨 예상 상세
const PrevGeCnt = ({ calcResult, onRenderGraph }) => {
  const { bjdInfo, prevGeCnt } = calcResult;

  return (
    <div class="con ani" id="ani11">
      <div class="tit_wrap">
        <span class="tit">과거 동일 청약 지역 <strong>신청 및 당첨 건수</strong></span>
        <button type="button" class="btn_tip GTM-PER-TIP-7" onClick={popupAlert04}>과거 동일 청약 지역 신청 및 당첨 건수 란?</button>
        <span class="info">* 최근 1년 기준</span>
      </div>
      <div class="caption_wrap">
        <div class="caption list">
          <ul>
            <li class="tit">{bjdInfo.bjdName} 청약 지역</li>
            <li><span>지역</span>{bjdInfo.bjdName}</li>
            <li><span>형태</span>일반공급</li>
            <li><span>순위</span>1순위</li>
          </ul>
        </div>
      </div>
      <div class="chart">
        <div id="rMateDiv4">
          {
            // onRenderGraph && <RMateCandleStickType parentDivId={'rMateDiv4'} graphList={graphList} />
            onRenderGraph && <RMateChartH5 chartId={'rMateDiv4'} layout={'COMBINATION_2D_CHART_2'} data={prevGeCnt} chartVars={''} width={'100%'} height={'300px'} />
          }
        </div>
      </div>
    </div>
  );
}

export default PrevGeCnt;
