import Moment from 'moment';

import { setLcUrlItem, getLcUrlItem, setLcParamItem, getLcParamItem } from 'routes/common/ParamStorage';
import {useHistory, useLocation} from "react-router-dom";

//// 컴포넌트 사용처 : 청약 당첨 예상 상세
const ExptScore = ({ calcResult, onApplyMenuType, onApplyPopupType }) => {
  // url 구분 작업
  const history = useHistory();
  const location = useLocation();

  const { applyInfo, applyCalendar, exptScore, score } = calcResult;
  const min10Percent = (exptScore != null ?
    Math.round(exptScore.exptMinScore / 10) :
    0);

    const { scoreType, percent } = (() => {
      let obj = {};

      if (exptScore) {
        if (exptScore.exptMinScore) {
          if (score.total <= (exptScore.exptMinScore - min10Percent)) {
            obj = { scoreType: 'bad', percent: Math.round((score.total * 25) / (exptScore.exptMinScore - 0)) };
          } else {
            if (score.total <= exptScore.exptMinScore) {
              obj = { scoreType: 'normal', percent: 25 + Math.round(((score.total - (exptScore.exptMinScore - min10Percent)) * 25) / min10Percent) };
            } else {
              if (score.total < exptScore.exptMinScore + min10Percent) {
                obj = { scoreType: 'good', percent: 50 + Math.round(((score.total - exptScore.exptMinScore) * 25) / min10Percent) };
              } else {
                obj = { scoreType: 'best', percent: 75 + Math.round(((score.total - (exptScore.exptMinScore + min10Percent)) * 25) / (84 - exptScore.exptMinScore + min10Percent)) };
              }
            }
          }
        } else {
          obj = { scoreType: 'shortfall', percent: 0 };
        }
      } else {
        obj = { scoreType: 'shortfall', percent: 0 };
      }

      return obj;
    })();

  // const { scoreType, percent } =
  //   (exptScore != null ? (
  //     score.total <= exptScore.exptMinScore - min10Percent ?
  //       { scoreType: 'bad', percent: Math.round((score.total * 25) / (exptScore.exptMinScore - 0)) }
  //       : score.total <= exptScore.exptMinScore ? { scoreType: 'normal', percent: 25 + Math.round(((score.total - (exptScore.exptMinScore - min10Percent)) * 25) / min10Percent) }
  //         : score.total < exptScore.exptMinScore + min10Percent ? { scoreType: 'good', percent: 50 + Math.round(((score.total - exptScore.exptMinScore) * 25) / min10Percent) }
  //           : { scoreType: 'best', percent: 75 + Math.round(((score.total - (exptScore.exptMinScore + min10Percent)) * 25) / (84 - exptScore.exptMinScore + min10Percent)) }
  //   ) : { scoreType: 'bad', percent: 0 });

  //console.log({ scoreType, percent });

  const firstDate = Math.floor(Moment.duration(Moment().diff(Moment(applyCalendar.fstLocalDate, 'YYYYMMDD'))).asDays());

  const getCatption = {
    'bad': <p>고객님은 해당 아파트 청약 신청 시 <em>당첨 될 가능성이 희박해요.</em><br /></p>
    , 'normal': <p>고객님은 해당 아파트 청약 신청 시 <em>당첨 될 가능성이 낮아요.</em><br />추첨을 노려보시는건 어떨까요?</p>
    , 'good': <p>고객님은 해당 아파트 청약 신청 시 <em>당첨 안정권으로</em><br />청약 신청해보시는 것을 조심스레 추천드려요.</p>
    , 'best': <p>고객님은 해당 아파트 청약 신청 시 <em>당첨 될 가능성이 아주 높아요.</em><br />무조건 신청하시는 것을 추천드려요.</p>
    , 'shortfall': <p>고객님은 해당 아파트 청약 신청 시 <em>당첨 안정권이에요.</em><br />단, 해당 지역 내 미달 공고가 발생하고 있으니 신중히 고민하시고 신청해주세요!</p>
  };

  //--'open' : 현 화면이 다음 화면으로 변경되는 action
  const openApplyDtl = () => {
    setLcUrlItem('applyPredictDtl', 'applyDtl');
    setLcParamItem({ 'applyId': applyInfo.applyId });
    onApplyPopupType('P_A');
    //props.onApplyMenuType('A');

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `APY-${applyInfo.applyId}#`
    })
  }

  return (
    <>
      <h3 className="s_tit"><strong>{applyInfo.aptName}</strong>에 당첨 될 가능성은?</h3>
      <div className={`ty_graph ani ${scoreType === 'shortfall' ? 'best' : scoreType}`} id="ani1">
        <div className="graph">
          <div className="dount_area">
            <div className="circle_chart" data-percent={percent} data-permax="100">
              <div className="chart-box">
                <svg>
                  <defs>
                    <linearGradient id={`dount_${scoreType}`} x1="0" y1="0" x2="1" y2="1">
                      <stop className="color_1" offset="0%" />
                      <stop className="color_2" offset="100%" />
                    </linearGradient>
                  </defs>
                  <circle stroke={`url(#dount_${scoreType})`} r="50%" cx="50%" cy="50%" className="circle_progress" stroke-width="18" stroke-linecap="round"></circle>
                  <filter id="shadow">
                    <feDropShadow dx="0" dy="0" stdDeviation="2" flood-color="#CDCDCD" />
                  </filter>
                  <circle className="marker" r="11" cx="50%" cy="107" style={{ 'filter': 'url(#shadow)' }}></circle>
                </svg>
                <div className="per-txt"><span>
                  {
                    scoreType === 'bad' ? '당첨희박' :
                      scoreType === 'normal' ? '당첨저조' :
                        scoreType === 'good' ? '안정권' :
                          scoreType === 'best' ? '당첨권' :
                            scoreType === 'shortfall' ? '미달예상' : ''
                  }</span></div>
              </div>
            </div>
          </div>
          <div className="btn_inner">
            <button typ1e="button" className="btn_line GTM-PER-1" onClick={() => openApplyDtl()}>청약정보 상세확인</button>
          </div>
        </div>
        <div className="caption emoji">
          {
            getCatption[scoreType]
          }
          <strong>1순위 접수일 : <code>{Moment(applyCalendar.fstLocalDate).format('YYYY. MM. DD')}</code> <span>D{firstDate > 0 ? '+' + Math.abs(firstDate) : '-' + Math.abs(firstDate)}</span></strong>
        </div>
      </div>
      <div className="progress multi ani" id="ani2">
        <div className="bg">
          <div className="point low" data-point={exptScore != null ? exptScore.exptMinScore : 0}>
            {/* <span><em><code>{exptScore != null ? exptScore.exptMinScore : 0}</code>점</em><strong>최저</strong></span> */}
            <span>
              {(exptScore) ? ((exptScore.exptMinScore) ? <><em><code>{exptScore.exptMinScore}</code>점</em><strong>최저</strong></> : <><div>미달</div><div>예상</div></>) : <><div>미달</div><div>예상</div></>}
            </span>
          </div>
          <div className="point my" data-point={score.total}>
            <span><strong>내 가점</strong><em><code>{score.total}</code>점</em></span>
          </div>
          <div className="point max" data-point={exptScore != null ? exptScore.exptMaxScore : 0}>
            {/* <span><em><code>{exptScore != null ? exptScore.exptMaxScore : 0}</code>점</em><strong>최고</strong></span> */}
            <span>
              {(exptScore) ? ((exptScore.exptMaxScore) ? <><em><code>{exptScore.exptMaxScore}</code>점</em><strong>최고</strong></> : <><div>미달</div><div>예상</div></>) : <><div>미달</div><div>예상</div></>}
            </span>
          </div>
        </div>
        <div className="progress_lbl">
          <span>0</span>
          <span>84</span>
        </div>
        <div className="progress_label">
          <span className="low">예상 최저 가점</span>
          <span className="my">내 가점 점수</span>
          <span className="max">예상 최고 가점</span>
        </div>
      </div>
    </>
  );
}

export default ExptScore;
