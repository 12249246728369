import React, {useEffect} from 'react';

import {CouponRoot} from "./layout/CouponRoot";
import {COUPON} from "../../consts/coupon";
import Barcode from "react-barcode";
import {useCouponStore} from "../../store/couponStore";
import {useCoupon} from "../../hooks/useCoupon";

/**
 * @desc 쿠폰 상세화면
 */
export const Pin = () => {
    const {couponItem, setCouponItem} = useCouponStore();
    const {expireCoupon} = useCoupon();

    async function copyPinNo() {
        const text = couponItem.pinNo.replaceAll(' ', '');
        await navigator.clipboard.writeText(text).then(() => {
            alert("복사가 완료되었습니다.");
        });
    }

    // 언마운트시 쿠폰 정보 지워주기
    useEffect(() => {
        return () => {
            setCouponItem(null);
        };
    }, []);

    return (
            <>
                {
                        couponItem &&
                        <CouponRoot couponItem={couponItem}>
                            <p className="coupon-desc-01">아래 바코드 또는<br/>번호를 사용해주세요.</p>
                            <div className="coupon-detail-box">
                                {
                                    couponItem.cpnGrpCd === "GS"
                                            ? <img src={COUPON[couponItem.key].image01} alt="" className="img-logo"
                                                   style={{height: '36px'}}/> // GS 쿠폰의 경우 높이 조절 속성이 들어감
                                            : <img src={COUPON[couponItem.key].image01} alt="" className="img-logo"/>
                                }
                                {
                                    couponItem.cpnGrpCd === "NV"
                                            ? <></>
                                            : <div className="barcode-box">
                                                <Barcode
                                                        value={couponItem.pinNo}
                                                        renderer={"img"}
                                                        width={8}
                                                        height={250}
                                                        displayValue={false}
                                                        textAlign={"left"}
                                                        fontSize={70}
                                                />
                                            </div>
                                }
                                <div className="number-box">
                                    <span className="number">{couponItem.pinNo}</span>
                                    <button type="button" className="btn-copy" onClick={copyPinNo}>복사</button>
                                </div>
                                <dl className="coupon-detail-info">
                                    <div className="coupon-detail-info-item">
                                        <dt>유효기간</dt>
                                        <dd>{couponItem.cpnEndDttm}</dd>
                                    </div>
                                    <div className="coupon-detail-info-item">
                                        <dt>사용처</dt>
                                        <dd>{couponItem.cpnGrpNm}</dd>
                                    </div>
                                </dl>
                            </div>
                        </CouponRoot>
                }
            </>
    );
};
