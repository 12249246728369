import {ButtonPrevious} from "../../../components/common/ButtonPrevious";
import {CouponNotice} from "../../../components/coupon/widgets/CouponNotice";
import {CouponGSNotice} from "../../../components/coupon/widgets/CouponGSNotice";
import {CouponNaverNotice} from "../../../components/coupon/widgets/CouponNaverNotice";
import {CouponTestNotice} from "../../../components/coupon/widgets/CouponTestNotice";

export const CouponRoot = (props) => {
    const couponItem = props.couponItem;
    const getCouponNotice = () => {
        try {
            if (couponItem.cpnGrpCd === "TE") {
                return <CouponTestNotice/>;
            }

            if (couponItem.cpnGrpCd === "NV") {
                return <CouponNaverNotice/>;
            }

            if (couponItem.cpnGrpCd === "GS") {
                return <CouponGSNotice/>
            }

            return <CouponNotice/>;
        } catch (error) {
            return <CouponNotice/>
        }
    };

    return (
            <div className="ly_apt_mypage full">
                <div className="wrap">
                    <div className="content">
                        <header className="header">
                            <ButtonPrevious/>
                        </header>
                        <div className="ly_content">
                            <div className="coupon-root">
                                {Array(props.children).map(v => v)}
                                {getCouponNotice()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};