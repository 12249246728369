import { popupOpen } from 'routes/common/Design.js'
import $ from 'jquery';

//// 컴포넌트 사용처 : 청약 당첨 예상 상세
export const popupSecInfo01 = () => {

  if ($('#mw-secInfo01').length > 0) {
    popupOpen('secInfo01', 'mwf', 0);

  } else {
    $('#pageWrap').append(`<div id="mw-secInfo01" class="ly_pop popBtm ly_alert_b">
    <div class="wrap">        
        <div class="content">
            <strong class="tit">당첨 가능성 및 예상 경쟁률이란?</strong>
            <p class="txt">고객님의 입력하신 청약가점으로, 청약을 희망하는 아파트에 당첨 될 가능성이 있는지를 안내해드려요.<br>과거 청약결과와 주변 정보들을 기반으로 청약을 희망하는 아파트의 당첨 예상 최저 가점과 최고가점을 계산해요.<br>또한, 해당 아파트에 접수 될 청약 신청 건수를 예측하여 예상경쟁률을 산출하여 안내해드려요.</p>
            <button type="button" onclick="popupClose('secInfo01','mw',0);" class="close"><i>닫기</i></button>
        </div>
    </div>
</div>`);

    popupOpen('secInfo01', 'mwf', 0);
  }
}

export const popupSecInfo02 = () => {

  if ($('#mw-secInfo02').length > 0) {
    popupOpen('secInfo02', 'mwf', 0);

  } else {
    $('#pageWrap').append(`<div id="mw-secInfo02" class="ly_pop popBtm ly_alert_b">
    <div class="wrap">        
        <div class="content">
            <strong class="tit">나의 가점 분석이란?</strong>
            <p class="txt">고객님께서 계산하시거나, 입력하신 청약가점을 분석하여 어느 가점 항목이 부족한지 안내해드려요.</p>
            <button type="button" onclick="popupClose('secInfo02','mw',0);" class="close"><i>닫기</i></button>
        </div>
    </div>
</div>`);

    popupOpen('secInfo02', 'mwf', 0);
  }
}

export const popupSecInfo03 = () => {

  if ($('#mw-secInfo03').length > 0) {
    popupOpen('secInfo03', 'mwf', 0);

  } else {
    $('#pageWrap').append(`<div id="mw-secInfo03" class="ly_pop popBtm ly_alert_b">
    <div class="wrap">        
        <div class="content">
            <strong class="tit">아파트 건설사 순위란?</strong>
            <p class="txt">2022년 대한건설협회에서 발표하는 시공능력평가순위를 기준으로, 청약아파트의 건설사 순위를 안내해드려요.</p>
            <button type="button" onclick="popupClose('secInfo03','mw',0);" class="close"><i>닫기</i></button>
        </div>
    </div>
</div>`);

    popupOpen('secInfo03', 'mwf', 0);
  }
}

export const popupSecInfo04 = () => {
  if ($('#mw-secInfo02').length) {
    popupOpen('secInfo04', 'mwf', 0);
  } else {
    $('#pageWrap').append(`<div id="mw-secInfo04" class="ly_pop popBtm ly_alert_b">
    <div class="wrap">        
        <div class="content">
            <strong class="tit">아파트 가격 적합성이란?</strong>
            <p class="txt">가격 적합성은 2020년도 주택 청약 공고 및 주위 실거래 데이터를 기반으로 산정된 정보에요. <br />또한 부동산 흐름을 예측할 수 있는 자료를 제공하는 것으로 모든 책임은 투자자 분들께 있어요!</p>
            <button type="button" onclick="popupClose('secInfo04','mw',0);" class="close"><i>닫기</i></button>
        </div>
    </div>
</div>`);

    popupOpen('secInfo04', 'mwf', 0);
  }
}

export const popupAlert01 = () => {

  if ($('#mw-alert01').length > 0) {
    popupOpen('alert01', 'mwf', 0);

  } else {
    $('#pageWrap').append(`<div id="mw-alert01" class="ly_pop popBtm ly_alert_b">
        <div class="wrap">        
            <div class="content">
                <strong class="tit">실거래가란?</strong>
                <p class="txt">청약 아파트 지역의 최근 1년간의 실거래가 발생한 아파트 정보를 안내해드려요.<br/>
                해당 정보를 바탕으로, 청약 아파트가 위치한 지역의 시세가 상승 중인지, 하락 중인지를 확인 할 수 있어요.</p>
                <button type="button" onclick="popupClose('alert01','mw',0);" class="close"><i>닫기</i></button>
            </div>
        </div>
      </div>`);

    popupOpen('alert01', 'mwf', 0);
  }
}

export const popupAlert02 = () => {

  if ($('#mw-alert02').length > 0) {
    popupOpen('alert02', 'mwf', 0);

  } else {
    $('#pageWrap').append(`<div id="mw-alert02" class="ly_pop popBtm ly_alert_b">
        <div class="wrap">        
            <div class="content">
                <strong class="tit">청약 지역 전입 · 전출 인구란?</strong>
                <p class="txt">청약아파트가 위치하는 지역의 최근 1년간 전입/전출인구를 안내해드려요.<br/>
                해당 지역의 인구흐름을 확인하여, 청약 신청에 도움을 드려요.<br/>
                (지역에 따라 시/도/군/구 기준으로 안내)</p>
                <button type="button" onclick="popupClose('alert02','mw',0);" class="close"><i>닫기</i></button>
            </div>
        </div>
      </div>`);

    popupOpen('alert02', 'mwf', 0);
  }
}

export const popupAlert03 = () => {

  if ($('#mw-alert03').length > 0) {
    popupOpen('alert03', 'mwf', 0);

  } else {
    $('#pageWrap').append(`<div id="mw-alert03" class="ly_pop popBtm ly_alert_b">
        <div class="wrap">        
            <div class="content">
                <strong class="tit">과거 청약 당첨가점이란?</strong>
                <p class="txt">청약아파트가 위치하는 지역에서 진행되었었던, 과거 청약 아파트들의 당첨가점을 안내해드려요.<br/>
                해당 지역의 청약 당첨가점이 상승중인지, 하락중인지 확인하여 청약 신청에 도움을 드려요.</p>
                <button type="button" onclick="popupClose('alert03','mw',0);" class="close"><i>닫기</i></button>
            </div>
        </div>
      </div>`);

    popupOpen('alert03', 'mwf', 0);
  }
}

export const popupAlert04 = () => {

  if ($('#mw-alert04').length > 0) {
    popupOpen('alert04', 'mwf', 0);

  } else {
    $('#pageWrap').append(`<div id="mw-alert04" class="ly_pop popBtm ly_alert_b">
        <div class="wrap">        
            <div class="content">
                <strong class="tit">과거 동일 청약 지역 신청 및 당첨 건수란?</strong>
                <p class="txt">청약아파트가 위치하는 지역에서 진행되었었던, 과거 청약 아파트들의 신청 및 당첨건수를 안내해드려요.<br/>
                신청자수 및 당첨건수가 증가하는지, 감소하는지를 확인하여 청약 신청에 도움을 드려요.</p>
                <button type="button" onclick="popupClose('alert04','mw',0);" class="close"><i>닫기</i></button>
            </div>
        </div>
      </div>`);

    popupOpen('alert04', 'mwf', 0);
  }
}


export const popupCompany = (constructList, buildRank, constComp) => {

  if ($('#mw-company').length > 0) {
    $('#mw-company ol').find('li').each(function () {
      if (buildRank != null && buildRank.rank == $(this).attr('data-rank')) {
        $(this).addClass('point');
      }
      else {
        $(this).removeClass('point');
      }
    });

    popupOpen('company', 'mwf', 0);
    $(window).scrollTop(0);

  } else {
    $('#mw-company').remove();
    $('#pageWrap').append(`<div id="mw-company" class="ly_pop popBtm ly_alert ndim">
    <div class="wrap">        
        <div class="content">
            <button type="button" onclick="popupClose('company','mwf',0);" class="close"><i>닫기</i></button>
            <div class="txt_wrap">
                <strong class="tit">건설협회 시공능력 평가 순위</strong>
                <div class="scroll_y">
                    <ol></ol>
                </div>
            </div>
        </div>
    </div>
</div>`);

    let construtorListHtml = '';
    constructList.map((v, idx) => {
      construtorListHtml += `<li data-rank=${v.rank}>${v.name}</li>`;
    });

    $('#mw-company ol').append(construtorListHtml);

    $('#mw-company ol').find('li').each(function () {
      if (buildRank != null && buildRank.rank == $(this).attr('data-rank')) {
        $(this).addClass('point');
      }
      else {
        $(this).removeClass('point');
      }
    });

    popupOpen('company', 'mwf', 0);
    $(window).scrollTop(0);
  }
}