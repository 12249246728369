import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'; // url 구분 작업

import {ApplyOverlay} from 'routes/overlay/ApplyOverlay';
import {AroundOverlay} from 'routes/overlay/AroundOverlay';

import axios from 'axios';
import {orderBy} from 'lodash';
import $ from 'jquery';
import {
    popupClose,
    popupOpen,
    uiChartTap,
    uiFilterTabSwipe,
    uiFixValue,
    uiInputDel,
    uiIpFocus,
    uiMainSwitch,
    uiSelFocus,
    uiToggle,
    uiTopGo
} from 'routes/common/Design.js'

import {getLcParamItem, getLcUrlItem, setLcParamItem, setLcUrlItem} from 'routes/common/ParamStorage';

let map = {};
let overlayList = [];
let aroundOverlayList = [];

//// 컴포넌트 사용처 : 청약 비용 계산기
const ApplyPayDtl = (props) => {

  const [applyId, setApplyId] = useState('');
  const [applyDtlId, setApplyDtlId] = useState('');
  const [applyDtl, setApplyDtl] = useState([]);

  const [flrNoList, setFlrNoList] = useState([]);
  const [hsTypeList, setHsTypeList] = useState([]);
  const [flrNo, setFlrNo] = useState('');
  const [hsType, setHsType] = useState('');
  const [balconyYn, setBalconyYn] = useState(false);
  const [optYn, setOptYn] = useState(false);
  const [optAmt, setOptAmt] = useState(0);

  const lcUrlMap = getLcUrlItem();
  const lcParamMap = getLcParamItem();

  // url 구분 작업
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    uiFixValue.init();
    uiFilterTabSwipe.init();
    uiSelFocus.init();
    uiMainSwitch.init();
    uiChartTap.init();
    uiToggle.init();
    uiTopGo.init();

    uiIpFocus.init();
    uiInputDel.init();

    $(window).scrollTop(0);

    //uiAccoScrollFix.init();

    // input val change > input display:block
    $('.rdo_question input').change(function () {
      var _this = $(this),
        _next = _this.parents('.rdo_question').next('.rdo_answer');
      if ($(this).hasClass('ip_yes')) {
        _next.slideDown(300);
        $("html,body").animate({ scrollTop: _next.offset().top }, 200);
      } else {
        _next.slideUp(300);
      }
    });



    window.goback = backToApplyPayList;
    window.checkMainPage = () => { return false; }

    return () => {
      window.checkMainPage = () => { return true; }
    }
  }, []);

  useEffect(() => {
    setApplyDtl([]);
    clearOverlay();
    clearAroundOverlay();

    let lat = 37.57100;
    let lng = 126.97696;

    let mapContainer = document.getElementById('mapviewDtl'); // 지도를 표시할 div 

    let mapOption = {
      center: new kakao.maps.LatLng(lat, lng), // eslint-disable-line
      level: 5
    };

    map = new kakao.maps.Map(mapContainer, mapOption);  // eslint-disable-line
    map.setMinLevel(2);
    map.setMaxLevel(11);

    kakao.maps.event.addListener(map, 'dragend', () => { }); // eslint-disable-line
    kakao.maps.event.addListener(map, 'zoom_changed', () => { });  // eslint-disable-line

    //if (aptDtl != null && aptDtl.applyId != '') {

    //map.relayout();
    //onPanTo(aptDtl.lat, aptDtl.lng);
    //}

    //setTimeout(() => {
    fetchApplyDtl(lcParamMap.applyId);
    //}, 100);

  }, [lcParamMap.applyId]);

  const onPanTo = (paramLat, paramLng) => {
    let moveLatLng = new kakao.maps.LatLng(paramLat, paramLng); // eslint-disable-line
    map.panTo(moveLatLng);
  }



  const fetchApplyDtl = (paramApplyId) => {
    axios
      .get(`apt/apply/dtl?applyId=${paramApplyId}`)
      .then(res => {
        setApplyDtl(res.data.response.applyDtl);
        fetchHsTypeList(res.data.response.applyDtl);

        renderMarker(res.data.response.applyDtl);
        onPanTo(res.data.response.applyDtl.lat, res.data.response.applyDtl.lng);
        renderingAroundDataIcons(res.data.response.applyDtl);
      });
  }

  const fetchHsTypeList = (detail) => {
    const promise = axios.get('/apt/apply/applyPayHsTypeList?applyId=' + detail.applyId)
      .then(res => {
        //this.setState({ applyId: detail.applyId, keyword: detail.aptName, hsTypeList: res.data.response, step: 2 });
        setApplyId(detail.applyId);
        setHsTypeList(res.data.response);
        //uiSelFocusHsType.init();
      });

    promise.then(() => {
      bindSelect01();
    })
  }

  const onChangeFlrNo = (selectedFlrNo) => {
    //this.setState({ flrNo: selectedFlrNo });
    let flrNoArr = selectedFlrNo.split('_');
    setFlrNo(flrNoArr[0]);
    setApplyDtlId(flrNoArr[1]);
  }

  const onChangeHsType = (selectedHsType) => {
    setHsType(selectedHsType);
    setFlrNo('');
    setFlrNoList([]);
    //층수 선택 div 텍스트초기화
    $('.btn_sel_wrap').eq(1).find('.lbl').removeClass('hide');
    $('.btn_sel_wrap').eq(1).find('.sel_txt').text('');

    const promise2 = axios.get('/apt/apply/applyPayFlrNoList?applyId=' + lcParamMap.applyId + '&hsType=' + selectedHsType)
      .then(res => {
        //this.setState({ hsType: selectedHsType, flrNoList: res.data.response, flrNo: '', });

        setFlrNoList(res.data.response);
        //uiSelFocusFlrNo.init();
      });

    promise2.then(() => {
      bindSelect02();
    })
  }

  const onChangeOptAmt = (e) => {

    const max = e.target.getAttribute('maxLength');
    let value = e.target.value;

    if (value.length > max) value = value.slice(0, max);
    e.target.value = value.replace(/[^0-9]/g, '').replace(/(^0+)/, "");

    setOptAmt(value);
  }


  const clearOverlay = () => {
    overlayList.forEach(ov => ov.setMap(null));
    overlayList = [];
  }

  const clearAroundOverlay = () => {
    aroundOverlayList.forEach(ov => ov.setMap(null));
    aroundOverlayList = [];
  }

  const renderMarker = (data) => {
    let overlay = new ApplyOverlay(data);
    overlay.setMap(map);
  }

  const renderingAroundDataIcons = (data) => {
    aroundOverlayList.forEach(ov => ov.setMap(null));
    aroundOverlayList = [];

    const dtl = data;

    dtl.schoolList.forEach(v => {
      v.type = 'school';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.departList.forEach(v => {
      v.type = 'ico_mart';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.stationList.forEach(v => {
      v.type = 'ico_subway';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.hospitalList.forEach(v => {
      v.type = 'ico_hospital';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });

    dtl.parkList.forEach(v => {
      v.type = 'ico_park02';
      let overlay = new AroundOverlay(v);
      overlay.setMap(map);
      aroundOverlayList.push(overlay);
    });
  }

  const popupWording = () => {
    if (flrNo === '' && hsType === '' && balconyYn === null && optYn === null) {
      alert('아파트 비용 계산을 위해, 정보를 입력해주세요.');
      return;
    }
    else if (flrNo === '' && hsType === '' && balconyYn != null && optYn != null) {
      alert('아파트 유형 및 층수를 선택해주세요.');
      return;
    }
    else if (flrNo === '' && hsType != '' && balconyYn != null && optYn != null) {
      alert('아파트 층수를 선택해주세요.');
      return;
    }
    else if (flrNo != '' && hsType === '' && balconyYn != null && optYn != null) {
      alert('아파트 유형을 선택해주세요.');
      return;
    }
    else if (flrNo != '' && hsType != '' && balconyYn === null && optYn != null) {
      alert('발코니 확장여부를 선택해주세요.');
      return;
    }
    else if (flrNo != '' && hsType != '' && balconyYn != null && optYn === null) {
      alert('유상옵션 금액을 선택해주세요.');
      return;
    }
    else if (flrNo === '' && hsType === '' && balconyYn === null && optYn != null) {
      alert('아파트 유형 및 층수와 발코니 확장여부를 선택해주세요.');
      return;
    }
    else if (flrNo != '' && hsType != '' && balconyYn === null && optYn === null) {
      alert('발코니 확장여부와 유상옵션 금액을 선택해주세요.');
      return;
    }
    else if (flrNo === '' && hsType === '' && balconyYn != null && optYn === null) {
      alert('유형 및 층수와 유상옵션 금액을 선택해주세요.');
      return;
    }
    else if (optYn === true && optAmt === 0) {
      alert('유상옵션 금액을 입력해주세요.');
      return;
    }
    else {
      return true;
    }
  }

  const bindSelect01 = () => {
    $(".btn_sel_wrap").each(function (idx) {
      $(this).data("key", idx + 1);
      // btm select popup 호출
      $(this).find(".btn_sel").on("click", function () {
        var key = $(this).parents(".btn_sel_wrap").data("key");
        popupOpen('select0' + key, 'mwf', 0);
      });
    });

    $(".ly_select_wrap").each(function (idx) {
      $(this).data("ctrlKey", idx + 1);
      // btm select 값 선택// btm select 값 선택
      $(this).find(".pop_sel_wrap .list input").on("change", function () {
        var ctrlKey = $(this).parents(".ly_select_wrap").data("ctrlKey");
        var selTxt = $(this).parents(".item").find(".txt").text();

        $(".btn_sel_wrap").eq(ctrlKey - 1).removeClass("focus").find(".lbl").addClass("hide");
        $(".btn_sel_wrap").eq(ctrlKey - 1).find(".sel_txt").text(selTxt);

        //select value 찾도록 추가
        if (ctrlKey === 1) {
          var selVal = $(this).parents(".item").find(".txt").attr('data-value');
          onChangeHsType(selVal);
        }

        popupClose('select0' + ctrlKey, 'mwf', 0);
      });
    });
  }

  const bindSelect02 = () => {
    $(".btn_sel_wrap").each(function (idx) {
      $(this).data("key", idx + 1);
      // btm select popup 호출
      $(this).find(".btn_sel").on("click", function () {
        var key = $(this).parents(".btn_sel_wrap").data("key");
        popupOpen('select0' + key, 'mwf', 0);
      });
    });

    $(".ly_select_wrap").each(function (idx) {
      $(this).data("ctrlKey", idx + 1);
      // btm select 값 선택// btm select 값 선택
      $(this).find(".pop_sel_wrap .list input").on("change", function () {
        var ctrlKey = $(this).parents(".ly_select_wrap").data("ctrlKey");
        var selTxt = $(this).parents(".item").find(".txt").text();

        $(".btn_sel_wrap").eq(ctrlKey - 1).removeClass("focus").find(".lbl").addClass("hide");
        $(".btn_sel_wrap").eq(ctrlKey - 1).find(".sel_txt").text(selTxt);

        //select value 찾도록 추가
        if (ctrlKey === 2) {
          var selVal = $(this).parents(".item").find(".txt").attr('data-value');
          onChangeFlrNo(selVal);
        }

        popupClose('select0' + ctrlKey, 'mwf', 0);
      });
    });
  }


  //--'open' : 현 화면이 다음 화면으로 변경되는 action
  const openApplyPayCalc = () => {
    if (popupWording()) {
      setLcUrlItem('applyPayDtl', 'applyPayCalc');

      let applyPayParams = {
        "applyDtl": applyDtl,
        "applyDtlId": applyDtlId,
        "flrNo": flrNo,
        "hsType": hsType,
        "balconyYn": balconyYn,
        "optYn": optYn,
        "optAmt": optAmt
      };

      setLcParamItem({ 'applyId': lcParamMap.applyId, 'applyPayParams': applyPayParams });
      //props.onApplyPayParams(applyPayParams);
      props.onApplyPopupType('P_D');

      // url 구분 작업
      history.push({
        pathname: location.pathname,
        search: `?CAL-1300#`
      });
    }
  }


  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  const backToApplyList = () => {
    setLcUrlItem('applyPayDtl', 'applyList');
    setTimeout(() => {
      $('.bg_body_full').remove();
      uiFixValue.init();
    }, 100);
    props.onApplyMenuType('A');
    props.onApplyPopupType('');

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?APY-1000#`
    });
  }

  const backToApplyPayList = () => {
    setLcUrlItem('applyPayDtl', 'applyPayList');
    props.onApplyPopupType('');
    props.onApplyMenuType('D');

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?CAL-1000#`
    });
  }

  return (
    <>
      <div id="mw-costfilter" className="ly_apt_cost_filter full">
        <div className="wrap">
          <div className="content">
            <header className="header">
              <button type="button" onClick={() => backToApplyList()} className="btn_home" title="홈으로 바로가기"><i></i></button>
              <button type="button" onClick={() => backToApplyPayList()} className="btn_prev" title="뒤로가기"><i></i></button>
              <strong className="tit">비용 계산기</strong>
            </header>
            <div className="ly_content">
              <section className="view_tit_wrap">
                <strong className="tit">{applyDtl.aptName}</strong>
                <div className="sub_txt">
                  <span>{applyDtl.address}</span>
                  {/* <span>{applyDtl.maxHsType} / {applyDtl.maxHsTypePy}평({applyDtl.maxHsArea}㎡)</span> */}
                </div>
              </section>
              <section className="map_sec" id="mapviewDtl">
                {/* <img src="resources/images/temp/map.png" alt="" /> */}
              </section>
              <section className="filter_wrap">
                <div className="item">
                  <strong className="tit">유형 및 층수</strong>
                  <div className="form_wrap col_wrap">
                    <div className="form">
                      <div className="ip_box btn_sel_wrap">
                        <div className="inner_wrap">
                          <button type="button" className="btn_sel">
                            <span className="lbl">유형</span>
                            <span className="sel_txt"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form">
                      <div className="ip_box btn_sel_wrap">
                        <div className="inner_wrap">
                          <button type="button" className="btn_sel">
                            <span className="lbl">층수</span>
                            <span className="sel_txt"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="button" className="btn_tip" onClick={() => popupOpen('alert01', 'mwf', 0)}>유형 및 층수란?</button>
                </div>
                <div className="item">
                  <strong className="tit">발코니 확장여부</strong>
                  <div className="form_wrap">
                    <div className="rdo_wrap">
                      <div className="rdo_item">
                        <input className="ip_yes" type="radio" id="rdo01-02" name="rdo01"
                          checked={balconyYn}
                          onClick={() => setBalconyYn(true)} />
                        <label for="rdo01-02"><i>확장</i></label>
                      </div>
                      <div className="rdo_item">
                        <input type="radio" id="rdo01-01" name="rdo01"
                          checked={!balconyYn}
                          onClick={() => setBalconyYn(false)} />
                        <label for="rdo01-01"><i>비확장</i></label>
                      </div>
                    </div>
                  </div>
                  <button type="button" className="btn_tip" onClick={() => popupOpen('alert02', 'mwf', 0)}>발코니 확장여부란?</button>
                </div>
                <div className="item">
                  <strong className="tit">유상옵션 금액</strong>
                  <div className="form_wrap">
                    <div className="rdo_wrap rdo_question">
                      <div className="rdo_item">
                        <input className="ip_yes" type="radio" id="rdo02-02" name="rdo02" value="option_o"
                          checked={optYn}
                          onClick={() => setOptYn(true)} />
                        <label for="rdo02-02" className="option_o"><i>선택</i></label>
                      </div>
                      <div className="rdo_item">
                        <input type="radio" id="rdo02-01" name="rdo02" value="option_x"
                          checked={!optYn}
                          onClick={() => setOptYn(false)} />
                        <label for="rdo02-01" className="option_x"><i>미선택</i></label>
                      </div>
                    </div>
                    <div className="form rdo_answer">
                      <div className="ip_box ip_del">
                        <label for="ip02" className="lbl">금액을 입력하세요.</label>
                        <input type="number" id="ip02" className="ip code" maxLength="8"
                          onChange={onChangeOptAmt} />
                        <button type="button" onClick="" className="del"><i>삭제</i></button>
                        <em className="txt">만원</em>
                      </div>
                    </div>
                  </div>
                  <button type="button" className="btn_tip" onClick={() => popupOpen('alert03', 'mwf', 0)}>유상옵션 금액이란?</button>
                </div>
              </section>
            </div>
            <div className="btn_area_wide fix btm">
              <button type="button" className="skip" onClick={() => openApplyPayCalc()}>결과보기</button>
            </div>
          </div>
        </div>
      </div>
      <div id="mw-alert01" className="ly_pop popBtm ly_alert_b">
        <div className="wrap">
          <div className="content">
            <strong className="tit">유형 및 층수?</strong>
            <p className="txt">아파트 분양가는 같은 평수라도 동, 층수에 따라 분양가 차이가 발생할 수 있어요. 궁금하신 동 및 층수와 주택 유형을 선택해주세요</p>
            <button type="button" onClick={() => popupClose('alert01', 'mwf', 0)} className="close"><i>닫기</i></button>
          </div>
        </div>
      </div>
      <div id="mw-alert02" className="ly_pop popBtm ly_alert_b">
        <div className="wrap">
          <div className="content">
            <strong className="tit">발코니 확장여부?</strong>
            <p className="txt">분양가 이외에 발코니 확장 시 추가 비용이 발생해요. 비확장을 선택하셔도 분양가가 낮아지진 않아요.</p>
            <button type="button" onClick={() => popupClose('alert02', 'mwf', 0)} className="close"><i>닫기</i></button>
          </div>
        </div>
      </div>
      <div id="mw-alert03" className="ly_pop popBtm ly_alert_b">
        <div className="wrap">
          <div className="content">
            <strong className="tit">유상옵션 금액?</strong>
            <p className="txt">청약 아파트도 자동차 옵션 처럼 다양한 옵션이 존재해요.<br />예상되는 유상옵션 비용을 직접 입력해주세요.</p>
            <button type="button" onClick={() => popupClose('alert03', 'mwf', 0)} className="close"><i>닫기</i></button>
          </div>
        </div>
      </div>

      <div id="mw-select01" className="ly_pop popBtm ly_select_wrap">
        <div className="wrap">
          <button type="button" onClick={() => popupClose('select01', 'mwf', 0)} className="close"><i>닫기</i></button>
          <div className="content pop_sel_wrap">
            <ul className="list">
              {
                hsTypeList && orderBy(hsTypeList, ["hsType"], ["desc"]).map((v, idx) => {
                  const radioId = `select01_${idx}`
                  return (
                    <li>
                      <span className="item">
                        <input type="radio" id={radioId} name="select01" />
                        <label for={radioId}>
                          <span className="txt" data-value={v.hsType}>{v.hsType}</span>
                        </label>
                      </span>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>

      <div id="mw-select02" className="ly_pop popBtm ly_select_wrap">
        <div className="wrap">
          <button type="button" onClick={() => popupClose('select02', 'mwf', 0)} className="close"><i>닫기</i></button>
          <div className="content pop_sel_wrap">
            <ul className="list">
              {
                flrNoList && orderBy(flrNoList, ["flrNo"], ["desc"]).map((v, idx) => {
                  const radioId = `select02_${idx}`
                  return (
                    <li>
                      <span className="item">
                        <input type="radio" id={radioId} name="select02" />
                        <label for={radioId}>
                          <span className="txt" data-value={v.flrNo + `_` + v.applyDtlId}>{v.flrNo}</span>
                        </label>
                      </span>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>



    </>
  )
}
export default ApplyPayDtl;