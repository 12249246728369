
import { uiFixValue, uiFilterTabSwipe, popupOpen, popupClose, uiIpFocus, uiInputDel, uiAccoScrollFix, uiSelFocus, uiMainSwitch, uiChartTap, uiToggle, uiTopGo } from 'routes/common/Design.js'
import { callAppInterface } from 'routes/common/AppInterface';

const SubFooter = () => {

  const companyClickPop = () => {
    const agreeUrl = process.env.REACT_APP_ENV_URL + 'resources/global/terms/company.html';
    callAppInterface('openWebPage', JSON.stringify({ type: 'I', url: agreeUrl }), result => {

    });
  }

  return (
    <footer className="sub_footer">
      <div className="inner_wrap">
        <button type="button" className="tit" onClick={companyClickPop}><u>(주) 헥토이노베이션 사업자 정보</u></button>
        <p className="txt">본 서비스는 가입 즉시 서비스가 개시되는 상품으로<br />
          청약철회가 불가능합니다.<br />
          (전자상거래 등에서 소비자보호에 관한 법률 제17조 2항 5호에 따라
                  디지털 콘텐츠의 제공이 개시 된 경우 청약철회를 제공하지 않습니다.)<br />
          서비스 이용/가입/해지문의 : (주) 헥토이노베이션 고객센터 1599-2985</p>
      </div>
    </footer>
  );
}

export default SubFooter;