import React, {useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'; // url 구분 작업
import {useStateWithCallbackLazy} from 'use-state-with-callback';

import axios from 'axios';
import $ from 'jquery';
import {
    dateFormatMsg2,
    dateFormatMsgYYYY,
    numberReplaceWithCodetag,
    numberWithCommas,
    numberWithCounts
} from 'routes/common/StringUtil';
import {
    popupClose,
    uiChartTap,
    uiFilterTabSwipe,
    uiFixValue,
    uiInputDel,
    uiIpFocus,
    uiMainSwitch,
    uiSelFocus,
    uiToggle,
    uiTopGo
} from 'routes/common/Design.js'
import {orderBy} from 'lodash';

import {getLcParamItem, getLcUrlItem, setLcParamItem, setLcUrlItem} from 'routes/common/ParamStorage';

//// 컴포넌트 사용처 : 아파트 추천
const RcmdList = (props) => {
  const [aptRecommResultList, setAptRecommResultList] = useStateWithCallbackLazy([]);

  const lcUrlMap = getLcUrlItem();
  const lcParamMap = getLcParamItem();

  // url 구분 작업
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    uiFixValue.init();
    uiFilterTabSwipe.init();
    uiSelFocus.init();
    uiMainSwitch.init();
    uiChartTap.init();
    uiToggle.init();
    uiTopGo.init();

    uiIpFocus.init();
    uiInputDel.init();

    $(window).scrollTop(0);

    fetchResultList();

    window.goback = backToRcmdSrch;
    window.checkMainPage = () => { return false; }

    return () => {
      window.checkMainPage = () => { return true; }
    };

  }, []);




  const fetchResultList = () => {
    const param1 = lcParamMap.rcmdParams.param1;
    const param2 = lcParamMap.rcmdParams.param2;
    const param3Min = lcParamMap.rcmdParams.param3Min;
    const param3Max = lcParamMap.rcmdParams.param3Max;
    const param4Min = lcParamMap.rcmdParams.param4Min;
    const param4Max = lcParamMap.rcmdParams.param4Max;
    const param5 = lcParamMap.rcmdParams.param5;
    const param6 = lcParamMap.rcmdParams.param6;
    const param7 = lcParamMap.rcmdParams.param7;

    setAptRecommResultList([]);

    axios
      .get(`apt/rcmd/aptList?&param1=${param1}&param2=${param2}&param3Min=${param3Min}&param3Max=${param3Max}&param4Min=${param4Min}&param4Max=${param4Max}&param5=${param5}&param6=${param6}&param7=${param7}`)
      .then(res => {

        if (res.data.response != null && res.data.response.aptList.length > 0) {
          setAptRecommResultList(res.data.response.aptList);
        }
        else {
          alert('검색 결과가 없습니다.');
          return false;
        }
      });
  }


  //--'open' : 현 화면이 다음 화면으로 변경되는 action
  const openRcmdDtlMap = () => {
    setLcUrlItem('rcmdList', 'rcmdDtlMap');
    setLcParamItem({ 'rcmdParams': lcParamMap.rcmdParams, 'aptList': aptRecommResultList });
    props.onRcmdPopupType('P_C');
  }


  const openRcmdDtl = (aptId, aptAreaId) => {
    setLcUrlItem('rcmdList', 'rcmdDtl');
    setLcParamItem({ 'rcmdParams': lcParamMap.rcmdParams, 'aptId': aptId, 'aptAreaId': aptAreaId });
    props.onRcmdPopupType('P_B');

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?REC-${aptAreaId}#`
    });
  }


  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  const backToRcmdSrch = () => {
    setLcUrlItem('rcmdList', 'rcmdSrch');
    props.onRcmdPopupType('');

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: `?REC-1000#`
    });
  }

  const backToAptMain = () => {
    setLcUrlItem('rcmdList', 'aptMain');
    popupClose('map', 'mwf');
    props.onRcmdPopupType('');
    setLcParamItem({});
    $('.map_wrap').show();
    props.onRcmdSrchDisplay(false);

    // url 구분 작업
    history.push({
      pathname: location.pathname,
      search: ``
    });
  }

  return (
    <>
      <div id="mw-recommend-list" className="ly_apt_list full">
        <div className="wrap">
          <div className="content">
            <header className="header">
              <button type="button" onClick={() => backToAptMain()} className="btn_home" title="홈으로 바로가기"><i></i></button>
              <button type="button" onClick={() => backToRcmdSrch()} className="btn_prev GTM-REC-PREV-2" title="뒤로가기"><i></i></button>
              <strong className="tit">아파트추천</strong>
              <button type="button" onClick={() => openRcmdDtlMap()} className="btn_location" title="지도보기"><i></i></button>
            </header>
            <div className="ly_content">
              <div className="apt_list_wrap">
                <div className="list_count">
                  <strong className="tit">추천결과</strong>
                  <span className="count"><code>{aptRecommResultList.length}</code>건</span>
                </div>
                <ul>
                  {
                    aptRecommResultList.length <= 0 ?
                      null
                      :
                      orderBy(aptRecommResultList, ["orderdDpsAmt"], ["desc"]).map((result, idx) => {
                        return (
                          <React.Fragment key={idx}>
                            <li className="item">
                              <button type="button" className="btn_bookmark">즐겨찾기 추가</button>
                              <a href="#" className="inner_wrap" onClick={() => openRcmdDtl(result.aptId, result.aptAreaId)}>
                                <div className="tit_wrap">
                                  <strong>{result.aptName}</strong>
                                  <span>{result.jibunAddr}</span>
                                </div>
                                <p className="price">{numberReplaceWithCodetag(numberWithCounts(result.dpsAmt * 10000))}</p>
                                <ul className="info">
                                  <li>{dateFormatMsg2(result.dpsAmtTrxDate)}거래</li>
                                  <li>{dateFormatMsgYYYY(result.regYearMonth)}년</li>
                                  <li>{numberWithCommas(result.hhldCnt)}세대</li>
                                  {
                                    (result.loanRateCase == 1 || result.loanRateCase == 2) ? <li>투기 과열</li> :
                                      (result.loanRateCase == 3 || result.loanRateCase == 4) ? <li>조정 지역</li> : null
                                  }
                                </ul>
                              </a>
                            </li>
                          </React.Fragment>
                        )
                      })
                  }

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RcmdList;