import React, {useEffect, useRef} from 'react';
import {useStateWithCallbackLazy} from 'use-state-with-callback';

import {dateDiffMsg, dateFormatMsg, numberWithCommas} from 'routes/common/StringUtil';

import Swiper from 'swiper/bundle';

import axiosRequest from 'routes/common/axios/services/AxiosRequest';

const ApplyInfoList = (props) => {
    const [list, setList] = useStateWithCallbackLazy([]);

    const mapBottom = useRef(null);

    const payload = {
      'trxMin': (props.trxMin || -1),
      'trxMax': (props.trxMax || 9999),
      'pyMin': (props.pyMin || -1),
      'pyMax': (props.pyMax || 9999),
      'hhldCntMin': (props.hhldMin || -1),
      'hhldCntMax': (props.hhldMax || 9999),
      'status': (props.status || '1'),
      'locals': ((props.locals) ? ((props.locals instanceof Array) ? props.locals.join(',') : props.locals) : ''),
      'aptName': (props.keyword || ''),
    };

    useEffect(async () => {
      try {
        const { data } = await axiosRequest.findApplyList({ config: { params: payload } });
        
        if (data.response.applyList.length > 0) { 
          const tmpList = data.response.applyList.slice(0, 12);
          
          setList(tmpList.division(3));

          const map_bottom_swiper = mapBottom.current.querySelectorAll('.map_bottom_swiper');
          
          map_bottom_swiper.forEach(function (el) {
            const swipers = new Swiper(el, {
              spaceBetween: 8,
              pagination: {
                el: '.swiper-pagination'
              },
            });
            
            mapBottom.current.querySelectorAll('[data-tab-target]').forEach(function (el) {
              el.addEventListener('tab.showing', function () {
                swipers.update();
              });
            });
          });
        }
      } catch (err) {
        console.log(err);
      }
    }, []);

    return (
        <>
            {/* 최근 청약정보 */}
            <section className="map_section" data-scroll-section="section1" ref={mapBottom}>
                <h3 className="content_title"><img src={process.env.REACT_APP_ENV_URL + "resources/images/icon_sub_info.png"} alt="정보" aria-hidden="true" />최근 청약 정보</h3>
                <div className="nav_tab" data-tab="group1">
                  {/* 2022.08.05 - GTM CLASS 추가 */}
                  <button type="button" className="on GTM-MAIN-APPLY-TAB-1" data-tab-trigger="#tab1">민간청약</button>
                  {/*<button type="button" className="GTM-MAIN-APPLY-TAB-2" data-tab-trigger="#tab2">공공청약</button>*/}
                  {/*<button type="button" className="GTM-MAIN-APPLY-TAB-3" data-tab-trigger="#tab3">재개발/재건축</button>*/}
                  {/*<button type="button" className="GTM-MAIN-APPLY-TAB-4" data-tab-trigger="#tab4">공정률</button>*/}
                </div>
                <div id="tab1" data-tab-target="group1" className="shown">
                  {
                    list.length ? 
                    <div className="swiper-container map_bottom_swiper">
                      <ul className="swiper-wrapper">
                        {list.map(dataDep1 => {
                          return (
                            <li className="swiper-slide">
                              <ul className="card_list">
                                {dataDep1.map(dataDep2 => {
                                  return (
                                    <li onClick={() => props.openApplyDtl(dataDep2.applyId)}>
                                      <strong>{dataDep2.aptName}</strong>
                                      <p>{dataDep2.address.ellipsis(35)}</p>
                                      <ul className="text_pipe">
                                        <li className="co_blue_green fw_bold">
                                        {
                                            dataDep2.notiYn === 'N' ?
                                            <>청약예정</>
                                            :
                                            dateDiffMsg(dataDep2.notiDate) != '완료' ? <>모집공고 {dateDiffMsg(dataDep2.notiDate)}</> :
                                            dataDep2.spLocalDate != '' && dateDiffMsg(dataDep2.spLocalDate) != '완료' ? <>특별공급 {dateDiffMsg(dataDep2.spLocalDate)}</> :
                                                dateDiffMsg(dataDep2.fstLocalDate) != '완료' ? <>일반공급 {dateDiffMsg(dataDep2.fstLocalDate)}</> :
                                                  dateDiffMsg(dataDep2.pbshDate) != '완료' ? <>당첨자발표 {dateDiffMsg(dataDep2.pbshDate)}</> : <>완료</>
                                        }
                                        </li>
                                        <li>{dateFormatMsg(dataDep2.notiDate)}</li>
                                        <li>{numberWithCommas(dataDep2.applyCnt)}세대</li>
                                      </ul>
                                      {/* <Link to="/" className="link_ab"><span className="blind">@{dataDep2.aptName} 상세보기</span></Link> */}
                                    </li>
                                  )
                                })}
                              </ul>
                            </li>    
                          )
                        })}
                      </ul>
                      <div className="swiper-pagination"></div>
                    </div>
                     :
                    <div className="info_box">
                      <p>주택청약 공고가 없습니다.</p>
                    </div>
                  }
                </div>
                <div id="tab2" data-tab-target="group1">
                  <div className="info_box">
                    <p>서비스를 준비중 입니다.</p>
                  </div>
                </div>
                <div id="tab3" data-tab-target="group1">
                  <div className="info_box">
                    <p>서비스를 준비중 입니다.</p>
                  </div>
                </div>
                <div id="tab4" data-tab-target="group1">
                  <div className="info_box">
                    <p>서비스를 준비중 입니다.</p>
                  </div>
                </div>
            </section>
        </>
    );
};

export default ApplyInfoList;