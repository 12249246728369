import React, { useEffect, useState } from 'react'
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import MainFilter from 'routes/apply/filter/MainFilter';
import LocalsFilter from 'routes/apply/filter/LocalFilter';
import HhldFilter from 'routes/apply/filter/HhldFilter';
import PyFilter from 'routes/apply/filter/PyFilter';
import TrxAmtFilter from 'routes/apply/filter/TrxAmtFilter';

import { AptOverlay } from 'routes/overlay/AptOverlay.js';
import { BjdOverlay } from 'routes/overlay/BjdOverlay.js';

import axios from 'axios';
import $ from 'jquery';

import { searchApply, addRecentItem, getRecentItem, clearRecentItem, setRecentList, addLsItem, getLsItem } from 'routes/common/Search';
import { dateDiffMsg, numberWithCounts, numberWithCommas, numberWithCommas2, dateFormatMsg, dateFormatMsg2, numberReplaceWithStag, textReplaceWithStag, numberReplaceWithCodetag } from 'routes/common/StringUtil';
import { uiFixValue, uiFilterTabSwipe, popupOpen, popupClose, uiIpFocus, uiInputDel, uiAccoScrollFix, uiSelFocus, uiMainSwitch, uiChartTap, uiToggle, uiTopGo } from 'routes/common/Design.js'
import { findIndex } from 'routes/common/ArrayUtil';
import _ from 'lodash';

import RecentResult from 'routes/common/RecentResult';
import SearchResult from 'routes/common/SearchResult';
import Moment from 'moment';
import RealtyPolicy from './RealtyPolicy';

//// 컴포넌트 사용처 : 부동산계산기
const RealtyCalc = (props) => {

  useEffect(() => {
    function receiveMessage(event) {
      if (event.origin.indexOf("mportal.r114.co.kr") < 0) {
        return;
      }
      if (isNaN(event.data)) {
        return;
      }
      document.getElementById('iframe_con').style.height = String(parseInt(event.data)) + 'px';
      window.scrollTo(0, 0);
    }

    $(document).ready(function () {
      if ('addEventListener' in window) {
        window.addEventListener('message', receiveMessage, false);
      } else if ('attachEvent' in window) {
        window.attachEvent('onmessage', receiveMessage);
      }
    });

    $(window).scrollTop(0);

  }, []);

  return (
    <>
      <div className="ly_content">
        <div className="iframe_wrap">
          <iframe src="https://mportal.r114.com/?_c=calculator&only=7004" title="부동산 계산기" id="iframe_con" frameborder="0" scrolling="no"
            style={{
              "width": "100%", "min-height": "300px", "height": //"478px" 
                "980px"
            }}
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default RealtyCalc;