import React, {useEffect} from "react";
import {popupClose} from "../../routes/common/Design";
import {callAppInterface} from "../../routes/common/AppInterface";

export const KtMvno = ({callCheckPhone, agreeClickPop}) => {

    const openPopup = () => {
        const agreeUrl = `${process.env.REACT_APP_APT_WEB_ORIGIN}/apt/terms?type=5&loc=N&telcoCode=2`;
        callAppInterface('openWebPage', JSON.stringify({ type: 'I', url: agreeUrl }), result => {

        });
    };
    const agree = () => {
        const mvnoChk = document.getElementById('mvnoChk');
        if (!mvnoChk.checked && !window.confirm('동의가 필요합니다. 동의하시겠습니까?')) {
            return;
        }

        window.popupClose('alert', 'mwf');

        callCheckPhone(1,'N'); // callCheckPhone 메서드 호출
      };

    return (
        <>
            {/* KT MVNO 동의 팝업 */}
             <div id="mw-alert" className="ly_pop popBtm ly_alert ndim">
               <div className="wrap">
                 <div className="content">
                   <div className="txt_wrap">
                     <strong className="tit">안내</strong>
                     <p className="sTxt">KT 알뜰폰 사용고객님들은 추가로<br/>
                       동의가 필요합니다.</p>
                     <span className="checkbox i_all" style={{marginTop: '20px'}}>
                       <input type="checkbox" name="mvnoChk" id="mvnoChk" value=""/>
                         <label htmlFor="mvnoChk"><i></i><a href="#" onClick={openPopup} style={{fontSize:'14px',fontWeight:'normal',textDecoration:'underline',color:'#282a2d'}}>KT MVNO 제3자제공</a></label>
                     </span>
                   </div><button type="button" onClick={()=> popupClose('alert','mwf')} className="close"><i>닫기</i></button>
                 </div>
                 <ul className="btn_area_half">
                   <li><button type="button" className="skip" onClick={agree}>동의 후 인증번호 받기</button></li>
                 </ul>
               </div>
             </div>
        </>
    )
}