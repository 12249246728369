import React, { useEffect, useState } from 'react'
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Viewer } from "@toast-ui/react-editor";

import SubFooter from 'routes/SubFooter';

import MainFilter from 'routes/apply/filter/MainFilter';
import LocalsFilter from 'routes/apply/filter/LocalFilter';
import HhldFilter from 'routes/apply/filter/HhldFilter';
import PyFilter from 'routes/apply/filter/PyFilter';
import TrxAmtFilter from 'routes/apply/filter/TrxAmtFilter';

import { AptOverlay } from 'routes/overlay/AptOverlay.js';
import { BjdOverlay } from 'routes/overlay/BjdOverlay.js';

import axios from 'axios';
import $ from 'jquery';

import { searchApply, addRecentItem, getRecentItem, clearRecentItem, setRecentList, addLsItem, getLsItem } from 'routes/common/Search';
import { dateDiffMsg, numberWithCounts, numberWithCommas, numberWithCommas2, dateFormatMsg, dateFormatMsg2, numberReplaceWithStag, textReplaceWithStag, numberReplaceWithCodetag } from 'routes/common/StringUtil';
import { uiFixValue, uiFilterTabSwipe, popupOpen, popupClose, uiIpFocus, uiInputDel, uiAccoScrollFix, uiSelFocus, uiMainSwitch, uiChartTap, uiToggle, uiTopGo } from 'routes/common/Design.js'
import { findIndex } from 'routes/common/ArrayUtil';
import _ from 'lodash';

import RecentResult from 'routes/common/RecentResult';
import SearchResult from 'routes/common/SearchResult';
import Moment from 'moment';
import { callAppInterface } from 'routes/common/AppInterface';

//// 컴포넌트 사용처 : 공지사항
const Notice = (props) => {

  const [noticeList, setNoticeList] = useStateWithCallbackLazy([]);
  const [updateState, setUpdateState] = useStateWithCallbackLazy(false);
  const [limitSize, setLimitSize] = useStateWithCallbackLazy(10);

  useEffect(() => {
    $('.bbs_list p').css('line-height','1.3');
  });

  useEffect(() => {
    const handleScroll = () => {
      if ((window.innerHeight + window.scrollY) >= $('.bbs_list').innerHeight()) {
        setLimitSize(limitSize => limitSize + 10);
        uiToggle.init();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    uiFixValue.init();
    uiFilterTabSwipe.init();
    uiSelFocus.init();
    uiMainSwitch.init();
    uiChartTap.init();
    uiToggle.init();
    uiTopGo.init();

    $(window).scrollTop(0);
    window.goback = backToAptMain;

    fetchNoticeList();

  }, []);

  // notice 
  const fetchNoticeList = (e) => {
    axios
      .get('apt/notice/mblList')
      .then(res => {
        setNoticeList(res.data.response.noticeList);
        uiToggle.init();
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          alert(error.response.data.message);
          return;
        }
      });
  }

  //--'back' : 현 화면이 이전 화면으로 변경되는 action
  //단독페이지들은 location href = / 로 보냄
  const backToAptMain = () => {
    //window.location.href = '/';
    //window.close();
    callAppInterface("closeWebPage", JSON.stringify({ url: '/' }), result => {
      //alert(result);
    });
  }

  return (
    <>
      <div id="mw-calculator" className="ly_apt_sale full">
        <div className="wrap">
          <div className="content">
            <div className="header">
              <header>
                <button type="button" onClick={backToAptMain} className="btn_prev" title="뒤로가기"><i></i></button>
                <strong className="tit">고객센터</strong>
                {/* <button type="button" onClick="popupOpen('srch','mwf');" className="btn_srch" title="검색하기"><i></i></button> */}
              </header>
              <ul className="menu_wrap">

                <li className='active'><a href="/notice">공지사항</a></li>
                <li><a href="/faq">FAQ</a></li>
                <li><a href="/qna">1:1문의</a></li>
                <li><a href="/expire">서비스해지</a></li>
              </ul>
            </div>
            <div className="ly_content">
              {
                noticeList.length <= 0 ?
                  <div className="nodata_wrap">
                    <p className="txt">게시글이 없습니다.</p>
                  </div>
                  :
                  <ul className="bbs_list">
                    {
                      noticeList.map((notice, idx) => {
                        return (
                          idx < limitSize &&
                          <li className="toggle_wrap">
                            <button type="button" className="tit_wrap btn_toggle">
                              <strong className="tit">{notice.title}</strong>
                              <code className="date">{notice.regDate}</code>
                            </button>
                            <div className="view_toggle">
                              {/* <pre dangerouslySetInnerHTML={{ __html: notice.body }}>
                              </pre> */}
                              <Viewer
                                initialValue={notice.body}
                              />
                            </div>
                          </li>
                        )
                      })
                    }
                  </ul>
              }

            </div>
            <SubFooter></SubFooter>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notice;